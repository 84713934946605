.catalog {
   &__section {
      margin: 80px 0;
      @media (max-width: 1441px) {
         margin: 40px 0 60px;
      }
      @media (max-width: 1201px) {
         margin: 30px 0 45px;
      }
      @media (max-width: 769px) {
         margin: 20px 0 30px;
      }
   }
   &__wrap {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      @media (max-width: 769px) {
         gap: 9px;
      }
      &--mobScroll {
         @media (max-width: 769px) {
            flex-wrap: nowrap;
            width: fit-content;
         }
      }
   }
   &__item {
      width: calc(25% - 15px);
      position: relative;
      padding: 18px 27px 22px;
      aspect-ratio: 28/12;
      border-radius: 9px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      @media (max-width: 1441px) {
         padding: 15px 20px 19px;
      }
      @media (max-width: 1025px) {
         width: calc(100% / 3 - 40px / 3);
      }
      @media (max-width: 769px) {
         width: calc(25% - 27px / 4);
         aspect-ratio: initial;
         border-radius: 0;
         padding: 0;
         gap: 6px;
         text-align: center;
      }
      @media (max-width: 501px) {
         width: calc(100% / 3 - 6px);
      }
      &::after {
         content: "";
         display: block;
         width: 100%;
         height: 100%;
         left: 0;
         top: 0;
         background-color: rgba(42, 72, 92, 0);
         transition: all 0.4s;
         position: absolute;
         z-index: 4;
      }
      &:hover {
         &::after {
            background-color: rgba(42, 72, 92, 0.05);
            @media (max-width: 769px) {
               background-color: transparent;
            }
         }
         & .catalog__btn {
            opacity: 1;
         }
      }
      &--mobScroll {
         @media (max-width: 769px) {
            width: 60px;
         }
      }
   }
   &__img {
      display: block;
      width: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      @media (max-width: 769px) {
         position: relative;
         border-radius: 10px;
         overflow: hidden;
      }
   }
   &__name {
      font-weight: 700;
      font-size: 18px;
      line-height: 1.2;
      color: var(--dark-color);
      position: relative;
      z-index: 5;
      max-width: 155px;
      @media (max-width: 1441px) {
         font-size: 16px;
         max-width: 135px;
      }
      @media (max-width: 769px) {
         max-width: 100%;
         padding-left: 3px;
         padding-right: 3px;
         font-size: 10px;
         font-weight: 400;
      }
   }
   &__btn {
      position: relative;
      z-index: 5;
      margin-top: auto;
      opacity: 0;
      padding: 10px 17px;
      font-weight: 400;
      font-size: 10px;
      line-height: 1.2;
      @media (max-width: 769px) {
         display: none;
      }
   }
   &__itemMini {
      display: block;
      text-align: center;
      width: 106px;
      @media (max-width: 769px) {
         width: 60px;
      }
   }
   &__image {
      display: block;
      width: 100%;
      aspect-ratio: 1/1;
      object-fit: cover;
      border-radius: 10px;
      margin-bottom: 6px;
   }
   &__nameMiniCard {
      font-size: 10px;
      line-height: 1.2;
      text-align: center;
      color: var(--dark-color);
      transition: all 0.4s;
      &:hover {
         color: var(--dark-color-hover-link);
      }
   }
}
