.btnStnd {
   display: flex;
   width: fit-content;
   gap: 6px;
   align-items: center;
   justify-content: center;
   background: #ed0000;
   border-radius: 9px;
   cursor: pointer;
   font-weight: 700;
   font-size: 13px;
   line-height: 1.25;
   color: #ffffff;
   border: none;
   padding: 10px 20px;
   transition: all 0.4s;
   &:hover {
      background: #c70000;
   }
   &:disabled {
      opacity: 0.2;
      pointer-events: none;
   }
   &--more {
      padding: 21.5px;
      width: 100%;
      text-align: center;
      background: var(--dark-gray-color3);
      border-radius: 7px;
      display: block;
      transition: all 0.4s;
      font-weight: 400;
      font-size: 14px;
      color: var(--dark-color);
      line-height: 1.2;
      @media (max-width: 769px) {
         padding: 11.5px;
      }
      &:hover {
         background-color: var(--dark-gray-color3-hover-btn);
      }
      &:disabled {
         &:hover {
            background: var(--dark-gray-color3);
         }
      }
   }
   &--catalog {
      padding: 15px 28px 15px 20px;
      font-size: 16px;
      gap: 14px;
      line-height: 1.2;
      @media (max-width: 1201px) {
         font-size: 14px;
         padding: 11px 15px;
      }
   }
   &--searchResult {
      padding: 11.5px;
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.2;
      text-align: center;
      color: var(--dark-color);
      background-color: var(--dark-gray-color3);
      &:hover {
         background-color: var(--dark-gray-color3-hover-btn);
      }
   }
   &--searchResultCard {
      padding: 10px;
      font-size: 24px;
      color: var(--gray-color);
      width: fit-content;
      line-height: 1;
      border-radius: 7px;
      background-color: var(--dark-gray-color3);
      font-weight: 400;
      &:hover {
         background-color: var(--dark-gray-color3-hover-btn);
      }
   }
   &--callMe {
      padding: 16px;
      width: 100%;
      font-size: 16px;
      line-height: 1.15;
      gap: 15px;
      width: 100%;
   }
   &--basketLink {
      padding: 18.5px 54px;
      font-size: 16px;
   }
   &--inputSubmit {
      padding: 18.5px;
      width: 100%;
      border: none;
      font-size: 16px;
      text-transform: none;
   }
   &--basket {
      padding: 12.5px 50.5px;
      &.in_cart_already {
          background: var(--light-color);
          border: 1px solid var(--red-color);
          color: var(--dark-color);
          padding-top: 11.5px;
          padding-bottom: 11.5px;
          transition: background, color 0.4s;
          &:hover {
              color: var(--light-color);
              background: var(--red-color);
          }
      }
      @media (max-width: 769px) {
         padding: 8.5px;
         width: 100%;
         font-size: 11px;
      }
   }
   &--mobModal {
      padding: 18.5px;
      width: 100%;
      font-size: 16px;
   }
   &--big {
      padding: 15px 40px;
      font-size: 14px;
      line-height: 1.15;
   }
   &--big2 {
      padding: 15px 40px;
      font-size: 14px;
      line-height: 1.15;
      width: 100%;
   }
   &--big3 {
      padding: 20.5px;
      width: 100%;
      font-size: 16px;
      line-height: 1.25;
   }
   &--back {
      line-height: 1.15;
      color: var(--dark-color);
      background: var(--dark-gray-color3);
      padding: 10px 20px 10px 10px;
      &:hover {
         background: var(--dark-gray-color3-hover-btn);
      }
   }
   &--respond {
      font-size: 16px;
      line-height: 1.15;
      padding: 18.5px;
   }
   &--recall {
      padding: 16px 30px;
      font-size: 16px;
      line-height: 1.15;
      gap: 15px;
      & i {
         font-size: 24px;
      }
   }
   &--paymentGray {
      padding: 15.5px;
      width: 100%;
      font-size: 16px;
      line-height: 1.25;
      gap: 10px;
      background: var(--dark-gray-color3);
      color: var(--dark-color);
      &:hover {
         background: var(--dark-gray-color3-hover-btn);
      }
   }
   &--newAddr {
      padding: 12.5px 15px;
      background: var(--dark-gray-color3);
      color: var(--dark-color);
      &:hover {
         background-color: var(--dark-gray-color3-hover-btn);
      }
   }
   &--promo {
      padding: 12.5px;
      font-size: 13px;
      color: var(--dark-color);
      background-color: var(--dark-gray-color3);
      &:hover {
         background-color: var(--dark-gray-color3-hover-btn);
      }
   }
   &--addAddr {
      padding: 12.5px;
      background: var(--dark-gray-color3);
      color: var(--dark-color);
      &:hover {
         background: var(--dark-gray-color3-hover-btn);
      }
   }
   &--editData {
      padding: 16.5px;
      width: 100%;
      font-size: 16px;
      background: var(--dark-gray-color3);
      color: var(--dark-color);
      &:hover {
         background: var(--dark-gray-color3-hover-btn);
      }
   }
   &--editPass {
      padding: 16.5px;
      width: 100%;
      background: transparent;
      color: var(--dark-color);
      border: 1px solid var(--red-color);
      font-size: 16px;
      &:hover {
         border-color: #c70000;
         background-color: transparent;
      }
   }
   &--addAddr2 {
      padding: 18.5px;
      width: 100%;
      font-size: 16px;
   }
   &--designProject {
      padding: 12.5px;
      width: 100%;
      line-height: 1.15;
      background: var(--dark-gray-color3);
      color: var(--dark-color);
      &:hover {
         background: var(--dark-gray-color3-hover-btn);
      }
   }
   &--catalogProduct {
      padding: 12.5px;
      width: 100%;
      line-height: 1.15;
   }
   &--catalogProduct2 {
      padding: 10px;
      width: 100%;
      line-height: 1.15;
      gap: 6px;
      background-color: #37a72e;
      &:hover {
         background: #2a8124;
      }
   }
   &--oneClick {
      width: 100%;
      border: 1px solid var(--red-color);
      border-radius: 9px;
      padding: 16.5px;
      background-color: transparent;
      font-size: 16px;
      line-height: 1.15;
      color: var(--dark-color);
      &:hover {
         background-color: transparent;
         border-color: #c70000;
      }
   }
   &--choice {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.2;
      padding: 6px 10px;
      width: 100%;
      color: var(--dark-color);
      background-color: var(--dark-gray-color3);
      &:hover {
         background: var(--dark-gray-color3-hover-btn);
      }
   }
   &--addReviews {
      padding: 16.5px;
      font-size: 16px;
      width: 100%;
   }
   &--allReviews {
      padding: 16.5px;
      font-size: 16px;
      width: 100%;
      color: var(--dark-color);
      background-color: var(--dark-gray-color3);
      &:hover {
         background: var(--dark-gray-color3-hover-btn);
      }
   }
   &--gray {
      background-color: var(--dark-gray-color3);
      color: var(--dark-color);
      &:hover {
         background-color: var(--dark-gray-color3-hover-btn);
      }
   }
}
