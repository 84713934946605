.blog {
   &__page {
      padding: 0 0 80px;
      @media (max-width: 1441px) {
         padding-bottom: 50px;
      }
      @media (max-width: 769px) {
         padding-bottom: 40px;
      }
   }
   &__tabsWrap {
      background: #f2f5f7;
      margin-bottom: 30px;
      @media (max-width: 1201px) {
         overflow: auto;
         padding: 0 12px;
         & .container {
            padding: 0;
            width: fit-content;
         }
      }
      @media (max-width: 769px) {
         margin-bottom: 20px;
      }
   }
   &__tabs {
      display: flex;
      gap: 20px;
      justify-content: flex-start;
      padding: 0 12px;
      @media (max-width: 1201px) {
         border-radius: 0;
         width: fit-content;
         min-width: 100%;
         padding: 0;
      }
   }
   &__tab {
      transition: all 0.4s;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background-color: transparent;
      border-bottom: 2px solid transparent;
      min-height: 54px;
      cursor: pointer;
      font-size: 16px;
      line-height: 1.2;
      white-space: nowrap;
      color: var(--dark-color);
      &--active {
         cursor: default;
      }
      &--active,
      &:hover {
         border-color: var(--red-color);
      }
   }
   &__more {
      margin-top: 50px;
      @media (max-width: 1025px) {
         margin-top: 35px;
      }
      @media (max-width: 769px) {
         margin-top: 20px;
      }
   }
   &__pagination {
      margin-top: 20px;
      @media (max-width: 1025px) {
         margin-top: 15px;
      }
      @media (max-width: 769px) {
         margin-top: 10px;
      }
   }
   &__wrap {
      display: flex;
      gap: 30px 20px;
      flex-wrap: wrap;
      @media (max-width: 601px) {
         gap: 20px;
      }
   }
   &__card {
      display: block;
      width: calc(100% / 3 - 40px / 3);
      @media (max-width: 1025px) {
         width: calc(50% - 10px);
      }
      @media (max-width: 601px) {
         width: 100%;
      }
   }
   &__cardImg {
      display: block;
      width: 100%;
      border-radius: 5px;
      aspect-ratio: 38/20;
      object-fit: cover;
      margin-bottom: 15px;
   }
   &__cardCol {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      margin-bottom: 10px;
   }
   &__cardDescr {
      font-size: 13px;
      line-height: 1.2;
      color: var(--red-color);
      @media (max-width: 1441px) {
         font-size: 11px;
      }
      @media (max-width: 769px) {
         font-size: 12px;
      }
   }
   &__cardDate {
      font-size: 13px;
      line-height: 1.2;
      color: var(--gray-color);
      @media (max-width: 1441px) {
         font-size: 11px;
      }
      @media (max-width: 769px) {
         font-size: 12px;
      }
   }
   &__cardName {
      margin: 0;
      font-weight: 400;
      font-size: 20px;
      line-height: 1.3;
      color: var(--dark-color);
      transition: all 0.4s;
      @media (max-width: 1441px) {
         font-size: 18px;
      }
      &:hover {
         color: var(--dark-color-hover-link);
      }
   }
}
