@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@800&family=Roboto:wght@400;700&display=swap");
:root {
  --font-family: "Roboto", sans-serif;
  --content-width: 1420px;
  --container-offset: 12px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --transition-time: 0.4s;
  --light-color: #fff;
  --dark-color: #000;
  --dark-color-hover-link: #ed0000;
  --gray-color: #b8c0c5;
  --gray-color-hover-link: #ed0000;
  --dark-gray-color: #494f54;
  --dark-gray-color-hover-link: #ed0000;
  --blue-color: #1f98dd;
  --blue-color-hover-link: #ed0000;
  --red-color: #ed0000;
  --red-color-hover-link: #1f2d35;
  --dark-gray-color3: #f2f5f7;
  --dark-gray-color3-hover-btn: #e2e8ec;
  --gray-color2: #8c959c;
}
@media (max-width: 1441px) {
  :root {
    --content-width: 1180px;
  }
}

/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */
/* stylelint-disable */
@font-face {
  font-family: "iconsvg";
  src: url("../fonts/iconsvg.ttf?d8j7fg") format("truetype"), url("../fonts/iconsvg.woff?d8j7fg") format("woff"), url("../fonts/iconsvg.svg?d8j7fg#iconsvg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "iconsvg" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-vk-new:before {
  content: "\e96d";
}

.icon-add_a_photo:before {
  content: "\e96c";
}

.icon-lock:before {
  content: "\e96b";
}

.icon-free:before {
  content: "\e962";
}

.icon-Axors .path1:before {
  content: "\e955";
  color: rgb(237, 0, 0);
}

.icon-Axors .path2:before {
  content: "\e95a";
  margin-left: -1.5em;
  color: rgb(255, 255, 255);
}

.icon-avatar:before {
  content: "\e94f";
}

.icon-close2:before {
  content: "\e94d";
}

.icon-fav2:before {
  content: "\e94e";
}

.icon-view_list:before {
  content: "\e94b";
}

.icon-view_module:before {
  content: "\e94c";
}

.icon-icon-chevron_rightNP:before {
  content: "\e94a";
}

.icon-telegram .path1:before {
  content: "\e925";
  color: #22a6dc;
}

.icon-telegram .path2:before {
  content: "\e940";
  margin-left: -1.5em;
  color: rgb(255, 255, 255);
}

.icon-telegram .path3:before {
  content: "\e941";
  margin-left: -1.5em;
  color: rgb(210, 228, 240);
}

.icon-telegram .path4:before {
  content: "\e942";
  margin-left: -1.5em;
  color: rgb(181, 207, 228);
}

.icon-viber:before {
  content: "\e944";
  color: #7f4da0;
}

.icon-whatsapp:before {
  content: "\e949";
  color: #67c15e;
}

.icon-basket:before {
  content: "\e924";
}

.icon-fav:before {
  content: "\e923";
}

.icon-notes:before {
  content: "\e91c";
}

.icon-map_pin:before {
  content: "\e919";
}

.icon-play_video .path1:before {
  content: "\e917";
  color: rgb(255, 255, 255);
  transform: translateX(100%);
}

.icon-play_video .path2:before {
  content: "\e918";
  margin-left: -1.5em;
  color: rgb(237, 0, 0);
}

.icon-yandex:before {
  content: "\e909";
}

.icon-account_balance_wallet:before {
  content: "\e900";
}

.icon-account_circle:before {
  content: "\e901";
}

.icon-add:before {
  content: "\e902";
}

.icon-arrow_back:before {
  content: "\e903";
}

.icon-arrow-forward:before {
  content: "\e904";
}

.icon-article:before {
  content: "\e905";
}

.icon-attach_file:before {
  content: "\e906";
}

.icon-axor:before {
  content: "\e907";
}

.icon-axor1:before {
  content: "\e908";
}

.icon-axor2:before {
  content: "\e90a";
}

.icon-border_color:before {
  content: "\e90b";
}

.icon-brand:before {
  content: "\e90c";
}

.icon-brightness-percent:before {
  content: "\e90d";
}

.icon-call:before {
  content: "\e90e";
}

.icon-chat:before {
  content: "\e90f";
}

.icon-check-decagram:before {
  content: "\e910";
}

.icon-chevron_left:before {
  content: "\e911";
}

.icon-chevron_right:before {
  content: "\e912";
}

.icon-close:before {
  content: "\e913";
}

.icon-construction:before {
  content: "\e914";
}

.icon-contacts:before {
  content: "\e915";
}

.icon-content_copy:before {
  content: "\e916";
}

.icon-delete:before {
  content: "\e91a";
}

.icon-Delivery:before {
  content: "\e91b";
}

.icon-dolly:before {
  content: "\e91d";
}

.icon-email:before {
  content: "\e91e";
}

.icon-equalizer:before {
  content: "\e91f";
}

.icon-error:before {
  content: "\e920";
}

.icon-expand_less:before {
  content: "\e921";
}

.icon-expand_more:before {
  content: "\e922";
}

.icon-file_download:before {
  content: "\e926";
}

.icon-file-document:before {
  content: "\e927";
}

.icon-file-excel:before {
  content: "\e928";
}

.icon-file-word:before {
  content: "\e929";
}

.icon-filter_list:before {
  content: "\e92a";
}

.icon-fire:before {
  content: "\e92b";
}

.icon-handshake:before {
  content: "\e92c";
}

.icon-help:before {
  content: "\e92d";
}

.icon-home:before {
  content: "\e92e";
}

.icon-info:before {
  content: "\e92f";
}

.icon-info_outline:before {
  content: "\e930";
}

.icon-local_shipping:before {
  content: "\e931";
}

.icon-medal:before {
  content: "\e932";
}

.icon-near_me:before {
  content: "\e933";
}

.icon-odnoklassniki:before {
  content: "\e934";
}

.icon-Ok:before {
  content: "\e935";
}

.icon-package-variant-closed:before {
  content: "\e936";
}

.icon-palette-swatch-variant:before {
  content: "\e937";
}

.icon-palette-swatch-variant-1:before {
  content: "\e938";
}

.icon-pencil-ruler:before {
  content: "\e939";
}

.icon-pencil-ruler-1:before {
  content: "\e93a";
}

.icon-percent:before {
  content: "\e93b";
}

.icon-percent-circle:before {
  content: "\e93c";
}

.icon-percent-circle-outline:before {
  content: "\e93d";
}

.icon-phone:before {
  content: "\e93e";
}

.icon-place:before {
  content: "\e93f";
}

.icon-play_circle_filled-1:before {
  content: "\e943";
}

.icon-playlist_add:before {
  content: "\e945";
}

.icon-playlist_add_check:before {
  content: "\e946";
}

.icon-question_answer:before {
  content: "\e947";
}

.icon-remove:before {
  content: "\e948";
}

.icon-search:before {
  content: "\e950";
}

.icon-slideshow:before {
  content: "\e951";
}

.icon-star:before {
  content: "\e952";
}

.icon-stars:before {
  content: "\e953";
}

.icon-tag:before {
  content: "\e954";
}

.icon-thumb_down:before {
  content: "\e956";
}

.icon-thumb_up:before {
  content: "\e957";
}

.icon-time:before {
  content: "\e958";
}

.icon-toolbox-outline:before {
  content: "\e959";
}

.icon-truck-fast:before {
  content: "\e95b";
}

.icon-tune:before {
  content: "\e95c";
}

.icon-twitter:before {
  content: "\e95d";
}

.icon-verified_user:before {
  content: "\e95e";
}

.icon-video:before {
  content: "\e95f";
}

.icon-visibility:before {
  content: "\e960";
}

.icon-vk:before {
  content: "\e961";
}

.icon-vr:before {
  content: "\e963";
}

.icon-accessories:before {
  content: "\e964";
}

.icon-kitchen:before {
  content: "\e965";
}

.icon-furniture:before {
  content: "\e966";
}

.icon-tile:before {
  content: "\e967";
}

.icon-floor:before {
  content: "\e968";
}

.icon-sale:before {
  content: "\e969";
}

.icon-plumbing:before {
  content: "\e96a";
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  line-height: 1.7;
  font-size: 14px;
  color: var(--dark-color);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 14px;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.site-container {
  min-height: 100vh;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}
@media (max-width: 1025px) {
  .container--adaptiveTabletFull {
    max-width: 100%;
    padding: 0;
  }
}
.container--min {
  max-width: 1204px;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  margin: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.main__borderTop {
  border-top: 1px solid #e2e8ec;
}
@media (max-width: 769px) {
  .main__borderTop {
    border-top: none;
  }
}

@media (max-width: 769px) {
  .mobHidden {
    display: none;
  }
}

.page__body.overflow {
  overflow: hidden !important;
}

.color__red {
  color: var(--red-color) !important;
}

.btnStnd {
  display: flex;
  width: fit-content;
  gap: 6px;
  align-items: center;
  justify-content: center;
  background: #ed0000;
  border-radius: 9px;
  cursor: pointer;
  font-weight: 700;
  font-size: 13px;
  line-height: 1.25;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  transition: all 0.4s;
}
.btnStnd:hover {
  background: #c70000;
}
.btnStnd:disabled {
  opacity: 0.2;
  pointer-events: none;
}
.btnStnd--more {
  padding: 21.5px;
  width: 100%;
  text-align: center;
  background: var(--dark-gray-color3);
  border-radius: 7px;
  display: block;
  transition: all 0.4s;
  font-weight: 400;
  font-size: 14px;
  color: var(--dark-color);
  line-height: 1.2;
}
@media (max-width: 769px) {
  .btnStnd--more {
    padding: 11.5px;
  }
}
.btnStnd--more:hover {
  background-color: var(--dark-gray-color3-hover-btn);
}
.btnStnd--more:disabled:hover {
  background: var(--dark-gray-color3);
}
.btnStnd--catalog {
  padding: 15px 28px 15px 20px;
  font-size: 16px;
  gap: 14px;
  line-height: 1.2;
}
@media (max-width: 1201px) {
  .btnStnd--catalog {
    font-size: 14px;
    padding: 11px 15px;
  }
}
.btnStnd--searchResult {
  padding: 11.5px;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
  color: var(--dark-color);
  background-color: var(--dark-gray-color3);
}
.btnStnd--searchResult:hover {
  background-color: var(--dark-gray-color3-hover-btn);
}
.btnStnd--searchResultCard {
  padding: 10px;
  font-size: 24px;
  color: var(--gray-color);
  width: fit-content;
  line-height: 1;
  border-radius: 7px;
  background-color: var(--dark-gray-color3);
  font-weight: 400;
}
.btnStnd--searchResultCard:hover {
  background-color: var(--dark-gray-color3-hover-btn);
}
.btnStnd--callMe {
  padding: 16px;
  width: 100%;
  font-size: 16px;
  line-height: 1.15;
  gap: 15px;
  width: 100%;
}
.btnStnd--basketLink {
  padding: 18.5px 54px;
  font-size: 16px;
}
.btnStnd--inputSubmit {
  padding: 18.5px;
  width: 100%;
  border: none;
  font-size: 16px;
  text-transform: none;
}
.btnStnd--basket {
  padding: 12.5px 50.5px;
}
@media (max-width: 769px) {
  .btnStnd--basket {
    padding: 8.5px;
    width: 100%;
    font-size: 11px;
  }
}
.btnStnd--mobModal {
  padding: 18.5px;
  width: 100%;
  font-size: 16px;
}
.btnStnd--big {
  padding: 15px 40px;
  font-size: 14px;
  line-height: 1.15;
}
.btnStnd--big2 {
  padding: 15px 40px;
  font-size: 14px;
  line-height: 1.15;
  width: 100%;
}
.btnStnd--big3 {
  padding: 20.5px;
  width: 100%;
  font-size: 16px;
  line-height: 1.25;
}
.btnStnd--back {
  line-height: 1.15;
  color: var(--dark-color);
  background: var(--dark-gray-color3);
  padding: 10px 20px 10px 10px;
}
.btnStnd--back:hover {
  background: var(--dark-gray-color3-hover-btn);
}
.btnStnd--respond {
  font-size: 16px;
  line-height: 1.15;
  padding: 18.5px;
}
.btnStnd--recall {
  padding: 16px 30px;
  font-size: 16px;
  line-height: 1.15;
  gap: 15px;
}
.btnStnd--recall i {
  font-size: 24px;
}
.btnStnd--paymentGray {
  padding: 15.5px;
  width: 100%;
  font-size: 16px;
  line-height: 1.25;
  gap: 10px;
  background: var(--dark-gray-color3);
  color: var(--dark-color);
}
.btnStnd--paymentGray:hover {
  background: var(--dark-gray-color3-hover-btn);
}
.btnStnd--newAddr {
  padding: 12.5px 15px;
  background: var(--dark-gray-color3);
  color: var(--dark-color);
}
.btnStnd--newAddr:hover {
  background-color: var(--dark-gray-color3-hover-btn);
}
.btnStnd--promo {
  padding: 12.5px;
  font-size: 13px;
  color: var(--dark-color);
  background-color: var(--dark-gray-color3);
}
.btnStnd--promo:hover {
  background-color: var(--dark-gray-color3-hover-btn);
}
.btnStnd--addAddr {
  padding: 12.5px;
  background: var(--dark-gray-color3);
  color: var(--dark-color);
}
.btnStnd--addAddr:hover {
  background: var(--dark-gray-color3-hover-btn);
}
.btnStnd--editData {
  padding: 16.5px;
  width: 100%;
  font-size: 16px;
  background: var(--dark-gray-color3);
  color: var(--dark-color);
}
.btnStnd--editData:hover {
  background: var(--dark-gray-color3-hover-btn);
}
.btnStnd--editPass {
  padding: 16.5px;
  width: 100%;
  background: transparent;
  color: var(--dark-color);
  border: 1px solid var(--red-color);
  font-size: 16px;
}
.btnStnd--editPass:hover {
  border-color: #c70000;
  background-color: transparent;
}
.btnStnd--addAddr2 {
  padding: 18.5px;
  width: 100%;
  font-size: 16px;
}
.btnStnd--designProject {
  padding: 12.5px;
  width: 100%;
  line-height: 1.15;
  background: var(--dark-gray-color3);
  color: var(--dark-color);
}
.btnStnd--designProject:hover {
  background: var(--dark-gray-color3-hover-btn);
}
.btnStnd--catalogProduct {
  padding: 12.5px;
  width: 100%;
  line-height: 1.15;
}
.btnStnd--catalogProduct2 {
  padding: 10px;
  width: 100%;
  line-height: 1.15;
  gap: 6px;
  background-color: #37a72e;
}
.btnStnd--catalogProduct2:hover {
  background: #2a8124;
}
.btnStnd--oneClick {
  width: 100%;
  border: 1px solid var(--red-color);
  border-radius: 9px;
  padding: 16.5px;
  background-color: transparent;
  font-size: 16px;
  line-height: 1.15;
  color: var(--dark-color);
}
.btnStnd--oneClick:hover {
  background-color: transparent;
  border-color: #c70000;
}
.btnStnd--choice {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  padding: 6px 10px;
  width: 100%;
  color: var(--dark-color);
  background-color: var(--dark-gray-color3);
}
.btnStnd--choice:hover {
  background: var(--dark-gray-color3-hover-btn);
}
.btnStnd--addReviews {
  padding: 16.5px;
  font-size: 16px;
  width: 100%;
}
.btnStnd--allReviews {
  padding: 16.5px;
  font-size: 16px;
  width: 100%;
  color: var(--dark-color);
  background-color: var(--dark-gray-color3);
}
.btnStnd--allReviews:hover {
  background: var(--dark-gray-color3-hover-btn);
}
.btnStnd--gray {
  background-color: var(--dark-gray-color3);
  color: var(--dark-color);
}
.btnStnd--gray:hover {
  background-color: var(--dark-gray-color3-hover-btn);
}

.inputDecor {
  position: relative;
  display: block;
  width: 100%;
}
.inputDecor--full {
  height: 100%;
}
.inputDecor--req::after {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguNzUgNS40MTY2N0g2TDcuOTU4MzMgNy4zNzVMNy4zNzUgNy45NTgzM0w1LjQxNjY3IDZWOC43NUg0LjU4MzMzVjUuOTU4MzNMMi42MjUgNy45MTY2N0wyLjA0MTY3IDcuMzMzMzNMMy45MTY2NyA1LjQxNjY3SDEuMjVWNC41ODMzM0g0TDIuMDQxNjcgMi42MjVMMi42MjUgMi4wNDE2N0w0LjU4MzMzIDRWMS4yNUg1LjQxNjY3VjMuOTE2NjdMNy4zMzMzMyAyTDcuOTE2NjcgMi42MjVMNS45NTgzMyA0LjU4MzMzSDguNzVWNS40MTY2N1oiIGZpbGw9IiNFRDAwMDAiLz4KPC9zdmc+Cg==);
  display: block;
  position: absolute;
  top: 0;
  right: 4px;
  display: block;
  width: 10px;
  height: 10px;
}
.inputDecor.err input, .inputDecor.err textarea {
  border-color: var(--red-color);
}
.inputDecor.err + .inputDecor__error {
  display: block;
}
.inputDecor input, .inputDecor textarea {
  background: transparent;
  border: 1px solid var(--dark-gray-color3-hover-btn);
  border-radius: 5px;
  padding: 31px 18px 12px;
  display: block;
  width: 100%;
  outline: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  color: #2b2b2b;
  transition: all 0.4s;
}
.inputDecor input:hover, .inputDecor input:focus, .inputDecor textarea:hover, .inputDecor textarea:focus {
  border-color: var(--gray-color);
}
.inputDecor input:focus ~ span, .inputDecor input.act ~ span, .inputDecor textarea:focus ~ span, .inputDecor textarea.act ~ span {
  top: 20px;
  font-size: 13px;
}
.inputDecor input::placeholder, .inputDecor textarea::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: var(--gray-color);
}
.inputDecor span {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: var(--gray-color);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.5s;
  left: 18px;
  pointer-events: none;
}
.inputDecor textarea {
  height: 100%;
  resize: none;
  padding-top: 15px;
}
.inputDecor__error {
  display: none;
  font-weight: 400;
  font-size: 11px;
  line-height: 1.3;
  color: var(--red-color);
  margin-top: 5px;
}

.checkbox {
  cursor: pointer;
}
.checkbox__wr {
  display: flex;
  gap: 14px;
}
.checkbox__text {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.7;
  color: var(--dark-color);
}
.checkbox__text a {
  color: var(--blue-color);
  transition: all 0.4s;
}
.checkbox__text a:hover {
  color: var(--blue-color-hover-link);
}
.checkbox:hover input + span {
  border-color: #c9c9c9;
}
.checkbox__textErr {
  display: none;
}
.checkbox input {
  display: none;
}
.checkbox input.err ~ .checkbox__textErr {
  display: block;
  color: #c8180f;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  margin: 0;
}
.checkbox input + span {
  display: inline-block;
  width: 16px;
  min-width: 16px;
  height: 16px;
  border: 2px solid var(--dark-gray-color3-hover-btn);
  border-radius: 3px;
  transition: all 0.4s;
  position: relative;
  margin-top: 3px;
}
.checkbox input + span::after {
  content: "";
  background: url("../img/ch.svg");
  background-repeat: no-repeat;
  display: none;
  width: 9px;
  height: 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.checkbox input:checked + span {
  background: var(--red-color);
  border-color: var(--red-color);
}
.checkbox input:checked + span::after {
  display: block;
}
.checkbox input:disabled + span {
  background: #e5e5e5;
  border-color: #e5e5e5;
}
.checkbox input:disabled ~ span {
  color: var(--gray-color);
}
@media (max-width: 769px) {
  .checkbox--mobBig input + span {
    width: 24px;
    min-width: 24px;
    height: 24px;
  }
  .checkbox--mobBig input + span::after {
    width: 12.5px;
    height: 9px;
    background-size: 12.5px;
  }
}
.checkbox--circle {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 14px;
  line-height: 1.2;
}
.checkbox--circle input + span {
  width: 24px;
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
  vertical-align: initial;
  display: block;
  margin: 0;
  background: transparent;
}
.checkbox--circle input + span::after {
  width: 0;
  height: 0;
  border-radius: 50%;
  transition: all 0.4s;
  content: "";
  background: transparent;
  display: block;
}
.checkbox--circle input:checked + span {
  background: transparent;
  border-color: var(--dark-gray-color3-hover-btn);
}
.checkbox--circle input:checked + span::after {
  width: 12px;
  height: 12px;
  background: var(--red-color);
}
.checkbox--circleMin {
  line-height: 1.5;
}
.checkbox--circleMin input + span {
  width: 20px;
  min-width: 20px;
  height: 20px;
}
.checkbox--circleMin input + span::after {
  width: 10px !important;
  height: 10px !important;
}
.checkbox--customPay {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 20px;
  position: relative;
}
.checkbox--customPay input ~ span.decor::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  border: 2px solid var(--dark-gray-color3);
  border-radius: 5px;
  position: absolute;
  top: 0;
  transition: all 0.4s;
  left: 0;
}
.checkbox--customPay input:checked ~ span.decor::before {
  border-color: var(--red-color) !important;
}
.checkbox--customPay:hover input ~ span.decor::before {
  border-color: var(--dark-gray-color3-hover-btn);
}
.checkbox--pr {
  padding-right: 55px;
}
.checkbox__textFl {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.checkbox__textFl1 {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: var(--dark-color);
}
.checkbox__textFl2 {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.2;
  color: var(--gray-color);
}

.checkboxFilter {
  cursor: pointer;
  display: flex;
  gap: 10px;
  align-items: center;
}
.checkboxFilter:hover input + span {
  border-color: #c9c9c9;
}
.checkboxFilter i {
  font-size: 24px;
  color: var(--dark-gray-color3-hover-btn);
  transition: all 0.4s;
}
.checkboxFilter i.big {
  font-size: 32px;
}
.checkboxFilter i.color {
  color: var(--red-color);
}
.checkboxFilter__text {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  color: var(--dark-color);
}
.checkboxFilter__text--bold {
  font-weight: 700;
}
.checkboxFilter__text--big {
  font-size: 16px;
}
.checkboxFilter__text a {
  color: var(--blue-color);
  transition: all 0.4s;
}
.checkboxFilter__text a:hover {
  color: var(--blue-color-hover-link);
}
.checkboxFilter input {
  display: none;
}
.checkboxFilter input.err ~ .checkbox__textErr {
  display: block;
  color: #c8180f;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  margin: 0;
}
.checkboxFilter input + span {
  display: inline-block;
  width: 20px;
  min-width: 20px;
  height: 20px;
  border: 2px solid var(--dark-gray-color3-hover-btn);
  border-radius: 3px;
  transition: all 0.4s;
  position: relative;
}
@media (max-width: 769px) {
  .checkboxFilter input + span {
    width: 24px;
    height: 24px;
  }
}
.checkboxFilter input + span::after {
  content: "";
  background: url("../img/ch.svg");
  background-repeat: no-repeat;
  display: none;
  background-size: 10.3px;
  width: 10.3px;
  height: 7.5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 769px) {
  .checkboxFilter input + span::after {
    width: 12.4px;
    height: 9px;
    background-size: 12.4px;
  }
}
.checkboxFilter input:checked ~ i {
  color: var(--red-color);
}
.checkboxFilter input:checked + span {
  background: var(--red-color);
  border-color: var(--red-color);
}
.checkboxFilter input:checked + span::after {
  display: block;
}
.checkboxFilter input:disabled + span {
  background: #e5e5e5;
  border-color: #e5e5e5;
}
.checkboxFilter input:disabled ~ span {
  color: var(--gray-color);
}
.checkboxFilter__textQuantity {
  font-size: 12px;
  line-height: 1.2;
  color: var(--gray-color);
  display: inline-block;
  margin-left: 5px;
}
.checkboxFilter--custom {
  width: 100%;
}
.checkboxFilter--custom input ~ span.custom {
  margin-left: auto;
  display: block;
  width: 54px;
  height: 30px;
  border-radius: 15px;
  border: none;
  background-color: var(--dark-gray-color3-hover-btn);
  padding: 3px;
  transition: all 0.4s;
  position: relative;
}
.checkboxFilter--custom input ~ span.custom::after {
  content: "";
  display: block !important;
  background: var(--light-color);
  position: absolute;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  left: 3px;
  top: 50%;
  opacity: 1 !important;
  transform: translateY(-50%);
  transition: all 0.4s;
}
.checkboxFilter--custom input:checked ~ span.custom {
  background-color: var(--red-color);
}
.checkboxFilter--custom input:checked ~ span.custom::after {
  left: calc(100% - 27px);
}

.selectSt {
  display: block;
  width: 100%;
  color: var(--dark-color);
}
.selectSt__select {
  display: block;
  width: 100%;
  padding: 20px;
  border: 1px solid #e2e8ec;
  border-radius: 5px;
  font-size: 16px;
  line-height: 1.5;
  color: var(--dark-color);
  appearance: none;
  background: url("../img/svg/expand_more.svg") no-repeat right 16px center/24px;
}
.selectSt__select--grayText {
  color: var(--gray-color);
}

.fileLabel {
  display: block;
  width: 100%;
  padding: 30px;
  background: var(--dark-gray-color3);
  border-radius: 5px;
  transition: all 0.4s;
  cursor: pointer;
}
.fileLabel:hover {
  background-color: var(--dark-gray-color3-hover-btn);
}
.fileLabel input {
  display: none;
}
.fileLabel > span {
  display: flex;
  gap: 30px;
  align-items: center;
  font-size: 14px;
  line-height: 1.5;
  color: var(--dark-color);
}
@media (max-width: 769px) {
  .fileLabel > span {
    justify-content: center;
  }
  .fileLabel > span span {
    color: var(--dark-color) !important;
  }
}
.fileLabel__icon {
  font-size: 32px;
  color: #b8c0c5;
}
.fileLabel--hidden {
  display: none !important;
}
.fileLabel__list {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
@media (max-width: 769px) {
  .fileLabel__list {
    gap: 12px;
    height: 100%;
  }
}
.fileLabel__listItem {
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 5px;
  position: relative;
}
@media (max-width: 769px) {
  .fileLabel__listItem {
    width: 100px;
    height: 100px;
  }
}
.fileLabel__listItem img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.fileLabel__listItemRemove {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  transition: all 0.4s;
  opacity: 0;
  transform: translate(50%, -50%);
  border-radius: 50%;
}
@media (max-width: 769px) {
  .fileLabel__listItemRemove {
    transform: translate(25%, -25%);
    opacity: 1;
  }
}
.fileLabel__listItemRemove svg {
  display: block;
}
.fileLabel__listItem:hover .fileLabel__listItemRemove {
  opacity: 1;
}

.labelEdit {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}
.labelEdit:hover .labelEdit__btn {
  opacity: 1;
}
.labelEdit__name {
  font-size: 16px;
  line-height: 1.5;
  color: #97a5ad;
  width: 180px;
}
@media (max-width: 769px) {
  .labelEdit__name {
    width: fit-content;
  }
}
.labelEdit__input {
  width: 205px;
  flex: 1 1 auto;
  display: block;
  outline: none;
  border: none;
  font-size: 16px;
  line-height: 1.5;
}
@media (max-width: 769px) {
  .labelEdit__input {
    width: fit-content;
    flex: initial initial initial;
    min-width: initial;
  }
  .labelEdit__input:not(:first-child) {
    text-align: right;
  }
}
.labelEdit__btn {
  opacity: 0;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.5;
  text-align: right;
  white-space: nowrap;
  color: var(--gray-color);
  transition: all 0.4s;
}
.labelEdit__btn:hover {
  color: var(--gray-color-hover-link);
}
@media (max-width: 769px) {
  .labelEdit__btn {
    display: none;
  }
}

.rating {
  position: relative;
  width: fit-content;
}
.rating--grade .rating__star {
  cursor: pointer;
}
.rating__empty, .rating__filled {
  display: flex;
  gap: 5.7px;
}
@media (max-width: 769px) {
  .rating__empty, .rating__filled {
    gap: 4px;
  }
}
.rating__empty--bigAdaptive, .rating__filled--bigAdaptive {
  gap: 6.9px;
}
.rating__empty--gapBig, .rating__filled--gapBig {
  gap: 9px;
}
.rating__empty--gapExtra, .rating__filled--gapExtra {
  gap: 12px;
}
.rating__star {
  display: block;
  line-height: 1;
  color: var(--dark-gray-color3-hover-btn);
  font-size: 16.8px;
}
@media (max-width: 769px) {
  .rating__star {
    font-size: 12.6px;
  }
}
.rating__star--adaptive {
  font-size: 16.8px;
}
.rating__star--bigAdaptive {
  color: #e2e8ec;
}
@media (max-width: 769px) {
  .rating__star--bigAdaptive {
    font-size: 20.2px;
  }
}
.rating__star--filled {
  color: #fec32b;
}
.rating__star--superBig {
  font-size: 24px;
}
.rating__star--superBig2 {
  font-size: 32px;
}
.rating__star--min {
  font-size: 12.6px;
}
.rating__filled {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 2;
}

.richText > *:first-child {
  margin-top: 0 !important;
}
.richText > *:last-child {
  margin-bottom: 0 !important;
}
.richText ul {
  padding-left: 25px;
  list-style: none;
}
.richText ul li {
  position: relative;
}
.richText ul li::after {
  content: "";
  display: block;
  position: absolute;
  width: 3px;
  height: 3px;
  background-color: currentColor;
  top: 10px;
  left: -11px;
  border-radius: 50%;
}
.richText p + h2 {
  margin-top: 23px;
}
.richText p + h3 {
  margin-top: 20px;
}
.richText p a {
  color: var(--blue-color);
  transition: all 0.4s;
}
.richText p a:hover {
  color: var(--blue-color-hover-link);
}
.richText h1 {
  font-weight: 700;
  font-size: 32px;
  line-height: 1.25;
  color: var(--dark-color);
  margin: 0 0 36px;
}
@media (max-width: 1025px) {
  .richText h1 {
    font-size: 28px;
    margin-bottom: 28px;
  }
}
@media (max-width: 769px) {
  .richText h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
}
.richText h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.25;
  color: var(--dark-color);
}
.richText h2 + p {
  margin-top: 37px;
}
.richText h2 + h3 {
  margin-top: 28px;
}
.richText h3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.25;
  color: var(--dark-color);
}
.richText h3 + p {
  margin-top: 32px;
}
.richText img {
  display: block;
  width: 100%;
  margin: 30px 0;
}
@media (max-width: 769px) {
  .richText img {
    margin: 45px 0;
  }
}
.richText--bigText {
  font-size: 16px;
}
.richText--bigText ul li::after {
  top: 13px;
}

:root {
  --swiper-theme-color: var(--red-color);
}

.swiper-button-next-custom,
.swiper-button-prev-custom {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  width: fit-content;
  width: 42px;
  height: 42px;
  cursor: pointer;
  transition: all 0.4s;
}
.swiper-button-next-custom:hover,
.swiper-button-prev-custom:hover {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
.swiper-button-next-custom svg,
.swiper-button-prev-custom svg {
  width: 100%;
  height: 100%;
  display: block;
}

.swiper-button-next-vertical, .swiper-button-prev-vertical {
  left: 0;
  right: 0;
  width: 100%;
  margin: 0;
  height: 15px;
  /*background-color: var(--dark-gray-color3);*/
  background-color: #fff;
  position: absolute;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.swiper-button-next-vertical:hover, .swiper-button-prev-vertical:hover {
  background-color: var(--dark-gray-color3-hover-btn);
}
.swiper-button-next-vertical:after, .swiper-button-prev-vertical:after {
  content: "next";
  font-family: swiper-icons;
  text-transform: none !important;
  letter-spacing: 0;
  text-transform: none;
  font-variant: initial;
  line-height: 1;
  color: var(--red-color);
  font-size: 14px;
  transform: rotate(90deg);
  font-weight: bold;
}
.swiper-button-next-vertical.swiper-button-disabled, .swiper-button-prev-vertical.swiper-button-disabled {
  display: none;
}

.swiper-button-prev-vertical {
  bottom: auto;
  top: 0;
}
.swiper-button-prev-vertical:after {
  content: "prev";
}

.swiper-button-next-vertical {
  bottom: 0;
  top: auto;
}

.sliderNavWrap {
  position: relative;
}
.sliderNavWrap .swiper-button-next-custom, .sliderNavWrap .swiper-button-prev-custom {
  position: absolute;
  top: 50%;
  z-index: 10;
}
.sliderNavWrap .swiper-button-next-custom {
  right: var(--container-offset);
  transform: translateX(50%) translateY(-50%);
}
.sliderNavWrap .swiper-button-next-custom.swiper-button-disabled {
  opacity: 0;
  pointer-events: none;
}
.sliderNavWrap .swiper-button-prev-custom {
  left: var(--container-offset);
  transform: translateX(-50%) translateY(-50%);
}
.sliderNavWrap .swiper-button-prev-custom.swiper-button-disabled {
  opacity: 0;
  pointer-events: none;
}
.sliderNavWrap .swiper-pagination {
  position: absolute;
  bottom: 10px;
  left: 22px;
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 75%;
}
@media (max-width: 1025px) {
  .sliderNavWrap .swiper-pagination {
    left: 18px;
    bottom: 12px;
  }
}
.sliderNavWrap .swiper-pagination > * {
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  opacity: 1;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  transition: all 0.4s;
  margin: 0 !important;
  padding: 0;
}
.sliderNavWrap .swiper-pagination > *:hover {
  background: rgba(255, 255, 255, 0.75);
}
.sliderNavWrap .swiper-pagination > *.swiper-pagination-bullet-active {
  background: var(--light-color);
  width: 12px;
}

.title {
  margin: 0;
  color: var(--dark-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 769px) {
  .title--mobHid {
    display: none;
  }
}
.title--hid {
  display: none;
}
@media (max-width: 769px) {
  .title--hid {
    display: flex;
  }
}
.title--mb {
  margin-bottom: 45px;
}
@media (max-width: 1201px) {
  .title--mb {
    margin-bottom: 40px;
  }
}
@media (max-width: 769px) {
  .title--mb {
    margin-bottom: 30px;
  }
}
.title--mbMin {
  margin-bottom: 30px;
}
.title--mbMin2 {
  margin-bottom: 25px;
}
@media (max-width: 769px) {
  .title--mbMin2 {
    margin-bottom: 20px;
  }
}
.title--mbMin3 {
  margin-bottom: 25px;
}
@media (max-width: 769px) {
  .title--mbMin3 {
    margin-bottom: 8px;
  }
}
.title--mbMin4 {
  margin-bottom: 18px;
}
@media (max-width: 769px) {
  .title--mbMin4 {
    margin-bottom: 12px;
  }
}
.title--mbMin5 {
  margin-bottom: 40px;
}
@media (max-width: 1025px) {
  .title--mbMin5 {
    margin-bottom: 25px;
  }
}
@media (max-width: 769px) {
  .title--mbMin5 {
    margin-bottom: 15px;
  }
}
.title--mbMin6 {
  margin-bottom: 28px;
}
@media (max-width: 769px) {
  .title--mbMin6 {
    margin-bottom: 20px;
  }
}
.title--mbMin7 {
  margin-bottom: 20px;
}
@media (max-width: 769px) {
  .title--mbMin7 {
    margin-bottom: 15px;
  }
}
.title--mbMin8 {
  margin-bottom: 35px;
}
@media (max-width: 769px) {
  .title--mbMin8 {
    margin-bottom: 20px;
  }
}
.title--mbMax {
  margin-bottom: 50px;
}
@media (max-width: 1201px) {
  .title--mbMax {
    margin-bottom: 40px;
  }
}
@media (max-width: 769px) {
  .title--mbMax {
    margin-bottom: 30px;
  }
}
.title--mbMax2 {
  margin-bottom: 59px;
}
@media (max-width: 769px) {
  .title--mbMax2 {
    margin-bottom: 30px;
  }
}
.title--mbMax3 {
  margin-bottom: 54px;
}
@media (max-width: 769px) {
  .title--mbMax3 {
    margin-bottom: 19px;
  }
}
@media (max-width: 769px) {
  .title--maAdaptiveCentered {
    text-align: center;
    justify-content: center;
  }
}
.title__name {
  font-size: 32px;
  margin: 0;
  line-height: 1.25;
}
@media (max-width: 1201px) {
  .title__name {
    font-size: 26px;
  }
}
@media (max-width: 769px) {
  .title__name {
    font-size: 20px;
  }
}
@media (max-width: 1201px) {
  .title__name--h1 {
    font-size: 28px;
  }
}
@media (max-width: 769px) {
  .title__name--h1 {
    font-size: 24px;
  }
}
.title__name--min {
  font-size: 24px;
}
.title__name--minAdaptive {
  font-size: 24px;
}
@media (max-width: 769px) {
  .title__name--minAdaptive {
    font-size: 20px;
  }
}
.title__link {
  padding: 15px 40px;
  font-size: 14px;
  line-height: 1.14;
}
@media (max-width: 1201px) {
  .title__link {
    font-size: 13px;
    padding: 13px 25px;
  }
}
@media (max-width: 769px) {
  .title__link {
    font-size: 12px;
    padding: 10px;
  }
}
.title__linkBack {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 13px;
  line-height: 1.2;
  color: var(--gray-color);
  transition: all 0.4s;
}
@media (max-width: 769px) {
  .title__linkBack {
    display: none;
  }
}
.title__linkBack:hover {
  color: var(--gray-color-hover-link);
}
.title__linkBackIcon {
  font-size: 24px;
}

.breadCrumbs {
  padding: 19px 0;
}
.breadCrumbs--bg {
  background-color: #f2f5f7;
}
@media (max-width: 769px) {
  .breadCrumbs--mobHid {
    display: none;
  }
}
@media (max-width: 1201px) {
  .breadCrumbs {
    padding: 17px 0;
  }
}
@media (max-width: 769px) {
  .breadCrumbs {
    padding: 15px 0 8px;
  }
}
.breadCrumbs__wrap {
  line-height: 1;
}
.breadCrumbs a {
  font-size: 13px;
  line-height: 1.15;
  color: var(--gray-color);
  position: relative;
  transition: all 0.4s;
}
@media (max-width: 1201px) {
  .breadCrumbs a {
    font-size: 12px;
  }
}
@media (max-width: 769px) {
  .breadCrumbs a {
    font-size: 10px;
  }
}
.breadCrumbs a::after {
  content: " —";
  display: inline-block;
  margin-left: 4px;
  color: var(--gray-color) !important;
}
.breadCrumbs a:hover {
  color: var(--gray-color-hover-lin);
}
.breadCrumbs span {
  font-size: 13px;
  line-height: 1.15;
  color: var(--gray-color);
  position: relative;
  transition: all 0.4s;
}
@media (max-width: 1201px) {
  .breadCrumbs span {
    font-size: 12px;
  }
}
@media (max-width: 769px) {
  .breadCrumbs span {
    font-size: 10px;
  }
}

.pagination {
  display: flex;
  gap: 6px;
  width: 100%;
  justify-content: center;
}
.pagination__btn {
  display: flex;
  gap: 10px;
  padding: 12px;
  min-width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.2;
  color: var(--dark-color);
  border: 1px solid var(--dark-gray-color3-hover-btn);
  cursor: pointer;
  transition: all 0.4s;
}
.pagination__btn:hover {
  border-color: var(--gray-color);
}
.pagination__btn--active {
  background: var(--red-color);
  border-color: var(--red-color) !important;
  color: var(--light-color);
  cursor: default;
}
@media (max-width: 769px) {
  .pagination__btn {
    padding: 10px;
    font-size: 13px;
  }
  .pagination__btn:not(.pagination__btn--nothide) {
    display: none;
  }
  .pagination__btn:nth-child(1) {
    display: flex;
  }
  .pagination__btn:nth-child(2) {
    display: flex;
  }
  .pagination__btn.pagination__btn--active {
    display: flex;
  }
  .pagination__btn:nth-last-child(2) {
    display: flex;
  }
  .pagination__btn:nth-last-child(1) {
    display: flex;
  }
}
.pagination__icon {
  font-size: 16px;
}
@media (max-width: 769px) {
  .pagination__btnText {
    display: none;
  }
}

.graph-modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity var(--transition-time), visibility var(--transition-time);
  -o-transition: opacity var(--transition-time), visibility var(--transition-time);
  transition: opacity var(--transition-time), visibility var(--transition-time);
  background-color: transparent;
  opacity: 1;
  transition: 0.5s;
  background-color: transparent;
}
@media (max-width: 769px) {
  .graph-modal {
    overflow: hidden;
  }
}
.graph-modal:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
.graph-modal.is-open {
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity var(--transition-time), visibility var(--transition-time);
  -o-transition: opacity var(--transition-time), visibility var(--transition-time);
  transition: opacity var(--transition-time), visibility var(--transition-time);
  background-color: rgba(0, 0, 0, 0.4);
  transition: 0.4s;
}
.graph-modal__header {
  display: block;
}
@media (max-width: 769px) {
  .graph-modal__header {
    display: flex;
    align-items: center;
    position: relative;
    min-height: 60px;
    padding: 12px;
    border-bottom: 1px solid #e5e5e5;
  }
  .graph-modal__header .graph-modal__close {
    color: var(--dark-color);
    right: 22px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.graph-modal__container {
  position: relative;
  width: 1000px;
  margin: 50px auto;
  vertical-align: middle;
  background-color: #fff;
  border-radius: 9px;
  padding: 20px;
  cursor: default;
  text-align: left;
  display: none;
  opacity: 0;
  transition: 0.4s !important;
}
.graph-modal__container.graph-modal-open {
  display: inline-block;
  opacity: 0;
}
.graph-modal__container.graph-modal-open[data-graph-target=mainMenu] {
  padding-right: 0;
}
.graph-modal__container.graph-modal-open[data-graph-target=mainMenu] .mainMenuModal__content {
  padding-right: 20px;
}
.graph-modal__container.time {
  filter: brightness(0.6);
}
.graph-modal__container.graph-modal-open-ok {
  opacity: 1;
}
.graph-modal__container.close {
  display: inline-block;
}
.graph-modal__container.lr {
  display: flex;
  flex-direction: column;
  transform: translateX(-100%) !important;
  transition: transform 0.5s;
  opacity: 1 !important;
}
.graph-modal__container.lr.graph-modal-open {
  transform: translateX(0) !important;
}
.graph-modal__container.lr .mainMenuModal__content {
  overflow: auto;
}

.graph-modal__close {
  position: absolute;
  cursor: pointer;
  right: 19px;
  top: 19px;
  width: initial;
  height: initial;
  background: none;
  color: var(--gray-color);
  transition: all 0.4s;
  font-size: 24px;
}
.graph-modal__close:hover {
  color: var(--gray-color-hover-link);
}

.modalLeft {
  width: calc(100% - 20px);
  position: absolute;
  left: 0;
  bottom: 70px;
  height: calc(100% - 70px);
  margin: 0;
  overflow: auto;
}

.modalCity {
  width: calc(100% - 40px);
  max-width: 980px;
  padding: 40px 50px;
}
@media (max-width: 769px) {
  .modalCity {
    width: 100%;
    border-radius: 0;
    flex-direction: column;
    margin: 0 !important;
    max-height: calc(100% - 70px);
    position: absolute;
    bottom: 70px;
    left: 0;
    padding: 0;
    opacity: 1 !important;
    transition: transform 0.4s !important;
    transform: translateX(-100%);
  }
  .modalCity.graph-modal-open {
    display: flex;
  }
  .modalCity.graph-modal-open-ok {
    transform: none;
  }
}
.modalCity__title {
  margin: 0 0 25px;
  font-weight: 700;
  font-size: 32px;
  line-height: 1.25;
  color: var(--dark-color);
}
@media (max-width: 1025px) {
  .modalCity__title {
    font-size: 24px;
    margin-bottom: 20px;
  }
}
@media (max-width: 769px) {
  .modalCity__title {
    font-size: 16px;
    text-align: center;
    margin-bottom: 0;
    width: 100%;
  }
}
.modalCity__content {
  column-count: 4;
  column-fill: balance;
  column-gap: 60px;
  max-width: 830px;
}
@media (max-width: 1025px) {
  .modalCity__content {
    column-count: 3;
  }
}
@media (max-width: 601px) {
  .modalCity__content {
    column-count: 2;
  }
}
@media (max-width: 401px) {
  .modalCity__content {
    column-count: 1;
  }
}
.modalCity__listWrap {
  page-break-inside: avoid;
  break-inside: avoid;
  display: flex;
  gap: 20px;
  margin: 0 0 30px;
}
.modalCity__listWrap:last-of-type {
  margin-bottom: 0;
}
.modalCity__listWrap > span {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.4;
  color: var(--red-color);
}
.modalCity__list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.modalCity__link {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  color: var(--gray-color);
  transition: all 0.5s;
}
.modalCity__link:hover, .modalCity__link--active {
  color: var(--dark-color);
}
@media (max-width: 769px) {
  .modalCity__link {
    color: var(--dark-color);
  }
  .modalCity__link--active {
    color: var(--red-color);
  }
}
.modalCity__search {
  display: block;
  width: 100%;
  margin-bottom: 25px;
  max-width: 420px;
}
@media (max-width: 769px) {
  .modalCity__search {
    margin-bottom: 30px;
  }
}
.modalCity__searchInp {
  display: block;
  width: 100%;
  border: 1px solid var(--dark-gray-color3-hover-btn);
  border-radius: 5px;
  background-color: transparent;
  padding: 11px 40px 11px 11px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: var(--dark-color);
  outline: none;
  background: url("../img/svg/search.svg") no-repeat center right 8px/24px;
}
.modalCity__searchInp::placeholder {
  color: var(--gray-color);
}
@media (max-width: 769px) {
  .modalCity__searchInp {
    font-size: 16px;
    padding: 15.5px 55px 15.5px 20px;
    background-position-x: right 20px;
  }
}
@media (max-width: 769px) {
  .modalCity__contentWrap {
    padding: 18px 12px;
    overflow: auto;
  }
}
.modalSearch {
  display: none;
  opacity: 1;
  background-color: transparent;
  overflow: hidden;
}
@media (max-width: 769px) {
  .modalSearch {
    width: 100%;
    border-radius: 0;
    flex-direction: column;
    margin: 0 !important;
    max-height: calc(100% - 70px);
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    opacity: 1 !important;
    transition: transform 0.4s, filter 0.4s !important;
    transform: translateY(-100%);
  }
  .modalSearch.graph-modal-open {
    display: flex;
  }
  .modalSearch.graph-modal-open-ok {
    transform: none;
  }
}
.modalSearch__header {
  background-color: var(--light-color);
  height: 80px;
  min-height: 80px;
}
.modalSearch__contentWrap {
  opacity: 0;
  pointer-events: none;
  transition: height 0.4s, opacity 0.4s 0.4s, padding-top 0.4s, padding-bottom 0.4s;
  background-color: var(--light-color);
  height: 0;
  overflow: auto;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 12px;
  padding-right: 12px;
}
.modalSearch__contentWrap--active {
  padding: 24px 12px;
  transition: opacity 0.4s;
  opacity: 1;
  height: calc(100vh - 150px);
  pointer-events: all;
}

.modalMain {
  display: none;
}
@media (max-width: 769px) {
  .modalMain {
    width: calc(100% - 40px);
    border-radius: 0;
    flex-direction: column;
    margin: 0 !important;
    max-height: calc(100% - 70px);
    position: absolute;
    bottom: 70px;
    left: 0;
    padding: 0;
    opacity: 1 !important;
    transition: transform 0.4s, filter 0.4s !important;
    transform: translateX(-100%);
  }
  .modalMain.graph-modal-open {
    display: flex;
  }
  .modalMain.graph-modal-open-ok {
    transform: none;
  }
}
@media (max-width: 769px) {
  .modalMain__contentWrap {
    overflow: auto;
  }
}
.modalMain__city {
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 14px;
  line-height: 1;
  color: var(--dark-color);
}
.modalMain__iconCity {
  font-size: 24px;
  color: var(--red-color);
}
.modalMain__btnCategories {
  display: flex;
  flex-direction: column;
  margin-bottom: 3px;
}
.modalMain__btnCategories--bg {
  background-color: var(--dark-gray-color3);
}
.modalMain__btnCategor {
  padding: 15px 20px;
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
  color: var(--dark-color);
}
.modalMain__btnIcon {
  font-size: 28px;
  color: var(--red-color);
}
.modalMain__btnIcon--arrow {
  color: var(--dark-color);
  font-size: 24px;
  margin-left: auto;
}
.modalMain__btnIcon--white {
  color: var(--light-color);
  font-size: 24px;
}
.modalMain__telsBlock {
  padding: 20px;
  background-color: var(--dark-gray-color3);
  margin-bottom: 3px;
}
.modalMain__tel {
  display: flex;
  gap: 15px;
  align-items: center;
  color: var(--dark-color);
  font-size: 20px;
  line-height: 1.15;
  width: fit-content;
  font-weight: 700;
  margin-bottom: 20px;
}
.modalMain__socWrap {
  display: flex;
  gap: 20px 40px;
  flex-wrap: wrap;
  padding: 20px;
  background-color: var(--dark-gray-color3);
}
.modalMain__soc {
  font-size: 48px;
}
.modalMain__soc span {
  pointer-events: none;
}
.modalMain__menuWrap {
  padding: 15px 20px;
  width: 100%;
}
.modalMain__menuTitle {
  font-size: 18px;
  color: var(--dark-color);
  line-height: 1.15;
  font-weight: 700;
  margin-bottom: 20px;
}
.modalMain__menuList {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 20px;
  align-items: flex-start;
}
.modalMain__menuListItem {
  display: block;
  width: calc(50% - 10px);
}
.modalMain__menuListItemImg {
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 10px;
  object-fit: cover;
  overflow: hidden;
  margin: 0 auto 5px auto;
}
.modalMain__menuListItemText {
  font-weight: 700;
  font-size: 14px;
  line-height: 1.2;
  color: var(--dark-color);
  text-align: center;
}

.modalCategor {
  display: none;
}
@media (max-width: 769px) {
  .modalCategor {
    width: calc(100% - 40px);
    border-radius: 0;
    flex-direction: column;
    margin: 0 !important;
    height: calc(100% - 70px);
    position: absolute;
    bottom: 70px;
    left: 0;
    padding: 0;
    opacity: 1 !important;
    transition: transform 0.4s, filter 0.4s !important;
    transform: translateX(-100%);
  }
  .modalCategor.graph-modal-open {
    display: flex;
  }
  .modalCategor.graph-modal-open-ok {
    transform: none;
  }
}
@media (max-width: 769px) {
  .modalCategor__contentWrap {
    overflow: auto;
    padding: 24px 10px;
  }
}
.modalCategor__back {
  font-size: 28px;
  color: var(--dark-color);
  display: flex;
  align-items: center;
}
.modalCategor__textBack {
  font-size: 18px;
  line-height: 1.15;
  font-weight: 700;
  margin-left: 15px;
}
.modalCategor__categorWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 20px;
  align-items: flex-start;
}
.modalCategor__img {
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 10px;
  object-fit: cover;
  overflow: hidden;
  margin: 0 auto 5px auto;
}
.modalCategor__categor {
  display: block;
  width: calc(50% - 10px);
}
.modalCategor__name {
  font-weight: 700;
  font-size: 14px;
  line-height: 1.2;
  color: var(--dark-color);
  text-align: center;
}

.modalAuth {
  width: calc(100% - 40px);
  max-width: 440px;
  padding: 40px 50px;
}
@media (max-width: 769px) {
  .modalAuth {
    width: 100%;
    max-width: 100%;
    border-radius: 0;
    flex-direction: column;
    margin: 0 !important;
    max-height: calc(100% - 70px);
    position: absolute;
    bottom: 70px;
    left: 0;
    padding: 0;
    opacity: 1 !important;
    transition: transform 0.4s !important;
    transform: translateY(100%);
  }
  .modalAuth.graph-modal-open {
    display: flex;
  }
  .modalAuth.graph-modal-open-ok {
    transform: none;
  }
}
@media (max-width: 769px) {
  .modalAuth__header {
    border: none;
  }
}
.modalAuth__title {
  margin: 0 0 30px;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.25;
  color: var(--dark-color);
  text-align: center;
  width: 100%;
}
@media (max-width: 1025px) {
  .modalAuth__title {
    font-size: 22px;
    margin-bottom: 20px;
  }
}
@media (max-width: 769px) {
  .modalAuth__title {
    font-size: 20px;
    text-align: left;
    margin-bottom: 0;
  }
}
.modalAuth__form {
  display: flex;
  gap: 20px;
  flex-direction: column;
}
.modalAuth__wrapBtn {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.modalAuth__btnMM {
  display: block;
  width: fit-content;
  font-size: 13px;
  line-height: 1.3;
  color: var(--gray-color);
  transition: all 0.4s;
}
.modalAuth__btnMM:hover {
  color: var(--gray-color-hover-link);
}
@media (max-width: 769px) {
  .modalAuth__contentWrap {
    overflow: auto;
    padding: 0 12px 20px;
  }
}

.modalRecovery {
  width: calc(100% - 40px);
  max-width: 440px;
  padding: 40px 50px;
}
@media (max-width: 769px) {
  .modalRecovery {
    width: 100%;
    max-width: 100%;
    border-radius: 0;
    flex-direction: column;
    margin: 0 !important;
    max-height: calc(100% - 70px);
    position: absolute;
    bottom: 70px;
    left: 0;
    padding: 0;
    opacity: 1 !important;
    transition: transform 0.4s !important;
    transform: translateY(100%);
  }
  .modalRecovery.graph-modal-open {
    display: flex;
  }
  .modalRecovery.graph-modal-open-ok {
    transform: none;
  }
}
@media (max-width: 769px) {
  .modalRecovery__header {
    border: none;
  }
}
.modalRecovery__title {
  margin: 0 0 30px;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.25;
  color: var(--dark-color);
  text-align: center;
  width: 100%;
}
@media (max-width: 1025px) {
  .modalRecovery__title {
    font-size: 22px;
    margin-bottom: 20px;
  }
}
@media (max-width: 769px) {
  .modalRecovery__title {
    font-size: 20px;
    text-align: left;
    margin-bottom: 0;
  }
}
.modalRecovery__form {
  display: flex;
  gap: 20px;
  flex-direction: column;
}
.modalRecovery__wrapBtn {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.modalRecovery__btnMM {
  display: block;
  width: fit-content;
  font-size: 13px;
  line-height: 1.3;
  color: var(--gray-color);
  transition: all 0.4s;
}
.modalRecovery__btnMM:hover {
  color: var(--gray-color-hover-link);
}
@media (max-width: 769px) {
  .modalRecovery__contentWrap {
    overflow: auto;
    padding: 0 12px 20px;
  }
}

.regModal {
  width: calc(100% - 40px);
  max-width: 800px;
  padding: 40px 50px;
}
@media (max-width: 769px) {
  .regModal {
    width: 100%;
    max-width: 100%;
    border-radius: 0;
    flex-direction: column;
    margin: 0 !important;
    max-height: calc(100% - 70px);
    position: absolute;
    bottom: 70px;
    left: 0;
    padding: 0;
    opacity: 1 !important;
    transition: transform 0.4s !important;
    transform: translateY(100%);
  }
  .regModal.graph-modal-open {
    display: flex;
  }
  .regModal.graph-modal-open-ok {
    transform: none;
  }
}
@media (max-width: 769px) {
  .regModal__header {
    border: none;
  }
}
.regModal__title {
  margin: 0 0 30px;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.25;
  color: var(--dark-color);
  text-align: left;
  width: 100%;
}
@media (max-width: 1025px) {
  .regModal__title {
    font-size: 22px;
    margin-bottom: 20px;
  }
}
@media (max-width: 769px) {
  .regModal__title {
    font-size: 20px;
    text-align: left;
    margin-bottom: 0;
  }
}
.regModal__form {
  display: flex;
  gap: 20px;
  flex-direction: column;
}
@media (max-width: 769px) {
  .regModal__form {
    gap: 12px;
  }
}
.regModal__wrapBtn {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.regModal__btnMM {
  display: block;
  width: fit-content;
  font-size: 13px;
  line-height: 1.3;
  color: var(--gray-color);
  transition: all 0.4s;
}
.regModal__btnMM:hover {
  color: var(--gray-color-hover-link);
}
@media (max-width: 769px) {
  .regModal__contentWrap {
    overflow: auto;
    padding: 0 12px 20px;
  }
}
.regModal__colFrom {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
@media (max-width: 769px) {
  .regModal__colFrom {
    gap: 12px;
  }
}
.regModal__colFromItem {
  width: calc(50% - 10px);
}
@media (max-width: 769px) {
  .regModal__colFromItem {
    width: 100%;
  }
}
.regModal__btnWr {
  display: flex;
  flex-direction: column;
}
.regModal__chWr {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
@media (max-width: 769px) {
  .regModal__chWr {
    margin-top: 8px;
  }
}
@media (max-width: 769px) {
  .regModal__submWr {
    margin: 8px 0;
  }
}

.brendsModal {
  display: none;
}
@media (max-width: 769px) {
  .brendsModal {
    width: 100%;
    max-width: 100%;
    border-radius: 0;
    flex-direction: column;
    margin: 0 !important;
    max-height: calc(100% - 70px);
    position: absolute;
    bottom: 70px;
    left: 0;
    padding: 0;
    opacity: 1 !important;
    transition: transform 0.4s, filter 0.4s !important;
    transform: translateY(100%);
  }
  .brendsModal.graph-modal-open {
    display: flex;
  }
  .brendsModal.graph-modal-open-ok {
    transform: none;
  }
}
@media (max-width: 769px) {
  .brendsModal__title {
    font-size: 16px;
    text-align: center;
    margin-bottom: 0;
    width: 100%;
  }
}
.brendsModal__content {
  padding-top: 15px;
}
.brendsModal__bottom {
  padding: 10px 12px;
  border-top: 1px solid #e2e8ec;
}
@media (max-width: 769px) {
  .brendsModal__contentWrap {
    overflow: auto;
    padding: 0 12px 20px;
  }
}

.filtersModal {
  display: none;
}
@media (max-width: 769px) {
  .filtersModal {
    width: 100%;
    max-width: 100%;
    border-radius: 0;
    flex-direction: column;
    margin: 0 !important;
    max-height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0;
    opacity: 1 !important;
    transition: transform 0.4s, filter 0.4s !important;
    transform: translateY(100%);
  }
  .filtersModal.graph-modal-open {
    display: flex;
  }
  .filtersModal.graph-modal-open-ok {
    transform: none;
  }
}
@media (max-width: 769px) {
  .filtersModal__title {
    font-size: 16px;
    width: 100%;
    text-align: center;
    margin-bottom: 0;
  }
}
.filtersModal__content {
  padding-top: 15px;
}
.filtersModal__bottom {
  padding: 10px 12px;
  border-top: 1px solid #e2e8ec;
}
@media (max-width: 769px) {
  .filtersModal__contentWrap {
    overflow: auto;
    padding: 0 12px 20px;
  }
}
.filtersModal__contentGap {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.filtersModal .catalogPageCatalogTegs {
  align-items: center;
}
.filtersModal .catalogPageCatalogTegs > .catalogPageCatalogTegs__btn--reset {
  order: -1;
  border-radius: 50px;
  width: 28px;
  height: 28px;
  padding: 0;
  justify-content: center;
  align-items: center;
  line-height: 1;
  text-align: center;
}
.filtersModal .catalogPageCatalogTegs > .catalogPageCatalogTegs__btn--reset .catalogPageCatalogTegs__textC {
  display: none;
}
.filtersModal .catalogPageCatalogTegs > .catalogPageCatalogTegs__btn--reset .catalogPageCatalogTegs__close {
  margin-left: 0;
  line-height: 1;
}

.buyersModal {
  display: none;
}
@media (max-width: 769px) {
  .buyersModal {
    width: 100%;
    max-width: 100%;
    border-radius: 0;
    flex-direction: column;
    margin: 0 !important;
    max-height: calc(100% - 70px);
    position: absolute;
    bottom: 70px;
    left: 0;
    padding: 0;
    opacity: 1 !important;
    transition: transform 0.4s, filter 0.4s !important;
    transform: translateY(100%);
  }
  .buyersModal.graph-modal-open {
    display: flex;
  }
  .buyersModal.graph-modal-open-ok {
    transform: none;
  }
}
@media (max-width: 769px) {
  .buyersModal__title {
    font-size: 16px;
    width: 100%;
    text-align: center;
    margin-bottom: 0;
  }
}
.buyersModal__content {
  padding-top: 15px;
  color: var(--gray-color);
}
@media (max-width: 769px) {
  .buyersModal__contentWrap {
    overflow: auto;
    padding: 0 12px 20px;
  }
}

.wholesaleBuyersModal {
  display: none;
}
@media (max-width: 769px) {
  .wholesaleBuyersModal {
    width: 100%;
    max-width: 100%;
    border-radius: 0;
    flex-direction: column;
    margin: 0 !important;
    max-height: calc(100% - 70px);
    position: absolute;
    bottom: 70px;
    left: 0;
    padding: 0;
    opacity: 1 !important;
    transition: transform 0.4s, filter 0.4s !important;
    transform: translateY(100%);
  }
  .wholesaleBuyersModal.graph-modal-open {
    display: flex;
  }
  .wholesaleBuyersModal.graph-modal-open-ok {
    transform: none;
  }
}
@media (max-width: 769px) {
  .wholesaleBuyersModal__title {
    font-size: 16px;
    width: 100%;
    text-align: center;
    margin-bottom: 0;
  }
}
.wholesaleBuyersModal__content {
  padding-top: 15px;
  color: var(--gray-color);
}
@media (max-width: 769px) {
  .wholesaleBuyersModal__contentWrap {
    overflow: auto;
    padding: 0 12px 20px;
  }
}

.addAddressModal {
  width: calc(100% - 40px);
  max-width: 840px;
  padding: 40px 50px;
}
@media (max-width: 769px) {
  .addAddressModal {
    width: 100%;
    max-width: 100%;
    border-radius: 0;
    flex-direction: column;
    margin: 0 !important;
    max-height: calc(100% - 70px);
    position: absolute;
    bottom: 70px;
    left: 0;
    padding: 0;
    opacity: 1 !important;
    transition: transform 0.4s !important;
    transform: translateY(100%);
  }
  .addAddressModal.graph-modal-open {
    display: flex;
  }
  .addAddressModal.graph-modal-open-ok {
    transform: none;
  }
}
.addAddressModal__title {
  margin: 0 0 30px;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.25;
  color: var(--dark-color);
  text-align: left;
  width: 100%;
}
@media (max-width: 1025px) {
  .addAddressModal__title {
    font-size: 20px;
    margin-bottom: 20px;
  }
}
@media (max-width: 769px) {
  .addAddressModal__title {
    font-size: 16px;
    margin-bottom: 0;
    text-align: center;
  }
}
@media (max-width: 769px) {
  .addAddressModal__contentWrap {
    overflow: auto;
    padding: 12px 12px 20px;
  }
}
.addAddressModal__form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.allReviewsModal {
  display: none;
}
@media (max-width: 769px) {
  .allReviewsModal {
    width: 100%;
    max-width: 100%;
    border-radius: 0;
    flex-direction: column;
    margin: 0 !important;
    height: calc(100% - 70px);
    max-height: calc(100% - 70px);
    position: absolute;
    bottom: 70px;
    left: 0;
    padding: 0;
    opacity: 1 !important;
    transition: transform 0.4s, filter 0.4s !important;
    transform: translateY(100%);
  }
  .allReviewsModal.graph-modal-open {
    display: flex;
  }
  .allReviewsModal.graph-modal-open-ok {
    transform: none;
  }
}
@media (max-width: 769px) {
  .allReviewsModal__title {
    font-size: 16px;
    width: 100%;
    text-align: center;
    margin-bottom: 0;
  }
}
.allReviewsModal__content {
  padding-top: 15px;
  overflow: auto;
}
@media (max-width: 769px) {
  .allReviewsModal__contentWrap {
    overflow: auto;
    padding: 15px 12px 20px;
  }
}

.oneClickModal {
  width: calc(100% - 40px);
  max-width: 890px;
  padding: 40px 50px;
}
@media (max-width: 769px) {
  .oneClickModal {
    width: 100%;
    border-radius: 0;
    flex-direction: column;
    margin: 0 !important;
    max-height: calc(100% - 70px);
    position: absolute;
    bottom: 70px;
    left: 0;
    padding: 0;
    opacity: 1 !important;
    transition: transform 0.4s !important;
    transform: translateY(100%);
  }
  .oneClickModal.graph-modal-open {
    display: flex;
  }
  .oneClickModal.graph-modal-open-ok {
    transform: none;
  }
}
.oneClickModal__title {
  margin: 0 0 30px;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.25;
  color: var(--dark-color);
}
@media (max-width: 1025px) {
  .oneClickModal__title {
    font-size: 22x;
    margin-bottom: 20px;
  }
}
@media (max-width: 769px) {
  .oneClickModal__title {
    font-size: 20px;
    margin-bottom: 0;
    width: 100%;
  }
}
@media (max-width: 769px) {
  .oneClickModal__contentWrap {
    overflow: auto;
    padding: 15px 12px 20px;
  }
}
.oneClickModal__wrapLabel {
  display: flex;
  gap: 20px;
}
@media (max-width: 769px) {
  .oneClickModal__wrapLabel {
    gap: 12px;
    flex-direction: column;
  }
}
.oneClickModal__wrapLabel > * {
  width: calc(33.3333333333% - 13.3333333333px);
}
@media (max-width: 769px) {
  .oneClickModal__wrapLabel > * {
    width: 100%;
  }
}
.oneClickModal__form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
}
.oneClickModal__wrapBtn {
  display: flex;
  gap: 36px;
  align-items: center;
}
@media (max-width: 769px) {
  .oneClickModal__wrapBtn {
    flex-direction: column;
    gap: 12px;
  }
}
.oneClickModal__submit {
  width: calc(33.3333333333% - 13.3333333333px);
}
@media (max-width: 769px) {
  .oneClickModal__submit {
    width: 100%;
  }
}
.oneClickModal__wrapBtnText {
  font-size: 12px;
  color: #b8c0c5;
}
@media (max-width: 769px) {
  .oneClickModal__wrapBtnText {
    text-align: center;
  }
}
.oneClickModal .basketProdCard__priceMain {
  font-size: 16px;
}

.quickViewModal {
  width: calc(100% - 40px);
  max-width: 1060px;
  padding: 40px 50px;
}
@media (max-width: 1025px) {
  .quickViewModal {
    padding: 40px 20px;
  }
}
@media (max-width: 769px) {
  .quickViewModal {
    display: none !important;
  }
}
.quickViewModal .product__topRight {
  width: 420px;
}
@media (max-width: 1025px) {
  .quickViewModal .product__topRight {
    width: fit-content;
  }
}
.quickViewModal .product__gallery--modal {
  position: relative;
  top: 0;
}

.addReviewModal {
  width: calc(100% - 40px);
  max-width: 730px;
  padding: 40px 50px;
}
@media (max-width: 769px) {
  .addReviewModal {
    width: 100%;
    max-width: 100%;
    border-radius: 0;
    flex-direction: column;
    margin: 0 !important;
    max-height: calc(100% - 70px);
    position: absolute;
    bottom: 70px;
    left: 0;
    padding: 0;
    opacity: 1 !important;
    transition: transform 0.4s, filter 0.4s !important;
    transform: translateY(100%);
  }
  .addReviewModal.graph-modal-open {
    display: flex;
  }
  .addReviewModal.graph-modal-open-ok {
    transform: none;
  }
}
.addReviewModal__formContentSubmModal {
  padding-top: 16px;
  padding-bottom: 16px;
}
@media (max-width: 769px) {
  .addReviewModal__formContentSubmModal {
    padding-top: 18px;
    padding-bottom: 18px;
  }
}
.addReviewModal__title {
  display: none;
}
@media (max-width: 769px) {
  .addReviewModal__title {
    display: block;
    text-align: center;
    font-size: 16px;
    width: 100%;
    margin-bottom: 0;
  }
}
.addReviewModal__content {
  padding-top: 15px;
  overflow: auto;
}
@media (max-width: 769px) {
  .addReviewModal__contentWrap {
    overflow: auto;
    padding: 15px 12px 20px;
  }
}
.addReviewModal__title2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.25;
  margin: 0;
}
@media (max-width: 769px) {
  .addReviewModal__title2 {
    font-size: 22px;
    margin-bottom: 15px;
  }
}
.addReviewModal__subTtitle {
  font-weight: 400;
  font-size: 20px;
  line-height: 1.5;
  margin: 0 0 42px;
}
@media (max-width: 769px) {
  .addReviewModal__subTtitle {
    font-size: 16px;
    margin-bottom: 20px;
  }
}
.addReviewModal__form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.addReviewModal__formWrap {
  display: flex;
  gap: 20px;
}
@media (max-width: 769px) {
  .addReviewModal__formWrap {
    flex-direction: column;
    gap: 6px;
  }
}
.addReviewModal__formCat {
  width: 130px;
  font-size: 14px;
  line-height: 30px;
}
@media (max-width: 769px) {
  .addReviewModal__formCat {
    width: 100%;
  }
}
.addReviewModal__formContent {
  width: calc(100% - 150px);
}
@media (max-width: 769px) {
  .addReviewModal__formContent {
    width: 100%;
  }
}
.addReviewModal__formColumns2 {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.addReviewModal__formColumns2 > * {
  width: calc(50% - 7.5px);
}
@media (max-width: 769px) {
  .addReviewModal__formColumns2 > * {
    width: 100%;
  }
}
.addReviewModal__formColumnsTextarea {
  display: flex;
  flex-direction: column;
  gap: 17px;
}
.addReviewModal__formColumnsTextareaText {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.2;
  color: var(--gray-color);
  margin: 0 0 10px;
}
.addReviewModal__formColumnsTextareaT {
  height: 110px;
}
.addReviewModal__formContentSubm {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
@media (max-width: 769px) {
  .addReviewModal__formContentSubm {
    gap: 20px;
  }
}
.addReviewModal__formContentSubmText {
  font-size: 10px;
  line-height: 12px;
  color: var(--gray-color);
  text-align: center;
}
.dopProdModal {
  width: calc(100% - 60px);
  max-width: 880px;
  padding: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  position: fixed;
  margin: 0;
  border-radius: 0;
  left: initial;
}
@media (max-width: 769px) {
  .dopProdModal {
    width: 100%;
    max-width: 100%;
    height: initial;
    top: initial;
    border-radius: 0;
    flex-direction: column;
    margin: 0 !important;
    max-height: calc(100% - 70px);
    position: absolute;
    bottom: 70px;
    left: 0;
    padding: 0;
    opacity: 1 !important;
    transition: transform 0.4s, filter 0.4s !important;
    transform: translateY(100%);
  }
  .dopProdModal.graph-modal-open {
    display: flex;
  }
  .dopProdModal.graph-modal-open-ok {
    transform: none;
  }
}
.dopProdModal__title {
  display: none;
}
@media (max-width: 769px) {
  .dopProdModal__title {
    display: block;
    text-align: center;
    font-size: 16px;
    width: 100%;
    margin-bottom: 0;
  }
}
.dopProdModal__content {
  overflow: auto;
}
.dopProdModal__btnCloseMob {
  display: none;
}
@media (max-width: 769px) {
  .dopProdModal__btnCloseMob {
    display: block;
  }
}
.dopProdModal__btnClose {
  display: block;
  position: absolute;
  top: 10px;
  width: fit-content;
  left: 0;
  transform: translateX(-52px);
  border-radius: 50%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}
.dopProdModal__btnClose svg {
  display: block;
}
.dopProdModal__btnClose svg rect {
  transition: all 0.4s;
}
.dopProdModal__btnClose:hover rect {
  fill: #e2e8ec;
}
.dopProdModal__filters {
  width: 300px;
  padding: 30px 40px;
  overflow: auto;
  height: 100%;
}
@media (max-width: 1025px) {
  .dopProdModal__filters {
    width: 50%;
  }
}
@media (max-width: 769px) {
  .dopProdModal__filters {
    display: none;
  }
}
.dopProdModal__filtersName {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.5;
  margin: 0 0 30px;
}
.dopProdModal__contentWrap {
  height: 100%;
  display: flex;
}
@media (max-width: 769px) {
  .dopProdModal__contentWrap {
    flex-direction: column;
    gap: 15px;
    padding: 15px 12px;
    overflow: auto;
  }
}
.dopProdModal__cardsWrap {
  width: 400px;
  height: 100%;
  overflow: auto;
}
@media (max-width: 1025px) {
  .dopProdModal__cardsWrap {
    width: 50%;
  }
}
@media (max-width: 769px) {
  .dopProdModal__cardsWrap {
    width: 100%;
    height: initial;
    overflow: initial;
  }
}
.dopProdModal__card {
  padding: 20px;
  display: flex;
  gap: 30px;
  transition: all 0.4s;
}
.dopProdModal__card:hover .productCard__btn {
  opacity: 1;
}
.dopProdModal__card:hover .productCard__imgTabs {
  opacity: 1;
}
@media (max-width: 1025px) {
  .dopProdModal__card:hover:hover {
    background: #f2f5f7;
  }
}
@media (max-width: 769px) {
  .dopProdModal__card:hover:hover {
    background-color: transparent;
  }
}
.dopProdModal__card--active {
  background: #f2f5f7;
}
@media (max-width: 1025px) {
  .dopProdModal__card--active {
    background-color: transparent;
  }
}
.dopProdModal__cardImgWrap {
  width: 160px;
}
.dopProdModal__cardInfo {
  width: 170px;
}
.dopProdModal__cardImg {
  width: 100%;
  aspect-ratio: 1;
  position: relative;
  background-color: var(--light-color);
}
.dopProdModal__cardImg img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin-bottom: 3px;
}
.dopProdModal__cardName {
  font-size: 14px;
  line-height: 1.3;
  color: var(--dark-color);
  margin-bottom: 15px;
  display: inline-block;
  transition: all 0.4s;
}
.dopProdModal__cardName:hover {
  color: var(--red-color);
}
.dopProdModal__cardPrice {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 17px;
}
.dopProdModal__cardPrice span {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.2;
  text-decoration-line: line-through;
  color: #b8c0c5;
  margin-left: 4px;
}
.dopProdModal__cardBtn1 {
  margin-bottom: 6px;
  width: 120px;
}
.dopProdModal__cardBtn2 {
  width: 120px;
}
@media (max-width: 1025px) {
  .dopProdModal__cardBtn2 {
    display: none;
  }
}
.dopProdModal__cardBtn3 {
  width: 120px;
  display: none;
}
@media (max-width: 1025px) {
  .dopProdModal__cardBtn3 {
    display: flex;
  }
}
.dopProdModal__prod {
  width: 480px;
  background: #f2f5f7;
  padding: 20px;
  overflow: auto;
  height: 100%;
}
@media (max-width: 1025px) {
  .dopProdModal__prod {
    display: none;
  }
}
.dopProdModal__prod:hover .productCard__btns {
  opacity: 1;
}
.dopProdModal__prod:hover .productCard__imgTabs {
  opacity: 1;
}
.dopProdModal__prodImgWrap {
  width: 100%;
  margin-bottom: 14px;
}
.dopProdModal__prodImg {
  position: relative;
  aspect-ratio: 1;
  width: 100%;
  border-radius: 6px;
}
.dopProdModal__prodImg img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  margin-bottom: 4px;
  object-fit: contain;
}
.dopProdModal__prodName {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.5;
  color: var(--dark-color);
  margin: 0 0 20px;
}
.dopProdModal__prodWrap {
  background: #ffffff;
  border-radius: 5px;
  padding: 20px;
}
.dopProdModal__filtersMob {
  display: none !important;
}
@media (max-width: 769px) {
  .dopProdModal__filtersMob {
    display: flex !important;
  }
}

.prodPodrModal {
  width: calc(100% - 40px);
  max-width: 1200px;
  padding: 0;
}
@media (max-width: 769px) {
  .prodPodrModal {
    width: 100%;
    max-width: 100%;
    border-radius: 0;
    flex-direction: column;
    margin: 0 !important;
    max-height: calc(100% - 70px);
    position: absolute;
    bottom: 70px;
    left: 0;
    margin: 0;
    padding: 0;
    opacity: 1 !important;
    transition: transform 0.4s, filter 0.4s !important;
    transform: translateY(100%);
  }
  .prodPodrModal.graph-modal-open {
    display: flex;
  }
  .prodPodrModal.graph-modal-open-ok {
    transform: none;
  }
}
@media (max-width: 769px) {
  .prodPodrModal__header {
    background-color: transparent;
    border: none;
    height: 0;
    padding: 0;
    min-height: initial;
  }
}
.prodPodrModal__content {
  padding-top: 15px;
  overflow: auto;
}
.prodPodrModal .clue__clue {
  right: -15px;
}
@media (max-width: 1025px) {
  .prodPodrModal__contentWrap {
    overflow: auto;
    padding: 15px 12px 20px;
  }
}
.prodPodrModal__prod {
  display: block;
  background-color: transparent;
  padding: 0;
  width: 100%;
}
.prodPodrModal__prod .dopProdModal__prodWrap {
  padding: 0;
}
@media (max-width: 769px) {
  .prodPodrModal__prod .productCard__btns {
    right: initial;
    top: initial;
    left: 0;
    bottom: 10px;
  }
}
@media (max-width: 769px) {
  .prodPodrModal__btnCloseMob {
    top: 25px !important;
    z-index: 2;
  }
}

.comparisonModal {
  display: none;
}
@media (max-width: 769px) {
  .comparisonModal {
    width: 100%;
    max-width: 100%;
    border-radius: 0;
    flex-direction: column;
    margin: 0 !important;
    max-height: calc(100% - 70px);
    position: absolute;
    bottom: 70px;
    left: 0;
    padding: 0;
    opacity: 1 !important;
    transition: transform 0.4s, filter 0.4s !important;
    transform: translateY(100%);
  }
  .comparisonModal.graph-modal-open {
    display: flex;
  }
  .comparisonModal.graph-modal-open-ok {
    transform: none;
  }
}
@media (max-width: 769px) {
  .comparisonModal__title {
    font-size: 16px;
    width: 100%;
    text-align: center;
    margin-bottom: 0;
  }
}
.comparisonModal__content {
  padding-top: 15px;
}
.comparisonModal__bottom {
  padding: 10px 12px;
  border-top: 1px solid #e2e8ec;
}
@media (max-width: 769px) {
  .comparisonModal__contentWrap {
    overflow: auto;
    padding: 20px 12px;
  }
}
.comparisonModal__closeL, .comparisonModal__closeR {
  font-size: 28px;
}
.comparisonModal__label {
  margin-bottom: 12px;
}
@media (max-width: 769px) {
  .comparisonModal .swiper-button-next-custom {
    display: none !important;
  }
}
@media (max-width: 769px) {
  .comparisonModal .swiper-button-prev-custom {
    display: none !important;
  }
}

.modalReviers {
  width: calc(100% - 80px);
  max-width: 1180px;
  padding: 0;
  max-height: 580px;
  overflow: initial;
}
@media (max-width: 769px) {
  .modalReviers {
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    border-radius: 0;
    flex-direction: column;
    margin: 0 !important;
    max-height: calc(100% - 70px);
    height: calc(100% - 70px);
    position: absolute;
    bottom: 70px;
    left: 0;
    padding: 0;
    opacity: 1 !important;
    transition: transform 0.4s, filter 0.4s !important;
    transform: translateY(100%);
  }
  .modalReviers.graph-modal-open {
    display: flex;
  }
  .modalReviers.graph-modal-open-ok {
    transform: none;
  }
}
.modalReviers__content {
  padding: 20px;
  height: 100%;
  overflow: auto;
  width: 380px;
  max-height: 580px;
}
@media (max-width: 769px) {
  .modalReviers__content {
    height: initial;
    max-height: initial;
    width: 100%;
    overflow: initial;
  }
  .modalReviers__content > * {
    margin-top: 0 !important;
  }
}
.modalReviers__content::-webkit-scrollbar {
  width: 6px;
}
.modalReviers__content::-webkit-scrollbar-track {
  background-color: darkgrey;
}
.modalReviers__content::-webkit-scrollbar-thumb {
  background-color: #d3d3d3;
  border-radius: 10px;
}
.modalReviers__contentWrap {
  display: flex;
}
@media (max-width: 769px) {
  .modalReviers__contentWrap {
    flex-direction: column;
    overflow: auto;
  }
}
.modalReviers__sliderWrap {
  width: calc(100% - 380px);
  position: relative;
}
@media (max-width: 769px) {
  .modalReviers__sliderWrap {
    width: 100%;
  }
}
.modalReviers__sliderWrap .swiper-button-next-custom {
  position: absolute;
  top: 50%;
  right: 28px;
  transform: translateY(-50%);
  z-index: 3;
  display: block !important;
}
@media (max-width: 769px) {
  .modalReviers__sliderWrap .swiper-button-next-custom {
    right: 15px;
    transform: translateY(-50%) !important;
  }
}
.modalReviers__sliderWrap .swiper-button-prev-custom {
  position: absolute;
  top: 50%;
  left: 28px;
  transform: translateY(-50%);
  z-index: 3;
  display: block !important;
}
@media (max-width: 769px) {
  .modalReviers__sliderWrap .swiper-button-prev-custom {
    left: 15px;
    transform: translateY(-50%) !important;
  }
}
.modalReviers__sliderWrap .swiper-button-next-custom.swiper-button-disabled, .modalReviers__sliderWrap .swiper-button-prev-custom.swiper-button-disabled {
  display: none !important;
}
.modalReviers__top {
  display: flex;
  gap: 20px;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}
@media (max-width: 769px) {
  .modalReviers__top {
    display: none;
    gap: 10px;
  }
}
.modalReviers__top--mob {
  display: none;
  position: absolute;
  left: 12px;
  max-width: calc(100% - 140px);
  bottom: 14px;
  width: fit-content;
  z-index: 4;
  background: #ffffff;
  border-radius: 9px;
  padding: 10px;
  margin-bottom: 0;
}
@media (max-width: 769px) {
  .modalReviers__top--mob {
    display: flex;
  }
}
.modalReviers__img {
  display: block;
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
  object-fit: cover;
}
@media (max-width: 769px) {
  .modalReviers__img {
    width: 30px;
    height: 30px;
  }
}
.modalReviers__nameWrap {
  width: calc(100% - 70px);
  display: flex;
  flex-direction: column;
  gap: 6px;
}
@media (max-width: 769px) {
  .modalReviers__nameWrap {
    width: calc(100% - 40px);
    gap: 1px;
  }
}
.modalReviers__name {
  font-weight: 700;
  font-size: 14px;
  line-height: 1.2;
  color: var(--dark-color);
}
@media (max-width: 769px) {
  .modalReviers__name {
    font-size: 12px;
  }
}
.modalReviers__date {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: var(--gray-color);
}
@media (max-width: 769px) {
  .modalReviers__date {
    font-size: 12px;
  }
}
.modalReviers__cardPhoto {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  overflow: hidden;
  margin-top: 20px;
}
.modalReviers__cardPhoto img {
  display: block;
  aspect-ratio: 1/1;
  width: calc(33.3333333333% - 10px);
}
.modalReviers__raiting {
  position: absolute;
  z-index: 4;
  top: 20px;
  right: 20px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 17px;
  padding: 8px 12px;
}
@media (max-width: 769px) {
  .modalReviers__raiting {
    left: 12px;
    top: 14px;
    right: initial;
  }
}
.modalReviers__like {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 4;
}
.modalReviers__like .reviews__cardContentGradeBtn {
  color: #ffffff;
}
.modalReviers__closeR {
  display: none;
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 4;
  color: var(--light-color);
}
@media (max-width: 769px) {
  .modalReviers__closeR {
    display: block;
  }
}
.modalReviers__closeR--pc {
  display: block;
  right: -30px;
  top: 0;
}

.modalReviersSwiper {
  width: 100%;
  height: 100%;
  max-height: 580px;
}
@media (max-width: 769px) {
  .modalReviersSwiper {
    max-height: unset;
  }
}
.modalReviersSwiper__slide {
  height: auto;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modalReviersSwiper__slide::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(31, 45, 53, 0.8);
  backdrop-filter: blur(10px);
}
.modalReviersSwiper__img {
  display: flex;
  max-width: 100%;
  max-height: 100%;
  position: relative;
  z-index: 2;
}
@media (max-width: 769px) {
  .modalReviersSwiper__img {
    min-height: 300px;
  }
}

.modalOrder {
  width: calc(100% - 40px);
  max-width: 1000px;
}
@media (max-width: 769px) {
  .modalOrder {
    width: 100%;
    max-width: 100%;
    border-radius: 0;
    flex-direction: column;
    margin: 0 !important;
    max-height: calc(100% - 70px);
    position: absolute;
    bottom: 70px;
    left: 0;
    padding: 0;
    opacity: 1 !important;
    transition: transform 0.4s !important;
    transform: translateY(100%);
  }
  .modalOrder.graph-modal-open {
    display: flex;
  }
  .modalOrder.graph-modal-open-ok {
    transform: none;
  }
}
.modalOrder__header {
  margin-bottom: 30px;
}
@media (max-width: 769px) {
  .modalOrder__header {
    margin-bottom: 0;
  }
}
.modalOrder__title {
  line-height: 1.25;
  color: var(--dark-color);
}
@media (max-width: 1025px) {
  .modalOrder__title {
    font-size: 22px;
    margin-bottom: 20px;
  }
}
@media (max-width: 769px) {
  .modalOrder__title {
    font-size: 20px;
    text-align: left;
    margin-bottom: 0;
  }
}
@media (max-width: 769px) {
  .modalOrder__contentWrap {
    overflow: auto;
    padding: 0 12px 20px;
  }
}
.modalOrder .productCard__btn {
  opacity: 1;
}
.modalOrder__btn {
  width: fit-content;
  margin-top: 30px;
}

.filters--gap10 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.filters--gap20 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.filters__btn {
  display: flex;
  text-align: left;
  align-items: center;
  gap: 10px;
  width: 100%;
  justify-content: space-between;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2;
  color: var(--dark-color);
  background-color: transparent;
  border: none;
  padding: 0;
  min-height: 24px;
  cursor: pointer;
  transition: all 0.4s;
}
.filters__btn i {
  font-size: 24px;
  color: var(--gray-color);
  transition: all 0.5s;
}
.filters__btn--active i {
  transform: scaleY(-1);
}
.filters__listCat {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.filters__content {
  margin-top: 12px;
  display: none;
}
.filters__content--active {
  display: block;
}
.filters__cat {
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: var(--dark-color);
  transition: all 0.4s;
}
.filters__cat:hover {
  color: var(--dark-color-hover-link);
}
.filters__cat--active {
  font-weight: 700;
}
.filters__cat--active:hover {
  color: var(--dark-color);
}
.filters__catIcon {
  font-size: 12px;
  color: var(--red-color);
  display: block;
}
.filters__fromTo {
  display: flex;
  gap: 5px;
}
.filters__fromTo > * {
  width: 50%;
}
.filters__inpLabel {
  display: block;
  position: relative;
  margin-bottom: 12px;
}
.filters__inpLabel input {
  display: block;
  width: 100%;
  padding: 11px 24px 11px 11px;
  border: 1px solid #e2e8ec;
  outline: none;
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: var(--dark-color);
  transition: all 0.4s;
}
.filters__inpLabel input::placeholder {
  color: var(--gray-color);
}
.filters__inpLabel input:focus {
  border-color: var(--gray-color);
}
.filters__inpReset {
  position: absolute;
  right: 5.5px;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  color: var(--dark-gray-color3-hover-btn);
  transition: all 0.4s;
}
.filters__inpReset:hover {
  color: var(--dark-color);
}
.filters__inpSearch {
  position: absolute;
  right: 8px;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  color: var(--dark-gray-color3-hover-btn);
  transition: all 0.4s;
}
.filters__inpSearch:hover {
  color: var(--dark-color);
}
.filters__letter {
  font-weight: 700;
  font-size: 10px;
  line-height: 1.2;
  color: var(--red-color);
  margin-bottom: -6px;
  text-transform: uppercase;
}
.filters--searching + .filters__moreBtn {
  display: none;
}
.filters__moreBtn {
  display: flex;
  width: fit-content;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 20px 0 0;
  background-color: transparent;
  transition: all 0.4s;
  font-size: 14px;
  line-height: 1.2;
  color: var(--red-color);
  border: none;
  cursor: pointer;
}
.filters__moreBtn:hover {
  color: var(--red-color-hover-link);
}
.filters--maxElements:not(.filters--searching) > *:nth-child(n+16) {
  display: none;
}
.filters--maxElements5:not(.filters--searching) > *:nth-child(n+6) {
  display: none;
}
.filters--full > * {
  display: flex !important;
}

.leftMenu {
  border: 1px solid #e2e8ec;
  border-radius: 5px;
  overflow: hidden;
}
.leftMenu__item:not(:last-child) {
  border-bottom: 1px solid #e2e8ec;
}
.leftMenu__itemFirst {
  padding: 20px 10px 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--dark-color);
  font-size: 14px;
  line-height: 1.7;
  transition: all 0.4s;
  width: 100%;
}
.leftMenu__itemFirst:hover {
  background: var(--dark-gray-color3);
  color: var(--dark-color);
}
.leftMenu__itemFirst--active {
  background: #1f2d35 !important;
  color: var(--light-color) !important;
}
.leftMenu__itemFirst--active .leftMenu__itemIcon {
  transform: rotate(90deg);
}
.leftMenu__itemFirst--active ~ .leftMenu__itemTwo {
  display: block;
}
.leftMenu__itemTwo {
  padding: 10px 30px;
  color: var(--dark-color);
  font-size: 14px;
  line-height: 1.7;
  transition: all 0.4s;
  display: block;
  width: 100%;
  display: none;
}
.leftMenu__itemTwo--active, .leftMenu__itemTwo:hover {
  background: #f2f5f7;
}
.leftMenu__itemText span {
  color: #b8c0c5;
  margin-left: 10px;
}
.leftMenu__itemIcon {
  font-size: 24px;
}

@media (max-width: 769px) {
  .mobScroll {
    width: calc(100% + var(--container-offset) * 2);
    overflow: auto;
    padding-left: var(--container-offset);
    padding-right: var(--container-offset);
    margin-left: calc(-1 * var(--container-offset));
    margin-right: calc(-1 * var(--container-offset));
  }
}

.tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}
.tabs__tab {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 8px 14px;
  transition: all 0.4s;
  background: var(--dark-gray-color3);
  border-radius: 5px;
  font-size: 14px;
  line-height: 1.4;
  color: var(--dark-color);
}
.tabs__tab:hover {
  background: var(--dark-gray-color3-hover-btn);
}
.tabs__tab--active {
  background: var(--red-color-hover-link);
  color: var(--light-color);
}
.tabs__tab--active:hover {
  background: var(--red-color-hover-link);
  color: var(--light-color);
}

.tabsBtn {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
}
@media (max-width: 769px) {
  .tabsBtn {
    flex-wrap: nowrap;
    width: fit-content;
  }
}
.tabsBtn__tab {
  display: flex;
  gap: 6px;
  align-items: center;
  padding: 8px 14px;
  font-size: 14px;
  background: var(--dark-gray-color3);
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: all 0.4s;
  white-space: nowrap;
  color: var(--dark-color);
}
.tabsBtn__tab:hover {
  background: var(--dark-gray-color3-hover-btn);
}
.tabsBtn__tab--active {
  background: var(--red-color-hover-link) !important;
  color: var(--light-color);
}
.tabsBtn__tab--big {
  line-height: 1.43;
}

.tabsContent__card {
  display: none;
}
.tabsContent__card--active {
  display: block;
}

.faq {
  display: flex;
  flex-direction: column;
}
.faq__icon {
  font-size: 24px;
  transition: all 0.4s;
  transform: scaleY(-1);
}
.faq__btn {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  border-top: 1px solid var(--dark-gray-color3-hover-btn);
  padding: 20px 0;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.85;
  color: var(--dark-color);
  text-align: left;
}
@media (max-width: 1025px) {
  .faq__btn {
    align-items: flex-start;
    line-height: 1.4;
  }
}
@media (max-width: 769px) {
  .faq__btn {
    width: calc(100% + var(--container-offset) * 2);
    padding-left: var(--container-offset);
    padding-right: var(--container-offset);
    margin-left: calc(-1 * var(--container-offset));
    margin-right: calc(-1 * var(--container-offset));
  }
}
.faq__btn--active .faq__icon {
  transform: none;
}
@media (max-width: 769px) {
  .faq__btn--active .faq__icon {
    color: var(--red-color);
  }
}
.faq__content {
  display: none;
  margin-bottom: 20px;
  font-weight: 400;
  color: #97a5ad;
}
.faq__content--active {
  display: block;
}
@media (max-width: 769px) {
  .faq__content {
    width: calc(100% + var(--container-offset) * 2);
    padding-left: var(--container-offset);
    padding-right: var(--container-offset);
    margin-left: calc(-1 * var(--container-offset));
    margin-right: calc(-1 * var(--container-offset));
  }
}

.clue {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  background: #f2f5f7;
  border-radius: 9px;
}
.clue__main {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 14px;
  line-height: 1;
}
.clue__icon {
  font-size: 24px;
  color: var(--red-color);
}
.clue__info {
  font-size: 20px;
  color: var(--gray-color);
  cursor: pointer;
  position: relative;
}
.clue__info::after {
  content: "";
  display: block;
  border: 7px solid transparent;
  border-bottom: 10px solid #fff;
  z-index: 30;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 10px);
  pointer-events: none;
  opacity: 0;
  pointer-events: none;
  transition: all 0.4s;
}
.clue__info--active::after {
  opacity: 1;
}
.clue__clue {
  position: absolute;
  right: -25px;
  bottom: -8px;
  transform: translateY(100%);
  background: var(--light-color);
  border-radius: 9px;
  box-shadow: 0px 0px 20px rgba(151, 165, 173, 0.75);
  width: 445px;
  max-width: calc(100vw - 36px);
  padding: 40px 50px;
  color: var(--dark-color);
  font-family: var(--font-family, sans-serif);
  opacity: 0;
  pointer-events: none;
  transition: all 0.4s;
  z-index: 20;
}
@media (max-width: 769px) {
  .clue__clue {
    padding: 25px 30px;
  }
}
.clue__clue--active {
  opacity: 1;
  pointer-events: all;
}
.clue__clue--min {
  width: 300px;
  padding: 20px 30px;
}
@media (max-width: 769px) {
  .clue__clue--min {
    position: fixed;
    bottom: 50%;
    z-index: 300;
    left: 50%;
    transform: translateX(-50%) translateY(50%);
  }
}
.clue__clue--min .clue__clueClose {
  right: 10px;
  top: 10px;
}
@media (min-width: 769px) {
  .clue__clue--bottom {
    top: -8px;
    bottom: initial;
    transform: translateY(-100%);
    height: fit-content;
    transition: opacity 0.4s;
  }
}
.clue__link {
  display: block;
  width: fit-content;
  margin-top: 15px;
  color: var(--blue-color);
  transition: all 0.3s;
}
.clue__link:hover {
  color: var(--dark-color-hover-link);
}
.clue__title {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.25;
  margin-bottom: 30px;
}
@media (max-width: 769px) {
  .clue__title {
    font-size: 18px;
    margin-bottom: 20px;
  }
}
.clue__text {
  font-size: 14px;
  line-height: 1.7;
}
@media (max-width: 769px) {
  .clue__text {
    font-size: 12px;
  }
}
.clue__clueClose {
  position: absolute;
  top: 22px;
  right: 22px;
  font-size: 24px;
  color: var(--gray-color);
  transition: all 0.4s;
}
.clue__clueClose:hover {
  color: var(--gray-color-hover-link);
}

.quantity {
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid var(--dark-gray-color3);
  display: flex;
  width: fit-content;
}
.quantity--nb {
  border: none;
}
.quantity__btn {
  font-size: 16px;
  padding: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s;
  background: var(--dark-gray-color3);
  color: var(--dark-color);
}
.quantity__btn:hover {
  background: var(--dark-gray-color3-hover-btn);
}
@media (max-width: 1025px) {
  .quantity__btn {
    font-size: 20px;
    padding: 16px;
  }
}
.quantity__btn:disabled {
  background: var(--dark-gray-color3);
  cursor: default;
}
.quantity__text {
  width: 60px;
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  line-height: 1.2;
  text-align: center;
}
@media (max-width: 1025px) {
  .quantity__text {
    font-size: 20px;
  }
  .quantity__text div {
    display: none;
  }
}
.quantity__text--min {
  font-size: 13px;
  width: 80px;
}
@media (max-width: 1025px) {
  .quantity__text--min {
    font-size: 13px;
    width: 50px;
  }
  .quantity__text--min div {
    display: block;
  }
}
.quantity__text--min2 {
  width: 45px;
}
.quantity__text--width {
  flex: 1 1 auto;
}

.reviews {
  margin: 60px 0;
}
@media (max-width: 1025px) {
  .reviews {
    margin: 50px 0;
  }
}
@media (max-width: 769px) {
  .reviews {
    margin: 0;
    padding: 20px 0;
    background: #f2f5f7;
  }
}
.reviews__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.5;
  margin: 0 0 20px;
}
.reviews__title span {
  margin-left: 10px;
  font-weight: 400;
  color: var(--gray-color);
}
.reviews__photos {
  margin-bottom: 30px;
}
@media (max-width: 769px) {
  .reviews__photos {
    margin: 20px 0;
  }
}
.reviews__photosText {
  font-size: 14px;
  line-height: 1.2;
  margin: 0 0 15px;
}
.reviews__main {
  width: calc(100% - 515px);
}
@media (max-width: 1441px) {
  .reviews__main {
    width: calc(100% - 480px);
  }
}
@media (max-width: 1025px) {
  .reviews__main {
    width: calc(100% - 360px);
  }
}
.reviews__br {
  margin: 18px 0 30px;
  display: block;
  width: 100%;
  height: 1px;
  background: #e2e8ec;
}
.reviews__cards {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 30px 0;
}
.reviews__pagination {
  margin-top: 30px;
  justify-content: flex-start;
}
.reviews__card {
  display: flex;
  gap: 20px;
  /*cursor: pointer;*/
}
.reviews__cardLogo {
  display: block;
  width: 50px;
  height: 50px;
  overflow: hidden;
}
.reviews__cardLogo img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.reviews__cardContent {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: calc(100% - 70px);
}
.reviews__cardHead {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.reviews__cardContentGrade {
  display: flex;
  gap: 10px;
  align-items: center;
}
.reviews__cardContentGradeBtn {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  color: var(--dark-color);
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.4s;
}
.reviews__cardContentGradeBtn--active .reviews__cardContentGradeBtnIcon {
  color: var(--red-color) !important;
}
.reviews__cardContentGradeBtn:hover .reviews__cardContentGradeBtnIcon {
  color: var(--dark-gray-color3-hover-btn);
}
.reviews__cardContentGradeBtnIcon {
  font-size: 24px;
  color: var(--dark-gray-color3);
  transition: all 0.4s;
}
.reviews__cardNameBlock {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.reviews__cardNameWrap {
  display: flex;
  gap: 16px;
}
.reviews__cardName {
  font-weight: 700;
  font-size: 14px;
  line-height: 1.2;
  color: var(--dark-color);
  transition: all 0.4s;
}
.reviews__cardName:hover {
  color: var(--dark-color-hover-link);
}
.reviews__cardPosl {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: var(--gray-color);
}
.reviews__cardPhoto {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
@media (max-width: 769px) {
  .reviews__cardPhoto {
    margin: 15px 0;
  }
}
.reviews__cardPhotoImg {
  display: block;
  width: 60px;
  height: 60px;
  overflow: hidden;
  object-fit: cover;
  border-radius: 5px;
}
.reviews__cardText {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
@media (max-width: 769px) {
  .reviews__cardText {
    margin: 15px 0;
  }
}
.reviews__cardTextL {
  font-size: 14px;
  line-height: 1.7;
}
.reviews__filter {
  border: 1px solid #e2e8ec;
  border-radius: 9px;
  padding: 20px;
  width: 420px;
  transform: translateY(-50px);
  position: sticky;
  top: 150px;
}
@media (max-width: 1025px) {
  .reviews__filter {
    width: 320px;
  }
}
@media (max-width: 769px) {
  .reviews__filter {
    position: relative;
    top: initial;
    width: 100%;
    background: #ffffff;
    padding: 12px;
    transform: none;
  }
}
.reviews__filter--modal {
  padding: 0;
  border: none;
}
.reviews__wrap {
  display: flex;
  gap: 95px;
  align-items: flex-start;
}
@media (max-width: 1441px) {
  .reviews__wrap {
    gap: 60px;
  }
}
@media (max-width: 1025px) {
  .reviews__wrap {
    gap: 40px;
  }
}
@media (max-width: 769px) {
  .reviews__wrapPc {
    display: none;
  }
}
.reviews__wrapMob {
  display: none;
}
@media (max-width: 769px) {
  .reviews__wrapMob {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}
.reviews__filterTitle {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.2;
}
.reviews__filterRaiting {
  display: flex;
  align-items: center;
  gap: 15px;
}
@media (max-width: 769px) {
  .reviews__filterRaiting {
    gap: 12px;
  }
}
.reviews__filterRaitingText {
  font-weight: 700;
  font-size: 29px;
  line-height: 1;
}
@media (max-width: 769px) {
  .reviews__filterRaitingText {
    font-size: 23px;
  }
}
.reviews__filterRaitingText1 {
  font-weight: 400;
  font-size: 13px;
  line-height: 1.2;
  color: var(--gray-color);
}
.reviews__rv {
  display: block;
}
.reviews__filterBr {
  background: #e2e8ec;
  width: 100%;
  height: 1px;
  display: block;
  margin: 20px 0 30px;
}
.reviews__filterBr--adaptive {
  margin: 15px 0;
}
.reviews__filterBtn {
  margin-top: 30px;
}
@media (max-width: 769px) {
  .reviews__filterBtn {
    margin-top: 0;
  }
}
.reviews__filterLabelWrap {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.reviews__filterLabelText {
  display: flex;
  gap: 15px;
  align-items: center;
  font-size: 14px;
  line-height: 17px;
}
.reviews__cardMob {
  background: #ffffff;
  border-radius: 9px;
  padding: 12px;
}
.reviews__cardMob--modal {
  padding: 15px 0;
  border-bottom: 1px solid #e2e8ec;
}
.reviews__cardMobBtn {
  margin-top: 30px;
}
.reviews__cardMobHead {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 15px;
}
.reviews__notWrap {
  display: flex;
  gap: 15px 20px;
  align-items: flex-start;
  justify-content: space-between;
}
@media (max-width: 769px) {
  .reviews__notWrap {
    flex-direction: column;
  }
}
.reviews__notWrapLeft {
  width: 620px;
}
@media (max-width: 769px) {
  .reviews__notWrapLeft {
    width: 100%;
  }
}
.reviews__notWrapRight {
  width: 380px;
}
@media (max-width: 769px) {
  .reviews__notWrapRight {
    width: 100%;
  }
}
.reviews__notRevText {
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: 10px;
}
@media (max-width: 769px) {
  .reviews__notRevText {
    margin-bottom: 6px;
  }
}
@media (max-width: 769px) {
  .reviews__notRev {
    background: #ffffff;
    border: 1px solid #e2e8ec;
    border-radius: 9px;
    padding: 12px;
    margin-bottom: 15px;
  }
}
.reviews__cardMobWrap {
  margin-top: 20px;
}

.reviewsSlider__slide {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 5px;
}
@media (max-width: 1441px) {
  .reviewsSlider__slide {
    width: 80px;
    height: 80px;
  }
}
@media (max-width: 769px) {
  .reviewsSlider__slide {
    width: 60px;
    height: 60px;
  }
}
.reviewsSlider__slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.reviewsSlider ~ .swiper-button-prev-custom {
  left: 0;
}
.reviewsSlider ~ .swiper-button-next-custom {
  right: 0;
}
.reviewsSlider__slideBtn {
  background: var(--dark-gray-color3);
  font-size: 32px;
  color: var(--gray-color);
  transition: all 0.4s;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.reviewsSlider__slideBtn:hover {
  background-color: var(--dark-gray-color3-hover-btn);
}

.header {
  position: sticky;
  top: 0;
  z-index: 1000;
  background: #ffffff;
}
.header--scroll {
  box-shadow: 0px 0px 20px rgba(151, 165, 173, 0.75);
  transition: all 0.4s;
}

.headerTop {
  padding: 10px 0;
  min-height: 40px;
  margin-bottom: 10px;
  background: #f2f5f7;
  display: flex;
  align-items: center;
}
@media (max-width: 1201px) {
  .headerTop {
    padding: 7px 0;
    min-height: 34px;
    margin-bottom: 5px;
  }
}
@media (max-width: 769px) {
  .headerTop {
    display: none;
  }
}
.headerTop .container {
  width: 100%;
}
.headerTop--scroll {
  display: none;
}
.headerTop__wrap {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}
.headerTop__addr {
  display: flex;
  gap: 10px;
  align-items: center;
}
@media (max-width: 1201px) {
  .headerTop__addr {
    gap: 8px;
  }
}
.headerTop__addrSep {
  font-size: 13px;
  line-height: 1.15;
  color: rgba(0, 0, 0, 0.4);
}
@media (max-width: 1201px) {
  .headerTop__addrSep {
    font-size: 12px;
  }
}
.headerTop__addrC {
  font-size: 12px;
  line-height: 1.4;
  color: #677178;
}
@media (max-width: 1201px) {
  .headerTop__addrC {
    font-size: 11px;
  }
}
.headerTop__addrBtn {
  font-size: 12px;
  line-height: 1.15;
  color: #677178;
  transition: all 0.4s;
}
@media (max-width: 1201px) {
  .headerTop__addrBtn {
    font-size: 11px;
  }
}
.headerTop__addrBtn:hover {
  color: var(--dark-color);
}
.headerTop__addrIcon {
  font-size: 16px;
  color: var(--dark-color);
  vertical-align: text-bottom;
  margin-right: 6px;
  display: inline-block;
}
@media (max-width: 1201px) {
  .headerTop__addrIcon {
    font-size: 14px;
    margin-right: 5px;
  }
}
.headerTop__links {
  display: flex;
  gap: 10px;
  align-items: center;
}
@media (max-width: 1201px) {
  .headerTop__links {
    gap: 8px;
  }
}
.headerTop__link {
  font-weight: 400;
  font-size: 13px;
  line-height: 1.15;
  color: #677178;
  transition: all 0.4s;
}
@media (max-width: 1201px) {
  .headerTop__link {
    font-size: 12px;
  }
}
.headerTop__link[href^="tel:"] {
  font-weight: 700;
  color: var(--dark-color);
}
.headerTop__link[href^="tel:"]:hover {
  color: #677178;
}
.headerTop__link:hover {
  color: var(--dark-color);
}

.headerMiddle {
  padding: 10px 0;
  transition: none;
}
@media (max-width: 1201px) {
  .headerMiddle {
    padding: 7px 0;
  }
}
@media (max-width: 769px) {
  .headerMiddle {
    display: none;
  }
}
.headerMiddle--scroll {
  transition: all 0.4s;
}
.headerMiddle__wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.headerMiddle__logo {
  font-size: 111px;
  width: 111px;
  height: 28px;
  position: relative;
  color: var(--dark-color);
  transition: all 0.4s;
  margin-right: 10px;
}
@media (max-width: 1201px) {
  .headerMiddle__logo {
    font-size: 95px;
    width: 95px;
    height: 24px;
    margin-right: 0;
  }
}
.headerMiddle__logo::before {
  pointer-events: none;
  display: block;
  transform: translateY(-50%);
  top: 50%;
  position: absolute;
}
.headerMiddle__iconBtnCatalog {
  font-size: 24px;
}
@media (max-width: 1201px) {
  .headerMiddle__iconBtnCatalog {
    font-size: 20px;
  }
}
.headerMiddle__search {
  flex: 1 1 auto;
  display: block;
  margin-right: 15px;
  position: relative;
}
@media (max-width: 1201px) {
  .headerMiddle__search {
    margin-right: 0;
  }
}
.headerMiddle__searchLabel {
  display: block;
  width: 100%;
  position: relative;
  z-index: 10;
}
.headerMiddle__searchLabel--mob {
  width: calc(100% - 48px);
}
.headerMiddle__searchInput {
  display: block;
  width: 100%;
  padding: 17px 55px 18px 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  color: #2b2b2b;
  background: #ffffff;
  border: 1px solid #e2e8ec;
  border-radius: 9px;
  outline: none;
}
@media (max-width: 1201px) {
  .headerMiddle__searchInput {
    font-size: 14px;
    padding: 13px 50px 13px 15px;
  }
}
.headerMiddle__searchInput::placeholder {
  color: #b8c0c5;
  font-weight: inherit;
  font-size: inherit;
}
.headerMiddle__searchBtn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
  width: 44px;
  height: 44px;
  background: #ed0000;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: var(--light-color);
  transition: all 0.4s;
}
@media (max-width: 1201px) {
  .headerMiddle__searchBtn {
    width: 36px;
    height: 36px;
    font-size: 20px;
  }
}
@media (max-width: 769px) {
  .headerMiddle__searchBtn {
    width: 44px;
    height: 44px;
    font-size: 24px;
  }
}
.headerMiddle__searchBtn:hover {
  background: #c70000;
}
.headerMiddle__icons {
  display: flex;
  gap: 30px;
}
@media (max-width: 1201px) {
  .headerMiddle__icons {
    gap: 20px;
  }
}
.headerMiddle__searchBg {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  opacity: 0;
  pointer-events: none;
  transition: all 0.4s;
}
.headerMiddle__searchBg--active {
  opacity: 1;
  pointer-events: all;
}

.itemBtn {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  color: var(--gray-color);
  font-size: 12px;
  line-height: 1.15;
  text-align: center;
  position: relative;
  outline: none;
}
.itemBtn--active {
  color: var(--dark-color);
}
@media (max-width: 1201px) {
  .itemBtn {
    font-size: 11px;
    gap: 2px;
  }
}
@media (max-width: 769px) {
  .itemBtn {
    font-size: 9px;
  }
}
.itemBtn__account:hover::after {
  content: "";
  display: block;
  width: 100%;
  height: 20px;
  z-index: 2;
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  left: 0;
}
.itemBtn__account:hover .itemBtn__profileMenu {
  opacity: 1;
  pointer-events: all;
}
.itemBtn__profileMenu {
  z-index: 10;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 240px;
  filter: drop-shadow(0px 0px 20px rgba(151, 165, 173, 0.75));
  display: flex;
  flex-direction: column;
  background: #ffffff;
  transform: translateY(calc(100% + 10px));
  border: 1px solid #e2e8ec;
  border-radius: 5px 5px 0px 0px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s;
}
@media (max-width: 1201px) {
  .itemBtn__profileMenu {
    width: 220px;
  }
}
@media (max-width: 1025px) {
  .itemBtn__profileMenu {
    width: 160px;
  }
}
.itemBtn__profileMenu a {
  text-align: left;
  display: block;
  width: 100%;
  padding: 16px 20px;
  font-weight: 700;
  font-size: 14px;
  color: #2b2b2b;
  transition: all 0.4s;
}
@media (max-width: 1201px) {
  .itemBtn__profileMenu a {
    font-size: 13px;
    padding: 13px 17px;
  }
}
@media (max-width: 1025px) {
  .itemBtn__profileMenu a {
    font-size: 12px;
    padding: 11px 15px;
  }
}
.itemBtn__profileMenu a:hover {
  background: #f2f5f7;
}
.itemBtn__profileMenu a:not(:last-child) {
  border-bottom: 1px solid #e2e8ec;
}
.itemBtn__name {
  transition: all 0.4s;
}
.itemBtn__name--bold {
  font-weight: 700;
}
.itemBtn:hover {
  color: var(--dark-color);
}
.itemBtn:hover .itemBtn__name {
  color: var(--red-color);
}
.itemBtn__icon {
  font-size: 32px;
  position: relative;
  transition: all 0.4s;
}
@media (max-width: 1201px) {
  .itemBtn__icon {
    font-size: 28px;
  }
}
.itemBtn__icon--numb {
  color: var(--dark-color);
}
.itemBtn__icon--numb ~ .itemBtn__name {
  color: var(--dark-color);
}
.itemBtn__icon--numb::after {
  content: attr(data-numb);
  font-family: var(--font-family);
  top: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: var(--red-color);
  border: 2px solid var(--light-color);
  border-radius: 20px;
  padding: 1px 5px;
  font-weight: 700;
  font-size: 10px;
  line-height: 1.4;
  color: var(--light-color);
  transform: translateX(33%);
  transition: all 0.4s;
}
.itemBtn__basketHover {
  opacity: 0;
  pointer-events: none;
  transition: all 0.4s;
  position: absolute;
  z-index: 5;
  bottom: 0;
  right: 0;
  transform: translateY(calc(100% + 12px));
}
.itemBtn__basket {
  position: relative;
}
.itemBtn__basket:hover::after {
  content: "";
  display: block;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 15px;
  transform: translateY(100%);
  position: absolute;
}
.itemBtn__basket:hover .itemBtn__basketHover {
  opacity: 1;
  pointer-events: all;
}

.headerBottom {
  position: relative;
  z-index: 3;
  background-color: var(--light-color);
  padding: 0 0 10px;
}
@media (max-width: 1201px) {
  .headerBottom {
    padding: 0 0 7px;
  }
}
@media (max-width: 769px) {
  .headerBottom {
    display: none;
  }
}
.headerBottom--scroll {
  display: none;
}
.headerBottom__wrap {
  display: flex;
  gap: 2px 40px;
  flex-wrap: wrap;
}
@media (max-width: 1201px) {
  .headerBottom__wrap {
    gap: 2px 20px;
  }
}
@media (max-width: 1025px) {
  .headerBottom__wrap {
    gap: 2px 15px;
  }
}
.headerBottom__link {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  line-height: 1;
  color: var(--dark-color);
  padding: 8px 0;
  transition: all 0.4s;
}
@media (max-width: 1201px) {
  .headerBottom__link {
    gap: 6px;
    font-size: 13px;
    padding: 5px 0;
  }
}
@media (max-width: 1025px) {
  .headerBottom__link {
    gap: 5px;
    font-size: 12px;
    padding: 4px 0;
  }
}
.headerBottom__link:hover {
  color: var(--dark-color-hover-link);
}
.headerBottom__linkIcon {
  font-size: 24px;
  color: var(--red-color);
}
@media (max-width: 1201px) {
  .headerBottom__linkIcon {
    font-size: 20px;
  }
}
@media (max-width: 1025px) {
  .headerBottom__linkIcon {
    font-size: 18px;
  }
}

.headerMob {
  display: none;
  height: 60px;
  padding: 10px 0;
  background: #ffffff;
  align-items: center;
  transition: all 0.4s;
  border-bottom: 1px solid #e5e5e5;
}
@media (max-width: 769px) {
  .headerMob {
    display: flex;
  }
}
.headerMob .container {
  width: 100%;
}
.headerMob__wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.headerMob__burger, .headerMob__search {
  font-size: 28px;
  color: var(--red-color);
}
.headerMob__logo {
  font-size: 87px;
  width: 87px;
  height: 22px;
  position: relative;
  color: var(--dark-color);
  transition: all 0.4s;
}
.headerMob__logo::before {
  pointer-events: none;
  display: block;
  transform: translateY(-50%);
  top: 50%;
  position: absolute;
}

.menuMob {
  display: none;
  position: fixed;
  align-items: center;
  height: 70px;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  padding: 14px 0;
  z-index: 1010;
  border: 1px solid #e5e5e5;
}
@media (max-width: 769px) {
  .menuMob {
    display: flex;
  }
}
.menuMob .container {
  width: 100%;
}
.menuMob__wrap {
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.menuMob__item {
  font-size: 9px;
  width: 50px;
  gap: 3px;
}

.searchResult {
  max-height: calc(100vh - 150px);
  overflow: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  min-width: 450px;
  background: #ffffff;
  border-radius: 15px;
  z-index: 10;
  padding: 20px;
  transform: translateY(calc(100% + 10px));
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s;
}
.searchResult::-webkit-scrollbar {
  width: 6px;
}
.searchResult::-webkit-scrollbar-track {
  background-color: darkgrey;
}
.searchResult::-webkit-scrollbar-thumb {
  background-color: #d3d3d3;
  border-radius: 10px;
}
.searchResult--active {
  opacity: 1;
  pointer-events: all;
}
.searchResult--mob {
  position: relative;
  left: initial;
  bottom: initial;
  left: initial;
  opacity: 1;
  transform: none;
  padding: 0;
  border-radius: 0;
  width: 100%;
  max-height: initial;
  min-width: initial;
  pointer-events: all;
}
.searchResult__listLinks {
  display: flex;
  flex-direction: column;
  gap: 17px;
  margin-bottom: 20px;
}
@media (max-width: 1025px) {
  .searchResult__listLinks {
    gap: 10px;
    margin-bottom: 15px;
  }
}
@media (max-width: 1025px) and (max-width: 769px) {
  .searchResult__listLinks {
    margin-bottom: 20px;
    gap: 17px;
  }
}
.searchResult__listLinks a {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  color: var(--dark-color);
  transition: all 0.4s;
}
@media (max-width: 1025px) {
  .searchResult__listLinks a {
    font-size: 14px;
  }
}
@media (max-width: 769px) {
  .searchResult__listLinks a {
    font-size: 16px;
  }
}
.searchResult__listLinks a:hover {
  color: var(--dark-color-hover-link);
}
@media (max-width: 769px) {
  .searchResult__more {
    margin-top: 5px;
  }
}
.searchResultCard {
  padding: 4px 0;
  border-top: 1px solid #e2e8ec;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.searchResultCard__img {
  display: block;
  width: 60px;
  height: 60px;
  object-fit: cover;
}
.searchResultCard__info {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  gap: 6px;
  padding: 3px 0;
}
.searchResultCard__name {
  font-size: 14px;
  line-height: 1.15;
  color: var(--dark-color);
  transition: all 0.4s;
}
.searchResultCard__name:hover {
  color: var(--dark-color-hover-link);
}
.searchResultCard__descr {
  font-weight: 700;
  font-size: 12px;
  line-height: 1.2;
  color: var(--gray-color);
  display: flex;
  gap: 8px;
  align-items: center;
}
.searchResultCard__descr--delivery {
  color: #a7352e;
}
.searchResultCard__descr--in {
  color: #37a72e;
}
.searchResultCard__icon {
  font-size: 16px;
}
.searchResultCard__priceWrap {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-end;
  text-align: right;
}
.searchResultCard__price {
  font-weight: 700;
  font-size: 14px;
  line-height: 1.2;
  text-align: right;
  color: var(--dark-color);
  white-space: nowrap;
}
.searchResultCard__saleWrap {
  display: flex;
  gap: 7px;
  align-items: center;
}
.searchResultCard__priceOld {
  font-size: 12px;
  line-height: 1.2;
  text-decoration: line-through;
  color: var(--gray-color);
  white-space: nowrap;
}
.searchResultCard__sale {
  font-size: 10px;
  white-space: nowrap;
  line-height: 1.2;
  color: var(--light-color);
  padding: 3px 4px 3px 6px;
  background: var(--red-color);
  border-radius: 10px 0px 0px 10px;
}

.searchMob {
  padding: 16px 55px 16px 20px;
  font-size: 16px;
}

.headerKatalog {
  position: absolute;
  border: 1px solid var(--dark-gray-color3);
  z-index: 2;
  width: 100%;
  bottom: 0;
  transform: translateY(100%);
  background: var(--dark-gray-color3);
  filter: drop-shadow(0px 20px 20px rgba(151, 165, 173, 0.35));
  height: calc(100vh - 200px);
  max-height: 750px;
  overflow: hidden;
  transition: opacity 0.4s;
  opacity: 0;
  pointer-events: none;
  /*&__menu {
     width: 290px;
     padding: 30px 0 30px 30px;
     position: relative;
     z-index: 2;
     height: fit-content;
     overflow-y: auto;
     overflow-x: hidden;
     max-height: 100%;
     &::-webkit-scrollbar {
        width: 6px;
     }
     &::-webkit-scrollbar-track {
        background-color: darkgrey;
     }
     &::-webkit-scrollbar-thumb {
        background-color: #d3d3d3;
        border-radius: 10px;
     }
     @media (max-width: 1025px) {
        padding: 20px 0;
        width: 220px;
     }
     // &::after {
     //    content: "";
     //    display: block;
     //    position: absolute;
     //    top: 0;
     //    left: 100%;
     //    height: 1000px;
     //    background-color: var(--light-color);
     //    width: 100000%;
     // }
  }
  &__menuList {
     list-style: 0;
     margin: 0;
     padding: 0;
     width: 100%;
     list-style: none;
     height: fit-content;
  }
  &__icon {
     color: var(--red-color);
     font-size: 28px;
     @media (max-width: 1025px) {
        font-size: 24px;
     }
  }
  &__menuItem {
     font-size: 14px;
     line-height: 1.2;
     color: var(--dark-color);
     display: flex;
     gap: 11px;
     align-items: center;
     padding: 15px;
     transition: all 0.4s;
     border-right: 2px solid transparent;
     @media (max-width: 1025px) {
        font-size: 12px;
        gap: 10px;
     }
     &--active {
        background: #ffffff;
        border-color: var(--red-color);
     }
  }

  &__contentBlock {
     display: flex;
     gap: 40px 20px;
     flex-wrap: wrap;
     width: 100%;
     @media (max-width: 1441px) {
        max-width: 700px;
     }
     @media (max-width: 1025px) {
        gap: 30px 20px;
     }
  }
  &__contentCard {
     width: calc(25% - 15px);
     @media (max-width: 1441px) {
        width: calc(100% / 3 - 40px / 3);
     }
     @media (max-width: 1025px) {
        width: calc(50% - 10px);
     }
  }

  &__contentCardList {
     padding: 0;
     margin: 0;
     list-style: none;
     line-height: 1;
  }
  &__contentCardLink {
     transition: all 0.4s;
     font-size: 14px;
     line-height: 1.6;
     color: var(--dark-color);
     &:hover {
        color: var(--dark-color-hover-link);
     }
  }*/
}
@media (max-width: 769px) {
  .headerKatalog {
    display: none;
  }
}
.headerKatalog--active {
  opacity: 1;
  pointer-events: all;
}
.headerKatalog .container {
  height: 100%;
}
.headerKatalog--scroll {
  height: calc(100vh - 100px);
}
.headerKatalog__wrap {
  display: flex;
  height: 100%;
}
.headerKatalog__contentWrap {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
}
@media (max-width: 1025px) {
  .headerKatalog__contentWrap {
    width: 100%;
  }
}
.headerKatalog__contentBack {
  display: none;
  position: absolute;
  left: 40px;
  top: 30px;
}
@media (max-width: 1025px) {
  .headerKatalog__contentBack {
    left: 30px;
  }
}
.headerKatalog__back {
  color: #677178;
}
.headerKatalog__back:hover {
  color: var(--dark-color);
}
.headerKatalog__content {
  height: 100%;
  padding: 30px 40px;
  overflow: auto;
  display: none;
}
@media (max-width: 1025px) {
  .headerKatalog__content {
    padding: 20px 30px;
  }
}
.headerKatalog__content--pad {
  padding-top: 60px;
}
.headerKatalog__content--active {
  display: block;
}
.headerKatalog__content::-webkit-scrollbar {
  width: 6px;
}
.headerKatalog__content::-webkit-scrollbar-track {
  background-color: darkgrey;
}
.headerKatalog__content::-webkit-scrollbar-thumb {
  background-color: #d3d3d3;
  border-radius: 10px;
}
.headerKatalog__contentName {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.25;
  color: var(--dark-color);
  margin-bottom: 28px;
}
@media (max-width: 1025px) {
  .headerKatalog__contentName {
    font-size: 22px;
    margin-bottom: 22px;
  }
}
.headerKatalog__contenClose {
  font-size: 24px;
  position: absolute;
  top: 23px;
  right: 0;
  color: var(--gray-color);
  transition: all 0.4s;
}
.headerKatalog__contenClose:hover {
  color: var(--gray-color-hover-link);
}
.headerKatalog__contentNameCat {
  font-weight: 700;
  font-size: 14px;
  line-height: 1.2;
  color: var(--dark-color);
  margin-bottom: 10px;
  width: fit-content;
  position: relative;
  transition: all 0.4s;
}
.headerKatalog__contentNameCat:hover {
  color: var(--dark-color-hover-link);
}
.headerKatalog__contentNameCat::after {
  content: attr(data-text);
  font-weight: 400;
  font-size: 12px;
  line-height: 1.2;
  color: var(--gray-color);
  padding-left: 2px;
  padding-top: 5px;
  white-space: nowrap;
  display: block;
}
.headerKatalog__contentCardItem {
  width: calc(100% - 130px);
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
}
@media (max-width: 769px) {
  .headerKatalog__contentCardItem {
    gap: 22px;
    width: calc(100% - 90px);
  }
}

.basketHover {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(151, 165, 173, 0.75);
  border-radius: 9px;
  padding: 20px;
  width: 540px;
  cursor: default;
}
.basketHover__card {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}
.basketHover__card:not(:last-of-type) {
  margin-bottom: 20px;
}
.basketHover__imgWrap {
  display: block;
  width: 60px;
}
.basketHover__imgWrap img {
  display: block;
  width: 60px;
  height: 60px;
  object-fit: cover;
}
.basketHover__remove {
  color: var(--gray-color);
  font-size: 16px;
  transition: all 0.4s;
}
.basketHover__remove:hover {
  color: var(--gray-color-hover-link);
}
.basketHover__info {
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-start;
  flex: 1 1 auto;
}
.basketHover__infoAttr {
  font-size: 12px;
  line-height: 1.4;
  color: var(--gray-color);
}
.basketHover__infoName {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.15;
  color: var(--dark-color);
  transition: all 0.4s;
}
.basketHover__infoName:hover {
  color: var(--dark-color-hover-link);
}
.basketHover__infoDescr {
  font-size: 12px;
  line-height: 1.4;
  color: var(--gray-color);
}
.basketHover__wrap {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
}
.basketHover__total {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.2;
  color: var(--dark-color);
}
.basketHover__cardsWrap {
  max-height: 450px;
  overflow: auto;
  padding-bottom: 20px;
}
.basketHover__cardsWrap::-webkit-scrollbar {
  width: 6px;
}
.basketHover__cardsWrap::-webkit-scrollbar-track {
  background-color: darkgrey;
}
.basketHover__cardsWrap::-webkit-scrollbar-thumb {
  background-color: #d3d3d3;
  border-radius: 10px;
}

.headerBannerText {
  min-height: 60px;
  padding: 10px 0;
  font-size: 16px;
  line-height: 1.15;
  color: #ffffff;
  display: flex;
  align-items: center;
}
@media (max-width: 1025px) {
  .headerBannerText {
    font-size: 14px;
    min-height: 55px;
  }
}
@media (max-width: 769px) {
  .headerBannerText {
    font-size: 12px;
    min-height: 50px;
  }
}
.headerBannerText--hidden {
  display: none;
}
.headerBannerText .container {
  width: 100%;
}
.headerBannerText__wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 19px;
  text-align: center;
}
@media (max-width: 769px) {
  .headerBannerText__wrap {
    justify-content: flex-start;
    text-align: left;
  }
}
.headerBannerText__wrap p {
  margin: 0;
  text-align: center;
}
@media (max-width: 769px) {
  .headerBannerText__wrap p {
    text-align: left;
  }
}
.headerBannerText__close {
  display: none;
  font-size: 28px;
  opacity: 0.5;
  color: #ffffff;
}
@media (max-width: 769px) {
  .headerBannerText__close {
    display: block;
  }
}
@media (max-width: 769px) {
  .headerBannerText__pc {
    display: none;
  }
}
.headerBannerText__mob {
  display: none;
}
@media (max-width: 769px) {
  .headerBannerText__mob {
    display: inline-block;
  }
}

.headerBannerDecor {
  background: #31383c;
  height: 60px;
  overflow: hidden;
  display: flex;
  align-items: center;
}
@media (max-width: 769px) {
  .headerBannerDecor {
    height: 50px;
  }
}
.headerBannerDecor .container {
  width: 100%;
}
.headerBannerDecor--hidden {
  display: none;
}
.headerBannerDecor__wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
@media (max-width: 1025px) {
  .headerBannerDecor__wrap {
    gap: 15px;
  }
}
@media (max-width: 769px) {
  .headerBannerDecor__wrap {
    display: none;
  }
}
.headerBannerDecor__wrap--mob {
  display: none;
}
@media (max-width: 769px) {
  .headerBannerDecor__wrap--mob {
    display: flex;
    gap: 19px;
  }
}
.headerBannerDecor__sale {
  display: flex;
  gap: 0;
  align-items: center;
}
.headerBannerDecor__sale1 {
  font-weight: 800;
  font-size: 23.5px;
  line-height: 1.2;
  color: #ffffff;
  padding: 14.7px;
  background: #ff0000;
  border-radius: 13px;
  transform: rotate(-5.52deg);
  font-family: "Montserrat", sans-serif;
}
@media (max-width: 1025px) {
  .headerBannerDecor__sale1 {
    font-size: 20px;
    padding: 14.7px 12px;
  }
}
@media (max-width: 769px) {
  .headerBannerDecor__sale1 {
    font-size: 17.5px;
    padding: 11px;
    transform: rotate(-11deg);
  }
}
.headerBannerDecor__sale2 {
  font-weight: 800;
  font-size: 14.5px;
  line-height: 1.15;
  color: var(--light-color);
  padding: 10px;
  background: #1a1a1a;
  border-radius: 8px;
  transform: rotate(-5.52deg) translateX(-7px);
  font-family: "Montserrat", sans-serif;
}
@media (max-width: 1025px) {
  .headerBannerDecor__sale2 {
    font-size: 12px;
    padding: 8px;
  }
}
@media (max-width: 769px) {
  .headerBannerDecor__sale2 {
    font-size: 10.5px;
    padding: 6.5px;
    transform: rotate(-11deg) translateX(-2px) translateY(-7px);
  }
}
.headerBannerDecor__bigText {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 18.5px;
  line-height: 1.25;
  color: var(--light-color);
}
@media (max-width: 1025px) {
  .headerBannerDecor__bigText {
    font-size: 14px;
  }
}
@media (max-width: 769px) {
  .headerBannerDecor__bigText {
    font-size: 9.3px;
  }
}
.headerBannerDecor__promo {
  padding: 10px 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 18.5px;
  line-height: 1.25;
  color: var(--light-color);
  border: 1px dashed #818181;
  border-radius: 10px;
}
@media (max-width: 1025px) {
  .headerBannerDecor__promo {
    font-size: 16px;
    padding: 8px 15px;
  }
}
.headerBannerDecor__text {
  margin-right: auto;
  max-width: 205px;
  font-family: "Montserrat", sans-serif;
  font-size: 6.75px;
  line-height: 1.2;
  color: var(--light-color);
  opacity: 0.3;
}
.headerBannerDecor__closePc {
  font-size: 14px;
  padding: 5px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50px;
  color: rgba(255, 255, 255, 0.5);
  transition: all 0.4s;
}
.headerBannerDecor__closePc:hover {
  color: var(--light-color);
}
.headerBannerDecor__wrapMob {
  display: flex;
  gap: 5px;
  align-items: center;
}

.footer {
  padding: 50px 0;
  background: #f2f5f7;
}
@media (max-width: 1201px) {
  .footer {
    padding: 40px 0 82px;
  }
}
@media (max-width: 769px) {
  .footer {
    padding-top: 30px;
  }
}
.footer__info {
  margin: 50px 0;
}
@media (max-width: 1201px) {
  .footer__info {
    margin: 40px 0;
  }
}
@media (max-width: 769px) {
  .footer__info {
    margin: 30px 0;
  }
}

.footerMenu {
  column-count: 4;
  column-gap: 20px;
}
@media (max-width: 769px) {
  .footerMenu {
    column-count: 2;
  }
}
@media (max-width: 501px) {
  .footerMenu {
    column-count: initial;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
}
.footerMenu__col {
  page-break-inside: avoid;
  break-inside: avoid;
  margin-bottom: 44px;
}
@media (max-width: 1441px) {
  .footerMenu__col:last-of-type {
    margin-left: 35px;
  }
}
@media (max-width: 1201px) {
  .footerMenu__col:last-of-type {
    margin-left: initial;
  }
}
@media (max-width: 501px) {
  .footerMenu__col {
    display: none;
    margin-bottom: 0;
  }
  .footerMenu__col--mob {
    display: block;
  }
}
.footerMenu__name {
  margin: 0 0 20px;
  font-size: 14px;
  line-height: 1.1;
}
@media (max-width: 769px) {
  .footerMenu__name {
    margin-bottom: 12px;
  }
}
@media (max-width: 501px) {
  .footerMenu__name {
    text-align: center;
    display: none;
  }
  .footerMenu__name--mob {
    display: block;
  }
}
.footerMenu__name--mb {
  margin-bottom: 28px;
}
@media (max-width: 769px) {
  .footerMenu__name--mb {
    margin-bottom: 12px;
  }
}
.footerMenu__list li {
  line-height: 1.4;
}
.footerMenu__list li:not(:last-child) {
  margin-bottom: 10px;
}
.footerMenu__list li a {
  color: var(--dark-gray-color);
  transition: all 0.4s;
}
.footerMenu__list li a:hover {
  color: var(--dark-gray-color-hover-link);
}
.footerMenu__soc {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
@media (max-width: 501px) {
  .footerMenu__soc {
    gap: 20px 30px;
  }
}
.footerMenu__socLink {
  font-size: 24px;
  transition: all 0.4s;
  color: var(--red-color);
}
.footerMenu__socLink.icon-vk-new:hover {
  color: #0077ff;
}
.footerMenu__socLink.icon-odnoklassniki:hover {
  color: #ee8208;
}
.footerMenu__socLink.icon-twitter:hover {
  color: #1da1f2;
}
.footerMenu__contactsCard {
  display: flex;
  gap: 17px;
  line-height: 1.4;
}
.footerMenu__contactsCard:not(:last-of-type) {
  margin-bottom: 10px;
}
@media (max-width: 501px) {
  .footerMenu__contactsCard {
    text-align: center;
  }
}
.footerMenu__contactsIcon {
  font-size: 18px;
  color: var(--red-color);
}
@media (max-width: 501px) {
  .footerMenu__contactsIcon {
    display: none;
  }
}
.footerMenu__contactsContent {
  width: calc(100% - 35px);
}
@media (max-width: 501px) {
  .footerMenu__contactsContent {
    width: 100%;
    font-size: 16px;
  }
}
.footerMenu__telCard {
  display: flex;
  flex-direction: column;
}
.footerMenu__telCard:not(:last-of-type) {
  margin-bottom: 10px;
}
.footerMenu__tel {
  color: var(--dark-gray-color);
  transition: all 0.4s;
}
.footerMenu__tel:hover {
  color: var(--dark-gray-color-hover-link);
}
@media (max-width: 501px) {
  .footerMenu__tel {
    font-size: 16px;
  }
}
.footerMenu__telDescrription {
  font-size: 10px;
  color: #8c959c;
}
@media (max-width: 501px) {
  .footerMenu__telDescrription {
    font-size: 12px;
  }
}
.footerMenu__contactsEmail {
  color: var(--dark-gray-color);
  transition: all 0.4s;
}
.footerMenu__contactsEmail:hover {
  color: var(--dark-gray-color-hover-link);
}
@media (max-width: 501px) {
  .footerMenu__contactsEmail {
    font-size: 16px;
  }
}

.footerInfo {
  border-top: 1px solid #b8c0c5;
  border-bottom: 1px solid #b8c0c5;
  padding: 29px 0;
  display: flex;
  justify-content: space-between;
  gap: 30px 20px;
  flex-wrap: wrap;
  align-items: center;
}
@media (max-width: 1025px) {
  .footerInfo {
    flex-direction: column;
  }
}
.footerInfo__banks {
  display: flex;
  gap: 18px 30px;
  flex-wrap: wrap;
  align-items: center;
}
@media (max-width: 769px) {
  .footerInfo__banks {
    justify-content: center;
    padding: 0 30px;
  }
}
.footerInfo__bank svg {
  display: block;
}
.footerInfo__bank svg * {
  transition: all 0.4s;
}
.footerInfo__bank svg:not(:hover) * {
  fill: #b8c0c5;
}
.footerInfo__ratings {
  display: flex;
  gap: 20px 60px;
  flex-wrap: wrap;
}
@media (max-width: 769px) {
  .footerInfo__ratings {
    flex-direction: column;
    align-items: center;
  }
}
.footerInfo__rating {
  margin-bottom: 14px;
}
@media (max-width: 769px) {
  .footerInfo__rating {
    margin-bottom: 16.8px;
  }
}
.footerInfo__yandexMarket {
  display: block;
  width: fit-content;
  height: 20px;
}
@media (max-width: 769px) {
  .footerInfo__yandexMarket {
    height: initial;
  }
}
.footerInfo__yandexMarket img {
  display: block;
  width: 100%;
  height: 100%;
}
@media (max-width: 769px) {
  .footerInfo__yandexMarket img {
    height: 24px;
    width: 130px;
    object-fit: contain;
  }
}
.footerInfo__yandex {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
@media (max-width: 769px) {
  .footerInfo__yandex {
    gap: 7.6px;
  }
}
.footerInfo__yandexWrap {
  display: flex;
  align-items: center;
  gap: 14px;
}
.footerInfo__yandexDescr {
  color: var(--gray-color2);
  font-size: 10px;
  line-height: 1.4;
}
@media (max-width: 769px) {
  .footerInfo__yandexDescr {
    font-size: 12px;
    text-align: center;
  }
}
.footerInfo__yandexIcon {
  font-size: 32px;
  color: #fc3f1d;
}
.footerInfo__yandexRating {
  font-size: 18px;
  line-height: 1;
  font-weight: 700;
}

.footerBottom__text {
  font-size: 12px;
  line-height: 1.4;
  color: var(--gray-color2);
  margin-bottom: 45px;
}
@media (max-width: 769px) {
  .footerBottom__text {
    text-align: center;
    margin-bottom: 30px;
  }
}
.footerBottom__info {
  display: flex;
  align-items: center;
  gap: 20px 40px;
  flex-wrap: wrap;
  justify-content: space-between;
  color: var(--gray-color2);
}
@media (max-width: 1025px) {
  .footerBottom__info {
    flex-direction: column;
    align-items: center;
  }
}
.footerBottom__wrap {
  display: flex;
  align-items: center;
  gap: 20px 105px;
  flex-wrap: wrap;
}
@media (max-width: 1201px) {
  .footerBottom__wrap {
    gap: 20px 50px;
  }
}
@media (max-width: 1025px) {
  .footerBottom__wrap {
    flex-direction: column;
    align-items: center;
  }
}
.footerBottom__logo {
  font-size: 95px;
  width: 95px;
  height: 24px;
  position: relative;
  color: var(--gray-color2);
  transition: all 0.4s;
}
.footerBottom__logo::before {
  pointer-events: none;
  display: block;
  transform: translateY(-50%);
  top: 50%;
  position: absolute;
}
.footerBottom__logo:hover {
  color: var(--dark-color);
}
.footerBottom__copyright {
  font-size: 12px;
  line-height: 1.4;
}
.footerBottom__termsOfUse {
  font-size: 12px;
  line-height: 1.4;
  color: var(--gray-color2);
  transition: all 0.4s;
}
.footerBottom__termsOfUse:hover {
  color: var(--gray-color-hover-link);
}
.footerBottom__design {
  margin-left: auto;
  display: flex;
  gap: 7px;
  align-items: center;
  font-size: 12px;
  line-height: 1.4;
  color: var(--gray-color2);
}
.footerBottom__design img {
  display: block;
  width: 110px;
  object-fit: contain;
  margin-bottom: -5px;
}

.mainSlider {
  overflow: hidden;
}
.mainSlider__section {
  margin-bottom: 30px;
}
@media (max-width: 769px) {
  .mainSlider__section {
    margin-bottom: 20px;
  }
}
.mainSlider__slide {
  display: block;
  width: 980px;
  border-radius: 10px;
  overflow: hidden;
}
@media (max-width: 1201px) {
  .mainSlider__slide {
    width: 880px;
  }
}
@media (max-width: 1025px) {
  .mainSlider__slide {
    width: 100%;
    border-radius: 0;
  }
}
.mainSlider__slide img {
  display: block;
  object-fit: cover;
  width: 100%;
}
@media (max-width: 1201px) {
  .mainSlider__nav.swiper-button-next-custom {
    transform: translateX(-50%) translateY(-50%);
  }
  .mainSlider__nav.swiper-button-prev-custom {
    transform: translateX(50%) translateY(-50%);
  }
}
@media (max-width: 1025px) {
  .mainSlider__nav {
    display: none;
  }
}

.banners__section {
  margin: 30px 0 80px;
}
@media (max-width: 1441px) {
  .banners__section {
    margin-bottom: 40px;
  }
}
@media (max-width: 1201px) {
  .banners__section {
    margin-bottom: 30px;
  }
}
@media (max-width: 769px) {
  .banners__section {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.banners__wrap {
  display: flex;
  gap: 20px;
  flex-direction: column;
}
.banners__banner {
  display: block;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
}
.banners__banner img {
  display: block;
  width: 100%;
}
@media (max-width: 767px) {
  .banners__banner img {
    height: 56vw;
    object-fit: cover;
  }
}
.banners__btnVideo {
  position: absolute;
  top: 50%;
  right: 60px;
  transform: translateY(-50%);
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  color: var(--light-color);
  display: flex;
  align-items: center;
  gap: 15px;
  text-align: left;
}
@media (max-width: 1201px) {
  .banners__btnVideo {
    gap: 12px;
    font-size: 18px;
    right: 40px;
  }
}
@media (max-width: 769px) {
  .banners__btnVideo {
    gap: 10px;
    font-size: 12px;
    right: initial;
    bottom: 20px;
    left: 20px;
    top: initial;
    transform: none;
  }
}
.banners__btnVideoIcon {
  font-size: 77px;
}
@media (max-width: 1201px) {
  .banners__btnVideoIcon {
    font-size: 58px;
  }
}
@media (max-width: 1201px) {
  .banners__btnVideoIcon {
    font-size: 42px;
  }
}

.catalog__section {
  margin: 80px 0;
}
@media (max-width: 1441px) {
  .catalog__section {
    margin: 40px 0 60px;
  }
}
@media (max-width: 1201px) {
  .catalog__section {
    margin: 30px 0 45px;
  }
}
@media (max-width: 769px) {
  .catalog__section {
    margin: 20px 0 30px;
  }
}
.catalog__wrap {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
@media (max-width: 769px) {
  .catalog__wrap {
    gap: 9px;
  }
}
@media (max-width: 769px) {
  .catalog__wrap--mobScroll {
    flex-wrap: nowrap;
    width: fit-content;
  }
}
.catalog__item {
  width: calc(25% - 15px);
  position: relative;
  padding: 18px 27px 22px;
  aspect-ratio: 28/12;
  border-radius: 9px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1441px) {
  .catalog__item {
    padding: 15px 20px 19px;
  }
}
@media (max-width: 1025px) {
  .catalog__item {
    width: calc(33.3333333333% - 13.3333333333px);
  }
}
@media (max-width: 769px) {
  .catalog__item {
    width: calc(25% - 6.75px);
    aspect-ratio: initial;
    border-radius: 0;
    padding: 0;
    gap: 6px;
    text-align: center;
  }
}
@media (max-width: 501px) {
  .catalog__item {
    width: calc(33.3333333333% - 6px);
  }
}
.catalog__item::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(42, 72, 92, 0);
  transition: all 0.4s;
  position: absolute;
  z-index: 4;
}
.catalog__item:hover::after {
  background-color: rgba(42, 72, 92, 0.05);
}
@media (max-width: 769px) {
  .catalog__item:hover::after {
    background-color: transparent;
  }
}
.catalog__item:hover .catalog__btn {
  opacity: 1;
}
@media (max-width: 769px) {
  .catalog__item--mobScroll {
    width: 60px;
  }
}
.catalog__img {
  display: block;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (max-width: 769px) {
  .catalog__img {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
  }
}
.catalog__name {
  font-weight: 700;
  font-size: 18px;
  line-height: 1.2;
  color: var(--dark-color);
  position: relative;
  z-index: 5;
  max-width: 155px;
}
@media (max-width: 1441px) {
  .catalog__name {
    font-size: 16px;
    max-width: 135px;
  }
}
@media (max-width: 769px) {
  .catalog__name {
    max-width: 100%;
    padding-left: 3px;
    padding-right: 3px;
    font-size: 10px;
    font-weight: 400;
  }
}
.catalog__btn {
  position: relative;
  z-index: 5;
  margin-top: auto;
  opacity: 0;
  padding: 10px 17px;
  font-weight: 400;
  font-size: 10px;
  line-height: 1.2;
}
@media (max-width: 769px) {
  .catalog__btn {
    display: none;
  }
}
.catalog__itemMini {
  display: block;
  text-align: center;
  width: 106px;
}
@media (max-width: 769px) {
  .catalog__itemMini {
    width: 60px;
  }
}
.catalog__image {
  display: block;
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 6px;
}
.catalog__nameMiniCard {
  font-size: 10px;
  line-height: 1.2;
  text-align: center;
  color: var(--dark-color);
  transition: all 0.4s;
}
.catalog__nameMiniCard:hover {
  color: var(--dark-color-hover-link);
}

.stocks__section {
  margin: 80px 0;
}
@media (max-width: 1441px) {
  .stocks__section {
    margin: 60px 0;
  }
}
@media (max-width: 1201px) {
  .stocks__section {
    margin: 45px 0;
  }
}
@media (max-width: 769px) {
  .stocks__section {
    margin: 30px 0;
  }
}
.stocks__page {
  margin: 0 0 80px;
}
@media (max-width: 1441px) {
  .stocks__page {
    margin-bottom: 40px;
  }
}
@media (max-width: 769px) {
  .stocks__page {
    margin-bottom: 50px;
  }
}
.stocks__wrap {
  display: flex;
  gap: 30px 20px;
  flex-wrap: wrap;
}
.stocks__item {
  width: calc(33.3333333333% - 13.3333333333px);
}
@media (max-width: 769px) {
  .stocks__item {
    width: calc(50% - 10px);
  }
}
@media (max-width: 501px) {
  .stocks__item {
    width: 100%;
  }
}
.stocks__more {
  margin-top: 30px;
}
@media (max-width: 1440px) {
  .stocks__more {
    margin-top: 50px;
  }
}
.stocks__pagination {
  margin-top: 20px;
}
@media (max-width: 769px) {
  .stocks__pagination {
    margin-top: 10px;
  }
}

.stocksItem__imgWrap {
  position: relative;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
  aspect-ratio: 2/1;
}
@media (max-width: 1441px) {
  .stocksItem__imgWrap {
    padding: 20px;
  }
}
@media (max-width: 1025px) {
  .stocksItem__imgWrap {
    padding: 15px;
    margin-bottom: 16px;
  }
}
@media (max-width: 501px) {
  .stocksItem__imgWrap {
    padding: 10px;
    margin-bottom: 12px;
  }
}
.stocksItem__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
.stocksItem__date {
  padding: 8px 9px;
  font-weight: 400;
  font-size: 10px;
  line-height: 1.2;
  position: relative;
  z-index: 2;
  background: #000000;
  border-radius: 7px;
  width: fit-content;
  color: var(--light-color);
}
@media (max-width: 1441px) {
  .stocksItem__date {
    font-size: 11px;
  }
}
.stocksItem__sale {
  padding: 10.5px 10px;
  background: #ed0000;
  border-radius: 7px;
  position: relative;
  z-index: 2;
  margin-top: auto;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2;
  color: var(--light-color);
  width: fit-content;
}
@media (max-width: 1025px) {
  .stocksItem__sale span {
    display: none;
  }
}
.stocksItem__name {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.3;
  color: var(--dark-color);
  transition: all 0.4s;
}
.stocksItem__name:hover {
  color: var(--dark-color-hover-link);
}
@media (max-width: 1441px) {
  .stocksItem__name {
    font-size: 16px;
  }
}
@media (max-width: 1025px) {
  .stocksItem__name {
    font-size: 15px;
  }
}
@media (max-width: 501px) {
  .stocksItem__name {
    font-size: 14px;
  }
}

.popular__section {
  margin: 80px 0;
}
@media (max-width: 1441px) {
  .popular__section {
    margin: 60px 0;
  }
}
@media (max-width: 1201px) {
  .popular__section {
    margin: 45px 0;
  }
}
@media (max-width: 769px) {
  .popular__section {
    margin: 30px 0;
  }
}
@media (max-width: 1201px) {
  .popular__tabsWrap {
    overflow: auto;
    width: calc(100% + var(--container-offset) * 2);
    margin-left: calc(-1 * var(--container-offset));
    margin-right: calc(-1 * var(--container-offset));
    padding-bottom: 10px;
  }
}
.popular__tabs {
  display: flex;
  gap: 20px;
  background: #f2f5f7;
  border-radius: 9px;
  justify-content: center;
  padding: 0 12px;
}
@media (max-width: 1201px) {
  .popular__tabs {
    border-radius: 0;
    width: fit-content;
    min-width: 100%;
  }
}
.popular__tab {
  transition: all 0.4s;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  border-bottom: 2px solid transparent;
  min-height: 54px;
  cursor: pointer;
  font-size: 16px;
  line-height: 1.2;
  white-space: nowrap;
  color: var(--dark-color);
}
.popular__tab--active {
  cursor: default;
}
.popular__tab:hover {
  border-color: var(--gray-color);
}
.popular__tab--active {
  border-color: var(--red-color) !important;
}
@media (max-width: 1201px) {
  .popular__tab {
    font-size: 14px;
    min-height: 50px;
  }
}
@media (max-width: 769px) {
  .popular__tab {
    font-size: 13px;
    min-height: 46px;
  }
}
.popular__brands {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  display: none;
}
.popular__brands--active {
  display: flex;
}
@media (max-width: 769px) {
  .popular__brands {
    gap: 9px;
  }
}
.popular__brand {
  width: calc(16.6666666667% - 16.6666666667px);
  padding: 30px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1441px) {
  .popular__brand {
    padding: 20px 30px;
  }
}
@media (max-width: 1025px) {
  .popular__brand {
    padding: 15px 25px;
  }
}
@media (max-width: 769px) {
  .popular__brand {
    padding: 10px 20px;
    width: calc(25% - 6.75px);
  }
}
@media (max-width: 501px) {
  .popular__brand {
    padding: 7px 15px;
    width: calc(33.3333333333% - 6px);
  }
}
.popular__brand img {
  width: 100%;
  display: block;
  filter: grayscale(1);
  transition: all 0.4s;
}
.popular__brand:hover img {
  filter: none;
}

.seoText__section {
  margin: 80px 0;
}
@media (max-width: 1441px) {
  .seoText__section {
    margin: 60px 0;
  }
}
@media (max-width: 1201px) {
  .seoText__section {
    margin: 45px 0;
  }
}
@media (max-width: 769px) {
  .seoText__section {
    margin: 30px 0;
  }
}
.seoText__text {
  max-width: 1180px;
}

.benefits__section {
  margin: 80px 0;
}
@media (max-width: 1441px) {
  .benefits__section {
    margin: 60px 0;
  }
}
@media (max-width: 1201px) {
  .benefits__section {
    margin: 45px 0;
  }
}
@media (max-width: 769px) {
  .benefits__section {
    margin: 30px 0;
  }
}
.benefits__section--mMin {
  margin: 60px 0;
}
@media (max-width: 769px) {
  .benefits__section--mMin {
    margin: 50px 0;
  }
}
.benefits__wrap {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
@media (max-width: 769px) {
  .benefits__wrap {
    gap: 9px;
  }
}
.benefits__item {
  width: calc(25% - 15px);
  background: #f2f5f7;
  border-radius: 9px;
  padding: 17px 20px;
  display: flex;
  align-items: center;
  gap: 10px 20px;
  font-size: 17px;
  line-height: 1.15;
}
@media (max-width: 1441px) {
  .benefits__item {
    font-size: 14px;
  }
}
@media (max-width: 1025px) {
  .benefits__item {
    width: calc(33.3333333333% - 13.3333333333px);
  }
}
@media (max-width: 769px) {
  .benefits__item {
    width: calc(33.3333333333% - 6px);
    flex-direction: column;
    font-size: 11px;
    padding: 10px;
    text-align: center;
  }
}
.benefits__icon {
  color: var(--red-color);
  font-size: 42px;
}
@media (max-width: 1441px) {
  .benefits__icon {
    font-size: 32px;
  }
}
@media (max-width: 769px) {
  .benefits__icon {
    font-size: 40px;
  }
}
@media (max-width: 769px) {
  .benefits__name {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
  }
}

.catalogPage {
  overflow-x: hidden;
}
.catalogPage__page {
  margin: 0 0 80px;
}
@media (max-width: 1441px) {
  .catalogPage__page {
    margin-bottom: 40px;
  }
}
@media (max-width: 769px) {
  .catalogPage__page {
    margin-bottom: 30px;
  }
}
.catalogPage__infoImg {
  display: block;
  width: 100%;
  margin: 60px 0;
}
@media (max-width: 1025px) {
  .catalogPage__infoImg {
    margin: 30px 0;
  }
}
@media (max-width: 769px) {
  .catalogPage__infoImg {
    margin: 10px 0;
  }
}
.catalogPage__catalogProducts {
  margin-bottom: 66px;
}
@media (max-width: 1201px) {
  .catalogPage__catalogProducts {
    margin-bottom: 50px;
  }
}
@media (max-width: 769px) {
  .catalogPage__catalogProducts {
    margin-bottom: 10px;
  }
}
.catalogPage__infoText {
  margin-bottom: 35px;
}
@media (max-width: 769px) {
  .catalogPage__infoText {
    margin-bottom: 30px;
  }
}
.catalogPage__selectMob {
  display: none;
}
@media (max-width: 769px) {
  .catalogPage__selectMob {
    display: block;
    margin-bottom: 12px;
  }
}
.catalogPage__tabs {
  margin: 15px 0;
}
@media (max-width: 769px) {
  .catalogPage__tabs {
    margin: 0;
  }
}
.catalogPage__brends {
  margin: 15px 0;
}
@media (max-width: 769px) {
  .catalogPage__brends {
    display: none;
  }
}
.catalogPage__br {
  width: 100%;
  height: 1px;
  background: #e2e8ec;
  margin: 15px 0 25px;
}
.catalogPage__br--mob {
  display: none;
}
@media (max-width: 769px) {
  .catalogPage__br--mob {
    display: block;
  }
}
@media (max-width: 769px) {
  .catalogPage__br {
    margin: 0;
  }
}
.catalogPage__wrapFilterCatalog {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}
.catalogPage__wrapFilterCatalog--mt {
  margin-top: 20px;
}
@media (max-width: 769px) {
  .catalogPage__wrapFilterCatalog--mt {
    margin-top: 0;
  }
}
.catalogPage__wrapFilter {
  width: 220px;
}
@media (max-width: 769px) {
  .catalogPage__wrapFilter {
    display: none !important;
  }
}
.catalogPage__wrapFilter--fav {
  width: 220px;
}
@media (max-width: 1441px) {
  .catalogPage__wrapFilter--fav {
    width: 240px;
  }
}
.catalogPage__wrapCatalog {
  width: calc(100% - 240px);
}
@media (max-width: 1441px) {
  .catalogPage__wrapCatalog {
    max-width: 900px;
  }
}
@media (max-width: 769px) {
  .catalogPage__wrapCatalog {
    width: 100%;
  }
}
.catalogPage__mobFiltersVariant {
  display: none;
}
@media (max-width: 769px) {
  .catalogPage__mobFiltersVariant {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding: 8px 0;
    align-items: center;
  }
}
.catalogPage__mobFiltersBtn {
  padding: 10px 17.25px 10px 12.25px;
  border-radius: 9px;
  background-color: var(--dark-gray-color3);
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  justify-content: center;
  text-align: center;
  gap: 6px;
  align-self: normal;
  font-size: 13px;
  line-height: 1.15;
  color: var(--dark-color);
  position: relative;
}
.catalogPage__mobFiltersBtn--active::after {
  content: attr(data-numb);
  top: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: var(--red-color);
  border: 2px solid var(--light-color);
  border-radius: 20px;
  padding: 1px 5px;
  font-weight: 700;
  font-size: 10px;
  line-height: 1.4;
  color: var(--light-color);
  transform: translateX(33%) translateY(-3px);
  transition: all 0.4s;
}
.catalogPage__mobFiltersBtnIcon {
  font-size: 20px;
}
.catalogPage__sections {
  flex-direction: column;
  display: none;
}
@media (max-width: 769px) {
  .catalogPage__sections {
    display: flex;
    width: calc(100% + 24px);
    margin-left: -12px;
    margin-right: -12px;
    border-top: 1px solid var(--dark-gray-color3-hover-btn);
    margin-bottom: 6px;
  }
}
.catalogPage__section {
  display: block;
  padding: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: var(--dark-color);
  width: 100%;
  border-bottom: 1px solid var(--dark-gray-color3-hover-btn);
}
.catalogPage__slider {
  margin-bottom: 30px;
}
@media (max-width: 1025px) {
  .catalogPage__slider {
    margin-bottom: 20px;
  }
}
@media (max-width: 769px) {
  .catalogPage__slider {
    margin-bottom: 10px;
  }
}

.catalogPageTabs--active .catalogPageTabs__item {
  display: flex !important;
}
.catalogPageTabs--mobOnly {
  display: none;
}
@media (max-width: 769px) {
  .catalogPageTabs--mobOnly {
    display: block;
  }
}
.catalogPageTabs__list {
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
}
@media (max-width: 769px) {
  .catalogPageTabs__list {
    flex-wrap: nowrap;
    width: fit-content;
  }
}
.catalogPageTabs__list--gap {
  gap: 8px;
}
.catalogPageTabs__item {
  padding: 8px 14px;
  font-size: 14px;
  white-space: nowrap;
  line-height: 1.4;
  display: flex;
  align-items: center;
  background: var(--dark-gray-color3);
  border-radius: 5px;
  color: var(--dark-color);
  transition: all 0.4s;
}
@media (max-width: 769px) {
  .catalogPageTabs__item {
    padding: 4px 8px;
  }
}
.catalogPageTabs__item:nth-child(n+12) {
  display: none;
}
@media (max-width: 769px) {
  .catalogPageTabs__item:nth-child(n+12) {
    display: flex;
  }
}
.catalogPageTabs__item:hover {
  background-color: var(--dark-gray-color3-hover-btn);
}
.catalogPageTabs__item--nameCategory {
  padding: 8px 0;
  font-size: 11px;
  line-height: 1.9;
  background-color: transparent;
  color: var(--gray-color);
}
@media (max-width: 769px) {
  .catalogPageTabs__item--nameCategory {
    display: none !important;
  }
}
.catalogPageTabs__item--nameCategory:hover {
  background-color: transparent;
}
.catalogPageTabs__more {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 8px 14px;
  font-size: 14px;
  line-height: 1.4;
  color: var(--light-color);
  background: var(--red-color);
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: all 0.4s;
}
@media (max-width: 769px) {
  .catalogPageTabs__more {
    display: none;
  }
}
.catalogPageTabs__more:hover {
  background-color: #c70000;
}
.catalogPageTabs__nameMob {
  display: none;
  margin-bottom: 6px;
  font-size: 11px;
  line-height: 1.9;
  color: var(--gray-color);
}
@media (max-width: 769px) {
  .catalogPageTabs__nameMob {
    display: block;
  }
}
.catalogPageTabs__mobScroll {
  width: calc(100% + var(--container-offset) * 2);
  margin-left: calc(-1 * var(--container-offset));
  margin-right: calc(-1 * var(--container-offset));
  padding-left: var(--container-offset);
  padding-right: var(--container-offset);
  overflow: auto;
  padding-bottom: 10px;
}
.catalogPageTabs__title {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2;
  color: var(--dark-color);
  margin: 0 0 20px;
}

.catalogPageBrends__text {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2;
  color: var(--dark-color);
  display: inline-block;
  margin-right: 20px;
  vertical-align: middle;
}
.catalogPageBrends__list {
  display: inline-flex;
  gap: 5px 2px;
  flex-wrap: wrap;
  align-items: flex-start;
  vertical-align: middle;
}
.catalogPageBrends__nameText {
  font-size: 17px;
  line-height: 1.2;
  color: var(--dark-color);
  position: relative;
  z-index: 4;
  transition: all 0.4s;
  cursor: default;
}
.catalogPageBrends__lang {
  font-size: 11px;
  line-height: 1.2;
  color: var(--gray-color);
  padding-top: 10px;
}
.catalogPageBrends__name {
  padding: 10px 14px;
  background-color: transparent;
  transition: all 0.4s;
  z-index: 2;
  position: relative;
  border-radius: 5px 5px 0px 0px;
}
.catalogPageBrends__name::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 3;
  background-color: #ffffff;
  border-radius: 5px 5px 0px 0px;
  transition: all 0.4s;
  opacity: 0;
  pointer-events: none;
}
.catalogPageBrends__name:hover {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(151, 165, 173, 0.25);
}
.catalogPageBrends__name:hover::after {
  opacity: 1;
}
.catalogPageBrends__name:hover .catalogPageBrends__nameText {
  color: var(--red-color);
}
.catalogPageBrends__name:hover .catalogPageBrends__nameList {
  opacity: 1;
  pointer-events: all;
  background: #ffffff;
  border-radius: 0px 5px 5px 5px;
  box-shadow: 0px 0px 10px rgba(151, 165, 173, 0.25);
}
.catalogPageBrends__nameList {
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateY(100%);
  transition: all 0.4s;
  padding: 15px;
  list-style: none;
  margin: 0;
  width: 170px;
}
.catalogPageBrends__nameList a {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
  color: var(--dark-color);
  transition: all 0.4s;
}
.catalogPageBrends__nameList a:hover {
  color: var(--dark-color-hover-link);
}

.catalogPageFilter {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.catalogPageFilter__brend {
  display: block;
  width: 100%;
}
.catalogPageFilter__test {
  background: var(--dark-gray-color3);
  border-radius: 7px;
  padding: 20px;
  position: relative;
}
.catalogPageFilter__testTitle {
  font-weight: 700;
  font-size: 15px;
  line-height: 1.2;
  color: var(--dark-color);
  margin: 0 0 15px;
}
.catalogPageFilter__testText {
  font-size: 12px;
  line-height: 1.15;
  color: #97a5ad;
  margin: 15px 0;
}
.catalogPageFilter__testBtn {
  font-size: 14px;
  line-height: 1.15;
  gap: 8px;
  border-radius: 4px;
  padding: 10px 15px;
}
.catalogPageFilter__testIcon {
  font-size: 24px;
}
.catalogPageFilter__testClose {
  padding: 0;
  margin: 0;
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 14px;
  background-color: transparent;
  transition: all 0.4s;
  cursor: pointer;
  border: none;
  color: var(--gray-color);
}
.catalogPageFilter__testClose:hover {
  color: var(--gray-color-hover-link);
}
.catalogPageFilter__banner {
  display: block;
  width: 100%;
  border-radius: 9px;
  overflow: hidden;
}
.catalogPageFilter__banner img {
  display: block;
  width: 100%;
}
.catalogPageCatalog__more {
  margin-top: 30px;
}
.catalogPageCatalog__pagination {
  margin-top: 20px;
}
.catalogPageCatalog__seo {
  margin-top: 40px;
}
@media (max-width: 1441px) {
  .catalogPageCatalog__seo {
    margin-top: 72px;
  }
}
@media (max-width: 1201px) {
  .catalogPageCatalog__seo {
    margin-top: 40px;
  }
}
.catalogPageCatalog__tegs {
  margin-bottom: 15px;
}
@media (max-width: 769px) {
  .catalogPageCatalog__tegs {
    margin: 8px 0 0;
  }
}
.catalogPageCatalog__sortVariant {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
@media (max-width: 769px) {
  .catalogPageCatalog__sortVariant {
    display: none;
  }
}
@media (max-width: 769px) {
  .catalogPageCatalog__productCards {
    width: calc(100% + var(--container-offset) * 2);
    margin-left: calc(-1 * var(--container-offset));
    margin-right: calc(-1 * var(--container-offset));
  }
}

.catalogPageFilterMoreFilters {
  display: flex;
  gap: 20px;
  align-items: center;
}
.catalogPageFilterMoreFilters__iconInfo {
  font-size: 24px;
  color: var(--dark-gray-color3-hover-btn);
  display: block;
}
.catalogPageFilterMoreFilters__textWrap {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.catalogPageFilterMoreFilters__text {
  font-weight: 700;
  font-size: 14px;
  line-height: 1.4;
  color: var(--dark-color);
}
.catalogPageFilterMoreFilters__btn {
  transition: all 0.4s;
  font-size: 12px;
  line-height: 0.9;
  color: var(--blue-color);
  text-align: left;
  width: fit-content;
}
.catalogPageFilterMoreFilters__btn:hover {
  color: var(--blue-color-hover-link);
}

.cardProductFilterSlider {
  width: 100%;
  border: 1px solid #e2e8ec;
  border-radius: 9px;
  padding: 10px;
  overflow: hidden;
}
.cardProductFilterSlider__card {
  padding: 0 !important;
}
.cardProductFilterSlider__card::after {
  content: none !important;
}
.cardProductFilterSlider .swiper-pagination {
  position: relative;
  bottom: initial;
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}
.cardProductFilterSlider .swiper-pagination > * {
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  opacity: 1;
  background: var(--dark-gray-color3);
  border-radius: 5px;
  transition: all 0.4s;
  margin: 0 !important;
  padding: 0;
}
.cardProductFilterSlider .swiper-pagination > *:hover {
  background: var(--dark-gray-color3-hover-btn);
}
.cardProductFilterSlider .swiper-pagination > *.swiper-pagination-bullet-active {
  background: var(--red-color);
  width: 12px;
}

.catalogPageCatalogTegs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
}
@media (max-width: 769px) {
  .catalogPageCatalogTegs {
    flex-wrap: nowrap;
    width: fit-content;
  }
}
@media (max-width: 769px) {
  .catalogPageCatalogTegs__mobScroll {
    width: calc(100% + var(--container-offset) * 2);
    margin-left: calc(-1 * var(--container-offset));
    margin-right: calc(-1 * var(--container-offset));
    padding-left: var(--container-offset);
    padding-right: var(--container-offset);
    overflow: auto;
    padding-bottom: 10px;
  }
}
.catalogPageCatalogTegs__btn {
  padding: 4px 11px 4px 14px;
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--gray-color);
  border: 2px solid var(--gray-color);
  border-radius: 15px;
  white-space: nowrap;
}
@media (max-width: 769px) {
  .catalogPageCatalogTegs__btn {
    padding: 6px 14px;
    border: none;
    background: #1f2d35;
  }
}
.catalogPageCatalogTegs__btn:hover .catalogPageCatalogTegs__close {
  color: var(--red-color);
}
.catalogPageCatalogTegs__btn--reset {
  border-color: transparent;
}
.catalogPageCatalogTegs__text {
  font-size: 13px;
  line-height: 20px;
  color: var(--dark-color);
}
@media (max-width: 769px) {
  .catalogPageCatalogTegs__text {
    color: #f2f5f7;
  }
}
.catalogPageCatalogTegs__textC {
  font-size: 11px;
  line-height: 20px;
  color: var(--gray-color);
}
@media (max-width: 769px) {
  .catalogPageCatalogTegs__textC {
    color: #b8c0c5;
  }
}
.catalogPageCatalogTegs__close {
  font-size: 8.67px;
  transition: all 0.4s;
  display: block;
  line-height: 20px;
  margin-left: 4px;
}
@media (max-width: 769px) {
  .catalogPageCatalogTegs__close {
    color: #b8c0c5;
  }
}

.catalogPageCatalogSort {
  display: flex;
  gap: 8px 16px;
  flex-wrap: wrap;
}
.catalogPageCatalogSort__text {
  font-weight: 700;
  font-size: 14px;
  line-height: 1.2;
  color: var(--dark-color);
}
.catalogPageCatalogSort__btn {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: var(--gray-color);
  transition: 0.4s;
}
.catalogPageCatalogSort__btn--active, .catalogPageCatalogSort__btn:hover {
  color: var(--dark-color);
}

.catalogPageCatalogVariant {
  display: flex;
  gap: 10px;
}
.catalogPageCatalogVariant__btn {
  font-size: 24px;
  color: var(--dark-gray-color3-hover-btn);
  transition: all 0.4s;
  display: block;
}
.catalogPageCatalogVariant__btn--active, .catalogPageCatalogVariant__btn:hover {
  color: var(--red-color);
}

@media (max-width: 1025px) {
  .catalogPageSlider {
    overflow: visible;
  }
}

.catalogFavoritePopup {
  position: fixed;
  z-index: 1050;
  top: 0;
  left: 0;
  width: 100%;
  background: #ffffff;
  transition: all 0.4s;
  padding: 10px 0;
  transform: translateY(-100%);
}
@media (max-width: 769px) {
  .catalogFavoritePopup {
    display: none;
  }
}
.catalogFavoritePopup--active {
  transform: none;
  box-shadow: 0px 0px 20px rgba(151, 165, 173, 0.75);
}
.catalogFavoritePopup__wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
}
.catalogFavoritePopup__card {
  display: flex;
  gap: 20px;
  align-items: center;
}
.catalogFavoritePopup__cardImgWrap {
  display: block;
  width: 60px;
  height: 60px;
}
.catalogFavoritePopup__cardImgWrap img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.catalogFavoritePopup__cardInfo {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.catalogFavoritePopup__cardName {
  font-size: 14px;
  line-height: 1.3;
}
.catalogFavoritePopup__cardName a {
  font-weight: 700;
  color: var(--dark-color);
  transition: all 0.4s;
}
.catalogFavoritePopup__cardName a:hover {
  color: var(--dark-color-hover-link);
}
.catalogFavoritePopup__cardDescr {
  font-size: 14px;
  line-height: 1.3;
  color: var(--gray-color);
}
.catalogFavoritePopup__right {
  display: flex;
  gap: 20px;
  align-items: center;
}
.catalogFavoritePopup__close {
  font-size: 24px;
  transition: all 0.4s;
  color: var(--gray-color);
}
.catalogFavoritePopup__close:hover {
  color: var(--gray-color-hover-link);
}

.productCards {
  display: flex;
  flex-wrap: wrap;
  z-index: 1;
}
.productCards__card {
  width: 25%;
}
@media (max-width: 1441px) {
  .productCards__card {
    width: 33.3333333333%;
  }
}
@media (max-width: 1025px) {
  .productCards__card {
    width: 50%;
  }
}
@media (max-width: 769px) {
  .productCards__card {
    width: 33.3333333333%;
  }
}
@media (max-width: 601px) {
  .productCards__card {
    width: 50%;
  }
}
.productCards--line .productCards__card {
  width: 100%;
}
.productCards--line .productCard {
  display: grid;
  gap: 0 30px;
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: 210px auto 230px;
  grid-template-rows: auto 1fr;
  grid-template-areas: "a b c" "a b d";
}
@media (max-width: 1025px) {
  .productCards--line .productCard {
    gap: 0 20px;
    grid-template-columns: 170px auto 160px;
  }
}
@media (max-width: 769px) {
  .productCards--line .productCard {
    padding-left: 12px;
    padding-right: 12px;
  }
}
@media (max-width: 601px) {
  .productCards--line .productCard {
    grid-template-columns: 120px auto;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a b" "a c" "a d";
  }
}
.productCards--line .productCard::after {
  border-left: none;
  border-right: none;
}
.productCards--line .productCard__item1 {
  grid-area: a;
  margin-bottom: 0;
}
.productCards--line .productCard__item2 {
  grid-area: c;
}
.productCards--line .productCard__item3 {
  grid-area: b;
  margin-bottom: 0;
}
@media (max-width: 1025px) {
  .productCards--line .productCard__item3 {
    gap: 6px;
  }
}
.productCards--line .productCard__item4 {
  grid-area: d;
  gap: 20px;
  margin-top: 0;
}
@media (max-width: 1025px) {
  .productCards--line .productCard__item4 {
    gap: 8px;
  }
}
@media (max-width: 601px) {
  .productCards--line .productCard__item4 {
    align-items: flex-start;
  }
}
.productCards--line .productCard__basketInfo {
  flex-direction: column;
  gap: 10px;
}
.productCards--line .productCard__info {
  flex-direction: row;
  gap: 2px;
}
.productCards--line .productCard__characteristics {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.productCards--line .productCard__characteristic {
  font-size: 14px;
  line-height: 1.2;
  color: var(--gray-color);
}
.productCards--line .productCard__characteristic span {
  color: var(--dark-color);
}
.productCards--line .productCard__basket {
  width: 100%;
}
.productCards--line .productCard__promo {
  width: 100%;
}
@media (max-width: 1025px) {
  .productCards--line .productCard__imgWrap {
    margin-bottom: 10px;
  }
}
@media (max-width: 1025px) {
  .productCards--line .productCard__tegs {
    top: 0;
    left: 0;
  }
}
@media (max-width: 601px) {
  .productCards--line .productCard__tegs {
    display: none;
  }
}
@media (max-width: 601px) {
  .productCards--line .productCard__characteristics {
    display: none;
  }
}
@media (max-width: 1025px) {
  .productCards--line .productCard__tegs {
    font-size: 10px;
  }
}
@media (max-width: 1025px) {
  .productCards--line .productCard__videoCountry {
    bottom: 0;
    left: 0;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
  }
}
@media (max-width: 1025px) {
  .productCards--line .productCard__country {
    font-size: 10px;
  }
}
@media (max-width: 1025px) {
  .productCards--line .productCard__name {
    font-size: 13px;
  }
}
@media (max-width: 1025px) {
  .productCards--line .productCard .rating__star {
    font-size: 12.6px;
  }
}
@media (max-width: 1025px) {
  .productCards--line .productCard__rating {
    gap: 6px;
  }
}
@media (max-width: 601px) {
  .productCards--line .productCard__rating {
    margin-bottom: 6px;
  }
}
@media (max-width: 1025px) {
  .productCards--line .productCard__reviews {
    font-size: 11px;
  }
}
@media (max-width: 1025px) {
  .productCards--line .productCard__characteristic {
    font-size: 11px;
  }
}
@media (max-width: 1025px) {
  .productCards--line .productCard__price {
    font-size: 16px;
    width: 100%;
  }
}
@media (max-width: 1025px) {
  .productCards--line .productCard__bonus {
    font-size: 11px;
    padding: 0;
    border-radius: 0;
    background-color: transparent;
    order: 1;
    width: fit-content;
  }
}
@media (max-width: 601px) {
  .productCards--line .productCard__bonus {
    width: fit-content;
  }
}
@media (max-width: 1025px) {
  .productCards--line .productCard__bonusIcon {
    font-size: 14px;
  }
}
@media (max-width: 1025px) {
  .productCards--line .productCard__priceBlock {
    gap: 4px;
    align-items: center;
  }
}
@media (max-width: 1025px) {
  .productCards--line .productCard__saleText {
    display: none;
  }
}
@media (max-width: 1025px) {
  .productCards--line .productCard__saleSum {
    font-size: 11px;
  }
  .productCards--line .productCard__salePercent {
    font-size: 11px;
  }
}
@media (max-width: 1025px) {
  .productCards--line .productCard__promo {
    font-size: 11px;
    width: fit-content;
  }
  .productCards--line .productCard__promoMobHidden {
    display: none;
  }
}
@media (max-width: 1025px) {
  .productCards--line .productCard__status {
    font-size: 11px;
    font-weight: 400;
  }
}
@media (max-width: 1025px) {
  .productCards--line .productCard__basket {
    padding: 8.5px;
  }
}
@media (max-width: 601px) {
  .productCards--line .productCard__basket {
    width: fit-content;
    padding-left: 24px;
    padding-right: 24px;
    order: 1;
  }
}
@media (max-width: 1025px) {
  .productCards--line .productCard__info {
    font-size: 11px;
    color: var(--dark-color);
  }
}
@media (max-width: 601px) {
  .productCards--line .productCard__imgTabs {
    height: initial;
  }
}
@media (max-width: 601px) {
  .productCards--line .productCard__imgTab {
    display: block;
    width: 8px;
    height: 8px;
    flex-basis: initial;
    flex-shrink: 0;
    flex-grow: 0;
    border-radius: 50%;
  }
}
@media (max-width: 601px) {
  .productCards--line .productCard__btns {
    left: calc(100vw - 24px);
    right: initial;
    transform: translateX(-100%);
    top: initial;
    bottom: 0;
    flex-direction: row;
  }
}
@media (max-width: 601px) {
  .productCards--line .productCard__info {
    margin-bottom: 8px;
  }
}
@media (max-width: 601px) {
  .productCards--line .productCard__basketInfo {
    align-items: flex-start;
  }
}

.productCard {
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
}
@media (max-width: 769px) {
  .productCard {
    padding: 12px;
  }
}
.productCard:hover .productCard__imgTabs {
  opacity: 1;
}
.productCard:hover .productCard__btn {
  opacity: 1;
}
.productCard:hover .productCard__btns--fav > * {
  opacity: 1 !important;
}
.productCard::after {
  content: "";
  display: block;
  position: absolute;
  top: -0.5px;
  left: -0.5px;
  width: calc(100% + 1px);
  height: calc(100% + 1px);
  border: 1px solid var(--dark-gray-color3-hover-btn);
  pointer-events: none;
}
.productCard__item1 {
  position: relative;
  margin-bottom: 15px;
}
@media (max-width: 769px) {
  .productCard__item1 {
    margin-bottom: 10px;
  }
}
.productCard__item2 {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 12px;
}
@media (max-width: 769px) {
  .productCard__item2 {
    gap: 6px;
  }
}
.productCard__item3 {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
@media (max-width: 769px) {
  .productCard__item3 {
    margin-bottom: 12.5px;
    gap: 8.5px;
  }
}
.productCard__item3--mbMin {
  margin-bottom: 12px;
}
.productCard__item4 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: auto;
}
@media (max-width: 769px) {
  .productCard__item4 {
    gap: 6px;
  }
}
.productCard__imgLink {
  display: block;
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
}
.productCard__imgLink img {
  display: block;
  aspect-ratio: 1;
  margin: 0 auto;
}
.productCard__imgTabs {
  display: flex;
  gap: 4px;
  height: 2px;
  opacity: 0;
  transition: all 0.4s;
}
@media (max-width: 769px) {
  .productCard__imgTabs {
    opacity: 1;
  }
}
.productCard__imgTab {
  flex: 1 1 auto;
  height: 2px;
  background-color: var(--dark-gray-color3-hover-btn);
  transition: all 0.4s;
}
.productCard__imgTab--active {
  background-color: var(--red-color);
}
.productCard__imgWrap {
  position: relative;
  margin-bottom: 5px;
}
.productCard__tegs {
  display: flex;
  flex-direction: column;
  gap: 3px;
  position: absolute;
  top: 4px;
  left: 4px;
  z-index: 1;
}
@media (max-width: 769px) {
  .productCard__tegs {
    top: 0;
    left: 0;
    gap: 2px;
  }
}
.productCard__teg {
  font-size: 12px;
  line-height: 1.2;
  color: #ffffff;
  background: var(--red-color-hover-link);
  border-radius: 3px;
  padding: 3px 8px;
  width: fit-content;
}
@media (max-width: 769px) {
  .productCard__teg {
    font-size: 10px;
  }
}
.productCard__videoCountry {
  position: absolute;
  bottom: 2px;
  left: 2px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
}
@media (max-width: 769px) {
  .productCard__videoCountry {
    width: 100%;
    justify-content: space-between;
    flex-direction: row-reverse;
    bottom: 0;
    left: 0;
    align-items: center;
  }
}
.productCard__video {
  font-size: 24px;
  color: var(--gray-color);
  transition: all 0.4s;
}
.productCard__video:hover {
  color: var(--gray-color-hover-link);
}
.productCard__country {
  background: var(--dark-gray-color3);
  border-radius: 3px;
  padding: 3px 8px;
  display: flex;
  align-items: center;
  gap: 7px;
  font-size: 12px;
  line-height: 1.2;
  color: var(--dark-color);
}
@media (max-width: 769px) {
  .productCard__country {
    padding: 3px 6px;
    font-size: 10px;
  }
}
.productCard__country img {
  display: block;
  width: auto;
  height: 12px;
}
.productCard__btns {
  display: flex;
  flex-direction: column;
  gap: 2px;
  position: absolute;
  z-index: 5;
  top: 4px;
  right: 4px;
  transition: all 0.4s;
}
@media (max-width: 769px) {
  .productCard__btns {
    top: 0;
    right: 0;
  }
}
.productCard__btns--fav {
  opacity: 1;
}
.productCard__btns--fav > *:not(:first-child) {
  opacity: 0;
  transition: opacity 0.4s;
}
.productCard__btn {
  background: #ffffff;
  border-radius: 5px;
  padding: 3px;
  display: block;
  position: relative;
  opacity: 0;
  transition: all 0.4s 0s;
  color: var(--gray-color);
}
@media (max-width: 769px) {
  .productCard__btn {
    opacity: 1;
  }
}
@media (max-width: 769px) {
  .productCard__btnVis {
    display: none;
  }
}
.productCard__btn > * {
  display: block;
  font-size: 24px;
}
.productCard__btn .iconActive {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.productCard__btn:hover {
  color: #97a5ad;
}
.productCard__btn--active {
  color: var(--red-color) !important;
  opacity: 10;
}
.productCard__btn--active .iconActive {
  opacity: 1;
}
.productCard__btnLike--textLike {
  margin-right: 85px;
}
@media (max-width: 1025px) {
  .productCard__btnLike--textLike {
    margin-right: 0;
  }
  .productCard__btnLike--textLike > * {
    font-size: 32px;
  }
}
.productCard__btnLike--textLike:after {
  content: "В избранное";
  position: absolute;
  top: 50%;
  width: 80px;
  right: 0;
  font-size: 13px;
  line-height: 1;
  color: var(--gray-color);
  transform: translateX(calc(100% + 5px)) translateY(-50%);
}
@media (max-width: 1025px) {
  .productCard__btnLike--textLike:after {
    content: none;
  }
}
.productCard__priceBlock {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}
@media (max-width: 769px) {
  .productCard__priceBlock {
    gap: 4px 6px;
  }
}
.productCard__price {
  font-weight: 700;
  font-size: 18px;
  line-height: 1.2;
  color: var(--dark-color);
}
@media (max-width: 769px) {
  .productCard__price {
    font-size: 16px;
  }
}
.productCard__bonus {
  display: flex;
  position: relative;
  cursor: pointer;
  gap: 5px;
  align-items: center;
  background: #f2f5f7;
  border-radius: 10px 10px 0px 10px;
  padding: 4px 5px 4px 10px;
  font-size: 14px;
  line-height: 1;
  color: var(--dark-color);
}
@media (max-width: 769px) {
  .productCard__bonus {
    order: 1;
    width: 100%;
    padding: 0;
    border-radius: 0;
    font-size: 11px;
    background-color: transparent;
    line-height: 14px;
    justify-content: flex-start;
  }
}
.productCard__bonusIcon {
  color: var(--red-color);
  display: block;
}
@media (max-width: 769px) {
  .productCard__bonusIcon {
    font-size: 14px;
    display: block;
  }
}
.productCard__sale {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px 8px;
}
@media (max-width: 769px) {
  .productCard__sale {
    gap: 4px 6px;
  }
}
.productCard__saleSum {
  font-size: 14px;
  line-height: 1.2;
  text-decoration: line-through;
  color: var(--gray-color);
  margin-right: 4px;
}
@media (max-width: 769px) {
  .productCard__saleSum {
    margin-right: 0;
    font-size: 11px;
  }
}
.productCard__salePercent {
  font-weight: 700;
  font-size: 13px;
  line-height: 1.2;
  color: #ffffff;
  padding: 3px 4px 3px 6px;
  background: var(--red-color);
  border-radius: 10px 0px 0px 10px;
}
@media (max-width: 769px) {
  .productCard__salePercent {
    font-size: 11px;
  }
}
.productCard__saleText {
  font-size: 14px;
  line-height: 1.2;
  color: var(--gray-color);
}
@media (max-width: 769px) {
  .productCard__saleText {
    display: none;
  }
}
.productCard__promo {
  padding: 6px 10px;
  background: var(--dark-gray-color3);
  border: 1px dashed var(--gray-color);
  border-radius: 5px;
  width: fit-content;
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
}
@media (max-width: 769px) {
  .productCard__promo {
    font-size: 11px;
  }
}
.productCard__promo span {
  font-weight: 700;
}
@media (max-width: 769px) {
  .productCard__promoMobHidden {
    display: none;
  }
}
.productCard__status {
  display: flex;
  gap: 8px;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.2;
  color: #37a72e;
}
@media (max-width: 769px) {
  .productCard__status {
    gap: 4px;
    font-size: 11px;
    font-weight: 400;
  }
}
.productCard__statusIcon {
  font-size: 13px;
  display: block;
}
@media (max-width: 769px) {
  .productCard__statusIcon {
    font-size: 11px;
  }
}
.productCard__status--zakaz {
  color: #a7352e;
}
.productCard__status--zakaz .productCard__statusIcon {
  font-size: 16px;
}
@media (max-width: 769px) {
  .productCard__status--zakaz .productCard__statusIcon {
    font-size: 13px;
  }
}
.productCard__basketInfo {
  display: flex;
  gap: 12px 20px;
  align-items: center;
  flex-wrap: wrap;
}
@media (max-width: 769px) {
  .productCard__basketInfo {
    gap: 6px;
  }
}
.productCard__info {
  font-size: 12px;
  line-height: 1.2;
  display: flex;
  align-items: center;
  color: var(--gray-color);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}
@media (max-width: 769px) {
  .productCard__info {
    gap: 2px;
    flex-direction: row;
    font-size: 11px;
  }
}
.productCard__info span {
  font-weight: 700;
}
.productCard__name {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  color: var(--dark-color);
  transition: all 0.4s;
  width: fit-content;
}
@media (max-width: 769px) {
  .productCard__name {
    font-size: 13px;
  }
}
.productCard__name:hover {
  color: var(--dark-color-hover-link);
}
.productCard__rating {
  display: flex;
  align-items: center;
  gap: 12px;
}
.productCard__reviews {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: var(--gray-color);
  display: block;
  width: fit-content;
  transition: all 0.4s;
}
@media (max-width: 769px) {
  .productCard__reviews {
    font-size: 11px;
  }
}
.productCard__reviews:hover {
  color: var(--gray-color-hover-link);
}
.productCard__characteristics {
  display: none;
}
.productCard__btnInfo {
  padding: 10.5px 15px;
  font-weight: 700;
  font-size: 13px;
  line-height: 1.15;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: var(--dark-color);
  background: var(--dark-gray-color3);
  border-radius: 9px;
  transition: all 0.4s;
  width: fit-content;
}
.productCard__btnInfo:hover {
  background-color: var(--dark-gray-color3-hover-btn);
}
.productCard__btnFav {
  position: relative;
}
.productCard__addBasket {
  display: none;
  background: #fff;
  box-shadow: 0px 0px 20px rgba(151, 165, 173, 0.75);
  border-radius: 9px;
  padding: 30px 20px 17px;
  position: fixed;
  z-index: 1250;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  width: 300px;
  max-width: 300px;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
}
.productCard__addBasket--active {
  opacity: 1;
  pointer-events: all;
}
@media (max-width: 769px) {
  .productCard__addBasket {
    display: block;
  }
}
.productCard__addBasketTitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.3;
  color: var(--dark-color);
  margin: 0 0 5px;
  text-align: left;
}
.productCard__addBasketText {
  font-size: 14px;
  line-height: 1.3;
  color: var(--gray-color);
  margin: 0 0 20px;
  text-align: left;
}
.productCard__addBasketClose {
  position: absolute;
  top: 8px;
  right: 10px;
  font-size: 24px;
  transition: all 0.4s;
  color: var(--gray-color);
}
.productCard__addBasketClose:hover {
  color: var(--red-color-hover-link);
}
.productCard__CountryImg {
  width: auto;
  height: 12px;
}

.collections__section {
  margin: 50px 0;
}
@media (max-width: 1025px) {
  .collections__section {
    margin: 45px 0;
  }
}
@media (max-width: 769px) {
  .collections__section {
    margin: 40px 0;
  }
}
.collections__wrapVert {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
@media (max-width: 1441px) {
  .collections__wrapVert {
    gap: 40px;
  }
}
@media (max-width: 1025px) {
  .collections__wrapVert {
    gap: 30px;
  }
}
@media (max-width: 769px) {
  .collections__wrapVert {
    gap: 20px;
  }
}
.collections__title {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.25;
  margin: 0 0 30px;
  color: var(--dark-color);
}
@media (max-width: 1441px) {
  .collections__title {
    margin-bottom: 20px;
  }
}
@media (max-width: 1025px) {
  .collections__title {
    font-size: 22px;
  }
}
@media (max-width: 769px) {
  .collections__title {
    font-size: 20px;
  }
}
.collections__wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 30px 20px;
}
@media (max-width: 769px) {
  .collections__wrap {
    gap: 20px;
  }
}
.collections__card {
  display: block;
  width: calc(33.3333333333% - 13.3333333333px);
}
@media (max-width: 769px) {
  .collections__card {
    width: calc(50% - 10px);
  }
}
@media (max-width: 601px) {
  .collections__card {
    width: 100%;
  }
}
.collections__card .collections__cardImg {
  display: block;
  width: 100%;
  border-radius: 5px;
  aspect-ratio: 2/1;
  margin-bottom: 20px;
  object-fit: cover;
}
@media (max-width: 1025px) {
  .collections__card .collections__cardImg {
    margin-bottom: 15px;
  }
}
@media (max-width: 769px) {
  .collections__card .collections__cardImg {
    margin-bottom: 12px;
  }
}
.collections__card--middle {
  width: calc(25% - 15px);
}
@media (max-width: 769px) {
  .collections__card--middle {
    width: calc(33.3333333333% - 13.3333333333px);
  }
}
@media (max-width: 601px) {
  .collections__card--middle {
    width: calc(50% - 10px);
  }
}
.collections__card--middle .collections__cardImg {
  aspect-ratio: 34/23;
}
@media (max-width: 601px) {
  .collections__card--middle .collections__cardImg {
    aspect-ratio: 1/1;
  }
}
.collections__card--big {
  width: calc(25% - 15px);
}
@media (max-width: 769px) {
  .collections__card--big {
    width: calc(33.3333333333% - 13.3333333333px);
  }
}
@media (max-width: 601px) {
  .collections__card--big {
    width: calc(50% - 10px);
  }
}
.collections__card--big .collections__cardImg {
  aspect-ratio: 1/1;
}
.collections__name {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.3;
  color: var(--dark-color);
  margin: 0;
  transition: all 0.4s;
}
.collections__name:hover {
  color: var(--dark-color-hover-link);
}
@media (max-width: 1441px) {
  .collections__name {
    font-size: 16px;
  }
}
@media (max-width: 769px) {
  .collections__name {
    font-size: 14px;
  }
}

.brends__page {
  padding-bottom: 80px;
}
@media (max-width: 769px) {
  .brends__page {
    padding-bottom: 60px;
  }
}
.brends__wrapFilterContent {
  display: flex;
  gap: 80px;
  justify-content: space-between;
}
@media (max-width: 1201px) {
  .brends__wrapFilterContent {
    gap: 30px;
  }
}
.brends__filters {
  max-width: 280px;
  width: 100%;
}
@media (max-width: 1441px) {
  .brends__filters {
    max-width: 220px;
  }
}
@media (max-width: 769px) {
  .brends__filters {
    display: none;
  }
}
.brends__content {
  width: calc(100% - 360px);
}
@media (max-width: 1441px) {
  .brends__content {
    width: calc(100% - 300px);
  }
}
@media (max-width: 1201px) {
  .brends__content {
    width: calc(100% - 250px);
  }
}
@media (max-width: 769px) {
  .brends__content {
    width: 100%;
  }
}
.brends__inpLabel {
  margin-bottom: 25px;
}
@media (max-width: 769px) {
  .brends__inpLabel {
    margin-bottom: 10px;
  }
}
.brends__letters {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px 20px;
  background: var(--dark-gray-color3);
  border-radius: 9px;
  margin-bottom: 25px;
}
@media (max-width: 769px) {
  .brends__letters {
    display: none;
  }
}
.brends__lettersList {
  display: flex;
  gap: 10px 21px;
  flex-wrap: wrap;
}
.brends__lettersLink {
  font-size: 14px;
  line-height: 1.2;
  color: var(--dark-color);
  transition: all 0.4s;
}
.brends__lettersLink:hover, .brends__lettersLink--active {
  color: var(--dark-color-hover-link);
}
.brends__listItem {
  display: flex;
  gap: 20px;
  padding: 20px 0;
  border-bottom: 1px solid #e2e8ec;
}
@media (max-width: 769px) {
  .brends__listItem {
    flex-direction: column;
  }
  .brends__listItem:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
}
.brends__listItem:first-child {
  padding-top: 0;
}
.brends__listLetter {
  width: 80px;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.4;
  color: var(--red-color);
}
@media (max-width: 769px) {
  .brends__listLetter {
    width: 100%;
  }
}
.brends__list {
  width: calc(100% - 100px);
  margin: 0;
  padding: 0;
  list-style: none;
  column-count: 4;
  column-gap: 20px;
}
@media (max-width: 1025px) {
  .brends__list {
    column-count: 3;
  }
}
@media (max-width: 769px) {
  .brends__list {
    width: 100%;
  }
}
@media (max-width: 501px) {
  .brends__list {
    column-count: 2;
  }
}
.brends__list a {
  transition: all 0.4s;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
  color: var(--dark-color);
}
.brends__list a:hover {
  color: var(--dark-color-hover-link);
}
.brends__filterBob {
  display: none;
  margin: 8px 0;
}
@media (max-width: 769px) {
  .brends__filterBob {
    display: flex;
  }
}
.brends__tabs {
  margin-bottom: 8px;
  display: none;
}
@media (max-width: 769px) {
  .brends__tabs {
    display: flex;
  }
}
.brends__tab {
  flex-basis: calc(25% - 3px);
}

.stock__page {
  padding-bottom: 60px;
}
@media (max-width: 769px) {
  .stock__page {
    padding-bottom: 40px;
  }
}
.stock__wrap {
  display: flex;
  gap: 30px;
}
.stock__left {
  width: 160px;
}
@media (max-width: 1025px) {
  .stock__left {
    width: 130px;
  }
}
@media (max-width: 769px) {
  .stock__left {
    display: none;
  }
}
.stock__content {
  max-width: 940px;
  width: calc(100% - 190px);
}
@media (max-width: 1441px) {
  .stock__content {
    max-width: 780px;
  }
}
@media (max-width: 1025px) {
  .stock__content {
    width: calc(100% - 160px);
  }
}
@media (max-width: 769px) {
  .stock__content {
    width: 100%;
  }
}
.stock__back {
  margin-left: 15px;
}
@media (max-width: 1201px) {
  .stock__back {
    margin-left: 0;
  }
}
@media (max-width: 1025px) {
  .stock__back {
    width: 100%;
  }
}
.stock__back--mob {
  display: none;
}
@media (max-width: 769px) {
  .stock__back--mob {
    display: flex;
  }
}
.stock__backIcon {
  font-size: 24px;
}
@media (max-width: 769px) {
  .stock__backIcon {
    font-size: 20px;
  }
}
.stock__imgBanner {
  display: block;
  width: 100%;
  border-radius: 5px;
  margin: 43px 0;
}
@media (max-width: 1441px) {
  .stock__imgBanner {
    margin: 38px 0;
  }
}
@media (max-width: 769px) {
  .stock__imgBanner {
    margin: 20px 0;
  }
}
.stock__dopInfo {
  margin: 43px 0;
}
@media (max-width: 1441px) {
  .stock__dopInfo {
    margin: 38px 0;
  }
}
@media (max-width: 769px) {
  .stock__dopInfo {
    margin: 20px 0;
  }
}
.stock__titleH2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.25;
  color: var(--dark-color);
  margin-bottom: 43px;
}
@media (max-width: 1441px) {
  .stock__titleH2 {
    margin-bottom: 38px;
  }
}
@media (max-width: 769px) {
  .stock__titleH2 {
    margin-bottom: 20px;
    font-size: 20px;
  }
}
.stock__dopInfoWrap {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  align-items: flex-start;
}
@media (max-width: 1025px) {
  .stock__dopInfoWrap {
    align-items: initial;
  }
}
.stock__dopInfoCard {
  background: #f2f5f7;
  border-radius: 9px;
  padding: 15px;
  width: calc(20% - 16px);
  display: flex;
  flex-direction: column;
  gap: 20px;
}
@media (max-width: 1025px) {
  .stock__dopInfoCard {
    width: initial;
    flex: 1 1 calc(33.3333333333% - 13.3333333333px);
  }
}
@media (max-width: 769px) {
  .stock__dopInfoCard {
    flex: 1 1 calc(50% - 10px);
  }
}
.stock__dopInfoName {
  font-weight: 700;
  font-size: 14px;
  line-height: 1.3;
  color: var(--dark-color);
  margin: 0;
}
.stock__dopInfoText {
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.3;
  color: var(--dark-color);
}

.blog__page {
  padding: 0 0 80px;
}
@media (max-width: 1441px) {
  .blog__page {
    padding-bottom: 50px;
  }
}
@media (max-width: 769px) {
  .blog__page {
    padding-bottom: 40px;
  }
}
.blog__tabsWrap {
  background: #f2f5f7;
  margin-bottom: 30px;
}
@media (max-width: 1201px) {
  .blog__tabsWrap {
    overflow: auto;
    padding: 0 12px;
  }
  .blog__tabsWrap .container {
    padding: 0;
    width: fit-content;
  }
}
@media (max-width: 769px) {
  .blog__tabsWrap {
    margin-bottom: 20px;
  }
}
.blog__tabs {
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  padding: 0 12px;
}
@media (max-width: 1201px) {
  .blog__tabs {
    border-radius: 0;
    width: fit-content;
    min-width: 100%;
    padding: 0;
  }
}
.blog__tab {
  transition: all 0.4s;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  border-bottom: 2px solid transparent;
  min-height: 54px;
  cursor: pointer;
  font-size: 16px;
  line-height: 1.2;
  white-space: nowrap;
  color: var(--dark-color);
}
.blog__tab--active {
  cursor: default;
}
.blog__tab--active, .blog__tab:hover {
  border-color: var(--red-color);
}
.blog__more {
  margin-top: 50px;
}
@media (max-width: 1025px) {
  .blog__more {
    margin-top: 35px;
  }
}
@media (max-width: 769px) {
  .blog__more {
    margin-top: 20px;
  }
}
.blog__pagination {
  margin-top: 20px;
}
@media (max-width: 1025px) {
  .blog__pagination {
    margin-top: 15px;
  }
}
@media (max-width: 769px) {
  .blog__pagination {
    margin-top: 10px;
  }
}
.blog__wrap {
  display: flex;
  gap: 30px 20px;
  flex-wrap: wrap;
}
@media (max-width: 601px) {
  .blog__wrap {
    gap: 20px;
  }
}
.blog__card {
  display: block;
  width: calc(33.3333333333% - 13.3333333333px);
}
@media (max-width: 1025px) {
  .blog__card {
    width: calc(50% - 10px);
  }
}
@media (max-width: 601px) {
  .blog__card {
    width: 100%;
  }
}
.blog__cardImg {
  display: block;
  width: 100%;
  border-radius: 5px;
  aspect-ratio: 38/20;
  object-fit: cover;
  margin-bottom: 15px;
}
.blog__cardCol {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 10px;
}
.blog__cardDescr {
  font-size: 13px;
  line-height: 1.2;
  color: var(--red-color);
}
@media (max-width: 1441px) {
  .blog__cardDescr {
    font-size: 11px;
  }
}
@media (max-width: 769px) {
  .blog__cardDescr {
    font-size: 12px;
  }
}
.blog__cardDate {
  font-size: 13px;
  line-height: 1.2;
  color: var(--gray-color);
}
@media (max-width: 1441px) {
  .blog__cardDate {
    font-size: 11px;
  }
}
@media (max-width: 769px) {
  .blog__cardDate {
    font-size: 12px;
  }
}
.blog__cardName {
  margin: 0;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.3;
  color: var(--dark-color);
  transition: all 0.4s;
}
@media (max-width: 1441px) {
  .blog__cardName {
    font-size: 18px;
  }
}
.blog__cardName:hover {
  color: var(--dark-color-hover-link);
}

.article__page {
  padding-bottom: 60px;
}
@media (max-width: 769px) {
  .article__page {
    padding-bottom: 40px;
  }
}
.article__wrap {
  display: flex;
  gap: 60px;
}
@media (max-width: 1441px) {
  .article__wrap {
    gap: 40px;
  }
}
@media (max-width: 769px) {
  .article__wrap {
    flex-direction: column;
    gap: 0;
  }
}
.article__menu {
  width: 340px;
}
@media (max-width: 1441px) {
  .article__menu {
    width: 260px;
  }
}
@media (max-width: 769px) {
  .article__menu {
    width: 100%;
  }
}
.article__menuTitle {
  margin: 0 0 20px;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.5;
  color: var(--dark-color);
}
@media (max-width: 769px) {
  .article__menuTitle {
    display: none;
  }
}
.article__menuWrap {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.article__menuTitle2 {
  font-weight: 700;
  font-size: 14px;
  line-height: 1.5;
  margin: 0;
  color: var(--dark-color);
}
@media (max-width: 1441px) {
  .article__menuTitle2 {
    font-size: 12px;
  }
}
.article__menuCard {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.article__menuItem {
  display: flex;
  gap: 10px;
}
.article__menuRating {
  font-weight: 700;
  font-size: 12px;
  line-height: 1.2;
  width: 40px;
  min-width: 40px;
  color: var(--red-color-hover-link);
}
@media (max-width: 1441px) {
  .article__menuRating {
    font-size: 10px;
    width: 32px;
    min-width: 32px;
  }
}
.article__menuRating span {
  font-weight: 700;
  font-size: 16px;
  color: var(--red-color);
}
@media (max-width: 1441px) {
  .article__menuRating span {
    font-size: 14px;
  }
}
.article__menuLink {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  color: #97a5ad;
  transition: all 0.4s;
}
@media (max-width: 1441px) {
  .article__menuLink {
    font-size: 12px;
  }
}
.article__menuLink:hover {
  color: var(--dark-color);
}
.article__content {
  width: calc(100% - 360px);
}
@media (max-width: 1441px) {
  .article__content {
    width: calc(100% - 300px);
  }
}
@media (max-width: 769px) {
  .article__content {
    width: 100%;
  }
}
.article__article {
  max-width: 820px;
}
@media (max-width: 1441px) {
  .article__article {
    max-width: 677px;
  }
}
@media (max-width: 769px) {
  .article__article {
    max-width: initial;
  }
  .article__article h1 {
    display: none;
  }
}
.article__titleMob {
  display: none;
  margin: 0 0 20px;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.2;
  color: var(--dark-color);
}
@media (max-width: 769px) {
  .article__titleMob {
    display: block;
  }
}
.article__ratingWrap {
  display: flex;
  gap: 40px;
  flex-direction: column;
  margin-top: 40px;
}
@media (max-width: 1025px) {
  .article__ratingWrap {
    margin-top: 35px;
  }
}
.article__ratingCard {
  display: flex;
  gap: 20px;
  align-items: flex-start;
}
@media (max-width: 1025px) {
  .article__ratingCard {
    flex-direction: column;
    gap: 40px;
  }
}
.article__ratingCard:not(:first-child) {
  padding-top: 30px;
  border-top: 1px solid var(--dark-gray-color3-hover-btn);
}
.article__ratingProduct {
  width: 180px;
  padding: 10px;
}
@media (max-width: 1025px) {
  .article__ratingProduct {
    width: 100%;
    display: grid;
    grid-template-columns: 160px auto;
    column-gap: 30px;
    row-gap: 6px;
    grid-template-rows: auto auto 1fr;
    grid-template-areas: "a b" "a c" "a d";
  }
  .article__ratingProduct .productCard__item1 {
    grid-area: a;
    margin-bottom: 0;
    margin-bottom: 0;
  }
  .article__ratingProduct .productCard__item2 {
    grid-area: c;
    margin-bottom: 0;
  }
  .article__ratingProduct .productCard__item3 {
    grid-area: b;
    margin-bottom: 0;
  }
}
@media (max-width: 1025px) and (max-width: 1025px) {
  .article__ratingProduct .productCard__item3 {
    gap: 6px;
  }
}
@media (max-width: 1025px) {
  .article__ratingProduct .productCard__item4 {
    grid-area: d;
    margin-top: auto;
    align-items: flex-start;
  }
}
.article__ratingContent {
  width: calc(100% - 200px);
}
@media (max-width: 1025px) {
  .article__ratingContent {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    gap: 40px;
  }
}
@media (max-width: 769px) {
  .article__ratingContent {
    gap: 0;
  }
}
.article__ratingHead {
  display: flex;
  gap: 30px;
  margin-bottom: 30px;
  align-items: center;
}
@media (max-width: 1025px) {
  .article__ratingHead {
    margin-bottom: 0;
  }
}
@media (max-width: 769px) {
  .article__ratingHead {
    margin-top: 40px;
  }
}
.article__ratingHeadRaiting {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 85px;
  height: 85px;
  font-size: 24px;
  line-height: 1;
  color: var(--dark-color);
  background: var(--dark-gray-color3);
  border-radius: 50%;
}
.article__ratingHeadRaiting span {
  color: var(--red-color);
  font-weight: 700;
}
.article__ratingHeadInfo {
  width: calc(100% - 115px);
}
.article__ratingHeadName {
  font-weight: 400;
  font-size: 20px;
  line-height: 1.5;
  color: var(--red-color-hover-link);
  margin: 0;
  display: block;
  width: fit-content;
  transition: all 0.4s;
}
.article__ratingHeadName:hover {
  color: var(--red-color);
}
.article__ratingHeadDescr {
  font-size: 15px;
  line-height: 1.5;
  color: var(--gray-color);
}

.vacancies__page {
  padding-bottom: 60px;
}
.vacancies__filters {
  margin-bottom: 30px;
}
.vacancies__wrap {
  border-bottom: 1px solid var(--dark-gray-color3-hover-btn);
}
.vacancies__faqBtn {
  padding-top: 30px;
  padding-bottom: 30px;
}
@media (max-width: 1025px) {
  .vacancies__faqBtn {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
@media (max-width: 769px) {
  .vacancies__faqBtn {
    flex-wrap: wrap;
    padding-top: 20px;
    padding-bottom: 20px;
    gap: 7px 20px;
    align-items: flex-start;
  }
}
.vacancies__faqName {
  width: 820px;
  font-size: 20px;
}
@media (max-width: 1025px) {
  .vacancies__faqName {
    font-size: 18px;
    width: 600px;
    line-height: 1.4;
  }
}
@media (max-width: 769px) {
  .vacancies__faqName {
    font-size: 16px;
    order: -2;
    width: calc(100% - 45px);
  }
}
.vacancies__faqCity {
  width: 180px;
  text-align: right;
  font-size: 14px;
  line-height: 2.1;
  color: var(--gray-color);
}
@media (max-width: 769px) {
  .vacancies__faqCity {
    order: 2;
    width: calc(40% - 10px);
  }
}
.vacancies__faqPrice {
  width: 340px;
  font-size: 20px;
  line-height: 1.5;
  text-align: right;
  color: var(--dark-color);
}
@media (max-width: 1025px) {
  .vacancies__faqPrice {
    font-size: 17px;
  }
}
@media (max-width: 769px) {
  .vacancies__faqPrice {
    font-size: 14px;
    order: 1;
    width: calc(60% - 10px);
    font-weight: 700;
    text-align: left;
  }
}
@media (max-width: 769px) {
  .vacancies .faq__icon {
    order: -1;
  }
}
.vacancies__faqContentWrap {
  display: flex;
  gap: 20px 90px;
  align-items: flex-start;
}
@media (max-width: 769px) {
  .vacancies__faqContentWrap {
    flex-direction: column;
  }
}
.vacancies__faqContent {
  color: var(--dark-color);
  width: calc(100% - 360px);
}
@media (max-width: 769px) {
  .vacancies__faqContent {
    width: 100%;
  }
}
.vacancies__faqRespond {
  width: 270px;
}

.buyers__page {
  padding-bottom: 60px;
}
@media (max-width: 769px) {
  .buyers__page--mobHidden {
    display: none;
  }
}
.buyers__page--mob {
  display: none;
}
@media (max-width: 769px) {
  .buyers__page--mob {
    display: block;
  }
}
.buyers__wrap {
  display: flex;
  gap: 60px;
}
@media (max-width: 1025px) {
  .buyers__wrap {
    gap: 30px;
  }
}
.buyers__menu {
  width: 300px;
}
@media (max-width: 1441px) {
  .buyers__menu {
    width: 240px;
  }
}
@media (max-width: 1025px) {
  .buyers__menu {
    width: 220px;
  }
}
@media (max-width: 769px) {
  .buyers__menu {
    display: none;
  }
}
.buyers__content {
  width: calc(100% - 360px);
}
@media (max-width: 1441px) {
  .buyers__content {
    width: calc(100% - 300px);
  }
}
@media (max-width: 1025px) {
  .buyers__content {
    width: calc(100% - 250px);
  }
}
@media (max-width: 769px) {
  .buyers__content {
    width: 100%;
  }
}
.buyers__filters {
  margin-bottom: 25px;
}
@media (max-width: 769px) {
  .buyers__filters {
    margin-bottom: 20px;
  }
}
.buyers__prepayment {
  max-width: 700px;
  color: #97a5ad;
}
.buyers__formPrepayment {
  margin-top: 20px;
}
@media (max-width: 769px) {
  .buyers__formPrepayment {
    margin-top: 0;
  }
}
@media (max-width: 769px) {
  .buyers__formPrepayment--mt30 {
    margin-top: 30px;
  }
}
.buyers__deliveryText {
  max-width: 780px;
}
.buyers__deliveryBlock {
  margin: 50px 0 40px;
}
@media (max-width: 769px) {
  .buyers__deliveryBlock {
    margin: 20px 0;
  }
}
.buyers__deliveryTitle {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.5;
  color: var(--dark-color);
  margin: 0 0 27px;
}
@media (max-width: 769px) {
  .buyers__deliveryTitle {
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 1.3;
  }
}
.buyers__deliveryBlockWrap {
  display: flex;
  gap: 17px;
}
.buyers__deliveryBlockLeft {
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-size: 12px;
  line-height: 1.7;
  color: #97a5ad;
  width: 90px;
}
@media (max-width: 769px) {
  .buyers__deliveryBlockLeft {
    display: none;
  }
}
.buyers__deliveryBlockLeft span {
  font-size: 14px;
  font-weight: 700;
  color: var(--dark-color);
}
.buyers__deliveryBlockCards {
  display: flex;
  gap: 12px;
  width: calc(100% - 107px);
  flex-wrap: wrap;
}
@media (max-width: 769px) {
  .buyers__deliveryBlockCards {
    width: 100%;
  }
}
.buyers__deliveryBlockCard {
  background: #f2f5f7;
  border-radius: 5px;
  padding: 40px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-direction: column;
}
@media (max-width: 769px) {
  .buyers__deliveryBlockCard {
    width: calc(50% - 6px);
  }
}
.buyers__deliveryBlockCardPrice {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.5;
}
.buyers__deliveryBlockCardDescr {
  font-size: 12px;
  line-height: 1.7;
  color: #97a5ad;
  text-align: center;
}
.buyers__deliverySubtitleMob {
  display: none;
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.7;
}
@media (max-width: 769px) {
  .buyers__deliverySubtitleMob {
    display: block;
  }
}
.buyers__700 {
  max-width: 700px;
  color: #97a5ad;
}
.buyers__opt {
  margin-bottom: 40px;
}
.buyers__optWrap {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}
.buyers__optIcon {
  font-size: 32px;
  color: var(--red-color);
}
.buyers__optTitle {
  font-weight: 400;
  font-size: 20px;
  line-height: 1.5;
  color: var(--dark-color);
}
.buyers__optContent {
  width: 100%;
  padding-left: 52px;
}
@media (max-width: 769px) {
  .buyers__optContent {
    padding-left: 0;
  }
}
.buyers__optCard {
  display: flex;
  gap: 20px;
}
.buyers__optType {
  width: 180px;
  font-size: 16px;
  line-height: 1.5;
  color: #97a5ad;
}
.buyers__optLink {
  font-size: 16px;
  line-height: 1.5;
  color: var(--dark-color);
  transition: all 0.4s;
}
.buyers__optLink:hover {
  color: var(--dark-color-hover-link);
}
.buyers__optLink--no:hover {
  color: var(--dark-color);
}
.buyers__optBr {
  display: block;
  width: 100%;
  height: 1px;
  margin: 20px 0;
  background: #e2e8ec;
}

.prepaymentForm {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.prepaymentForm__wrap {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.prepaymentForm__wrap > * {
  width: calc(50% - 10px);
}
@media (max-width: 769px) {
  .prepaymentForm__wrap > * {
    width: 100%;
  }
}
.prepaymentForm__wrap2 {
  display: flex;
  gap: 12px 35px;
  flex-wrap: wrap;
  align-items: center;
  line-height: 1.4;
  font-size: 12px;
}
@media (max-width: 769px) {
  .prepaymentForm__wrap2 {
    text-align: center;
    flex-direction: column;
    align-items: center;
  }
}
.prepaymentForm__textarea {
  height: 160px;
}
.prepaymentForm__subm {
  width: 250px;
}
@media (max-width: 769px) {
  .prepaymentForm__subm {
    width: 100%;
  }
}

.contacts__wrap {
  display: flex;
  justify-content: space-between;
  gap: 120px;
}
@media (max-width: 1025px) {
  .contacts__wrap {
    gap: 40px;
  }
}
@media (max-width: 769px) {
  .contacts__wrap {
    flex-direction: column;
  }
}
.contacts__main {
  width: calc(100% - 500px);
  max-width: 680px;
}
@media (max-width: 1025px) {
  .contacts__main {
    width: calc(100% - 340px);
  }
}
@media (max-width: 769px) {
  .contacts__main {
    width: 100%;
  }
}
.contacts__min {
  width: 380px;
}
@media (max-width: 1025px) {
  .contacts__min {
    width: 300px;
  }
}
@media (max-width: 769px) {
  .contacts__min {
    width: 100%;
  }
}
.contacts__titleH3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.5;
  margin: 0 0 20px;
}
.contacts__titleH3--mbMin {
  margin-bottom: 10px;
}
.contacts__subtitle {
  font-size: 16px;
  line-height: 1.5;
  color: var(--gray-color);
  margin: 0 0 20px;
}
.contacts__subtitle:last-child {
  margin-bottom: 0;
}
.contacts__mainWrap {
  display: flex;
  gap: 20px 15px;
  flex-wrap: wrap;
}
@media (max-width: 769px) {
  .contacts__mainWrap {
    gap: 15px;
  }
}
.contacts__mainWrap--mb {
  margin-bottom: 40px;
}
.contacts__wrapBlock {
  display: flex;
  gap: 20px;
  width: calc(50% - 7.5px);
}
@media (max-width: 501px) {
  .contacts__wrapBlock {
    width: 100%;
  }
}
.contacts__wrapIcon {
  color: var(--red-color);
  font-size: 32px;
}
.contacts__wrapContent {
  display: flex;
  flex-direction: column;
  margin-top: 4px;
}
.contacts__link {
  font-size: 16px;
  line-height: 1.5;
  color: var(--dark-color);
  transition: all 0.4s;
  width: fit-content;
}
.contacts__link:hover {
  color: var(--dark-color-hover-link);
}
.contacts__text {
  font-size: 16px;
  line-height: 1.5;
  color: var(--dark-color);
}
.contacts__descr {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: var(--gray-color);
}
.contacts__recall {
  margin-bottom: 60px;
}
@media (max-width: 769px) {
  .contacts__recall {
    margin-bottom: 40px;
  }
}
.contacts__socWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin: 36px 0 32px;
}
.contacts__soc {
  font-size: 48px;
}
.contacts__br {
  display: block;
  width: 100%;
  height: 1px;
  margin: 60px 0;
  background: #e2e8ec;
}
@media (max-width: 1025px) {
  .contacts__br {
    margin: 50px 0;
  }
}
@media (max-width: 769px) {
  .contacts__br {
    margin: 40px 0;
  }
}
.contacts__minText {
  font-size: 16px;
  line-height: 1.7;
}
.contacts__minText p {
  margin: 28px 0 !important;
}
.contacts__wrapinfBlWrap {
  display: flex;
  gap: 15px;
  flex-direction: column;
}
.contacts__wrapinfBlWrap--mb {
  margin-bottom: 40px;
}
.contacts__wrapinfBl {
  display: flex;
  gap: 20px;
  align-items: center;
  font-size: 16px;
  line-height: 1.5;
}
.contacts__wrapinfBlIcon {
  color: var(--red-color);
  font-size: 32px;
}

.contactsMap__map iframe {
  display: block;
  width: 100% !important;
  height: 540px !important;
  pointer-events: none;
}
@media (max-width: 769px) {
  .contactsMap__map iframe {
    height: 380px !important;
  }
}

#wrapMap {
  position: relative;
  cursor: help;
  overflow: hidden;
}

.mapTitle {
  position: absolute;
  z-index: 100;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 5px;
  display: none;
  padding: 5px 20px;
  border-radius: 5px;
  background: rgb(255, 255, 255);
  border-width: 1px;
  border-style: solid;
  border-color: rgb(204, 204, 204);
  border-image: initial;
}

@media (max-width: 769px) {
  .contactsBackCall--mobHidden {
    display: none;
  }
}
.contactsBackCall__wrap {
  display: flex;
  gap: 60px;
}
.contactsBackCall__menu {
  width: 300px;
}
.contactsBackCall__main {
  width: 100%;
  max-width: 700px;
}
.contactsBackCall__card {
  display: none;
}
.contactsBackCall__card--active {
  display: block;
}

.brend__page {
  margin-bottom: 100px;
}
@media (max-width: 769px) {
  .brend__page {
    margin-bottom: 75px;
  }
}
.brend__sliderMain {
  margin-bottom: 50px;
}
@media (max-width: 769px) {
  .brend__sliderMain {
    margin-bottom: 40px;
  }
}
.brend__infoBlock {
  display: flex;
  gap: 40px;
  align-items: flex-start;
  margin-bottom: 40px;
}
@media (max-width: 769px) {
  .brend__infoBlock {
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }
}
.brend__infoImg {
  display: block;
  width: 220px;
  height: auto;
}
.brend__infoBlockText {
  width: calc(100% - 260px);
}
@media (max-width: 769px) {
  .brend__infoBlockText {
    width: 100%;
  }
}
.brend__tabs {
  display: flex;
  gap: 30px;
  width: 100%;
  border-bottom: 2px solid #f2f5f7;
  margin-bottom: 40px;
}
@media (max-width: 769px) {
  .brend__tabs {
    border-width: 1px;
    gap: 0;
    margin-bottom: 25px;
  }
}
.brend__tab {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 24px;
  line-height: 1;
  border-bottom: 2px solid transparent;
  transition: all 0.4s;
  padding: 8px 0 18px;
  margin-bottom: -2px;
  color: var(--dark-color);
}
@media (max-width: 769px) {
  .brend__tab {
    font-size: 20px;
    padding: 8px 0;
    margin-bottom: -1px;
    width: 50%;
  }
}
.brend__tab:hover {
  border-bottom: 2px solid #ed0000;
}
.brend__tab--active {
  border-bottom: 2px solid #ed0000;
  font-weight: 700;
}
.brend__block {
  margin-bottom: 60px;
}
@media (max-width: 769px) {
  .brend__block {
    margin-bottom: 50px;
  }
}

.sliderBrend {
  overflow: hidden;
}
.sliderBrend__section {
  margin-bottom: 30px;
}
@media (max-width: 769px) {
  .sliderBrend__section {
    margin-bottom: 20px;
  }
}
.sliderBrend__slide {
  display: block;
  width: 980px;
  border-radius: 10px;
  overflow: hidden;
}
@media (max-width: 1201px) {
  .sliderBrend__slide {
    width: 880px;
  }
}
@media (max-width: 1025px) {
  .sliderBrend__slide {
    width: 100%;
    border-radius: 0;
  }
}
.sliderBrend__slide img {
  display: block;
  object-fit: cover;
  width: 100%;
}

.sliderProdMin__slide {
  width: 160px;
  padding: 0;
  border: none;
  height: auto;
}
@media (max-width: 769px) {
  .sliderProdMin__slide {
    width: 120px;
  }
  .sliderProdMin__slide .productCard__item4 {
    display: none;
  }
  .sliderProdMin__slide .productCard__item3 {
    margin-bottom: 0;
  }
}
.sliderProdMin__slide::after {
  content: none;
}
.sliderProdMin ~ .swiper-button-prev-custom {
  left: 0;
}
.sliderProdMin ~ .swiper-button-next-custom {
  right: 0;
}

.basket__page {
  padding-bottom: 80px;
}
@media (max-width: 769px) {
  .basket__page {
    padding-bottom: 60px;
  }
}
.basket__page--bg {
  background-color: #f2f5f7;
}
.basket__thanksWrap {
  display: flex;
  gap: 30px 20px;
  align-items: flex-start;
}
@media (max-width: 769px) {
  .basket__thanksWrap {
    flex-direction: column;
  }
}
.basket__thanksTitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 2.2;
  margin: 0 0 20px;
}
@media (max-width: 1025px) {
  .basket__thanksTitle {
    line-height: 1.3;
  }
}
.basket__thanksBack {
  margin-top: 32px;
  width: 100%;
  max-width: 340px;
  padding: 20.5px;
  font-size: 16px;
}
@media (max-width: 769px) {
  .basket__thanksBack--mobHidden {
    display: none;
  }
}
.basket__thanksBack--mobOnly {
  display: none;
}
@media (max-width: 769px) {
  .basket__thanksBack--mobOnly {
    display: flex;
    margin-top: 0;
  }
}
.basket__thanksContent {
  width: calc(100% - 480px);
}
@media (max-width: 1441px) {
  .basket__thanksContent {
    width: calc(100% - 400px);
  }
}
@media (max-width: 1025px) {
  .basket__thanksContent {
    width: calc(100% - 370px);
  }
}
@media (max-width: 769px) {
  .basket__thanksContent {
    width: 100%;
  }
}
.basket__thanksDop {
  background: #f2f5f7;
  border-radius: 9px;
  padding: 20px;
  width: 460px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}
@media (max-width: 1441px) {
  .basket__thanksDop {
    width: 380px;
  }
}
@media (max-width: 1025x) {
  .basket__thanksDop {
    width: 350px;
  }
}
@media (max-width: 769px) {
  .basket__thanksDop {
    width: 100%;
  }
}
.basket__thanksDopNumber {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.5;
}
.basket__thanksDopTotal {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: flex-end;
}
.basket__thanksDopTotalText {
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
}
.basket__thanksDopTotalSum {
  font-weight: 700;
  font-size: 22px;
  line-height: 1.3;
  text-align: right;
}
.basket__thanksDopBr {
  display: block;
  width: 100%;
  height: 1px;
  background: #e2e8ec;
}
.basket__thanksDopProd {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.basket__thanksDopName {
  transition: all 0.4s;
  font-size: 14px;
  line-height: 1.15;
  color: var(--dark-color);
}
.basket__thanksDopName:hover {
  color: var(--dark-color-hover-link);
}
.basket__thanksDopDescr {
  font-size: 14px;
  line-height: 1.4;
  color: #97a5ad;
}
.basket__slider {
  background: #ffffff;
  border-radius: 9px;
  padding: 20px;
}
@media (max-width: 769px) {
  .basket__slider {
    display: none;
  }
}
.basket__prodDop {
  display: flex;
  gap: 10px 20px;
  align-items: flex-start;
  margin-bottom: 25px;
  justify-content: space-between;
}
@media (max-width: 769px) {
  .basket__prodDop {
    flex-direction: column;
  }
  .basket__prodDop .basket__promo--monOnly {
    margin-bottom: 25px;
  }
  .basket__prodDop .basketForm__paymentBtn {
    margin-top: 25px;
  }
  .basket__prodDop .clue {
    margin-top: 10px;
    margin-bottom: 0;
  }
  .basket__prodDop .basketForm__prom {
    margin-top: 10px;
  }
}
.basket__prodDop .productCard__btn {
  opacity: 1;
}
.basket__prodDopPaymentInfo {
  position: sticky;
  top: 110px;
  width: 380px;
}
@media (max-width: 1441px) {
  .basket__prodDopPaymentInfo {
    top: 100px;
  }
}
@media (max-width: 1201px) {
  .basket__prodDopPaymentInfo {
    top: 80px;
  }
}
@media (max-width: 1025px) {
  .basket__prodDopPaymentInfo {
    width: 330px;
  }
}
@media (max-width: 769px) {
  .basket__prodDopPaymentInfo {
    width: 100%;
    gap: 0 !important;
    position: relative;
    top: initial;
  }
}
.basket__promo {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  gap: 20px;
  justify-content: space-between;
}
@media (max-width: 769px) {
  .basket__promo--monHidden {
    display: none;
  }
}
.basket__promo--monOnly {
  display: none;
}
@media (max-width: 769px) {
  .basket__promo--monOnly {
    display: flex;
  }
}
.basket__promo input {
  border: 1px solid #e2e8ec;
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: var(--dark-color);
  padding: 10px 18px;
  outline: none;
  width: calc(100% - 114px);
}
.basket__promo input::placeholder {
  color: #b8c0c5;
}
.basket__prodCardList {
  width: calc(100% - 400px);
  display: flex;
  flex-direction: column;
  gap: 10px;
}
@media (max-width: 1025px) {
  .basket__prodCardList {
    width: calc(100% - 350px);
  }
}
@media (max-width: 769px) {
  .basket__prodCardList {
    width: 100%;
  }
}
.basket__prodCardList--full {
  width: 100%;
}

.basketForm {
  display: flex;
  gap: 20px;
}
@media (max-width: 769px) {
  .basketForm {
    flex-direction: column;
  }
}
.basketForm__main {
  width: calc(100% - 400px);
  display: flex;
  flex-direction: column;
  gap: 20px;
}
@media (max-width: 1025px) {
  .basketForm__main {
    width: calc(100% - 350px);
  }
}
@media (max-width: 769px) {
  .basketForm__main {
    width: 100%;
  }
}
.basketForm__dop {
  display: flex;
  width: 380px;
  flex-direction: column;
  gap: 20px;
  height: fit-content;
  position: sticky;
  top: 110px;
}
@media (max-width: 1441px) {
  .basketForm__dop {
    top: 100px;
  }
}
@media (max-width: 1201px) {
  .basketForm__dop {
    top: 80px;
  }
}
@media (max-width: 1025px) {
  .basketForm__dop {
    width: 330px;
  }
}
@media (max-width: 769px) {
  .basketForm__dop {
    width: 100%;
    position: relative;
    top: initial;
    flex-direction: column-reverse;
  }
}
.basketForm__payment {
  background: #ffffff;
  border-radius: 9px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}
@media (max-width: 769px) {
  .basketForm__payment {
    flex-direction: column-reverse;
    gap: 0;
  }
}
.basketForm__paymentBtns {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
@media (max-width: 769px) {
  .basketForm__paymentBtns {
    margin-top: 25px;
  }
  .basketForm__paymentBtns--personal {
    margin-top: 0;
  }
}
.basketForm__paymentInfo {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.basketForm__paymentPrice {
  display: flex;
  gap: 20px;
  align-items: flex-end;
  justify-content: space-between;
}
.basketForm__paymentPriceText {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  color: var(--dark-color);
}
.basketForm__paymentPriceTotal {
  font-weight: 700;
  font-size: 22px;
  line-height: 1.3;
  text-align: right;
  color: var(--dark-color);
}
.basketForm__paymentBr {
  width: 100%;
  display: block;
  height: 1px;
  background: #e2e8ec;
}
.basketForm__paymentDescr {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.basketForm__paymentDescrCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.basketForm__paymentDescrText1 {
  font-size: 14px;
  line-height: 1.5;
}
.basketForm__paymentDescrText2 {
  font-size: 14px;
  line-height: 1.3;
  display: flex;
  align-items: center;
  gap: 5px;
}
.basketForm__paymentDescrText2--clue {
  position: relative;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
}
.basketForm__dopServ {
  background: #ffffff;
  border-radius: 9px;
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.basketForm__dopServTitle {
  margin: 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.5;
}
@media (max-width: 769px) {
  .basketForm__dopServTitle {
    font-size: 18px;
  }
}
.basketForm__textInfo {
  color: var(--gray-color);
  font-size: 13px;
  line-height: 1.2;
}
@media (max-width: 769px) {
  .basketForm__textInfo--mobHidden {
    display: none;
  }
}
.basketForm__textInfo--mobOnly {
  display: none;
}
@media (max-width: 769px) {
  .basketForm__textInfo--mobOnly {
    display: block;
  }
}
.basketForm__textInfo a {
  color: #97a5ad;
  transition: all 0.4s;
}
.basketForm__textInfo a:hover {
  color: var(--red-color);
}
.basketForm__payMeth {
  background: #ffffff;
  border-radius: 9px;
  padding: 20px;
}
.basketForm__titleH3 {
  margin: 0 0 20px;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.5;
}
@media (max-width: 769px) {
  .basketForm__titleH3 {
    font-size: 18px;
  }
}
.basketForm__payMethWrap {
  display: flex;
  gap: 20px;
  flex-direction: column;
}
.basketForm__payMethWrap2 {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.basketForm__payMethWrap2 > * {
  width: calc(50% - 10px);
}
@media (max-width: 1025px) {
  .basketForm__payMethWrap2 > * {
    width: 100%;
  }
}
.basketForm__data {
  padding: 20px;
  background: #ffffff;
  border-radius: 9px;
}
.basketForm__dataRadio {
  display: flex;
  gap: 12px 20px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.basketForm__dataBlok {
  display: none;
}
.basketForm__dataBlok--active {
  display: block;
}
.basketForm__dataWrap {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.basketForm__dataWrap2 {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 20px;
}
.basketForm__dataWrap2 > * {
  width: calc(50% - 10px);
}
@media (max-width: 1025px) {
  .basketForm__dataWrap2 > * {
    width: 100%;
  }
}
.basketForm__dataWrap3 {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 20px;
}
.basketForm__dataWrap3 > * {
  width: calc(33.3333333333% - 13.3333333333px);
}
@media (max-width: 1025px) {
  .basketForm__dataWrap3 > * {
    width: calc(50% - 10px);
  }
  .basketForm__dataWrap3 > *:first-child {
    width: 100%;
  }
}
.basketForm__receiving {
  padding: 20px;
  background: #ffffff;
  border-radius: 9px;
}
.basketForm__dataWrapTextarea {
  height: 95px;
}
.basketForm__dataWarning {
  display: flex;
  gap: 10px;
  align-items: center;
  background: #f2f5f7;
  border-radius: 9px;
  padding: 15px;
  font-size: 12px;
  line-height: 1.3;
}
.basketForm__dataWarningIcon {
  font-size: 24px;
  color: var(--red-color);
}
.basketForm__dataBlokMb {
  margin-bottom: 20px;
}
.basketForm__dataEditAddr {
  position: absolute;
  top: 50%;
  right: 20px;
  font-size: 24px;
  color: var(--dark-gray-color3-hover-btn);
  transform: translateY(-50%);
  transition: all 0.4s;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.basketForm__dataEditAddr:hover {
  color: var(--red-color);
}
@media (max-width: 769px) {
  .basketForm .buyers__optType {
    width: 60px;
    min-width: 60px;
  }
}
@media (max-width: 769px) {
  .basketForm__clue {
    order: -2;
    margin: -15px 0;
  }
}
@media (max-width: 769px) {
  .basketForm__prom {
    order: -3;
  }
}

.basketProdCard {
  background: #ffffff;
  border-radius: 9px;
  padding: 20px;
  width: 100%;
  display: flex;
  gap: 20px;
  align-items: flex-start;
  position: relative;
}
@media (max-width: 1025px) {
  .basketProdCard {
    padding: 12px;
  }
}
.basketProdCard--modal {
  padding: 0;
  justify-content: space-between;
}
.basketProdCard__img {
  display: block;
  width: 100px;
  height: 100px;
}
@media (max-width: 1025px) {
  .basketProdCard__img {
    width: 80px;
  }
}
.basketProdCard__img img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.basketProdCard__img--min {
  width: 80px;
  height: 80px;
}
.basketProdCard__wrap {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 120px);
  gap: 20px;
}
@media (max-width: 1025px) {
  .basketProdCard__wrap {
    width: calc(100% - 100px);
    flex-direction: column;
    gap: 12px;
  }
}
.basketProdCard__wrap--modal {
  width: calc(100% - 100px);
}
@media (max-width: 1025px) {
  .basketProdCard__wrap--modal {
    gap: 20px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .basketProdCard__wrap--modal .basketProdCard__price {
    width: fit-content;
    order: 1;
    justify-content: flex-end;
  }
  .basketProdCard__wrap--modal .basketProdCard__priceBonus {
    justify-content: flex-end;
  }
  .basketProdCard__wrap--modal .basketProdCard__quantity {
    width: fit-content;
    margin-left: -100px;
  }
}
.basketProdCard__price {
  width: 120px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 7px;
}
@media (max-width: 1025px) {
  .basketProdCard__price {
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 4px 7px;
    margin-bottom: 8px;
  }
}
.basketProdCard__priceMain {
  font-weight: 700;
  font-size: 20px;
  line-height: 130%;
}
.basketProdCard__priceSale {
  font-size: 15px;
  line-height: 1.2;
  text-align: right;
  text-decoration-line: line-through;
  color: var(--gray-color);
}
.basketProdCard__priceBonus {
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 15px;
  line-height: 1.2;
  position: relative;
  justify-content: flex-start;
  cursor: pointer;
  padding: 0;
  background-color: transparent;
}
.basketProdCard__priceBonusIcon {
  font-size: 14px;
  color: var(--red-color);
}
@media (max-width: 1025px) {
  .basketProdCard__priceBonus {
    width: 100%;
  }
}
.basketProdCard__mainInfo {
  width: calc(100% - 300px);
}
@media (max-width: 1025px) {
  .basketProdCard__mainInfo {
    width: 100%;
  }
}
.basketProdCard__cod {
  font-size: 12px;
  line-height: 1.4;
  color: var(--gray-color);
  margin-bottom: 8px;
}
.basketProdCard__name {
  font-size: 14px;
  line-height: 1.3;
  color: var(--dark-color);
  display: inline-block;
  margin-bottom: 8px;
  transition: all 0.4s;
}
.basketProdCard__name:hover {
  color: var(--dark-color-hover-link);
}
@media (max-width: 1025px) {
  .basketProdCard__name {
    font-size: 13px;
  }
}
.basketProdCard__descr {
  font-size: 12px;
  line-height: 1.4;
  color: var(--gray-color);
}
.basketProdCard__mainInfoBtns {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-top: 20px;
}
@media (max-width: 1025px) {
  .basketProdCard__mainInfoBtns {
    margin: 0;
    position: absolute;
    left: 20px;
    bottom: 22px;
  }
}
.basketProdCard__delete {
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 13px;
  line-height: 1.2;
  color: var(--gray-color);
}
.basketProdCard__deleteIcon {
  font-size: 24px;
  transition: all 0.4s;
}
@media (max-width: 1025px) {
  .basketProdCard__deleteIcon {
    font-size: 32px;
  }
}
.basketProdCard__delete:hover .basketProdCard__deleteIcon {
  color: #97a5ad;
}
@media (max-width: 1025px) {
  .basketProdCard__delete span {
    display: none;
  }
}
@media (max-width: 1025px) {
  .basketProdCard__quantity {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    order: 1;
  }
}

.personal__page {
  padding-bottom: 80px;
}
@media (max-width: 769px) {
  .personal__page {
    padding-bottom: 60px;
  }
}
.personal__wrap {
  display: flex;
  align-items: flex-start;
  gap: 60px;
}
.personal__menu {
  width: 300px;
  min-width: 300px;
}
@media (max-width: 1441px) {
  .personal__menu {
    width: 240px;
    min-width: 240px;
  }
}
@media (max-width: 769px) {
  .personal__menu {
    display: none;
  }
}
.personal__data {
  max-width: 880px;
  flex: 1 1 auto;
  display: flex;
  gap: 40px 100px;
  align-items: flex-start;
}
@media (max-width: 1251px) {
  .personal__data {
    flex-direction: column-reverse;
    width: calc(100% - 300px);
  }
}
@media (max-width: 769px) {
  .personal__data {
    width: 100%;
    gap: 0;
  }
}
.personal__dataWrap {
  width: calc(100% - 300px);
  display: flex;
  flex-direction: column;
  gap: 40px;
}
@media (max-width: 1251px) {
  .personal__dataWrap {
    width: 100%;
  }
}
@media (max-width: 769px) {
  .personal__avatar--personal {
    margin-bottom: 40px;
  }
}
.personal__avatar .input-file-row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 220px;
  border: 1px solid var(--dark-gray-color3-hover-btn);
  border-radius: 9px;
  transition: all 0.4s;
  color: var(--gray-color);
  padding: 20px;
  cursor: pointer !important;
}
.personal__avatar .input-file-row:hover {
  border-color: var(--dark-gray-color3);
}
@media (max-width: 769px) {
  .personal__avatar .input-file-row {
    width: 100%;
    height: 80px;
    border: none;
    padding: 0;
  }
}
.personal__avatar .fileLabel__listItem {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.personal__avatarIcon {
  font-size: 120px;
  color: var(--dark-gray-color3-hover-btn);
}
@media (max-width: 769px) {
  .personal__avatarIcon {
    font-size: 80px;
  }
}
.personal__avatarText {
  font-size: 14px;
  line-height: 1.5;
  color: currentColor;
}
.personal__avatar input {
  display: none;
}
.personal__avatarLabel {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  cursor: pointer;
}
@media (max-width: 769px) {
  .personal__avatarLabel {
    flex-direction: row;
    gap: 20px;
  }
}
.personal__avatar .fileLabel {
  width: initial;
  padding: initial;
  background: initial;
  border-radius: initial;
  transition: initial;
  cursor: pointer;
}
.personal__avatar .fileLabel > span {
  color: var(--gray-color);
}
.personal__dataTitle {
  margin-bottom: 20px;
  display: flex;
}
.personal__dataTitleIcon {
  font-size: 32px;
  color: var(--red-color);
  margin-right: 20px;
}
.personal__dataTitleText {
  font-size: 20px;
  line-height: 1.5;
}
.personal__dataLabel {
  padding: 20px 0;
  border-bottom: 1px solid var(--dark-gray-color3-hover-btn);
}
.personal__dataLabel:first-child {
  padding-top: 0;
}
.personal__dataLabel:last-child {
  padding-bottom: 0;
  border: none;
}
@media (max-width: 769px) {
  .personal__dataLabel--name input {
    max-width: 50%;
  }
}
.personal__dataListLabel {
  padding-left: 55px;
}
@media (max-width: 769px) {
  .personal__dataListLabel {
    padding-left: 0;
  }
}
.personal__dataListLabel--checkbox {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.personal__addAddr {
  margin-top: 20px;
}
.personal__mobBtns {
  display: none;
}
@media (max-width: 769px) {
  .personal__mobBtns {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }
}
@media (max-width: 769px) {
  .personal__mobBtns--personal {
    margin-top: 40px;
  }
}
.personal__history {
  width: calc(100% - 360px);
  display: flex;
  flex-direction: column;
  gap: 30px;
}
@media (max-width: 1441px) {
  .personal__history {
    width: calc(100% - 300px);
  }
}
@media (max-width: 769px) {
  .personal__history {
    width: 100%;
  }
}
.personal__historyCard {
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
}
.personal__historyMain {
  background: #f2f5f7;
  border-radius: 5px 5px 0px 0px;
  padding: 20px 30px;
  display: flex;
  gap: 12px;
  align-items: flex-start;
  justify-content: space-between;
}
@media (max-width: 769px) {
  .personal__historyMain {
    padding: 12px;
    flex-direction: column;
  }
}
.personal__historyDate {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.5;
  color: var(--dark-color);
  transition: all 0.4s;
}
@media (max-width: 769px) {
  .personal__historyDate {
    font-size: 16px;
    line-height: 1.875;
    text-decoration: underline;
  }
}
.personal__historyPrice {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.5;
}
@media (max-width: 769px) {
  .personal__historyPrice {
    font-size: 16px;
    line-height: 1.875;
  }
}
.personal__historyNumber {
  font-size: 14px;
  line-height: 1.5;
  color: #97a5ad;
}
.personal__historyMainLeft {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
@media (max-width: 769px) {
  .personal__historyMainLeft {
    gap: 0;
  }
}
.personal__historyInfo {
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #e2e8ec;
  border-radius: 0px 0px 5px 5px;
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px 25px;
  flex-wrap: wrap;
}
@media (max-width: 769px) {
  .personal__historyInfo {
    padding: 12px;
    gap: 12px;
  }
}
.personal__historyDeliveryDate {
  display: flex;
  flex-direction: column;
  width: 200px;
}
@media (max-width: 769px) {
  .personal__historyDeliveryDate {
    width: fit-content;
  }
}
.personal__historyDelivery {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.875;
}
.personal__historyDateDel {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.7;
  color: var(--gray-color);
}
.personal__historyProducts {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  flex: 1 1 auto;
  justify-content: flex-end;
}
@media (max-width: 769px) {
  .personal__historyProducts {
    justify-content: flex-start;
    width: 100%;
  }
}
.personal__historyProduct {
  display: block;
  width: 60px;
  height: 60px;
}
.personal__historyProduct img {
  display: block;
  width: 60px;
  height: 60px;
  object-fit: contain;
}
.personal__historyStatus {
  font-size: 12px;
  line-height: 1.7;
  padding: 8px 18px;
  border: 2px solid #e2e8ec;
  border-radius: 45px;
}
@media (max-width: 769px) {
  .personal__historyStatus {
    margin-left: auto;
  }
}
.personal__historyStatus--canceled {
  color: var(--red-color);
  background-color: #f2f5f7;
  border-color: #f2f5f7;
}
.personal__historyStatus--success {
  background-color: #f2f5f7;
  border-color: #f2f5f7;
}
.personal__backLink {
  display: none;
  margin: 15px 0 12px;
  width: fit-content;
  gap: 6px;
  font-size: 12px;
  line-height: 18px;
  align-items: center;
  color: var(--dark-color);
}
@media (max-width: 769px) {
  .personal__backLink {
    display: flex;
  }
}
.personal__backLinkIcon {
  color: var(--red-color);
  font-size: 18px;
}
.personal__bonus {
  width: calc(100% - 360px);
  display: flex;
  flex-direction: column;
  gap: 30px;
}
@media (max-width: 1441px) {
  .personal__bonus {
    width: calc(100% - 300px);
  }
}
@media (max-width: 769px) {
  .personal__bonus {
    width: 100%;
    gap: 20px;
  }
}
.personal__bonusCard {
  background: #1f2d35;
  border-radius: 7px;
  display: flex;
  gap: 50px;
  align-items: center;
  padding: 30px 60px;
}
@media (max-width: 1025px) {
  .personal__bonusCard {
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
    padding: 25px 40px;
  }
}
@media (max-width: 769px) {
  .personal__bonusCard {
    padding: 20px;
  }
}
.personal__bonusIcon {
  font-size: 36px;
}
.personal__bonusScore {
  display: flex;
  gap: 7px 10px;
  align-items: center;
  font-weight: 700;
  font-size: 32px;
  line-height: 1.2;
  color: var(--light-color);
  flex-wrap: wrap;
  width: 150px;
}
@media (max-width: 1025px) {
  .personal__bonusScore {
    width: 100%;
  }
}
.personal__bonusText {
  font-weight: 400;
  font-size: 18px;
  width: 100%;
}
@media (max-width: 1025px) {
  .personal__bonusText {
    width: fit-content;
    margin-left: 13px;
  }
}
.personal__bonusDecor {
  display: block;
  width: 1px;
  height: 100%;
  min-height: 100px;
  background: #e2e8ec;
}
@media (max-width: 1025px) {
  .personal__bonusDecor {
    display: none;
  }
}
.personal__bonusInfoBlock {
  width: calc(100% - 250px);
  max-width: 420px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
@media (max-width: 1025px) {
  .personal__bonusInfoBlock {
    width: 100%;
    max-width: 100%;
  }
}
.personal__bonusInfoText {
  font-size: 14px;
  line-height: 1.2;
  color: var(--dark-gray-color3);
  margin: 0;
}
@media (max-width: 769px) {
  .personal__bonusInfoText {
    font-size: 12px;
    color: #97a5ad;
  }
}
.personal__bonusInfoLink {
  font-size: 14px;
  line-height: 1.2;
  color: var(--red-color);
  transition: all 0.4s;
}
@media (max-width: 769px) {
  .personal__bonusInfoLink {
    font-size: 12px;
  }
}
.personal__bonusInfoLink:hover {
  color: var(--light-color);
}
.personal__bonusItem {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #e2e8ec;
}
@media (max-width: 1025px) {
  .personal__bonusItem {
    gap: 12px;
    flex-wrap: wrap;
    padding: 12px 0;
  }
}
.personal__bonusItem:first-of-type {
  padding-top: 0;
}
.personal__bonusItemBonus {
  display: flex;
  width: 100px;
  gap: 12px;
  align-items: center;
  font-size: 16px;
  line-height: 1.2;
  justify-content: flex-end;
}
@media (max-width: 1025px) {
  .personal__bonusItemBonus {
    order: -2;
    width: fit-content;
    font-weight: 700;
    gap: 10px;
  }
}
.personal__bonusItemBonus--delivery {
  color: #b8c0c5;
}
.personal__bonusItemBonus--fire {
  color: var(--red-color);
}
.personal__bonusItemBonusIcon {
  font-size: 24px;
  color: var(--red-color);
}
.personal__bonusItemCol {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.personal__bonusItemCol1 {
  width: 280px;
  flex: 1 1 auto;
}
@media (max-width: 1025px) {
  .personal__bonusItemCol1 {
    width: calc(50% - 6px);
    flex: 0 0 initial;
  }
}
.personal__bonusItemCol2 {
  width: 180px;
}
@media (max-width: 1025px) {
  .personal__bonusItemCol2 {
    width: calc(50% - 6px);
  }
}
.personal__bonusItemCol3 {
  width: 180px;
}
@media (max-width: 1025px) {
  .personal__bonusItemCol3 {
    order: -1;
    width: fit-content;
    flex-direction: row;
    margin-right: auto;
  }
  .personal__bonusItemCol3 .personal__bonusItemText2 {
    font-size: 12px;
    line-height: 1.5;
    color: var(--gray-color);
  }
}
.personal__bonusItemNumber {
  font-size: 14px;
  line-height: 1.5;
  color: var(--dark-color);
  transition: all 0.4s;
  display: block;
  width: fit-content;
}
.personal__bonusItemNumber:hover {
  color: var(--dark-color-hover-link);
}
.personal__bonusItemText {
  font-size: 12px;
  line-height: 1.5;
  color: var(--gray-color);
}
.personal__bonusItemText2 {
  font-size: 14px;
  line-height: 1.5;
  color: var(--dark-color);
}
.personal__listMenu {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.personal__listMenuLink {
  background: var(--dark-gray-color3);
  border-radius: 9px;
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 20px;
  color: var(--dark-color);
  transition: all 0.4s;
  font-size: 14px;
  line-height: 1.7;
}
.personal__listMenuLink:hover {
  background: var(--dark-gray-color3-hover-btn);
}
.personal__listMenuLinkIcon {
  font-size: 24px;
  color: var(--red-color);
}
.personal__titleMenuPage {
  margin: 40px 0 20px;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  color: var(--dark-color);
}

.designProject__card {
  width: 25%;
}
@media (max-width: 1441px) {
  .designProject__card {
    width: 33.3333333333%;
  }
}
@media (max-width: 1025px) {
  .designProject__card {
    width: 50%;
  }
}
@media (max-width: 551px) {
  .designProject__card {
    width: calc(100% + 24px);
    margin-left: -12px;
    margin-right: -12px;
  }
}

.designProjectCard {
  padding: 20px;
  position: relative;
  display: flex;
  gap: 20px;
  flex-direction: column;
}
@media (max-width: 551px) {
  .designProjectCard {
    padding: 12px;
  }
}
.designProjectCard:hover .productCard__imgTabs {
  opacity: 1;
}
.designProjectCard::after {
  content: "";
  display: block;
  position: absolute;
  top: -0.5px;
  left: -0.5px;
  width: calc(100% + 1px);
  height: calc(100% + 1px);
  border: 1px solid var(--dark-gray-color3-hover-btn);
  pointer-events: none;
}
.designProjectCard__img {
  display: block;
  width: 100%;
  aspect-ratio: 1/1;
  margin-bottom: 12px;
}
.designProjectCard__img img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.designProjectCard__link {
  margin-top: auto;
}
.designProjectCard__number {
  font-size: 14px;
  line-height: 1.3;
  color: var(--gray-color);
  margin-bottom: 6px;
}
.designProjectCard__name {
  transition: all 0.4s;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.3;
  color: var(--dark-color);
  margin-bottom: 10px;
  display: inline-block;
}
.designProjectCard__name:hover {
  color: var(--dark-color-hover-link);
}
.designProjectCard__inform {
  font-size: 14px;
  line-height: 1.2;
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.designProjectCard__inform span {
  color: var(--gray-color);
}

.catalogCollection__tabs {
  display: flex;
  gap: 0;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
@media (max-width: 769px) {
  .catalogCollection__tabs--pc {
    display: none;
  }
}
.catalogCollection__tabs--mob {
  display: none;
  margin-top: 13px;
  margin-bottom: 13px;
}
@media (max-width: 769px) {
  .catalogCollection__tabs--mob {
    display: flex;
  }
}
.catalogCollection__tab {
  display: flex;
  gap: 6px;
  align-items: center;
  font-size: 24px;
  line-height: 0.95;
  color: var(--dark-color);
  padding: 8px 14px;
  border-bottom: 1px solid var(--dark-gray-color3);
  transition: all 0.4s;
}
@media (max-width: 769px) {
  .catalogCollection__tab {
    font-size: 20px;
    width: 50%;
    text-align: center;
    justify-content: center;
  }
}
.catalogCollection__tab span {
  color: var(--gray-color);
  font-weight: 400;
}
@media (max-width: 769px) {
  .catalogCollection__tab span {
    display: none;
  }
}
.catalogCollection__tab:hover {
  border-color: var(--red-color);
}
.catalogCollection__tab--active {
  font-weight: 700;
  border-bottom: 2px solid var(--red-color);
}
.catalogCollection__card {
  width: 25%;
}
@media (max-width: 1441px) {
  .catalogCollection__card {
    width: 33.3333333333%;
  }
}
@media (max-width: 1025px) {
  .catalogCollection__card {
    width: 50%;
  }
}
@media (max-width: 551px) {
  .catalogCollection__card {
    width: calc(100% + 24px);
    margin-left: -12px;
    margin-right: -12px;
  }
}

.catalogCollectionCard {
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
}
@media (max-width: 551px) {
  .catalogCollectionCard {
    padding: 12px;
  }
}
.catalogCollectionCard::after {
  content: "";
  display: block;
  position: absolute;
  top: -0.5px;
  left: -0.5px;
  width: calc(100% + 1px);
  height: calc(100% + 1px);
  border: 1px solid var(--dark-gray-color3-hover-btn);
  pointer-events: none;
}
.catalogCollectionCard:hover .productCard__btn {
  opacity: 1;
}
.catalogCollectionCard__img {
  display: block;
  width: 100%;
  align-items: 1/1;
}
.catalogCollectionCard__img img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.catalogCollectionCard__imgBig {
  position: relative;
}
.catalogCollectionCard__country {
  left: 4px;
  bottom: 4px;
}
@media (max-width: 769px) {
  .catalogCollectionCard__country {
    left: 4px;
    right: initial;
    justify-content: flex-start;
    flex-direction: row;
  }
}
@media (max-width: 769px) {
  .catalogCollectionCard .productCard__tegs {
    top: 4px;
    left: 4px;
  }
}
@media (max-width: 769px) {
  .catalogCollectionCard .productCard__btns {
    top: 4px;
    right: 4px;
  }
}
.catalogCollectionCard__sale {
  position: absolute;
  bottom: 4px;
  right: 4px;
  background: var(--red-color);
  border-radius: 3px;
  padding: 3px 8px;
  font-weight: 700;
  font-size: 12px;
  line-height: 1.2;
  color: var(--light-color);
}
.catalogCollectionCard__imgs {
  height: 50px;
  margin: 5px -2px 0 -2px;
  overflow: hidden;
}
.catalogCollectionCard__link {
  display: block;
  margin: 0 2px;
  float: left;
}
.catalogCollectionCard__imgsCard {
  display: block;
  object-fit: cover;
  border-radius: 5px;
  height: 50px;
}
.catalogCollectionCard__imgsCard--text {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 12px;
  line-height: 1.3;
  color: var(--dark-color);
  background: var(--dark-gray-color3);
  float: right;
  height: 50px;
  width: 50px;
  margin-right: 2px;
}
.catalogCollectionCard__imgsCard--border {
  border: 1px solid var(--dark-gray-color3-hover-btn);
}
.catalogCollectionCard__info {
  margin: 15px 0 25px;
  display: flex;
  flex-direction: column;
}
.catalogCollectionCard__price {
  font-weight: 700;
  font-size: 18px;
  line-height: 1.2;
  color: var(--dark-color);
  margin-bottom: 9px;
}
.catalogCollectionCard__name1 {
  margin-bottom: 2px;
  font-size: 14px;
  line-height: 1.3;
  color: var(--gray-color);
}
.catalogCollectionCard__name2 {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.3;
  color: var(--dark-color);
  transition: all 0.4s;
}
.catalogCollectionCard__name2:hover {
  color: var(--dark-color-hover-link);
}

.catalogCollectionCardProduct {
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
@media (max-width: 769px) {
  .catalogCollectionCardProduct {
    gap: 12px;
  }
}
@media (max-width: 551px) {
  .catalogCollectionCardProduct {
    padding: 12px;
  }
}
.catalogCollectionCardProduct::after {
  content: "";
  display: block;
  position: absolute;
  top: -0.5px;
  left: -0.5px;
  width: calc(100% + 1px);
  height: calc(100% + 1px);
  border: 1px solid var(--dark-gray-color3-hover-btn);
  pointer-events: none;
}
.catalogCollectionCardProduct:hover .productCard__imgTabs {
  opacity: 1;
}
.catalogCollectionCardProduct__imgWrap {
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.catalogCollectionCardProduct__imgLink {
  display: block;
  border-radius: 5px;
  aspect-ratio: 2/1;
  overflow: hidden;
}
.catalogCollectionCardProduct__imgLink--border {
  border: 1px solid #f2f5f7;
}
.catalogCollectionCardProduct__imgLink img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.catalogCollectionCardProduct__name {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  color: var(--dark-color);
  transition: all 0.4s;
  margin-bottom: 10px;
  display: inline-block;
}
.catalogCollectionCardProduct__name:hover {
  color: var(--dark-color-hover-link);
}
.catalogCollectionCardProduct__dateBtn {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: auto;
}
@media (max-width: 769px) {
  .catalogCollectionCardProduct__dateBtn {
    flex-direction: column-reverse;
  }
}
.catalogCollectionCardProduct__date {
  font-size: 12px;
  line-height: 1.2;
  color: var(--gray-color);
  text-align: center;
}
.catalogCollectionCardProduct__date span {
  font-weight: 700;
}
@media (max-width: 769px) {
  .catalogCollectionCardProduct__date {
    text-align: left;
    color: var(--dark-color);
  }
}
.catalogCollectionCardProduct__bonus {
  padding: 3px;
  text-align: center;
  background: #f2f5f7;
  border-radius: 10px;
  font-size: 14px;
  line-height: 1.2;
  color: var(--dark-color);
  position: relative;
  cursor: pointer;
  display: block !important;
}
.catalogCollectionCardProduct__bonus .clue__clue {
  right: inherit;
  left: 50%;
  transform: translateX(-50%) translateY(100%);
  text-align: left !important;
}
@media (max-width: 769px) {
  .catalogCollectionCardProduct__bonus .clue__clue {
    transform: translateX(-50%);
  }
}
@media (min-width: 769px) {
  .catalogCollectionCardProduct__bonus .clue__clue.clue__clue--bottom {
    transform: translate(-50%, -100%) !important;
  }
}
.catalogCollectionCardProduct__bonus span {
  font-weight: 700;
}
.catalogCollectionCardProduct__bonusIcon {
  margin-left: 5px;
  font-size: 14px;
  line-height: 17px;
  vertical-align: bottom;
  color: var(--red-color);
  pointer-events: none;
}
.catalogCollectionCardProduct__raitingBonus {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
@media (max-width: 769px) {
  .catalogCollectionCardProduct__raitingBonus {
    margin-bottom: 8px;
  }
}
.catalogCollectionCardProduct__price {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2;
}
.catalogCollectionCardProduct__counter {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.catalogCollectionCardProduct__counterWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.catalogCollectionCardProduct__priceInf {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.2;
  color: var(--gray-color);
}
.catalogCollectionCardProduct__btn--hidden {
  display: none;
}
.catalogCollectionCardProduct__btn2Icon {
  font-size: 20px;
  vertical-align: middle;
}
.catalogCollectionCardProduct__btn2--hidden {
  display: none;
}
.catalogCollectionCardProduct__wrapCatCod {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.catalogCollectionCardProduct__categ, .catalogCollectionCardProduct__cod {
  font-size: 12px;
  line-height: 1.3;
  color: var(--gray-color);
}

.catalogCollectionBanner {
  padding: 17px 0;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(151, 165, 173, 0.75);
  z-index: 2000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: all 0.4s;
}
@media (max-width: 769px) {
  .catalogCollectionBanner {
    padding: 12px 0;
  }
}
.catalogCollectionBanner--hidden {
  transform: translateY(-100%);
}
.catalogCollectionBanner__wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
@media (max-width: 769px) {
  .catalogCollectionBanner__wrap {
    gap: 6px;
    flex-direction: column;
    align-items: flex-start;
  }
}
.catalogCollectionBanner__right {
  display: flex;
  gap: 20px;
  align-items: center;
}
@media (max-width: 769px) {
  .catalogCollectionBanner__right {
    gap: 12px;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
}
.catalogCollectionBanner__price {
  font-weight: 400;
  font-size: 21px;
  line-height: 1.2;
}
@media (max-width: 769px) {
  .catalogCollectionBanner__price {
    font-size: 16px;
  }
}
.catalogCollectionBanner__price span {
  font-weight: 700;
}
.catalogCollectionBanner__left {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.catalogCollectionBanner__text {
  font-weight: 700;
  font-size: 14px;
  line-height: 1.3;
}
@media (max-width: 769px) {
  .catalogCollectionBanner__text {
    font-size: 12px;
    font-weight: 400;
  }
}
.catalogCollectionBanner__linksWrap {
  display: flex;
  gap: 20px;
}
@media (max-width: 769px) {
  .catalogCollectionBanner__linksWrap {
    display: none;
  }
}
.catalogCollectionBanner__link {
  font-size: 13px;
  line-height: 1.2;
  color: var(--blue-color);
  transition: all 0.4s;
}
.catalogCollectionBanner__link:hover {
  color: var(--blue-color-hover-link);
}
@media (max-width: 769px) {
  .catalogCollectionBanner__btn {
    width: 100%;
    padding: 18.5px;
  }
}

.product {
  margin-bottom: 90px;
}
@media (max-width: 1441px) {
  .product {
    margin-bottom: 55px;
  }
}
@media (max-width: 769px) {
  .product {
    margin-bottom: 50px;
  }
}
.product--m0 {
  margin: 0;
}
.product__wrap {
  display: flex;
  gap: 60px;
  justify-content: space-between;
}
@media (max-width: 1025px) {
  .product__wrap {
    gap: 30px;
  }
}
@media (max-width: 769px) {
  .product__wrap {
    flex-direction: column;
    gap: 10px;
  }
}
.product__wrap--modal {
  gap: 40px;
}
.product__infoTitleModal {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.25;
  margin: 0 0 10px;
}
.product__gallery {
  width: calc(100% - 520px);
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  height: fit-content;
  position: sticky;
  top: 110px;
}
@media (max-width: 1025px) {
  .product__gallery {
    width: calc(100% - 350px);
  }
}
@media (max-width: 769px) {
  .product__gallery {
    width: 100%;
    position: relative;
    top: initial;
  }
}
.product__gallery--tile {
  width: calc(100% - 480px);
}
@media (max-width: 1025px) {
  .product__gallery--tile {
    width: calc(100% - 350px);
  }
}
@media (max-width: 769px) {
  .product__gallery--tile {
    width: 100%;
  }
}
.product__gallery--modal {
  flex-direction: column-reverse;
  width: 500px;
}
.product__gallery--modal .product__galleryVert {
  flex-direction: row;
  width: 100%;
}
.product__gallery--modal .product__galleryImgMin {
  width: auto;
  height: auto;
  flex: 1 1 100%;
  aspect-ratio: 1;
  padding: 5px;
}
.product__gallery--modal .product__galleryImgMinMore {
  flex: 1 1 100%;
}
.product__gallery--modal .product__galleryMain {
  width: 100%;
}
.product__info {
  width: 420px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
@media (max-width: 1025px) {
  .product__info {
    width: 320px;
  }
}
@media (max-width: 769px) {
  .product__info {
    width: 100%;
  }
}
.product__info--noGap {
  display: block;
  gap: 0;
}
@media (max-width: 769px) {
  .product__info--noGap .product__btnsWrap {
    margin: 0;
  }
}
@media (max-width: 1025px) {
  .product__info--modal {
    width: 420px;
  }
}
.product__galleryMain {
  position: relative;
  width: calc(100% - 120px);
}
.product__galleryMain .productCard__btns {
  display: none;
}
@media (max-width: 769px) {
  .product__galleryMain .productCard__btns {
    display: flex;
  }
}
@media (max-width: 1441px) {
  .product__galleryMain {
    width: calc(100% - 100px);
  }
}
@media (max-width: 1025px) {
  .product__galleryMain {
    width: calc(100% - 80px);
  }
}
@media (max-width: 769px) {
  .product__galleryMain {
    width: 100%;
  }
}
.product__galleryMain--full {
  width: 100%;
}
.product__galleryMain .productCard__btns {
  right: 10px;
  top: 10px;
}
.product__galleryTop {
  overflow: hidden;
  position: relative;
}
.product__galleryImg {
  display: block;
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 9px;
  object-fit: cover;
}
@media (max-width: 769px) {
  .product__galleryImg {
    margin-bottom: 8px;
  }
}
.product__galleryImg--tile {
  aspect-ratio: initial;
  margin-bottom: 0;
}
.product__galleryVertWrap {
  overflow: hidden;
  position: relative;
}
@media (max-width: 769px) {
  .product__galleryVertWrap {
    display: none;
  }
}
.product__galleryVert {
  width: 100px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1441px) {
  .product__galleryVert {
    width: 80px;
  }
}
@media (max-width: 1025px) {
  .product__galleryVert {
    width: 60px;
  }
}
.product__galleryImgMin {
  display: block;
  width: 100%;
  aspect-ratio: 1/1;
  border: 1px solid var(--dark-gray-color3-hover-btn);
  border-radius: 5px;
  transition: all 0.4s;
  padding: 8.75px;
  height: auto; /*added ipv*/
  box-sizing: border-box; /*added ipv*/
  margin-bottom: 10px;
}
@media (max-width: 1025px) {
  .product__galleryImgMin {
    padding: 5.5px;
  }
}
.product__galleryImgMin:last-child {
  margin-bottom: 0;
}
.product__galleryImgMin--active {
  padding: 7.75px;
  border: 2px solid var(--red-color);
}
@media (max-width: 1025px) {
  .product__galleryImgMin--active {
    padding: 4.5px;
  }
}
.product__galleryImgMin img {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
}
.product__galleryImgMinMore {
  font-weight: 400;
  font-size: 13px;
  line-height: 1.2;
  color: var(--blue-color);
  transition: all 0.4s;
}
.product__galleryImgMinMore:hover {
  color: var(--blue-color-hover-link);
}
.product__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 16px;
}
@media (max-width: 769px) {
  .product__top {
    display: none;
  }
}
.product__topLeft {
  display: flex;
  gap: 12px 20px;
  align-items: center;
  flex-wrap: wrap;
}
.product__topRight {
  display: flex;
  gap: 20px;
  align-items: center;
}
.product__topBtn {
  display: flex;
  gap: 10px;
  align-items: center;
  color: var(--blue-color);
  transition: all 0.4s;
}
.product__topBtn:hover {
  color: #1f2d35;
}
.product__topBtn:hover .product__topIcon {
  color: var(--red-color);
}
.product__topBtn--active {
  color: var(--red-color);
}
.product__topBtn--active .product__topTextSt {
  display: none;
}
.product__topBtn--active .product__topTextActive {
  display: inline-block;
}
.product__topBtn--active .product__topIcon span {
  opacity: 1;
}
.product__topIcon {
  transition: all 0.4s;
  position: relative;
}
.product__topIcon span {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.product__topTextActive {
  display: none;
}
.product__topIcon {
  font-size: 24px;
}
.product__cod {
  background: var(--dark-gray-color3);
  border-radius: 5px;
  font-size: 14px;
  line-height: 1.2;
  padding: 6.5px 10px;
}
.product__codCopy {
  font-size: 16px;
  color: var(--gray-color);
  transition: all 0.4s;
  display: inline-block;
  margin-left: 10px;
  vertical-align: text-top;
}
.product__codCopy:hover {
  color: var(--gray-color-hover-link);
}
.product__anchor {
  font-size: 13px;
  line-height: 1.2;
  color: var(--blue-color);
  transition: all 0.4s;
}
.product__anchor:hover {
  color: var(--blue-color-hover-link);
}
.product__galleryMainTegs {
  left: 15px;
  top: 15px;
}
.product__btnsWrap {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  border: 1px solid #e2e8ec;
  border-radius: 9px;
}
@media (max-width: 769px) {
  .product__btnsWrap {
    padding: 0;
    border: none;
    margin: -15px 0;
  }
}
.product__btnsWrap--noBorder {
  padding: 0;
  border-radius: 0;
  border: none;
}
.product__btn {
  padding: 16.5px;
  width: 100%;
  font-size: 16px;
  line-height: 1.15;
}
.product__btnLink {
  width: 100%;
  color: var(--dark-color);
  padding: 16.5px;
  font-size: 16px;
  line-height: 1.15;
  background: var(--dark-gray-color3);
}
.product__btnLink:hover {
  background: var(--dark-gray-color3-hover-btn);
}
.product__infoBlock {
  padding: 0 20px;
}
@media (max-width: 1025px) {
  .product__infoBlock {
    padding: 0;
  }
}
.product__infoBlock--m30 {
  margin: 30px 0;
}
.product__infoBlock--mb {
  margin-bottom: 20px;
}
@media (max-width: 769px) {
  .product__infoBlock--mb {
    margin: 20px 0 0;
  }
}
.product__infoBlockWrap {
  display: flex;
  gap: 20px;
  align-items: flex-end;
}
@media (max-width: 769px) {
  .product__infoBlockWrap {
    gap: 0;
  }
}
.product__infoBlockWrap:not(:last-child) {
  margin-bottom: 6px;
}
.product__infoBlockLeft {
  width: calc(50% - 10px);
  font-size: 14px;
  line-height: 1.2;
  display: flex;
  color: var(--gray-color);
  align-items: flex-end;
}
@media (max-width: 769px) {
  .product__infoBlockLeft {
    width: 50%;
  }
}
.product__infoBlockLeft span {
  flex: 1 1 auto;
  display: inline-block;
  border-bottom: 1px dashed #e2e8ec;
  margin-bottom: 3px;
}
.product__infoBlockRight {
  width: calc(50% - 10px);
  font-size: 14px;
  line-height: 1.2;
}
@media (max-width: 769px) {
  .product__infoBlockRight {
    width: 50%;
  }
}
.product__infoBlockRight a {
  transition: all 0.4s;
  color: var(--blue-color);
  text-decoration: underline;
}
.product__infoBlockRight a:hover {
  color: var(--blue-color-hover-link);
}
.product__infoBlockTitle {
  font-weight: 700;
  font-size: 14px;
  line-height: 0.9;
  margin-bottom: 15px;
}
.product__mobBlock {
  display: none;
}
@media (max-width: 769px) {
  .product__mobBlock {
    display: block;
  }
  .product__mobBlock--mb {
    margin-bottom: 20px;
  }
}
.product__mobBlockTitle {
  font-weight: 700;
  font-size: 22px;
  line-height: 1.2;
  margin: 23px 0 12px;
}
.product__mobBlockWrap {
  display: flex;
  gap: 12px 20px;
  flex-wrap: wrap;
}
.product__btnsPriceBlock {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}
.product__btnsSale {
  padding: 7px 4px;
  background: var(--red-color);
  border-radius: 4px;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.35;
  color: var(--light-color);
}
.product__btnsPrice {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.2;
  color: var(--dark-color);
}
.product__btnsPrice span {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  text-decoration-line: line-through;
  color: var(--gray-color);
  margin-left: 10px;
}
.product__btnsClue {
  margin-bottom: 5px;
}
@media (max-width: 769px) {
  .product__btnsClue {
    margin-top: 0;
    order: initial !important;
  }
}
.product__btnsStatus {
  margin-bottom: 5px;
}
.product__btnsInf {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 20px;
}
.product__btnsInfStr {
  font-size: 14px;
  line-height: 1.2;
}
.product__btnsCounter {
  align-items: center;
  margin-bottom: 10px;
}
.product__btnsCounterWrap {
  width: 100%;
}
.product__btnsCounterWrap .quantity {
  width: 100%;
}
.product__btnsPrice22 {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
}
.product__btnsPrice1 {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
}
.product__btnsPrice2 {
  font-weight: 700;
  font-size: 22px;
  line-height: 1.3;
  text-align: right;
}
.product__clueMt {
  margin-top: -20px;
  order: initial !important;
}
@media (max-width: 769px) {
  .product__clueMt {
    margin-top: 0;
  }
}
.product__clueMt10 {
  margin: 10px 0;
  padding-right: 20px;
  padding-left: 20px;
}
.product__diller {
  border: 1px solid #e2e8ec;
  border-radius: 9px;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  overflow: hidden;
}
.product__diller--m30 {
  margin: 30px 0;
}
.product__dillerImg {
  display: block;
  max-width: 140px;
  max-height: 70px;
  width: auto;
}
.product__dillerLeft {
  font-weight: 700;
  font-size: 14px;
  line-height: 0.9;
  display: flex;
  gap: 14px;
  align-items: center;
}
.product__dillerLeftIcon {
  font-size: 30px;
  color: var(--red-color);
}
.product__blockWrap {
  display: flex;
  gap: 12px 20px;
  flex-wrap: wrap;
}
.product__blockWrap > * {
  width: calc(50% - 10px);
}
.product__btnsWarning {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
  background: #f2f5f7;
  border-radius: 9px;
  margin-bottom: 20px;
}
.product__btnsWarning--mb10 {
  margin-bottom: 10px;
}
.product__btnsWarningIcon {
  font-size: 24px;
  color: var(--red-color);
}
.product__btnsWarningText {
  font-size: 12px;
  line-height: 1.3;
}
.product__topTop {
  font-size: 13px;
  line-height: 1.2;
  color: #3caa3c;
  width: 100%;
}
@media (max-width: 769px) {
  .product__topTop {
    margin-top: 12px;
  }
}
.product__topTopIcon {
  font-size: 16px;
  margin-right: 6px;
  vertical-align: middle;
}
.product__btnsComplectWrap {
  background: #f2f5f7;
  border-radius: 9px;
  padding: 20px;
  margin-bottom: 5px;
  display: none;
}
.product__btnsComplectWrap--active {
  display: block;
}
.product__btnsComplectWrapTitle {
  font-weight: 700;
  font-size: 14px;
  line-height: 1.2;
  margin: 0 0 20px;
}
.product__btnsComplectWrapFlex {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.product__btnsComplectCard {
  display: flex;
  gap: 20px;
  align-items: center;
}
.product__btnsComplectImg {
  display: block;
  width: 60px;
  height: 60px;
}
.product__btnsComplectImg img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.product__btnsComplectName {
  font-size: 14px;
  line-height: 1.3;
  color: var(--dark-color);
  transition: all 0.4s;
  width: calc(100% - 80px);
}
.product__btnsComplectName:hover {
  color: var(--dark-color-hover-link);
}
.product__btnsInstall {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f2f5f7;
  border-radius: 9px;
  padding: 15px;
  margin-bottom: 5px;
}
.product__btnsInstallLeft {
  display: flex;
  gap: 20px;
  align-items: center;
}
.product__btnsInstallIcon {
  font-size: 32px;
  color: var(--red-color) !important;
}
.product__btnsInstallTextWrap {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.product__btnsInstallPrice {
  font-weight: 700;
  font-size: 16px;
  line-height: 0.9;
}
.product__btnsInstallText {
  font-size: 12px;
  line-height: 0.9;
}
.product__btnsInstallRight {
  display: flex;
  gap: 10px;
  align-items: center;
}
.product__btnsInstallTextGr {
  font-size: 12px;
  line-height: 1.2;
  color: var(--gray-color);
}
@media (max-width: 1025px) {
  .product__btnsInstallTextGr {
    display: none;
  }
}
.product__infoBlockWrapSize {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}
.product__infoBlockBtnSize {
  width: calc(25% - 3.75px);
  padding: 10px 10px;
  border: 2px solid #e2e8ec;
  border-radius: 3px;
  transition: all 0.4s;
  color: var(--dark-color);
}
@media (max-width: 769px) {
  .product__infoBlockBtnSize {
    width: calc(33.3333333333% - 3.3333333333px);
  }
}
.product__infoBlockBtnSize:hover {
  border-color: var(--dark-gray-color3);
}
.product__infoBlockBtnSize:disabled {
  pointer-events: none;
  border-color: #f2f5f7;
  color: #e2e8ec;
}
.product__infoBlockBtnSize--width {
  width: fit-content;
  padding-left: 17px;
  padding-right: 17px;
}
.product__infoBlockBtnSize--active {
  border-color: var(--red-color) !important;
}
.product__credit {
  border: 1px solid #e2e8ec;
  border-radius: 9px;
  padding: 15px 20px 30px;
}
.product__creditTitleWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: 15px;
}
.product__creditTitle {
  font-weight: 700;
  font-size: 14px;
  line-height: 0.9;
}
.product__creditPeriod {
  display: flex;
  gap: 2px;
  background: #f2f5f7;
  border-radius: 5px;
  padding: 2px;
  margin-bottom: 17px;
}
.product__creditPeriodBtn {
  background: transparent;
  border-radius: 3px;
  font-weight: 400;
  font-size: 12px;
  line-height: 0.9;
  flex: 1 1 auto;
  padding: 7.5px;
  transition: all 0.4s;
}
.product__creditPeriodBtn:hover {
  background-color: var(--dark-gray-color3-hover-btn);
}
.product__creditPeriodBtn--active {
  background: #ffffff !important;
}
.product__creditTotalBtn {
  font-weight: 400;
  font-size: 10px;
  line-height: 1.2;
  padding: 10px 17px;
}
.product__creditTotalWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: 17px;
}
.product__creditTotal {
  font-weight: 700;
  font-size: 16px;
  line-height: 0.9;
}
.product__creditPayWrap {
  display: flex;
  gap: 3px;
}
.product__creditPay {
  flex: 1 1 auto;
}
.product__creditPayDecor {
  display: block;
  width: 100%;
  height: 3px;
  background: var(--red-color);
  border-radius: 5px;
  margin-bottom: 10px;
}
.product__creditPayDate {
  font-size: 12px;
  line-height: 0.9;
  margin-bottom: 7px;
}
.product__creditPayPrice {
  font-weight: 700;
  font-size: 14px;
  line-height: 0.9;
}
.product__btnsComplectSel {
  margin-bottom: 5px;
}
.product__btnsComplectSelWrap {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}
.product__btnsComplectSelTitle {
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 14px;
  line-height: 0.9;
}
.product__btnsComplectSelBtn {
  padding: 14px 21px;
  font-weight: 400;
  font-size: 14px;
  line-height: 0.9;
  border: 2px solid #e2e8ec;
  border-radius: 3px;
  transition: all 0.4s;
}
.product__btnsComplectSelBtn:hover {
  border-color: var(--dark-gray-color3);
}
.product__btnsComplectSelBtn--active {
  border-color: var(--red-color) !important;
}
.product__galleryReviews {
  background: #f2f5f7;
  border-radius: 9px;
  padding: 10px;
  width: 100%;
  display: flex;
  gap: 10px;
}
@media (max-width: 769px) {
  .product__galleryReviews {
    padding: 0;
    background-color: transparent;
    border-radius: 0;
    gap: 6px;
  }
}
.product__galleryReviewsImgWrap {
  width: calc(11.1111111111% - 8.8888888889px);
  border-radius: 5px;
  overflow: hidden;
}
@media (min-width: 1442px) {
  .product__galleryReviewsImgWrap:nth-child(n+10) {
    display: none;
  }
}
@media (max-width: 1441px) {
  .product__galleryReviewsImgWrap {
    width: calc(14.2857142857% - 8.5714285714px);
  }
  .product__galleryReviewsImgWrap:nth-child(n+8) {
    display: none;
  }
}
@media (max-width: 769px) {
  .product__galleryReviewsImgWrap {
    width: calc(14.2857142857% - 5.7142857143px);
  }
}
@media (max-width: 501px) {
  .product__galleryReviewsImgWrap {
    width: calc(25% - 4.5px);
  }
  .product__galleryReviewsImgWrap:nth-child(n+5) {
    display: none;
  }
}
.product__galleryReviewsImgWrap[data-col] {
  /*display: block !important;*/
  position: relative;
}
.product__galleryReviewsImgWrap[data-col]::after {
  content: attr(data-col);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  font-weight: 700;
  font-size: 22px;
  line-height: 1.2;
  color: #f2f5f7;
}
.product__galleryReviewsImgWrap img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.productDop {
  margin-bottom: 60px;
}
@media (max-width: 1025px) {
  .productDop {
    margin-bottom: 50px;
  }
}
@media (max-width: 769px) {
  .productDop {
    margin-bottom: 40px;
  }
}
.productDop__bigCard {
  width: 33.3333333333%;
}
@media (max-width: 1025px) {
  .productDop__bigCard {
    width: 50%;
  }
}
@media (max-width: 551px) {
  .productDop__bigCard {
    width: calc(100% + 24px);
    margin-left: -12px;
    margin-right: -12px;
  }
}
.productDop__mb {
  margin-bottom: 50px;
}
.productDop__mbMin {
  margin-bottom: 30px;
}
.productDop__card {
  width: 25%;
}
@media (max-width: 1025px) {
  .productDop__card {
    width: 33.3333333333%;
  }
}
@media (max-width: 769px) {
  .productDop__card {
    width: 50%;
  }
}
@media (max-width: 551px) {
  .productDop__card {
    width: calc(100% + 24px);
    margin-left: -12px;
    margin-right: -12px;
  }
}
.productDop__description {
  margin: 60px 0;
}
@media (max-width: 769px) {
  .productDop__description {
    margin: 50px 0;
  }
}

.productHead {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1200;
  background: #ffffff;
  transition: all 0.4s;
  transform: translateY(-100%);
  padding: 10px 0;
}
@media (max-width: 769px) {
  .productHead {
    display: none;
  }
}
.productHead--active {
  transform: none;
  box-shadow: 0px 0px 20px rgba(151, 165, 173, 0.75);
}
.productHead--hidden {
  transform: translateY(-100%);
}
.productHead__wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.productHead__wrapLeft {
  display: flex;
  gap: 20px;
  align-items: center;
}
.productHead__wrapImg {
  display: block;
  width: 60px;
  aspect-ratio: 1/1;
}
.productHead__wrapImg img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.productHead__wrapContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.productHead__wrapName {
  font-weight: 700;
  font-size: 14px;
  line-height: 1.3;
  color: var(--dark-color);
  transition: all 0.4s;
}
.productHead__wrapName:hover {
  color: var(--dark-color-hover-link);
}
.productHead__wrapRight {
  display: flex;
  gap: 5px 20px;
  align-items: center;
}
@media (max-width: 1025px) {
  .productHead__wrapRight {
    flex-direction: column;
    align-items: flex-end;
  }
}
.productHead__wrapRightPrice {
  font-weight: 700;
  font-size: 21px;
  line-height: 1.2;
  white-space: nowrap;
}
.productHead__wrapRightPrice span {
  font-weight: 400;
  font-size: 13px;
  line-height: 1.2;
  text-decoration-line: line-through;
  color: var(--gray-color);
  margin-left: 10px;
  white-space: nowrap;
}

.productBottom {
  border-top: 1px solid #e2e8ec;
  position: fixed;
  z-index: 1080;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(151, 165, 173, 0.75);
  padding: 15px 0;
  display: none;
  transition: all 0.4s;
}
.productBottom--hidden {
  transform: translateY(100%);
}
@media (max-width: 769px) {
  .productBottom {
    display: block;
  }
}
.productBottom__btn {
  justify-content: space-between;
}
.productBottom__btn span {
  font-weight: 400;
}
.productBottom__lineWrap {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 15px;
}
.productBottom__line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.productBottom__lineLeft {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
}
.productBottom__lineLeft a {
  color: var(--blue-color);
  text-decoration: underline;
}
.productBottom__lineLeft--red {
  color: var(--red-color);
}
.productBottom__lineRight {
  font-weight: 700;
  font-size: 14px;
  line-height: 1.2;
}
.productBottom__lineRight--red {
  color: var(--red-color);
}

.productDopDescription__wrap {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.productDopDescription__col {
  width: calc(50% - 10px);
}
@media (max-width: 769px) {
  .productDopDescription__col {
    width: 100%;
  }
}

.productInform {
  margin: 60px 0;
}
@media (max-width: 1025px) {
  .productInform {
    margin: 50px 0;
  }
}
@media (max-width: 769px) {
  .productInform {
    margin: 40px 0;
  }
}
.productInform__wrap {
  display: flex;
  gap: 60px;
  justify-content: space-between;
}
@media (max-width: 1025px) {
  .productInform__wrap {
    gap: 50px;
    flex-wrap: wrap;
  }
}
@media (max-width: 769px) {
  .productInform__wrap {
    gap: 40px;
  }
}
.productInform__left {
  width: 680px;
}
@media (max-width: 1025px) {
  .productInform__left {
    width: 100%;
  }
}
.productInform__right {
  width: 460px;
}
@media (max-width: 1441px) {
  .productInform__right {
    width: 380px;
  }
}
@media (max-width: 1025px) {
  .productInform__right {
    width: 100%;
  }
}
.productInform__sertificates {
  display: flex;
  flex-direction: column;
  gap: 13px;
}
.productInform__wrapCharacteristics {
  display: flex;
  gap: 60px;
  justify-content: space-between;
}
@media (max-width: 1025px) {
  .productInform__wrapCharacteristics {
    gap: 50px;
    flex-wrap: wrap;
  }
}
@media (max-width: 769px) {
  .productInform__wrapCharacteristics {
    gap: 40px;
  }
}
.productInform__videoWrap {
  width: 340px;
}
@media (max-width: 1441px) {
  .productInform__videoWrap {
    width: 280px;
  }
}
.productInform__characteristics {
  width: 810px;
}
@media (max-width: 1025px) {
  .productInform__characteristics {
    width: 100%;
  }
}

.sertificate {
  padding: 15px 20px;
  background: #f2f5f7;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.4s;
}
.sertificate:hover {
  background: #e2e8ec;
}
.sertificate__icon {
  font-size: 20px;
  color: #b8c0c5;
}
.sertificate__left {
  display: flex;
  gap: 20px;
  align-items: center;
}
.sertificate__leftIcon {
  font-size: 32px;
  color: var(--red-color);
}
.sertificate__leftWrap {
  display: flex;
  flex-direction: column;
  gap: 9px;
}
.sertificate__leftName {
  font-size: 14px;
  line-height: 0.9;
  color: var(--dark-color);
}
.sertificate__leftSize {
  font-weight: 700;
  font-size: 10px;
  line-height: 0.9;
  color: #97a5ad;
}

.productInformVideo__img {
  position: relative;
  aspect-ratio: 34/19.1;
  overflow: hidden;
  border-radius: 9px;
  margin-bottom: 20px;
}
.productInformVideo__img iframe {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.productInformVideo__btn {
  font-size: 94px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  transition: all 0.4s;
}
.productInformVideo__btn:hover {
  transform: translate(-50%, -50%) scale(1.025);
}
.productInformVideo__btn img {
  display: block;
  width: 94px;
}
@media (max-width: 1441px) {
  .productInformVideo__btn img {
    width: 78px;
  }
}
.productInformVideo__name {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
}

.characteristics__wrap {
  display: flex;
  gap: 30px 20px;
  flex-wrap: wrap;
}
.characteristics__infoBlock {
  width: calc(50% - 10px);
}
@media (max-width: 769px) {
  .characteristics__infoBlock {
    width: 100%;
  }
}
.characteristics__title {
  font-weight: 700;
  font-size: 14px;
  line-height: 0.9;
  margin-bottom: 15px;
}
.characteristics__infoBlockWrap {
  display: flex;
  gap: 20px;
  align-items: flex-end;
}
@media (max-width: 769px) {
  .characteristics__infoBlockWrap {
    gap: 0;
  }
}
.characteristics__infoBlockWrap:not(:last-child) {
  margin-bottom: 6px;
}
.characteristics__infoBlockLeft {
  width: calc(50% - 10px);
  font-size: 14px;
  display: flex;
  color: var(--gray-color);
  align-items: flex-end;
  overflow: hidden;
}
@media (max-width: 769px) {
  .characteristics__infoBlockLeft {
    width: 50%;
  }
}
.characteristics__infoBlockLeft span {
  line-height: 1.2;
  position: relative;
}
.characteristics__infoBlockLeft span:after {
  content: "";
  display: inline-block;
  white-space: pre;
  position: absolute;
  border-bottom: 1px dashed #e2e8ec;
  width: 1000px;
  height: 100%;
  bottom: 0;
}
.characteristics__infoBlockRight {
  width: calc(50% - 10px);
  font-size: 14px;
  line-height: 1.2;
}
@media (max-width: 769px) {
  .characteristics__infoBlockRight {
    width: 50%;
  }
}
.characteristics__infoBlockRight a {
  transition: all 0.4s;
  color: var(--blue-color);
  text-decoration: underline;
}
.characteristics__infoBlockRight a:hover {
  color: var(--blue-color-hover-link);
}
.characteristics__dop {
  margin-top: 60px;
}
@media (max-width: 1025px) {
  .characteristics__dop {
    margin-top: 30px;
  }
}

.productNecessarily {
  margin: 40px 0 60px;
}
.productNecessarily__card {
  border: 4px solid #e2e8ec;
  border-radius: 9px;
  padding: 25px;
}
@media (max-width: 769px) {
  .productNecessarily__card {
    border: none;
    padding: 0;
  }
}
.productNecessarily__btnsWrap {
  margin-bottom: 25px;
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
}
@media (max-width: 769px) {
  .productNecessarily__btnsWrap {
    display: none;
  }
}
.productNecessarily__btn {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.35;
  text-align: center;
  color: var(--dark-color);
  background: var(--dark-gray-color3);
  border-radius: 5px;
  transition: all 0.4s;
  padding: 8px 14px;
}
.productNecessarily__btn:hover {
  background: var(--dark-gray-color3-hover-btn);
}
.productNecessarily__bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px 20px;
  margin-top: 25px;
  flex-wrap: wrap;
}
@media (max-width: 769px) {
  .productNecessarily__bottom {
    display: none;
  }
}
.productNecessarily__btnMore {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  line-height: 1.7;
  color: var(--blue-color);
  transition: all 0.4s;
}
.productNecessarily__btnMore--active {
  display: none;
}
.productNecessarily__btnMore--sv {
  display: none;
}
.productNecessarily__btnMore--sv--active {
  display: flex;
}
.productNecessarily__btnMore:hover {
  color: var(--blue-color-hover-link);
}
.productNecessarily__btnMoreICon {
  font-size: 24px;
}
.productNecessarily__bottomLeftBtn {
  padding: 16.5px 30px;
  font-size: 16px;
  line-height: 1.15;
}
.productNecessarily__bottomLeft {
  display: flex;
  gap: 40px;
  align-items: center;
}
.productNecessarily__priceWrap {
  display: flex;
  gap: 20px;
  align-items: flex-end;
}
.productNecessarily__priceText {
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: 3px;
}
.productNecessarily__price {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.2;
}
.productNecessarily__price span {
  font-size: 16px;
  line-height: 1.2;
  text-decoration-line: line-through;
  color: #b8c0c5;
  margin-left: 10px;
  font-weight: 400;
}
.productNecessarily__benefit {
  background: #f2f5f7;
  border-radius: 22px;
  padding: 3px 14px 3px 4px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
}
.productNecessarily__benefitIcon {
  font-size: 24px;
  color: var(--red-color);
}
.productNecessarily__itemWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
@media (max-width: 769px) {
  .productNecessarily__itemWrap {
    flex-wrap: nowrap;
    width: fit-content;
    gap: 12px;
  }
}
.productNecessarily__itemWrap > *:nth-child(n+7) {
  display: none;
}
@media (max-width: 769px) {
  .productNecessarily__itemWrap > *:nth-child(n+7) {
    display: flex;
  }
}
.productNecessarily__itemWrap--active > *:nth-child(n+7) {
  display: flex;
}
.productNecessarily__item {
  width: calc(16.6666666667% - 13.3333333333px);
}
.productNecessarily__item:hover {
  box-shadow: 0px 0px 10px rgba(151, 165, 173, 0.5);
}
@media (max-width: 769px) {
  .productNecessarily__item:hover {
    box-shadow: none;
  }
}
@media (max-width: 1025px) {
  .productNecessarily__item {
    width: calc(33.3333333333% - 10.6666666667px);
  }
}
@media (max-width: 769px) {
  .productNecessarily__item {
    width: 140px;
  }
}

.productNecessarilyCard {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  transition: all 0.4s;
  border-radius: 9px;
  padding: 15px;
}
@media (max-width: 769px) {
  .productNecessarilyCard {
    padding: 0;
    border-radius: 0;
  }
}
@media (max-width: 769px) {
  .productNecessarilyCard--compl {
    display: none;
  }
}
.productNecessarilyCard__imgWrap {
  position: relative;
}
.productNecessarilyCard__imgWrap img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.productNecessarilyCard__warning {
  margin: 14px 0 6px;
  font-weight: 400;
  font-size: 10px;
  line-height: 1.3;
  display: flex;
  align-items: center;
  color: var(--red-color);
}
.productNecessarilyCard__warningIcon {
  font-size: 16px;
  margin-right: 7px;
}
.productNecessarilyCard__name {
  font-size: 12px;
  line-height: 1.3;
  display: inline-block;
  color: var(--dark-color);
  transition: all 0.4s;
  margin: 6px 0;
}
.productNecessarilyCard__name:hover {
  color: var(--red-color);
}
.productNecessarilyCard__price {
  font-weight: 700;
  font-size: 14px;
  line-height: 1.3;
  margin-bottom: auto;
}
.productNecessarilyCard__price span {
  font-size: 12px;
  line-height: 1.2;
  text-decoration-line: line-through;
  font-weight: 400;
  color: var(--gray-color);
  margin-left: 10px;
}
.productNecessarilyCard__label {
  top: 0;
  left: 0;
  position: absolute;
}
.productNecessarilyCard__labComp {
  position: absolute;
  top: 0;
  left: 0;
  background: #f2f5f7;
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  gap: 8px;
  padding-right: 8px;
  align-items: center;
}
.productNecessarilyCard__labCompCh {
  background: var(--red-color);
  border-radius: 3px;
  color: var(--light-color);
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}
.productNecessarilyCard__labCompText {
  font-size: 12px;
  line-height: 1.2;
  color: var(--dark-color);
}
.productNecessarilyCard__btn {
  margin-top: 14px;
}
.productNecessarilyCard__category {
  font-size: 12px;
  line-height: 1.3;
  margin: 14px 0 6px;
}

.comparison {
  margin-bottom: 60px;
}
@media (max-width: 1441px) {
  .comparison {
    margin-bottom: 50px;
  }
}
@media (max-width: 769px) {
  .comparison {
    margin-bottom: 40px;
  }
}
.comparison__filters {
  margin-bottom: 24px;
}
@media (max-width: 769px) {
  .comparison__filters {
    display: none !important;
  }
}
.comparison__sort {
  margin-bottom: 20px;
  width: 100%;
  max-width: 700px;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  display: flex;
}
@media (max-width: 769px) {
  .comparison__sort {
    display: none;
  }
}
.comparison__sortReset {
  display: flex;
  gap: 10px;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: var(--gray-color);
  transition: all 0.4s;
}
.comparison__sortReset:hover {
  color: var(--gray-color-hover-link);
}
.comparison__sortResetIcon {
  font-size: 24px;
  line-height: 1;
  color: currentColor;
  transition: all 0.4s;
}
@media (max-width: 769px) {
  .comparison__sliderWrap {
    display: none;
  }
}
.comparison__sliderWrap .swiper-button-next-custom {
  right: 0 !important;
}
@media (max-width: 1501px) {
  .comparison__sliderWrap .swiper-button-next-custom {
    transform: translateY(-50%);
  }
}
@media (max-width: 769px) {
  .comparison__sliderWrap .swiper-button-next-custom {
    display: none !important;
  }
}
.comparison__sliderWrap .swiper-button-prev-custom {
  left: 0 !important;
}
@media (max-width: 1501px) {
  .comparison__sliderWrap .swiper-button-prev-custom {
    transform: translateY(-50%);
  }
}
@media (max-width: 769px) {
  .comparison__sliderWrap .swiper-button-prev-custom {
    display: none !important;
  }
}
.comparison__mobBlock {
  display: none;
}
@media (max-width: 769px) {
  .comparison__mobBlock {
    display: block;
  }
}
.comparison__mobBlockBtn {
  width: 100%;
  margin-top: 15px;
  padding: 16.5px;
  font-size: 16px;
  display: none;
}
.comparison__mobBlockBtn--active {
  display: flex;
}
.comparison__mobBlockBtn2 {
  width: 100%;
  margin-top: 15px;
  padding: 16.5px;
  font-size: 16px;
  display: none;
}
.comparison__mobBlockBtn2--active {
  display: flex;
}
.comparison__sliderM {
  margin: 40px 0;
}
.comparison__sliderMBtn {
  gap: 10px;
  width: fit-content;
}
@media (max-width: 769px) {
  .comparison__sliderMBtn {
    width: 100%;
  }
}
.comparison__sliderSticky {
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  width: 100%;
  z-index: 1001;
  display: none;
}
.comparison__sliderSticky .sliderComparison__slideLine {
  border: none !important;
}
.comparison__sliderSticky--active {
  border-top: 1px solid #e2e8ec;
  display: block;
  box-shadow: 0px 10px 10px rgba(151, 165, 173, 0.5);
}
@media (max-width: 769px) {
  .comparison__sliderSticky .sliderComparison__slideImg {
    width: 60px;
    height: 60px;
  }
}
.comparison .sliderNavWrap .sliderComparison__slideLine--sticky {
  border-top: none;
  padding-top: 0;
}

.comparisonFilters {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.comparisonFilters__item {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 8px 10px 8px 14px;
  border: 1px solid var(--dark-gray-color3);
  transition: all 0.4s;
  border-radius: 5px;
  transition: all 0.4s;
  color: var(--dark-color);
}
.comparisonFilters__item:hover {
  border-color: var(--dark-gray-color3-hover-btn);
}
.comparisonFilters__item--active {
  background: #1f2d35;
  color: var(--light-color);
  border-color: #1f2d35;
}
.comparisonFilters__item--active:hover {
  border-color: #1f2d35;
}
.comparisonFilters__text {
  font-size: 14px;
  line-height: 1.25;
  color: currentColor;
}
.comparisonFilters__text span {
  font-size: 14px;
  line-height: 1.25;
  color: var(--gray-color);
  margin-left: 6px;
}
.comparisonFilters__close {
  font-size: 14px;
  transition: all 0.4s;
  color: var(--gray-color);
}
.comparisonFilters__close:hover {
  color: var(--gray-color-hover-link);
}

.comparisonMobBtns {
  display: flex;
  flex-direction: column;
}
.comparisonMobBtns--edit .comparisonMobBtns__btnRemove {
  display: block;
}
.comparisonMobBtns--edit .comparisonMobBtns__btnChevrone {
  display: none;
}
.comparisonMobBtns--edit .comparisonMobBtns__remove {
  display: block;
}
.comparisonMobBtns__btn {
  border: 1px solid #e2e8ec;
  text-align: left;
  padding: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
  width: 100%;
  position: relative;
}
.comparisonMobBtns__btn:not(:last-child) {
  border-bottom: none;
}
.comparisonMobBtns__btn:first-child {
  border-radius: 5px 5px 0px 0px;
}
.comparisonMobBtns__btn:last-child {
  border-radius: 0px 0px 5px 5px;
}
.comparisonMobBtns__btnText {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.7;
  color: var(--dark-color);
}
.comparisonMobBtns__btnText span {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.7;
  color: var(--gray-color);
  margin-left: 10px;
}
.comparisonMobBtns__btnChevrone {
  margin-left: auto;
  font-size: 24px;
}
.comparisonMobBtns__btnRemove {
  font-size: 24px;
  display: none;
}
.comparisonMobBtns__remove {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.sliderComparison__remove {
  position: absolute;
  top: 0;
  right: 40px;
  font-size: 16px;
  transition: all 0.3s;
  color: var(--dark-color);
}
.sliderComparison__remove:hover {
  color: var(--dark-color-hover-link);
}
.sliderComparison__remove--min {
  top: 10px;
}
@media (max-width: 769px) {
  .sliderComparison--line1 .sliderComparison__slideLine {
    border: none;
    padding: 6px 20px 6px 0;
  }
}
.sliderComparison__slide {
  width: 200px;
}
@media (max-width: 769px) {
  .sliderComparison__slide {
    width: 180px;
  }
}
.sliderComparison__slide:not(:first-child) .sliderComparison__slideLine--textTop::before {
  color: transparent;
}
.sliderComparison__slideLine {
  padding: 15px 40px 15px 0;
  border-bottom: 1px solid #e2e8ec;
}
@media (max-width: 769px) {
  .sliderComparison__slideLine {
    padding-right: 20px;
  }
}
.sliderComparison__slideLine:first-child {
  border-top: 1px solid #e2e8ec;
}
.sliderComparison__slideLine--noTop {
  padding-top: 0;
}
.sliderComparison__slideLine--noTop:first-child {
  border-top: none;
}
.sliderComparison__slideLine--textTop::before {
  content: attr(data-text);
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: var(--gray-color);
  margin-bottom: 9px;
  display: inline-block;
  height: 17px;
}
.sliderComparison__slideLine--sticky {
  padding-top: 10px;
  padding-bottom: 14px;
}
.sliderComparison__slideImg {
  display: block;
  width: 100%;
  aspect-ratio: 1/1;
  margin-bottom: 20px;
}
@media (max-width: 769px) {
  .sliderComparison__slideImg {
    max-width: 130px;
  }
}
.sliderComparison__slideImg--min {
  max-width: 130px;
}
.sliderComparison__slideImg--min2 {
  max-width: 60px;
  margin-bottom: 5px;
}
.sliderComparison__slideImg img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.sliderComparison__slideName {
  transition: all 0.4s;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.3;
  display: inline-block;
  color: var(--dark-color);
}
.sliderComparison__slideName:hover {
  color: var(--dark-color-hover-link);
}
.sliderComparison__slideRaiting {
  font-size: 14px;
  line-height: 1.2;
  color: var(--gray-color);
}
.sliderComparison__slideLinePrice {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2;
}
.sliderComparison__slideLinePrice span {
  margin-left: 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.2;
  text-decoration-line: line-through;
  color: var(--gray-color);
  vertical-align: bottom;
}
.sliderComparison__slideLineText {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: var(--dark-color);
}

.catalogList {
  margin-bottom: 90px;
}
@media (max-width: 1441px) {
  .catalogList {
    margin-bottom: 55px;
  }
}
@media (max-width: 769px) {
  .catalogList {
    margin-bottom: 50px;
  }
}
.catalogList__wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 70px 40px;
}
@media (max-width: 769px) {
  .catalogList__wrap {
    gap: 50px 30px;
  }
}
@media (max-width: 651px) {
  .catalogList__wrap--pc {
    display: none;
  }
}
.catalogList__wrap--mob {
  display: none;
}
@media (max-width: 651px) {
  .catalogList__wrap--mob {
    display: flex;
    gap: 0;
  }
}
.catalogList__category {
  width: calc(25% - 30px);
  display: flex;
  gap: 30px;
}
@media (max-width: 1441px) {
  .catalogList__category {
    width: calc(33.3333333333% - 26.6666666667px);
  }
}
@media (max-width: 1025px) {
  .catalogList__category {
    width: calc(50% - 20px);
  }
}
@media (max-width: 651px) {
  .catalogList__category {
    width: 100%;
  }
}
.catalogList__img {
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 7.35px;
  overflow: hidden;
}
@media (max-width: 769px) {
  .catalogList__img {
    width: 60px;
    min-width: 60px;
    height: 60px;
  }
}
.catalogList__img img {
  display: block;
}
.catalogList__content {
  width: calc(100% - 130px);
  display: flex;
  flex-direction: column;
  gap: 12px;
}
@media (max-width: 769px) {
  .catalogList__content {
    gap: 22px;
    width: calc(100% - 90px);
  }
}
.catalogList__name {
  font-weight: 700;
  font-size: 18px;
  line-height: 1.15;
  color: var(--dark-color);
  transition: all 0.4s;
}
.catalogList__name:hover:not(.catalogList__name--no_hover) {
  color: var(--dark-color-hover-link);
}
.catalogList__name--min {
  font-weight: 400;
  font-size: 16px;
}
.catalogList__list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.catalogList__list a {
  font-weight: 400;
  font-size: 14px;
  display: inline-block;
  width: fit-content;
  line-height: 1.4;
  color: var(--dark-color);
  transition: all 0.4s;
}
.catalogList__list a:hover {
  color: var(--dark-color-hover-link);
}
.catalogList__list > *:nth-child(n+8) {
  display: none;
}
.catalogList__list--active > *:nth-child(n+8) {
  display: inline;
}
.catalogList__more {
  display: block;
  width: fit-content;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.8;
  color: var(--blue-color);
  transition: all 0.4s;
}
.catalogList__more:hover {
  color: var(--blue-color-hover-link);
}
.catalogList__categoryMob {
  border-bottom: 1px solid var(--gray-color);
  width: 100%;
}
.catalogList__categoryWrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  padding: 5px 0;
}
.catalogList__categoryWrap--active .catalogList__arrow {
  transform: rotate(180deg);
}
.catalogList__arrow {
  font-size: 24px;
  color: var(--gray-color);
  margin-left: auto;
  transition: all 0.4s;
}
.catalogList__categorContent {
  display: none;
  padding: 0 0 20px 80px;
}
.catalogList__categorContent--active {
  display: block;
}
.catalogList__categorContent .catalogList__more {
  margin-top: 10px;
}

#tempates, .elem-template {
  display: none !important;
}

.loader_spinner {
  min-height: 180px !important;
  position: relative !important;
}
.loader_spinner:after {
  content: "";
  display: block;
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  transform-origin: 50%;
  animation: swiper-preloader-spin 1s infinite linear;
  box-sizing: border-box;
  border: 4px solid var(--swiper-preloader-color, var(--swiper-theme-color));
  border-radius: 50%;
  border-top-color: transparent;
}

.showModalReview {
  cursor: pointer;
}

.is_select_dop {
  display: none;
}

.richText__more > div {
  max-height: 140px;
  position: relative;
  overflow: hidden;
}
.richText__more > div:after {
  display: block;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  background: linear-gradient(to top, #fff, transparent);
}
.richText__showMore {
  font-size: 15px;
  margin-top: 20px;
  display: flex;
  align-items: center;
}