.tabs {
   display: flex;
   flex-wrap: wrap;
   gap: 4px;
   &__tab {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 8px 14px;
      transition: all 0.4s;
      background: var(--dark-gray-color3);
      border-radius: 5px;
      font-size: 14px;
      line-height: 1.4;
      color: var(--dark-color);
      &:hover {
         background: var(--dark-gray-color3-hover-btn);
      }
      &--active {
         background: var(--red-color-hover-link);
         color: var(--light-color);
         &:hover {
            background: var(--red-color-hover-link);
            color: var(--light-color);
         }
      }
   }
}
