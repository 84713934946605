.stocks {
   &__section {
      margin: 80px 0;
      @media (max-width: 1441px) {
         margin: 60px 0;
      }
      @media (max-width: 1201px) {
         margin: 45px 0;
      }
      @media (max-width: 769px) {
         margin: 30px 0;
      }
   }
   &__page {
      margin: 0 0 80px;
      @media (max-width: 1441px) {
         margin-bottom: 40px;
      }
      @media (max-width: 769px) {
         margin-bottom: 50px;
      }
   }
   &__wrap {
      display: flex;
      gap: 30px 20px;
      flex-wrap: wrap;
   }
   &__item {
      width: calc(100% / 3 - 40px / 3);
      @media (max-width: 769px) {
         width: calc(50% - 10px);
      }
      @media (max-width: 501px) {
         width: 100%;
      }
   }
   &__more {
      margin-top: 30px;
      @media (max-width: 1440px) {
         margin-top: 50px;
      }
   }
   &__pagination {
      margin-top: 20px;
      @media (max-width: 769px) {
         margin-top: 10px;
      }
   }
}
.stocksItem {
   &__imgWrap {
      position: relative;
      padding: 24px;
      display: flex;
      flex-direction: column;
      gap: 5px;
      border-radius: 5px;
      overflow: hidden;
      margin-bottom: 20px;
      aspect-ratio: 2/1;
      @media (max-width: 1441px) {
         padding: 20px;
      }
      @media (max-width: 1025px) {
         padding: 15px;
         margin-bottom: 16px;
      }
      @media (max-width: 501px) {
         padding: 10px;
         margin-bottom: 12px;
      }
   }
   &__img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
   }
   &__date {
      padding: 8px 9px;
      font-weight: 400;
      font-size: 10px;
      line-height: 1.2;
      position: relative;
      z-index: 2;
      background: #000000;
      border-radius: 7px;
      width: fit-content;
      color: var(--light-color);
      @media (max-width: 1441px) {
         font-size: 11px;
      }
   }
   &__sale {
      padding: 10.5px 10px;
      background: #ed0000;
      border-radius: 7px;
      position: relative;
      z-index: 2;
      margin-top: auto;
      font-weight: 700;
      font-size: 16px;
      line-height: 1.2;
      color: var(--light-color);
      width: fit-content;
      @media (max-width: 1025px) {
         & span {
            display: none;
         }
      }
   }
   &__name {
      font-weight: 400;
      font-size: 18px;
      line-height: 1.3;
      color: var(--dark-color);
      transition: all 0.4s;
      &:hover {
         color: var(--dark-color-hover-link);
      }
      @media (max-width: 1441px) {
         font-size: 16px;
      }
      @media (max-width: 1025px) {
         font-size: 15px;
      }
      @media (max-width: 501px) {
         font-size: 14px;
      }
   }
}
