// если вы хотите использовать sass-переменные - удалите root
// colors
:root {
  // base
  --font-family: "Roboto", sans-serif;
  --content-width: 1420px;
  --container-offset: 12px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --transition-time: 0.4s;

  @media (max-width: 1441px) {
    --content-width: 1180px;
  }

  // colors
  --light-color: #fff;
  --dark-color: #000;
  --dark-color-hover-link: #ed0000;
  --gray-color: #b8c0c5;
  --gray-color-hover-link: #ed0000;
  --dark-gray-color: #494f54;
  --dark-gray-color-hover-link: #ed0000;
  --blue-color: #1f98dd;
  --blue-color-hover-link: #ed0000;
  --red-color: #ed0000;
  --red-color-hover-link: #1f2d35;
  --dark-gray-color3: #f2f5f7;
  --dark-gray-color3-hover-btn: #e2e8ec;
  --green-color: #34c076;
  --gray-color2: #8c959c;
}
