.catalogPage {
   overflow-x: hidden;
   &__page {
      margin: 0 0 80px;
      @media (max-width: 1441px) {
         margin-bottom: 40px;
      }
      @media (max-width: 769px) {
         margin-bottom: 30px;
      }
   }
   &__infoImg {
      display: block;
      width: 100%;
      margin: 60px 0;
      @media (max-width: 1025px) {
         margin: 30px 0;
      }
      @media (max-width: 769px) {
         margin: 10px 0;
      }
   }
   &__catalogProducts {
      margin-bottom: 66px;
      @media (max-width: 1201px) {
         margin-bottom: 50px;
      }
      @media (max-width: 769px) {
         margin-bottom: 10px;
      }
   }
   &__infoText {
      margin-bottom: 35px;
      @media (max-width: 769px) {
         margin-bottom: 30px;
      }
   }
   &__selectMob {
      display: none;
      @media (max-width: 769px) {
         display: block;
         margin-bottom: 12px;
      }
   }
   &__tabs {
      margin: 15px 0;
      @media (max-width: 769px) {
         margin: 0;
      }
   }
   &__brends {
      margin: 15px 0;
      @media (max-width: 769px) {
         display: none;
      }
   }
   &__br {
      width: 100%;
      height: 1px;
      background: #e2e8ec;
      margin: 15px 0 25px;
      &--mob {
         display: none;
         @media (max-width: 769px) {
            display: block;
         }
      }
      @media (max-width: 769px) {
         margin: 0;
      }
   }
   &__wrapFilterCatalog {
      display: flex;
      gap: 20px;
      justify-content: space-between;
      &--mt {
         margin-top: 20px;
         @media (max-width: 769px) {
            margin-top: 0;
         }
      }
   }
   &__wrapFilter {
      width: 220px;
      @media (max-width: 769px) {
         display: none !important;
      }
      &--fav {
         width: 220px;
         @media (max-width: 1441px) {
            width: 240px;
         }
      }
   }
   &__wrapCatalog {
      width: calc(100% - 240px);
      @media (max-width: 1441px) {
         max-width: 900px;
      }
      @media (max-width: 769px) {
         width: 100%;
      }
   }
   &__mobFiltersVariant {
      display: none;
      @media (max-width: 769px) {
         display: flex;
         justify-content: space-between;
         gap: 10px;
         padding: 8px 0;
         align-items: center;
      }
   }
   &__mobFiltersBtn {
      padding: 10px 17.25px 10px 12.25px;
      border-radius: 9px;
      background-color: var(--dark-gray-color3);
      display: flex;
      align-items: center;
      flex: 1 1 auto;
      justify-content: center;
      text-align: center;
      gap: 6px;
      align-self: normal;
      font-size: 13px;
      line-height: 1.15;
      color: var(--dark-color);
      position: relative;
      &--active {
         &::after {
            content: attr(data-numb);
            top: 0;
            right: 0;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            background: var(--red-color);
            border: 2px solid var(--light-color);
            border-radius: 20px;
            padding: 1px 5px;
            font-weight: 700;
            font-size: 10px;
            line-height: 1.4;
            color: var(--light-color);
            transform: translateX(33%) translateY(-3px);
            transition: all 0.4s;
         }
      }
      &Icon {
         font-size: 20px;
      }
   }
   &__sections {
      flex-direction: column;
      display: none;
      @media (max-width: 769px) {
         display: flex;
         width: calc(100% + 24px);
         margin-left: -12px;
         margin-right: -12px;
         border-top: 1px solid var(--dark-gray-color3-hover-btn);
         margin-bottom: 6px;
      }
   }
   &__section {
      display: block;
      padding: 12px;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.2;
      color: var(--dark-color);
      width: 100%;
      border-bottom: 1px solid var(--dark-gray-color3-hover-btn);
   }
   &__slider {
      margin-bottom: 30px;
      @media (max-width: 1025px) {
         margin-bottom: 20px;
      }
      @media (max-width: 769px) {
         margin-bottom: 10px;
      }
   }
}

.catalogPageTabs {
   &--active {
      & .catalogPageTabs__item {
         display: flex !important;
      }
   }
   &--mobOnly {
      display: none;
      @media (max-width: 769px) {
         display: block;
      }
   }
   &__list {
      display: flex;
      gap: 6px;
      flex-wrap: wrap;
      @media (max-width: 769px) {
         flex-wrap: nowrap;
         width: fit-content;
      }
      &--gap {
         gap: 8px;
      }
   }
   &__item {
      padding: 8px 14px;
      font-size: 14px;
      white-space: nowrap;
      line-height: 1.4;
      display: flex;
      align-items: center;
      background: var(--dark-gray-color3);
      border-radius: 5px;
      color: var(--dark-color);
      transition: all 0.4s;
      @media (max-width: 769px) {
         padding: 4px 8px;
      }
      &:nth-child(n + 12) {
         display: none;
         @media (max-width: 769px) {
            display: flex;
         }
      }
      &:hover {
         background-color: var(--dark-gray-color3-hover-btn);
      }
      &--nameCategory {
         padding: 8px 0;
         font-size: 11px;
         line-height: 1.9;
         background-color: transparent;
         @media (max-width: 769px) {
            display: none !important;
         }
         &:hover {
            background-color: transparent;
         }
         color: var(--gray-color);
      }
   }
   &__more {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 8px 14px;
      font-size: 14px;
      line-height: 1.4;
      color: var(--light-color);
      background: var(--red-color);
      border-radius: 5px;
      border: none;
      cursor: pointer;
      transition: all 0.4s;
      @media (max-width: 769px) {
         display: none;
      }
      &:hover {
         background-color: #c70000;
      }
   }
   &__nameMob {
      display: none;
      margin-bottom: 6px;
      font-size: 11px;
      line-height: 1.9;
      color: var(--gray-color);
      @media (max-width: 769px) {
         display: block;
      }
   }
   &__mobScroll {
      width: calc(100% + var(--container-offset) * 2);
      margin-left: calc(-1 * var(--container-offset));
      margin-right: calc(-1 * var(--container-offset));
      padding-left: var(--container-offset);
      padding-right: var(--container-offset);
      overflow: auto;
      padding-bottom: 10px;
   }
   &__title {
      font-weight: 700;
      font-size: 16px;
      line-height: 1.2;
      color: var(--dark-color);
      margin: 0 0 20px;
   }
}

.catalogPageBrends {
   &__text {
      font-weight: 700;
      font-size: 16px;
      line-height: 1.2;
      color: var(--dark-color);
      display: inline-block;
      margin-right: 20px;
      vertical-align: middle;
   }
   &__list {
      display: inline-flex;
      gap: 5px 2px;
      flex-wrap: wrap;
      align-items: flex-start;
      vertical-align: middle;
   }
   &__nameText {
      font-size: 17px;
      line-height: 1.2;
      color: var(--dark-color);
      position: relative;
      z-index: 4;
      transition: all 0.4s;
      cursor: default;
   }
   &__lang {
      font-size: 11px;
      line-height: 1.2;
      color: var(--gray-color);
      padding-top: 10px;
   }
   &__name {
      padding: 10px 14px;
      background-color: transparent;
      transition: all 0.4s;
      z-index: 2;
      position: relative;
      border-radius: 5px 5px 0px 0px;
      &::after {
         content: "";
         display: block;
         width: 100%;
         height: 100%;
         top: 0;
         left: 0;
         position: absolute;
         z-index: 3;
         background-color: #ffffff;
         border-radius: 5px 5px 0px 0px;
         transition: all 0.4s;
         opacity: 0;
         pointer-events: none;
      }
      &:hover {
         background: #ffffff;
         box-shadow: 0px 0px 10px rgba(151, 165, 173, 0.25);
         &::after {
            opacity: 1;
         }
         & .catalogPageBrends__nameText {
            color: var(--red-color);
         }
         & .catalogPageBrends__nameList {
            opacity: 1;
            pointer-events: all;
            background: #ffffff;
            border-radius: 0px 5px 5px 5px;
            box-shadow: 0px 0px 10px rgba(151, 165, 173, 0.25);
         }
      }
      &List {
         z-index: 1;
         opacity: 0;
         pointer-events: none;
         position: absolute;
         left: 0;
         bottom: 0;
         transform: translateY(100%);
         transition: all 0.4s;
         padding: 15px;
         list-style: none;
         margin: 0;
         width: 170px;
         & a {
            font-weight: 400;
            font-size: 14px;
            line-height: 1.4;
            color: var(--dark-color);
            transition: all 0.4s;
            &:hover {
               color: var(--dark-color-hover-link);
            }
         }
      }
   }
}

.catalogPageFilter {
   display: flex;
   flex-direction: column;
   gap: 30px;
   &__brend {
      display: block;
      width: 100%;
   }
   &__test {
      background: var(--dark-gray-color3);
      border-radius: 7px;
      padding: 20px;
      position: relative;
      &Title {
         font-weight: 700;
         font-size: 15px;
         line-height: 1.2;
         color: var(--dark-color);
         margin: 0 0 15px;
      }
      &Text {
         font-size: 12px;
         line-height: 1.15;
         color: #97a5ad;
         margin: 15px 0;
      }
      &Btn {
         font-size: 14px;
         line-height: 1.15;
         gap: 8px;
         border-radius: 4px;
         padding: 10px 15px;
      }
      &Icon {
         font-size: 24px;
      }
      &Close {
         padding: 0;
         margin: 0;
         position: absolute;
         top: 8px;
         right: 8px;
         font-size: 14px;
         background-color: transparent;
         transition: all 0.4s;
         cursor: pointer;
         border: none;
         color: var(--gray-color);
         &:hover {
            color: var(--gray-color-hover-link);
         }
      }
   }
   &__banner {
      display: block;
      width: 100%;
      border-radius: 9px;
      overflow: hidden;
      & img {
         display: block;
         width: 100%;
      }
   }
   &__moreFilters {
   }
}

.catalogPageCatalog {
   &__more {
      margin-top: 30px;
   }
   &__pagination {
      margin-top: 20px;
   }
   &__seo {
      margin-top: 40px;
      @media (max-width: 1441px) {
         margin-top: 72px;
      }
      @media (max-width: 1201px) {
         margin-top: 40px;
      }
   }
   &__tegs {
      margin-bottom: 15px;
      @media (max-width: 769px) {
         margin: 8px 0 0;
      }
   }
   &__sortVariant {
      display: flex;
      gap: 20px;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      @media (max-width: 769px) {
         display: none;
      }
   }
   &__productCards {
      @media (max-width: 769px) {
         width: calc(100% + var(--container-offset) * 2);
         margin-left: calc(-1 * var(--container-offset));
         margin-right: calc(-1 * var(--container-offset));
      }
   }
}

.catalogPageFilterMoreFilters {
   display: flex;
   gap: 20px;
   align-items: center;
   &__iconInfo {
      font-size: 24px;
      color: var(--dark-gray-color3-hover-btn);
      display: block;
   }
   &__textWrap {
      display: flex;
      flex-direction: column;
      gap: 6px;
   }
   &__text {
      font-weight: 700;
      font-size: 14px;
      line-height: 1.4;
      color: var(--dark-color);
   }
   &__btn {
      transition: all 0.4s;
      font-size: 12px;
      line-height: 0.9;
      color: var(--blue-color);
      text-align: left;
      width: fit-content;
      &:hover {
         color: var(--blue-color-hover-link);
      }
   }
}

.cardProductFilterSlider {
   width: 100%;
   border: 1px solid #e2e8ec;
   border-radius: 9px;
   padding: 10px;
   overflow: hidden;
   &__card {
      padding: 0 !important;
      &::after {
         content: none !important;
      }
   }
   & .swiper-pagination {
      position: relative;
      bottom: initial;
      display: flex;
      gap: 6px;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 20px;
      & > * {
         display: block;
         width: 6px;
         height: 6px;
         border-radius: 50%;
         opacity: 1;
         background: var(--dark-gray-color3);
         border-radius: 5px;
         transition: all 0.4s;
         margin: 0 !important;
         padding: 0;
         &:hover {
            background: var(--dark-gray-color3-hover-btn);
         }
         &.swiper-pagination-bullet-active {
            background: var(--red-color);
            width: 12px;
         }
      }
   }
}

.catalogPageCatalogTegs {
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   gap: 8px;
   @media (max-width: 769px) {
      flex-wrap: nowrap;
      width: fit-content;
   }
   &__mobScroll {
      @media (max-width: 769px) {
         width: calc(100% + var(--container-offset) * 2);
         margin-left: calc(-1 * var(--container-offset));
         margin-right: calc(-1 * var(--container-offset));
         padding-left: var(--container-offset);
         padding-right: var(--container-offset);
         overflow: auto;
         padding-bottom: 10px;
      }
   }
   &__btn {
      padding: 4px 11px 4px 14px;
      display: flex;
      align-items: center;
      gap: 4px;
      color: var(--gray-color);
      border: 2px solid var(--gray-color);
      border-radius: 15px;
      white-space: nowrap;
      @media (max-width: 769px) {
         padding: 6px 14px;
         border: none;
         background: #1f2d35;
      }
      &:hover {
         & .catalogPageCatalogTegs__close {
            color: var(--red-color);
         }
      }
      &--reset {
         border-color: transparent;
      }
   }
   &__text {
      font-size: 13px;
      line-height: 20px;
      color: var(--dark-color);
      @media (max-width: 769px) {
         color: #f2f5f7;
      }
   }
   &__textC {
      font-size: 11px;
      line-height: 20px;
      color: var(--gray-color);
      @media (max-width: 769px) {
         color: #b8c0c5;
      }
   }
   &__close {
      font-size: 8.67px;
      transition: all 0.4s;
      display: block;
      line-height: 20px;
      margin-left: 4px;
      @media (max-width: 769px) {
         color: #b8c0c5;
      }
   }
}

.catalogPageCatalogSort {
   display: flex;
   gap: 8px 16px;
   flex-wrap: wrap;
   &__text {
      font-weight: 700;
      font-size: 14px;
      line-height: 1.2;
      color: var(--dark-color);
   }
   &__btn {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.2;
      color: var(--gray-color);
      transition: 0.4s;
      &--active,
      &:hover {
         color: var(--dark-color);
      }
   }
}

.catalogPageCatalogVariant {
   display: flex;
   gap: 10px;
   &__btn {
      font-size: 24px;
      color: var(--dark-gray-color3-hover-btn);
      transition: all 0.4s;
      display: block;
      &--active,
      &:hover {
         color: var(--red-color);
      }
   }
}

.catalogPageSlider {
   @media (max-width: 1025px) {
      overflow: visible;
   }
}

.catalogFavoritePopup {
   position: fixed;
   z-index: 1050;
   top: 0;
   left: 0;
   width: 100%;
   background: #ffffff;
   transition: all 0.4s;
   padding: 10px 0;
   transform: translateY(-100%);
   @media (max-width: 769px) {
      display: none;
   }
   &--active {
      transform: none;
      box-shadow: 0px 0px 20px rgba(151, 165, 173, 0.75);
   }
   &__wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      justify-content: space-between;
   }
   &__card {
      display: flex;
      gap: 20px;
      align-items: center;
      &ImgWrap {
         display: block;
         width: 60px;
         height: 60px;
         & img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
         }
      }
      &Info {
         display: flex;
         flex-direction: column;
         gap: 5px;
      }
      &Name {
         font-size: 14px;
         line-height: 1.3;
         & a {
            font-weight: 700;
            color: var(--dark-color);
            transition: all 0.4s;
            &:hover {
               color: var(--dark-color-hover-link);
            }
         }
      }
      &Descr {
         font-size: 14px;
         line-height: 1.3;
         color: var(--gray-color);
      }
   }
   &__right {
      display: flex;
      gap: 20px;
      align-items: center;
   }
   &__close {
      font-size: 24px;
      transition: all 0.4s;
      color: var(--gray-color);
      &:hover {
         color: var(--gray-color-hover-link);
      }
   }
}
