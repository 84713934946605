.leftMenu {
   border: 1px solid #e2e8ec;
   border-radius: 5px;
   overflow: hidden;
   &__item {
      &:not(:last-child) {
         border-bottom: 1px solid #e2e8ec;
      }
   }
   &__itemFirst {
      padding: 20px 10px 20px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--dark-color);
      font-size: 14px;
      line-height: 1.7;
      transition: all 0.4s;
      width: 100%;
      &:hover {
         background: var(--dark-gray-color3);
         color: var(--dark-color);
      }
      &--active {
         background: #1f2d35 !important;
         color: var(--light-color) !important;
         & .leftMenu__itemIcon {
            transform: rotate(90deg);
         }
         & ~ .leftMenu__itemTwo {
            display: block;
         }
      }
   }
   &__itemTwo {
      padding: 10px 30px;
      color: var(--dark-color);
      font-size: 14px;
      line-height: 1.7;
      transition: all 0.4s;
      display: block;
      width: 100%;
      display: none;
      text-align: left;
      &--active,
      &:hover {
         background: #f2f5f7;
      }
   }
   &__itemText {
      & span {
         color: #b8c0c5;
         margin-left: 10px;
      }
   }
   &__itemIcon {
      font-size: 24px;
   }
}
