:root {
    --swiper-theme-color: var(--red-color);
}
.swiper-button-next-custom,
.swiper-button-prev-custom {
   box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
   border-radius: 50%;
   width: fit-content;
   width: 42px;
   height: 42px;
   cursor: pointer;
   transition: all 0.4s;
   &:hover {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
   }
   & svg {
      width: 100%;
      height: 100%;
      display: block;
   }
}

.swiper-button-next-vertical,.swiper-button-prev-vertical {
    left: 0;
    right: 0;
    width: 100%;
    margin: 0;
    height: 15px;
    /*background-color: var(--dark-gray-color3);*/
    background-color: #fff;
    position: absolute;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: var(--dark-gray-color3-hover-btn);
    }
    &:after {
        content:"next";
        font-family: swiper-icons;
        text-transform: none !important;
        letter-spacing: 0;
        text-transform: none;
        font-variant: initial;
        line-height: 1;

        color: var(--red-color);
        font-size:14px;
        transform: rotate(90deg);
        font-weight: bold;
    }
    &.swiper-button-disabled{
        display: none;
    }
}

.swiper-button-prev-vertical {
    bottom: auto;
    top: 0;
    &:after {
        content: "prev";
    }
}

.swiper-button-next-vertical {
    bottom: 0;
    top: auto;
}


.sliderNavWrap {
   position: relative;
   & .swiper-button-next-custom,
   & .swiper-button-prev-custom {
      position: absolute;
      top: 50%;
      z-index: 10;
   }
   & .swiper-button-next-custom {
      right: var(--container-offset);
      transform: translateX(50%) translateY(-50%);
      &.swiper-button-disabled {
         opacity: 0;
         pointer-events: none;
      }
   }
   & .swiper-button-prev-custom {
      left: var(--container-offset);
      transform: translateX(-50%) translateY(-50%);
      &.swiper-button-disabled {
         opacity: 0;
         pointer-events: none;
      }
   }

   & .swiper-pagination {
      position: absolute;
      bottom: 10px;
      left: 22px;
      display: flex;
      gap: 6px;
      flex-wrap: wrap;
      justify-content: flex-start;
      width: 75%;
      @media (max-width: 1025px) {
         left: 18px;
         bottom: 12px;
      }
      & > * {
         display: block;
         width: 6px;
         height: 6px;
         border-radius: 50%;
         opacity: 1;
         background: rgba(255, 255, 255, 0.5);
         border-radius: 5px;
         transition: all 0.4s;
         margin: 0 !important;
         padding: 0;
         &:hover {
            background: rgba(255, 255, 255, 0.75);
         }
         &.swiper-pagination-bullet-active {
            background: var(--light-color);
            width: 12px;
         }
      }
   }
}
