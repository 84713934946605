.article {
   &__page {
      padding-bottom: 60px;
      @media (max-width: 769px) {
         padding-bottom: 40px;
      }
   }
   &__wrap {
      display: flex;
      gap: 60px;
      @media (max-width: 1441px) {
         gap: 40px;
      }
      @media (max-width: 769px) {
         flex-direction: column;
         gap: 0;
      }
   }
   &__menu {
      width: 340px;
      @media (max-width: 1441px) {
         width: 260px;
      }
      @media (max-width: 769px) {
         width: 100%;
      }
      &Title {
         margin: 0 0 20px;
         font-weight: 700;
         font-size: 20px;
         line-height: 1.5;
         color: var(--dark-color);
         @media (max-width: 769px) {
            display: none;
         }
      }
      &Wrap {
         display: flex;
         flex-direction: column;
         gap: 30px;
      }
      &Title2 {
         font-weight: 700;
         font-size: 14px;
         line-height: 1.5;
         margin: 0;
         color: var(--dark-color);
         @media (max-width: 1441px) {
            font-size: 12px;
         }
      }
      &Card {
         display: flex;
         flex-direction: column;
         gap: 15px;
      }
      &Item {
         display: flex;
         gap: 10px;
      }
      &Rating {
         font-weight: 700;
         font-size: 12px;
         line-height: 1.2;
         width: 40px;
         min-width: 40px;
         color: var(--red-color-hover-link);
         @media (max-width: 1441px) {
            font-size: 10px;
            width: 32px;
            min-width: 32px;
         }
         & span {
            font-weight: 700;
            font-size: 16px;
            color: var(--red-color);
            @media (max-width: 1441px) {
               font-size: 14px;
            }
         }
      }
      &Link {
         font-weight: 400;
         font-size: 14px;
         line-height: 1.5;
         color: #97a5ad;
         transition: all 0.4s;
         @media (max-width: 1441px) {
            font-size: 12px;
         }
         &:hover {
            color: var(--dark-color);
         }
      }
   }
   &__content {
      width: calc(100% - 360px);
      @media (max-width: 1441px) {
         width: calc(100% - 300px);
      }
      @media (max-width: 769px) {
         width: 100%;
      }
   }
   &__article {
      max-width: 820px;
      @media (max-width: 1441px) {
         max-width: 677px;
      }
      @media (max-width: 769px) {
         max-width: initial;
         & h1 {
            display: none;
         }
      }
   }
   &__titleMob {
      display: none;
      margin: 0 0 20px;
      font-weight: 700;
      font-size: 24px;
      line-height: 1.2;
      color: var(--dark-color);
      @media (max-width: 769px) {
         display: block;
      }
   }
   &__rating {
      &Wrap {
         display: flex;
         gap: 40px;
         flex-direction: column;
         margin-top: 40px;
         @media (max-width: 1025px) {
            margin-top: 35px;
         }
      }
      &Card {
         display: flex;
         gap: 20px;
         align-items: flex-start;
         @media (max-width: 1025px) {
            flex-direction: column;
            gap: 40px;
         }
         &:not(:first-child) {
            padding-top: 30px;
            border-top: 1px solid var(--dark-gray-color3-hover-btn);
         }
      }
      &Product {
         width: 180px;
         padding: 10px;
         @media (max-width: 1025px) {
            width: 100%;
            display: grid;
            grid-template-columns: 160px auto;
            column-gap: 30px;
            row-gap: 6px;
            grid-template-rows: auto auto 1fr;
            grid-template-areas:
               "a b"
               "a c"
               "a d";
            & .productCard__item1 {
               grid-area: a;
               margin-bottom: 0;
               margin-bottom: 0;
            }
            & .productCard__item2 {
               grid-area: c;
               margin-bottom: 0;
            }
            & .productCard__item3 {
               grid-area: b;
               margin-bottom: 0;
               @media (max-width: 1025px) {
                  gap: 6px;
               }
            }
            & .productCard__item4 {
               grid-area: d;
               margin-top: auto;
               align-items: flex-start;
            }
         }
      }
      &Content {
         width: calc(100% - 200px);
         @media (max-width: 1025px) {
            width: 100%;
            display: flex;
            flex-direction: column-reverse;
            gap: 40px;
         }
         @media (max-width: 769px) {
            gap: 0;
         }
      }
      &Head {
         display: flex;
         gap: 30px;
         margin-bottom: 30px;
         align-items: center;
         @media (max-width: 1025px) {
            margin-bottom: 0;
         }
         @media (max-width: 769px) {
            margin-top: 40px;
         }
         &Raiting {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 85px;
            height: 85px;
            font-size: 24px;
            line-height: 1;
            color: var(--dark-color);
            background: var(--dark-gray-color3);
            border-radius: 50%;
            & span {
               color: var(--red-color);
               font-weight: 700;
            }
         }
         &Info {
            width: calc(100% - 115px);
         }
         &Name {
            font-weight: 400;
            font-size: 20px;
            line-height: 1.5;
            color: var(--red-color-hover-link);
            margin: 0;
            display: block;
            width: fit-content;
            transition: all 0.4s;
            &:hover {
               color: var(--red-color);
            }
         }
         &Descr {
            font-size: 15px;
            line-height: 1.5;
            color: var(--gray-color);
         }
      }
   }
}
