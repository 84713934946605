.filters {
   &--gap10 {
      display: flex;
      flex-direction: column;
      gap: 10px;
   }
   &--gap20 {
      display: flex;
      flex-direction: column;
      gap: 20px;
   }
   &__btn {
      display: flex;
      text-align:left;
      align-items: center;
      gap: 10px;
      width: 100%;
      justify-content: space-between;
      font-weight: 700;
      font-size: 16px;
      line-height: 1.2;
      color: var(--dark-color);
      background-color: transparent;
      border: none;
      padding: 0;
      min-height: 24px;
      cursor: pointer;
      transition: all 0.4s;
      & i {
         font-size: 24px;
         color: var(--gray-color);
         transition: all 0.5s;
      }
      &--active {
         & i {
            transform: scaleY(-1);
         }
      }
   }
   &__listCat {
      display: flex;
      flex-direction: column;
      gap: 12px;
       &Sub {
           display: none;
           margin-left:18px;
       }
   }
   &__content {
      margin-top: 12px;
      display: none;
      &--active {
         display: block;
      }
   }
   &__cat {
      display: flex;
      width: fit-content;
      align-items: center;
      gap: 10px;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.2;
      color: var(--dark-color);
      transition: all 0.4s;
      &:hover {
         color: var(--dark-color-hover-link);
      }
      &--active {
         font-weight: 700;
         &:hover {
            color: var(--dark-color);
         }
           + .filters__listCatSub {
               display: flex;
           }
      }
      &Icon {
         font-size: 12px;
         color: var(--red-color);
         display: block;
      }
   }
   &__fromTo {
      display: flex;
      gap: 5px;
      & > * {
         width: 50%;
      }
   }
   &__inpLabel {
      display: block;
      position: relative;
      margin-bottom: 12px;
      & input {
         display: block;
         width: 100%;
         padding: 11px 24px 11px 11px;
         border: 1px solid #e2e8ec;
         outline: none;
         border-radius: 5px;
         font-weight: 400;
         font-size: 14px;
         line-height: 1.2;
         color: var(--dark-color);
         transition: all 0.4s;
         &::placeholder {
            color: var(--gray-color);
         }
         &:focus {
            border-color: var(--gray-color);
         }
      }
   }
   &__inpReset {
      position: absolute;
      right: 5.5px;
      cursor: pointer;
      top: 50%;
      transform: translateY(-50%);
      font-size: 16px;
      color: var(--dark-gray-color3-hover-btn);
      transition: all 0.4s;
      &:hover {
         color: var(--dark-color);
      }
   }
   &__inpSearch {
      position: absolute;
      right: 8px;
      cursor: pointer;
      top: 50%;
      transform: translateY(-50%);
      font-size: 24px;
      color: var(--dark-gray-color3-hover-btn);
      transition: all 0.4s;
      &:hover {
         color: var(--dark-color);
      }
   }
   &__letter {
      font-weight: 700;
      font-size: 10px;
      line-height: 1.2;
      color: var(--red-color);
      margin-bottom: -6px;
      text-transform: uppercase;
   }
   &--searching{
       + .filters__moreBtn {
           display: none;
       }
   }
   &__moreBtn {
      display: flex;
      width: fit-content;
      text-align: center;
      justify-content: center;
      align-items: center;
      padding: 0;
      margin: 20px 0 0;
      background-color: transparent;
      transition: all 0.4s;
      font-size: 14px;
      line-height: 1.2;
      color: var(--red-color);
      border: none;
      cursor: pointer;
      &:hover {
         color: var(--red-color-hover-link);
      }
   }
   &--maxElements {
      &:not(.filters--searching) > * {
         &:nth-child(n + 16) {
            display: none;
         }
      }
      &5 {
         &:not(.filters--searching) > * {
            &:nth-child(n + 6) {
               display: none;
            }
         }
      }
   }
   &--full {
      & > *:not(.filters__listCatSub) {
         display: flex !important;
      }
   }
}
