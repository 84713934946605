.buyers {
   &__page {
      padding-bottom: 60px;
      &--mobHidden {
         @media (max-width: 769px) {
            display: none;
         }
      }
      &--mob {
         display: none;
         @media (max-width: 769px) {
            display: block;
         }
      }
   }
   &__wrap {
      display: flex;
      gap: 60px;
      @media (max-width: 1025px) {
         gap: 30px;
      }
   }
   &__menu {
      width: 300px;
      @media (max-width: 1441px) {
         width: 240px;
      }
      @media (max-width: 1025px) {
         width: 220px;
      }
      @media (max-width: 769px) {
         display: none;
      }
   }
   &__content {
      width: calc(100% - 360px);
      @media (max-width: 1441px) {
         width: calc(100% - 300px);
      }
      @media (max-width: 1025px) {
         width: calc(100% - 250px);
      }
      @media (max-width: 769px) {
         width: 100%;
      }
   }
   &__filters {
      margin-bottom: 25px;
      @media (max-width: 769px) {
         margin-bottom: 20px;
      }
   }
   &__prepayment {
      max-width: 700px;
      color: #97a5ad;
   }
   &__formPrepayment {
      margin-top: 20px;
      @media (max-width: 769px) {
         margin-top: 0;
      }
      &--mt30 {
         @media (max-width: 769px) {
            margin-top: 30px;
         }
      }
   }
   &__deliveryText {
      max-width: 780px;
   }
   &__deliveryBlock {
      margin: 50px 0 40px;
      @media (max-width: 769px) {
         margin: 20px 0;
      }
   }
   &__deliveryTitle {
      font-weight: 700;
      font-size: 20px;
      line-height: 1.5;
      color: var(--dark-color);
      margin: 0 0 27px;
      @media (max-width: 769px) {
         font-size: 18px;
         margin-bottom: 20px;
         line-height: 1.3;
      }
   }
   &__deliveryBlockWrap {
      display: flex;
      gap: 17px;
   }
   &__deliveryBlockLeft {
      display: flex;
      flex-direction: column;
      gap: 15px;
      font-size: 12px;
      line-height: 1.7;
      color: #97a5ad;
      width: 90px;
      @media (max-width: 769px) {
         display: none;
      }
      & span {
         font-size: 14px;
         font-weight: 700;
         color: var(--dark-color);
      }
   }
   &__deliveryBlockCards {
      display: flex;
      gap: 12px;
      width: calc(100% - 107px);
      flex-wrap: wrap;
      @media (max-width: 769px) {
         width: 100%;
      }
   }
   &__deliveryBlockCard {
      background: #f2f5f7;
      border-radius: 5px;
      padding: 40px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      flex-direction: column;
      @media (max-width: 769px) {
         width: calc(50% - 6px);
      }
   }
   &__deliveryBlockCardPrice {
      font-weight: 700;
      font-size: 20px;
      line-height: 1.5;
   }
   &__deliveryBlockCardDescr {
      font-size: 12px;
      line-height: 1.7;
      color: #97a5ad;
      text-align: center;
   }
   &__deliverySubtitleMob {
      display: none;
      margin-bottom: 12px;
      font-weight: 700;
      font-size: 14px;
      line-height: 1.7;
      @media (max-width: 769px) {
         display: block;
      }
   }
   &__700 {
      max-width: 700px;
      color: #97a5ad;
   }
   &__opt {
      margin-bottom: 40px;
      &Wrap {
         display: flex;
         gap: 20px;
         margin-bottom: 20px;
      }
      &Icon {
         font-size: 32px;
         color: var(--red-color);
      }
      &Title {
         font-weight: 400;
         font-size: 20px;
         line-height: 1.5;
         color: var(--dark-color);
      }
      &Content {
         width: 100%;
         padding-left: 52px;
         @media (max-width: 769px) {
            padding-left: 0;
         }
      }
      &Card {
         display: flex;
         gap: 20px;
      }
      &Type {
         width: 180px;
         font-size: 16px;
         line-height: 1.5;
         color: #97a5ad;
      }
      &Link {
         font-size: 16px;
         line-height: 1.5;
         color: var(--dark-color);
         transition: all 0.4s;
         &:hover {
            color: var(--dark-color-hover-link);
         }
         &--no {
            &:hover {
               color: var(--dark-color);
            }
         }
      }
      &Br {
         display: block;
         width: 100%;
         height: 1px;
         margin: 20px 0;
         background: #e2e8ec;
      }
   }
}

.prepaymentForm {
   display: flex;
   flex-direction: column;
   gap: 20px;
   &__wrap {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      & > * {
         width: calc(50% - 10px);
         @media (max-width: 769px) {
            width: 100%;
         }
      }
   }
   &__wrap2 {
      display: flex;
      gap: 12px 35px;
      flex-wrap: wrap;
      align-items: center;
      line-height: 1.4;
      font-size: 12px;
      @media (max-width: 769px) {
         text-align: center;
         flex-direction: column;
         align-items: center;
      }
   }
   &__textarea {
      height: 160px;
   }
   &__subm {
      width: 250px;
      @media (max-width: 769px) {
         width: 100%;
      }
   }
}
