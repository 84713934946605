.rating {
   position: relative;
   width: fit-content;
   &--grade {
      & .rating__star {
         cursor: pointer;
      }
   }
   &__empty,
   &__filled {
      display: flex;
      gap: 5.7px;
      @media (max-width: 769px) {
         gap: 4px;
      }
      &--bigAdaptive {
         gap: 6.9px;
      }
      &--gapBig {
         gap: 9px;
      }
      &--gapExtra {
         gap: 12px;
      }
   }
   &__star {
      display: block;
      line-height: 1;
      color: var(--dark-gray-color3-hover-btn);
      font-size: 16.8px;
      @media (max-width: 769px) {
         font-size: 12.6px;
      }
      &--adaptive {
         font-size: 16.8px;
      }
      &--bigAdaptive {
         color: #e2e8ec;
         @media (max-width: 769px) {
            font-size: 20.2px;
         }
      }
      &--filled {
         color: #fec32b;
      }
      &--superBig {
         font-size: 24px;
      }
      &--superBig2 {
         font-size: 32px;
      }
      &--min {
         font-size: 12.6px;
      }
   }
   &__filled {
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      z-index: 2;
   }
}
