.banners {
   &__section {
      margin: 30px 0 80px;
      @media (max-width: 1441px) {
         margin-bottom: 40px;
      }
      @media (max-width: 1201px) {
         margin-bottom: 30px;
      }
      @media (max-width: 769px) {
         margin-top: 20px;
         margin-bottom: 20px;
      }
   }
   &__wrap {
      display: flex;
      gap: 20px;
      flex-direction: column;
   }
   &__banner {
      display: block;
      border-radius: 12px;
      overflow: hidden;
      position: relative;
      & img {
         display: block;
         width: 100%;

          @media (max-width: 767px) {
            height: 56vw;
            object-fit: cover;
          }

      }
   }
   &__btnVideo {
      position: absolute;
      top: 50%;
      right: 60px;
      transform: translateY(-50%);
      font-size: 20px;
      font-weight: 700;
      line-height: 1;
      color: var(--light-color);
      display: flex;
      align-items: center;
      gap: 15px;
      text-align: left;
      @media (max-width: 1201px) {
         gap: 12px;
         font-size: 18px;
         right: 40px;
      }
      @media (max-width: 769px) {
         gap: 10px;
         font-size: 12px;
         right: initial;
         bottom: 20px;
         left: 20px;
         top: initial;
         transform: none;
      }
   }
   &__btnVideoIcon {
      font-size: 77px;
      @media (max-width: 1201px) {
         font-size: 58px;
      }
      @media (max-width: 1201px) {
         font-size: 42px;
      }
   }
}
