.static_page{
    &--simple{
        min-height: 400px;
    }
}


.qr_sbp_wrap {
    display: flex;
    align-items: center;
    max-width: 600px;
    border: 2px solid #2dd26e;
    border-radius: 10px;
    margin-top: 30px;
    @media (max-width: 500px) {
        flex-direction: column-reverse;
        border: none;
        align-items: flex-start;
        margin: 0 -20px;
    }
    .qr_sbp {
        width: 204px;
        padding: 2px;
        @media (max-width: 500px) {
            margin: 0 auto;
        }
    }
    .qr_sbp_text {
        width: calc(100% - 204px);
        font-size: 18px;
        padding: 20px;
        span {
            font-size: 30px;
            font-weight: 600;
        }
        @media (max-width: 500px) {
            width: auto;
        }
    }
}
