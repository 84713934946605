.benefits {
   &__section {
      margin: 80px 0;
      @media (max-width: 1441px) {
         margin: 60px 0;
      }
      @media (max-width: 1201px) {
         margin: 45px 0;
      }
      @media (max-width: 769px) {
         margin: 30px 0;
      }
      &--mMin {
         margin: 60px 0;
         @media (max-width: 769px) {
            margin: 50px 0;
         }
      }
   }
   &__wrap {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      @media (max-width: 769px) {
         gap: 9px;
      }
   }
   &__item {
      width: calc(25% - 15px);
      background: #f2f5f7;
      border-radius: 9px;
      padding: 17px 20px;
      display: flex;
      align-items: center;
      gap: 10px 20px;
      font-size: 17px;
      line-height: 1.15;
      @media (max-width: 1441px) {
         font-size: 14px;
      }
      @media (max-width: 1025px) {
         width: calc(100% / 3 - 40px / 3);
      }
      @media (max-width: 769px) {
         width: calc(100% / 3 - 6px);
         flex-direction: column;
         font-size: 11px;
         padding: 10px;
         text-align: center;
      }
   }
   &__icon {
      color: var(--red-color);
      font-size: 42px;
      @media (max-width: 1441px) {
         font-size: 32px;
      }
      @media (max-width: 769px) {
         font-size: 40px;
      }
   }
   &__name {
      @media (max-width: 769px) {
         display: flex;
         align-items: center;
         flex: 1 1 auto;
      }
   }
}
