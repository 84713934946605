.richText {
   & > *:first-child {
      margin-top: 0 !important;
   }
   & > *:last-child {
      margin-bottom: 0 !important;
   }
   & ul {
      padding-left: 25px;
      list-style: none;
      & li {
         position: relative;
         &::after {
            content: "";
            display: block;
            position: absolute;
            width: 3px;
            height: 3px;
            background-color: currentColor;
            top: 10px;
            left: -11px;
            border-radius: 50%;
         }
      }
   }
   & p {
      & + h2 {
         margin-top: 23px;
      }
      & + h3 {
         margin-top: 20px;
      }
      & a {
         color: var(--blue-color);
         transition: all 0.4s;
         &:hover {
            color: var(--blue-color-hover-link);
         }
      }
   }
   & h1 {
      font-weight: 700;
      font-size: 32px;
      line-height: 1.25;
      color: var(--dark-color);
      margin: 0 0 36px;
      @media (max-width: 1025px) {
         font-size: 28px;
         margin-bottom: 28px;
      }
      @media (max-width: 769px) {
         font-size: 24px;
         margin-bottom: 20px;
      }
   }
   & h2 {
      font-weight: 700;
      font-size: 24px;
      line-height: 1.25;
      color: var(--dark-color);
      + p {
         margin-top: 37px;
      }
      & + h3 {
         margin-top: 28px;
      }
   }
   & h3 {
      font-weight: 700;
      font-size: 20px;
      line-height: 1.25;
      color: var(--dark-color);
      + p {
         margin-top: 32px;
      }
   }
   & img {
      display: block;
      width: 100%;
      margin: 30px 0;
      @media (max-width: 769px) {
         margin: 45px 0;
      }
   }
   &--bigText {
      font-size: 16px;
      & ul {
         & li {
            &::after {
               top: 13px;
            }
         }
      }
   }
}
