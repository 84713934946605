.contacts {
   &__wrap {
      display: flex;
      justify-content: space-between;
      gap: 120px;
      @media (max-width: 1025px) {
         gap: 40px;
      }
      @media (max-width: 769px) {
         flex-direction: column;
      }
   }
   &__main {
      width: calc(100% - 500px);
      max-width: 680px;
      @media (max-width: 1025px) {
         width: calc(100% - 340px);
      }
      @media (max-width: 769px) {
         width: 100%;
      }
   }
   &__min {
      width: 380px;
      @media (max-width: 1025px) {
         width: 300px;
      }
      @media (max-width: 769px) {
         width: 100%;
      }
   }
   &__titleH3 {
      font-weight: 700;
      font-size: 20px;
      line-height: 1.5;
      margin: 0 0 20px;
      &--mbMin {
         margin-bottom: 10px;
      }
   }
   &__subtitle {
      font-size: 16px;
      line-height: 1.5;
      color: var(--gray-color);
      margin: 0 0 20px;
      &:last-child {
         margin-bottom: 0;
      }
   }
   &__mainWrap {
      display: flex;
      gap: 20px 15px;
      flex-wrap: wrap;
      @media (max-width: 769px) {
         gap: 15px;
      }
      &--mb {
         margin-bottom: 40px;
      }
   }
   &__wrapBlock {
      display: flex;
      gap: 20px;
      width: calc(50% - 7.5px);
      @media (max-width: 501px) {
         width: 100%;
      }
   }
   &__wrapIcon {
      color: var(--red-color);
      font-size: 32px;
   }
   &__wrapContent {
      display: flex;
      flex-direction: column;
      margin-top: 4px;
   }
   &__link {
      font-size: 16px;
      line-height: 1.5;
      color: var(--dark-color);
      transition: all 0.4s;
      width: fit-content;
      &:hover {
         color: var(--dark-color-hover-link);
      }
   }
   &__text {
      font-size: 16px;
      line-height: 1.5;
      color: var(--dark-color);
   }
   &__descr {
      font-weight: 400;
      font-size: 16px;
      line-height: 1.5;
      color: var(--gray-color);
   }
   &__recall {
      margin-bottom: 60px;
      @media (max-width: 769px) {
         margin-bottom: 40px;
      }
   }
   &__socWrap {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      margin: 36px 0 32px;
   }
   &__soc {
      font-size: 48px;
   }
   &__br {
      display: block;
      width: 100%;
      height: 1px;
      margin: 60px 0;
      background: #e2e8ec;
      @media (max-width: 1025px) {
         margin: 50px 0;
      }
      @media (max-width: 769px) {
         margin: 40px 0;
      }
   }
   &__minText {
      font-size: 16px;
      line-height: 1.7;
      & p {
         margin: 28px 0 !important;
      }
   }
   &__wrapinfBlWrap {
      display: flex;
      gap: 15px;
      flex-direction: column;
      &--mb {
         margin-bottom: 40px;
      }
   }
   &__wrapinfBl {
      display: flex;
      gap: 20px;
      align-items: center;
      font-size: 16px;
      line-height: 1.5;
      &Icon {
         color: var(--red-color);
         font-size: 32px;
      }
   }
}

.contactsMap {
   &__map {
      & iframe {
         display: block;
         width: 100% !important;
         height: 540px !important;
         pointer-events: none;
         @media (max-width: 769px) {
            height: 380px !important;
         }
      }
   }
    &__map + &__img {
        margin-top: 20px;
    }
}

.wrapMap {
   position: relative;
   cursor: help;
   overflow: hidden;
}
.mapTitle {
   position: absolute;
   z-index: 100;
   box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 5px;
   display: none;
   padding: 5px 20px;
   border-radius: 5px;
   background: rgb(255, 255, 255);
   border-width: 1px;
   border-style: solid;
   border-color: rgb(204, 204, 204);
   border-image: initial;
}

.contactsBackCall {
   &--mobHidden {
      @media (max-width: 769px) {
         display: none;
      }
   }
   &__wrap {
      display: flex;
      gap: 60px;
   }
   &__menu {
      width: 300px;
   }
   &__main {
      width: 100%;
      max-width: 700px;
   }
   &__card {
      display: none;
      &--active {
         display: block;
      }
   }
}
