.mainSlider {
   overflow: hidden;
   &__section {
      margin-bottom: 30px;
      @media (max-width: 769px) {
         margin-bottom: 20px;
      }
   }
   &__slide {
      display: block;
      width: 980px;
      border-radius: 10px;
      overflow: hidden;
      @media (max-width: 1201px) {
         width: 880px;
      }
      @media (max-width: 1025px) {
         width: 100%;
         border-radius: 0;
      }
      & img {
         display: block;
         object-fit: cover;
         width: 100%;
      }
   }
   &__nav {
      @media (max-width: 1201px) {
         &.swiper-button-next-custom {
            transform: translateX(-50%) translateY(-50%);
         }
         &.swiper-button-prev-custom {
            transform: translateX(50%) translateY(-50%);
         }
      }
      @media (max-width: 1025px) {
         display: none;
      }
   }
}
