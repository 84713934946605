.inputDecor {
   position: relative;
   display: block;
   width: 100%;
   &--full {
      height: 100%;
   }
   &--req {
      &::after {
         content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguNzUgNS40MTY2N0g2TDcuOTU4MzMgNy4zNzVMNy4zNzUgNy45NTgzM0w1LjQxNjY3IDZWOC43NUg0LjU4MzMzVjUuOTU4MzNMMi42MjUgNy45MTY2N0wyLjA0MTY3IDcuMzMzMzNMMy45MTY2NyA1LjQxNjY3SDEuMjVWNC41ODMzM0g0TDIuMDQxNjcgMi42MjVMMi42MjUgMi4wNDE2N0w0LjU4MzMzIDRWMS4yNUg1LjQxNjY3VjMuOTE2NjdMNy4zMzMzMyAyTDcuOTE2NjcgMi42MjVMNS45NTgzMyA0LjU4MzMzSDguNzVWNS40MTY2N1oiIGZpbGw9IiNFRDAwMDAiLz4KPC9zdmc+Cg==);
         display: block;
         position: absolute;
         top: 0;
         right: 4px;
         display: block;
         width: 10px;
         height: 10px;
      }
   }
   &.err {
      & input,
      & textarea {
         border-color: var(--red-color);
      }
      & + .inputDecor__error {
         display: block;
      }
   }
   & input,
   & textarea {
      background: transparent;
      border: 1px solid var(--dark-gray-color3-hover-btn);
      border-radius: 5px;
      padding: 31px 18px 12px;
      display: block;
      width: 100%;
      outline: none;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.4;
      color: #2b2b2b;
      transition: all 0.4s;
      &:hover,
      &:focus {
         border-color: var(--gray-color);
      }
      &:focus,
      &.act {
         & ~ span {
            top: 20px;
            font-size: 13px;
         }
      }
      &::placeholder {
         font-weight: 400;
         font-size: 16px;
         line-height: 1.5;
         color: var(--gray-color);
      }
   }
   & span {
      font-weight: 400;
      font-size: 16px;
      line-height: 1.5;
      color: var(--gray-color);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.5s;
      left: 18px;
      pointer-events: none;
   }
   & textarea {
      height: 100%;
      resize: none;
      padding-top: 15px;
   }
   &__error {
      display: none;
      font-weight: 400;
      font-size: 11px;
      line-height: 1.3;
      color: var(--red-color);
      margin-top: 5px;
   }
}

.checkbox {
   cursor: pointer;
   &__wr {
      display: flex;
      gap: 14px;
   }
   &__text {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.7;
      color: var(--dark-color);
      & a {
         color: var(--blue-color);
         transition: all 0.4s;
         &:hover {
            color: var(--blue-color-hover-link);
         }
      }
   }
   &:hover {
      & input {
         & + span {
            border-color: #c9c9c9;
         }
      }
   }
   &__textErr {
      display: none;
   }
   & input {
      display: none;
      &.err {
         & ~ .checkbox__textErr {
            display: block;
            color: #c8180f;
            font-weight: 400;
            font-size: 14px;
            line-height: 1.5;
            margin: 0;
         }
      }
      & + span {
         display: inline-block;
         width: 16px;
         min-width: 16px;
         height: 16px;
         border: 2px solid var(--dark-gray-color3-hover-btn);
         border-radius: 3px;
         transition: all 0.4s;
         position: relative;
         margin-top: 3px;
         &::after {
            content: "";
            background: url("../img/ch.svg");
            background-repeat: no-repeat;
            display: none;
            width: 9px;
            height: 6px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
         }
      }
      &:checked {
         & + span {
            background: var(--red-color);
            border-color: var(--red-color);
            &::after {
               display: block;
            }
         }
      }
      &:disabled {
         & + span {
            background: #e5e5e5;
            border-color: #e5e5e5;
         }
         & ~ span {
            color: var(--gray-color);
         }
      }
   }
   &--mobBig {
      & input {
         & + span {
            @media (max-width: 769px) {
               width: 24px;
               min-width: 24px;
               height: 24px;
               &::after {
                  width: 12.5px;
                  height: 9px;
                  background-size: 12.5px;
               }
            }
         }
      }
   }
   &--circle {
      display: flex;
      gap: 10px;
      align-items: center;
      font-size: 14px;
      line-height: 1.2;
      & input {
         & + span {
            width: 24px;
            min-width: 24px;
            height: 24px;
            border-radius: 50%;
            vertical-align: initial;
            display: block;
            margin: 0;
            background: transparent;
            &::after {
               width: 0;
               height: 0;
               border-radius: 50%;
               transition: all 0.4s;
               content: "";
               background: transparent;
               display: block;
            }
         }
         &:checked {
            & + span {
               background: transparent;
               border-color: var(--dark-gray-color3-hover-btn);
               &::after {
                  width: 12px;
                  height: 12px;
                  background: var(--red-color);
               }
            }
         }
      }
   }
   &--circleMin {
      line-height: 1.5;
      & input {
         & + span {
            width: 20px;
            min-width: 20px;
            height: 20px;
            &::after {
               width: 10px !important;
               height: 10px !important;
            }
         }
      }
   }
   &--customPay {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 20px;
      position: relative;
      & input {
         & ~ span.decor {
            &::before {
               content: "";
               display: block;
               width: 100%;
               height: 100%;
               border: 2px solid var(--dark-gray-color3);
               border-radius: 5px;
               position: absolute;
               top: 0;
               transition: all 0.4s;
               left: 0;
            }
         }
         &:checked {
            & ~ span.decor {
               &::before {
                  border-color: var(--red-color) !important;
               }
            }
         }
      }
      &:hover {
         & input {
            & ~ span.decor {
               &::before {
                  border-color: var(--dark-gray-color3-hover-btn);
               }
            }
         }
      }
   }
   &--pr {
      padding-right: 55px;
   }
   &__textFl {
      display: flex;
      flex-direction: column;
      gap: 5px;
      &1 {
         font-weight: 400;
         font-size: 14px;
         line-height: 1.2;
         color: var(--dark-color);
      }
      &2 {
         font-weight: 400;
         font-size: 12px;
         line-height: 1.2;
         color: var(--gray-color);
      }
   }
}

.checkboxFilter {
   cursor: pointer;
   display: flex;
   gap: 10px;
   align-items: center;
   &:hover {
      & input {
         & + span {
            border-color: #c9c9c9;
         }
      }
   }
   & i {
      font-size: 24px;
      color: var(--dark-gray-color3-hover-btn);
      transition: all 0.4s;
      &.big {
         font-size: 32px;
      }
      &.color {
         color: var(--red-color);
      }
   }
   &__text {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.3;
      color: var(--dark-color);
      &--bold {
         font-weight: 700;
      }
      &--big {
         font-size: 16px;
      }
      & a {
         color: var(--blue-color);
         transition: all 0.4s;
         &:hover {
            color: var(--blue-color-hover-link);
         }
      }
   }
   & input {
      display: none;
      &.err {
         & ~ .checkbox__textErr {
            display: block;
            color: #c8180f;
            font-weight: 400;
            font-size: 14px;
            line-height: 1.5;
            margin: 0;
         }
      }
      & + span {
         display: inline-block;
         width: 20px;
         min-width: 20px;
         height: 20px;
         border: 2px solid var(--dark-gray-color3-hover-btn);
         border-radius: 3px;
         transition: all 0.4s;
         position: relative;
         @media (max-width: 769px) {
            width: 24px;
            height: 24px;
         }
         &::after {
            content: "";
            background: url("../img/ch.svg");
            background-repeat: no-repeat;
            display: none;
            background-size: 10.3px;
            width: 10.3px;
            height: 7.5px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            @media (max-width: 769px) {
               width: 12.4px;
               height: 9px;
               background-size: 12.4px;
            }
         }
      }
      &:checked {
         & ~ i {
            color: var(--red-color);
         }
         & + span {
            background: var(--red-color);
            border-color: var(--red-color);
            &::after {
               display: block;
            }
         }
      }
      &:disabled {
         & + span {
            background: #e5e5e5;
            border-color: #e5e5e5;
         }
         & ~ span {
            color: var(--gray-color);
         }
      }
   }
   &__textQuantity {
      font-size: 12px;
      line-height: 1.2;
      color: var(--gray-color);
      display: inline-block;
      margin-left: 5px;
   }
   &--custom {
      width: 100%;
      & input {
         & ~ span.custom {
            margin-left: auto;
            display: block;
            width: 54px;
            height: 30px;
            border-radius: 15px;
            border: none;
            background-color: var(--dark-gray-color3-hover-btn);
            padding: 3px;
            transition: all 0.4s;
            position: relative;
            &::after {
               content: "";
               display: block !important;
               background: var(--light-color);
               position: absolute;
               border-radius: 50%;
               width: 24px;
               height: 24px;
               left: 3px;
               top: 50%;
               opacity: 1 !important;
               transform: translateY(-50%);
               transition: all 0.4s;
            }
         }
         &:checked {
            & ~ span.custom {
               background-color: var(--red-color);
               &::after {
                  left: calc(100% - 27px);
               }
            }
         }
      }
   }
}

.selectSt {
   display: block;
   width: 100%;
   color: var(--dark-color);
   &__select {
      display: block;
      width: 100%;
      padding: 20px;
      border: 1px solid #e2e8ec;
      border-radius: 5px;
      font-size: 16px;
      line-height: 1.5;
      color: var(--dark-color);
      appearance: none;
      background: url("../img/svg/expand_more.svg") no-repeat right 16px center / 24px;
      &--grayText {
         color: var(--gray-color);
      }
   }
}
.fileLabel {
   display: block;
   width: 100%;
   padding: 30px;
   background: var(--dark-gray-color3);
   border-radius: 5px;
   transition: all 0.4s;
   cursor: pointer;
   &:hover {
      background-color: var(--dark-gray-color3-hover-btn);
   }
   & input {
      display: none;
   }
   & > span {
      display: flex;
      gap: 30px;
      align-items: center;
      font-size: 14px;
      line-height: 1.5;
      color: var(--dark-color);
      @media (max-width: 769px) {
         justify-content: center;
         & span {
            color: var(--dark-color) !important;
         }
      }
   }
   &__icon {
      font-size: 32px;
      color: #b8c0c5;
   }
   &--hidden {
      display: none !important;
   }
   &__list {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      @media (max-width: 769px) {
         gap: 12px;
         height: 100%;
      }
      &Item {
         display: block;
         width: 60px;
         height: 60px;
         border-radius: 5px;
         position: relative;
         @media (max-width: 769px) {
            width: 100px;
            height: 100px;
         }
         & img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 5px;
         }
         &Remove {
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            transition: all 0.4s;
            opacity: 0;
            transform: translate(50%, -50%);
            border-radius: 50%;
            @media (max-width: 769px) {
               transform: translate(25%, -25%);
               opacity: 1;
            }
            & svg {
               display: block;
            }
         }
         &:hover {
            & .fileLabel__listItemRemove {
               opacity: 1;
            }
         }
      }
   }
}

.labelEdit {
   display: flex;
   gap: 20px;
   align-items: center;
   justify-content: space-between;
   &:hover {
      & .labelEdit__btn {
         opacity: 1;
      }
   }
   &__name {
      font-size: 16px;
      line-height: 1.5;
      color: #97a5ad;
      width: 180px;
      @media (max-width: 769px) {
         width: fit-content;
      }
   }
   &__input {
      width: 205px;
      flex: 1 1 auto;
      display: block;
      outline: none;
      border: none;
      font-size: 16px;
      line-height: 1.5;
      @media (max-width: 769px) {
         width: fit-content;
         flex: initial initial initial;
         min-width: initial;
         &:not(:first-child) {
            text-align: right;
         }
      }
   }
   &__btn {
      opacity: 0;
      font-weight: 400;
      font-size: 13px;
      line-height: 1.5;
      text-align: right;
      white-space: nowrap;
      color: var(--gray-color);
      transition: all 0.4s;
      &:hover {
         color: var(--gray-color-hover-link);
      }
      @media (max-width: 769px) {
         display: none;
      }
   }
}
