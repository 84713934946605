#tempates, .elem-template{
    display: none !important;
}

.loader_spinner {
    min-height: 180px !important;
    position: relative !important;
    &:after {
        content:"";
        display: block;
        width: 42px;
        height: 42px;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -21px;
        margin-top: -21px;
        z-index: 10;
        transform-origin: 50%;
        animation: swiper-preloader-spin 1s infinite linear;
        box-sizing: border-box;
        border: 4px solid var(--swiper-preloader-color, var(--swiper-theme-color));
        border-radius: 50%;
        border-top-color: transparent;
    }
}

.showModalReview {
    cursor:pointer;
}
.is_select_dop {
    display: none;
}


.richText {
    &__more > div {
        max-height: 140px;
        position: relative;
        overflow: hidden;
        &:after {
            display: block;
            content: "";
            position: absolute;
            bottom:0;
            left:0;
            right:0;
            height: 40px;
            background: linear-gradient(to top, #fff, transparent);
        }
    }
    &__showMore {
        font-size: 15px;
        margin-top:20px;
        display: flex;
        align-items: center;
    }
}
