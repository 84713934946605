.breadCrumbs {
   padding: 19px 0;
   &--bg {
      background-color: #f2f5f7;
   }
   &--mobHid {
      @media (max-width: 769px) {
         display: none;
      }
   }
   @media (max-width: 1201px) {
      padding: 17px 0;
   }
   @media (max-width: 769px) {
      padding: 15px 0 8px;
   }
   &__wrap {
      line-height: 1;
   }
   & a {
      font-size: 13px;
      line-height: 1.15;
      color: var(--gray-color);
      position: relative;
      transition: all 0.4s;
      @media (max-width: 1201px) {
         font-size: 12px;
      }
      @media (max-width: 769px) {
         font-size: 10px;
      }
      &::after {
         content: " —";
         display: inline-block;
         margin-left: 4px;
         color: var(--gray-color) !important;
      }
      &:hover {
         color: var(--gray-color-hover-lin);
      }
   }
   & span {
      font-size: 13px;
      line-height: 1.15;
      color: var(--gray-color);
      position: relative;
      transition: all 0.4s;
      @media (max-width: 1201px) {
         font-size: 12px;
      }
      @media (max-width: 769px) {
         font-size: 10px;
      }
   }
}
