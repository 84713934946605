.mobScroll {
   @media (max-width: 769px) {
      width: calc(100% + var(--container-offset) * 2);
      overflow: auto;
      padding-left: var(--container-offset);
      padding-right: var(--container-offset);
      margin-left: calc(-1 * var(--container-offset));
      margin-right: calc(-1 * var(--container-offset));
   }
}
