// @include font-face("Muller", "../fonts/MullerRegular", 400, normal);
// @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@800&family=Roboto:wght@400;700&display=swap");

@font-face {
    font-family: 'iconsvg';
    src:  url('../fonts/iconsvg.eot?ae5994');
    src:  url('../fonts/iconsvg.eot?ae5994#iefix') format('embedded-opentype'),
    url('../fonts/iconsvg.ttf?ae5994') format('truetype'),
    url('../fonts/iconsvg.woff?ae5994') format('woff'),
    url('../fonts/iconsvg.svg?ae5994#iconsvg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
   /* use !important to prevent issues with browser extensions that change fonts */
   font-family: "iconsvg" !important;
   speak: never;
   font-style: normal;
   font-weight: normal;
   font-variant: normal;
   text-transform: none;
   line-height: 1;

   /* Better Font Rendering =========== */
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

.icon-vk-new:before {
   content: "\e96d";
}
.icon-add_a_photo:before {
   content: "\e96c";
}
.icon-lock:before {
   content: "\e96b";
}
.icon-free:before {
   content: "\e962";
}
.icon-Axors .path1:before {
   content: "\e955";
   color: rgb(237, 0, 0);
}
.icon-Axors .path2:before {
   content: "\e95a";
   margin-left: -1.5em;
   color: rgb(255, 255, 255);
}
.icon-avatar:before {
   content: "\e94f";
}
.icon-close2:before {
   content: "\e94d";
}
.icon-fav2:before {
   content: "\e94e";
}
.icon-view_list:before {
   content: "\e94b";
}
.icon-view_module:before {
   content: "\e94c";
}
.icon-icon-chevron_rightNP:before {
   content: "\e94a";
}
.icon-telegram .path1:before {
   content: "\e925";
   color: #22a6dc;
}
.icon-telegram .path1.no-color:before{
    color:unset;
}
.icon-telegram .path2:before {
   content: "\e940";
   margin-left: -1.5em;
   color: rgb(255, 255, 255);
}
.icon-telegram .path3:before {
   content: "\e941";
   margin-left: -1.5em;
   color: rgb(210, 228, 240);
}
.icon-telegram .path4:before {
   content: "\e942";
   margin-left: -1.5em;
   color: rgb(181, 207, 228);
}
.icon-viber:before {
   content: "\e944";
   color: #7f4da0;
}
.icon-whatsapp:before {
   content: "\e949";
   color: #67c15e;
}
.icon-basket:before {
   content: "\e924";
}
.icon-fav:before {
   content: "\e923";
}
.icon-notes:before {
   content: "\e91c";
}
.icon-map_pin:before {
   content: "\e919";
}
.icon-play_video .path1:before {
   content: "\e917";
   color: rgb(255, 255, 255);
   transform: translateX(100%);
}
.icon-play_video .path2:before {
   content: "\e918";
   margin-left: -1.5em;
   color: rgb(237, 0, 0);
}
.icon-yandex:before {
   content: "\e909";
}
.icon-account_balance_wallet:before {
   content: "\e900";
}
.icon-account_circle:before {
   content: "\e901";
}
.icon-add:before {
   content: "\e902";
}
.icon-arrow_back:before {
   content: "\e903";
}
.icon-arrow-forward:before {
   content: "\e904";
}
.icon-article:before {
   content: "\e905";
}
.icon-attach_file:before {
   content: "\e906";
}
.icon-axor:before {
   content: "\e907";
}
.icon-axor1:before {
   content: "\e908";
}
.icon-axor2:before {
   content: "\e90a";
}
.icon-border_color:before {
   content: "\e90b";
}
.icon-brand:before {
   content: "\e90c";
}
.icon-brightness-percent:before {
   content: "\e90d";
}
.icon-call:before {
   content: "\e90e";
}
.icon-chat:before {
   content: "\e90f";
}
.icon-check-decagram:before {
   content: "\e910";
}
.icon-chevron_left:before {
   content: "\e911";
}
.icon-chevron_right:before {
   content: "\e912";
}
.icon-close:before {
   content: "\e913";
}
.icon-construction:before {
   content: "\e914";
}
.icon-contacts:before {
   content: "\e915";
}
.icon-content_copy:before {
   content: "\e916";
}
.icon-delete:before {
   content: "\e91a";
}
.icon-Delivery:before {
   content: "\e91b";
}
.icon-dolly:before {
   content: "\e91d";
}
.icon-email:before {
   content: "\e91e";
}
.icon-equalizer:before {
   content: "\e91f";
}
.icon-error:before {
   content: "\e920";
}
.icon-expand_less:before {
   content: "\e921";
}
.icon-expand_more:before {
   content: "\e922";
}
.icon-file_download:before {
   content: "\e926";
}
.icon-file-document:before {
   content: "\e927";
}
.icon-file-excel:before {
   content: "\e928";
}
.icon-file-word:before {
   content: "\e929";
}
.icon-filter_list:before {
   content: "\e92a";
}
.icon-fire:before {
   content: "\e92b";
}
.icon-handshake:before {
   content: "\e92c";
}
.icon-help:before {
   content: "\e92d";
}
.icon-home:before {
   content: "\e92e";
}
.icon-info:before {
   content: "\e92f";
}
.icon-info_outline:before {
   content: "\e930";
}
.icon-local_shipping:before {
   content: "\e931";
}
.icon-medal:before {
   content: "\e932";
}
.icon-near_me:before {
   content: "\e933";
}
.icon-odnoklassniki:before {
   content: "\e934";
}
.icon-Ok:before {
   content: "\e935";
}
.icon-package-variant-closed:before {
   content: "\e936";
}
.icon-palette-swatch-variant:before {
   content: "\e937";
}
.icon-palette-swatch-variant-1:before {
   content: "\e938";
}
.icon-pencil-ruler:before {
   content: "\e939";
}
.icon-pencil-ruler-1:before {
   content: "\e93a";
}
.icon-percent:before {
   content: "\e93b";
}
.icon-percent-circle:before {
   content: "\e93c";
}
.icon-percent-circle-outline:before {
   content: "\e93d";
}
.icon-phone:before {
   content: "\e93e";
}
.icon-place:before {
   content: "\e93f";
}
.icon-play_circle_filled-1:before {
   content: "\e943";
}
.icon-playlist_add:before {
   content: "\e945";
}
.icon-playlist_add_check:before {
   content: "\e946";
}
.icon-question_answer:before {
   content: "\e947";
}
.icon-remove:before {
   content: "\e948";
}
.icon-search:before {
   content: "\e950";
}
.icon-slideshow:before {
   content: "\e951";
}
.icon-star:before {
   content: "\e952";
}
.icon-stars:before {
   content: "\e953";
}
.icon-tag:before {
   content: "\e954";
}
.icon-thumb_down:before {
   content: "\e956";
}
.icon-thumb_up:before {
   content: "\e957";
}
.icon-time:before {
   content: "\e958";
}
.icon-toolbox-outline:before {
   content: "\e959";
}
.icon-truck-fast:before {
   content: "\e95b";
}
.icon-tune:before {
   content: "\e95c";
}
.icon-twitter:before {
   content: "\e95d";
}
.icon-verified_user:before {
   content: "\e95e";
}
.icon-video:before {
   content: "\e95f";
}
.icon-visibility:before {
   content: "\e960";
}
.icon-vk:before {
   content: "\e961";
}
.icon-vr:before {
   content: "\e963";
}
.icon-accessories:before {
   content: "\e964";
}
.icon-kitchen:before {
   content: "\e965";
}
.icon-furniture:before {
   content: "\e966";
}
.icon-tile:before {
   content: "\e967";
}
.icon-floor:before {
   content: "\e968";
}
.icon-sale:before {
   content: "\e969";
}
.icon-plumbing:before {
   content: "\e96a";
}
.icon-dzen .path1:before {
    content: "\e96e";
    /*color: #202022;*/
}
.icon-dzen .path2:before {
    content: "\e96f";
    color: #fff;
    margin-left: -1em;
}
.icon-rutube {
    display: inline-block;
    overflow: hidden;
}
.icon-rutube .path1:before {
    content: "\e970";
    color: #100943;
}
.icon-rutube .path1.no-color:before{
    color:unset;
}
.icon-rutube .path2:before {
    content: "\e971";
    color: #ed143b;
    margin-left: -1em;
}
.icon-rutube .path3:before {
    content: "\e972";
    color: #fff;
    margin-left: -1em;
}
