.designProject {
   &__card {
      width: 25%;
      @media (max-width: 1441px) {
         width: calc(100% / 3);
      }
      @media (max-width: 1025px) {
         width: 50%;
      }
      @media (max-width: 551px) {
         width: calc(100% + 24px);
         margin-left: -12px;
         margin-right: -12px;
      }
   }
}

.designProjectCard {
   padding: 20px;
   position: relative;
   display: flex;
   gap: 20px;
   flex-direction: column;
   @media (max-width: 551px) {
      padding: 12px;
   }
   &:hover {
      & .productCard__imgTabs {
         opacity: 1;
      }
   }
   &::after {
      content: "";
      display: block;
      position: absolute;
      top: -0.5px;
      left: -0.5px;
      width: calc(100% + 1px);
      height: calc(100% + 1px);
      border: 1px solid var(--dark-gray-color3-hover-btn);
      pointer-events: none;
   }
   &__img {
      display: block;
      width: 100%;
      aspect-ratio: 1/1;
      margin-bottom: 12px;
      & img {
         display: block;
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }
   &__link {
      margin-top: auto;
   }
   &__number {
      font-size: 14px;
      line-height: 1.3;
      color: var(--gray-color);
      margin-bottom: 6px;
   }
   &__name {
      transition: all 0.4s;
      font-weight: 700;
      font-size: 14px;
      line-height: 1.3;
      color: var(--dark-color);
      margin-bottom: 10px;
      display: inline-block;
      &:hover {
         color: var(--dark-color-hover-link);
      }
   }
   &__inform {
      font-size: 14px;
      line-height: 1.2;
      display: flex;
      flex-direction: column;
      gap: 6px;
      & span {
         color: var(--gray-color);
      }
   }
}
