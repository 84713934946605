.tabsBtn {
   display: flex;
   gap: 4px;
   flex-wrap: wrap;
   @media (max-width: 769px) {
      flex-wrap: nowrap;
      width: fit-content;
   }
   &__tab {
      display: flex;
      gap: 6px;
      align-items: center;
      padding: 8px 14px;
      font-size: 14px;
      background: var(--dark-gray-color3);
      border-radius: 5px;
      border: none;
      cursor: pointer;
      transition: all 0.4s;
      white-space: nowrap;
      color: var(--dark-color);
      &:hover {
         background: var(--dark-gray-color3-hover-btn);
      }
      &--active {
         background: var(--red-color-hover-link) !important;
         color: var(--light-color);
      }
      &--big {
         line-height: 1.43;
      }
   }
}
.tabsContent {
   &__card {
      display: none;
      &--active {
         display: block;
      }
   }
}
