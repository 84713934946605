.catalogCollection {
   &__tabs {
      display: flex;
      gap: 0;
      flex-wrap: wrap;
      margin-bottom: 30px;
      &--pc {
         @media (max-width: 769px) {
            display: none;
         }
      }
      &--mob {
         display: none;
         margin-top: 13px;
         margin-bottom: 13px;
         @media (max-width: 769px) {
            display: flex;
         }
      }
   }
   &__tab {
      display: flex;
      gap: 6px;
      align-items: center;
      font-size: 24px;
      line-height: 0.95;
      color: var(--dark-color);
      padding: 8px 14px;
      border-bottom: 1px solid var(--dark-gray-color3);
      transition: all 0.4s;
      @media (max-width: 769px) {
         font-size: 20px;
         width: 50%;
         text-align: center;
         justify-content: center;
      }
      & span {
         color: var(--gray-color);
         font-weight: 400;
         @media (max-width: 769px) {
            display: none;
         }
      }
      &:hover {
         border-color: var(--red-color);
      }
      &--active {
         font-weight: 700;
         border-bottom: 2px solid var(--red-color);
      }
   }
   &__card {
      width: 25%;
      @media (max-width: 1441px) {
         width: calc(100% / 3);
      }
      @media (max-width: 1025px) {
         width: 50%;
      }
      @media (max-width: 551px) {
         width: calc(100% + 24px);
         margin-left: -12px;
         margin-right: -12px;
      }
   }
}
.catalogCollectionCard {
   padding: 20px;
   position: relative;
   display: flex;
   flex-direction: column;
   @media (max-width: 551px) {
      padding: 12px;
   }
   &::after {
      content: "";
      display: block;
      position: absolute;
      top: -0.5px;
      left: -0.5px;
      width: calc(100% + 1px);
      height: calc(100% + 1px);
      border: 1px solid var(--dark-gray-color3-hover-btn);
      pointer-events: none;
   }
   &:hover {
      & .productCard__btn {
         opacity: 1;
      }
   }
   &__img {
      display: block;
      width: 100%;
      align-items: 1/1;
      & img {
         display: block;
         width: 100%;
         height: 100%;
         object-fit: contain;
      }
   }
   &__imgWrap {
   }
   &__imgBig {
      position: relative;
   }
   &__country {
      left: 4px;
      bottom: 4px;
      @media (max-width: 769px) {
         left: 4px;
         right: initial;
         justify-content: flex-start;
         flex-direction: row;
      }
   }
   & .productCard__tegs {
      @media (max-width: 769px) {
         top: 4px;
         left: 4px;
      }
   }
   & .productCard__btns {
      @media (max-width: 769px) {
         top: 4px;
         right: 4px;
      }
   }
   &__sale {
      position: absolute;
      bottom: 4px;
      right: 4px;
      background: var(--red-color);
      border-radius: 3px;
      padding: 3px 8px;
      font-weight: 700;
      font-size: 12px;
      line-height: 1.2;
      color: var(--light-color);
   }
   &__imgs {
      height: 50px;
      margin: 5px -2px 0 -2px;
      overflow: hidden;
   }
   &__link {
      display: block;
      margin: 0 2px;
      float: left;
   }
   &__imgsCard {
      // flex: 1 1 100%;
      display: block;
      object-fit: cover;
      border-radius: 5px;
      height: 50px;
      // min-width: 35px;
      &--text {
         display: flex;
         align-items: center;
         justify-content: center;
         font-weight: 700;
         font-size: 12px;
         line-height: 1.3;
         color: var(--dark-color);
         background: var(--dark-gray-color3);
         float: right;
         height: 50px;
         width: 50px;
         margin-right: 2px;
      }
      &--border {
         border: 1px solid var(--dark-gray-color3-hover-btn);
      }
   }
   &__info {
      margin: 15px 0 25px;
      display: flex;
      flex-direction: column;
   }
   &__price {
      font-weight: 700;
      font-size: 18px;
      line-height: 1.2;
      color: var(--dark-color);
      margin-bottom: 9px;
   }
   &__name1 {
      margin-bottom: 2px;
      font-size: 14px;
      line-height: 1.3;
      color: var(--gray-color);
   }
   &__name2 {
      font-weight: 400;
      font-size: 16px;
      line-height: 1.3;
      color: var(--dark-color);
      transition: all 0.4s;
      &:hover {
         color: var(--dark-color-hover-link);
      }
   }
}
.catalogCollectionCardProduct {
   padding: 20px;
   position: relative;
   display: flex;
   flex-direction: column;
   gap: 20px;
   @media (max-width: 769px) {
      gap: 12px;
   }
   @media (max-width: 551px) {
      padding: 12px;
   }
   &::after {
      content: "";
      display: block;
      position: absolute;
      top: -0.5px;
      left: -0.5px;
      width: calc(100% + 1px);
      height: calc(100% + 1px);
      border: 1px solid var(--dark-gray-color3-hover-btn);
      pointer-events: none;
   }
   &:hover {
      & .productCard__imgTabs {
         opacity: 1;
      }
   }
   &__imgWrap {
      display: flex;
      flex-direction: column;
      gap: 7px;
   }
   &__imgLink {
      display: block;
      border-radius: 5px;
      aspect-ratio: 2/1;
      overflow: hidden;
      &--border {
         border: 1px solid #f2f5f7;
      }
      & img {
         display: block;
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }
   &__name {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.3;
      color: var(--dark-color);
      transition: all 0.4s;
      margin-bottom: 10px;
      display: inline-block;
      &:hover {
         color: var(--dark-color-hover-link);
      }
   }
   &__dateBtn {
      display: flex;
      flex-direction: column;
      gap: 6px;
      margin-top: auto;
      @media (max-width: 769px) {
         flex-direction: column-reverse;
      }
   }
   &__date {
      font-size: 12px;
      line-height: 1.2;
      color: var(--gray-color);
      text-align: center;
      & span {
         font-weight: 700;
      }
      @media (max-width: 769px) {
         text-align: left;
         color: var(--dark-color);
      }
   }
   &__bonus {
      padding: 3px;
      text-align: center;
      background: #f2f5f7;
      border-radius: 10px;
      font-size: 14px;
      line-height: 1.2;
      color: var(--dark-color);
      position: relative;
      cursor: pointer;
      display: block !important;
      & .clue__clue {
         right: inherit;
         left: 50%;
         transform: translateX(-50%) translateY(100%);
         text-align: left !important;
         @media (max-width: 769px) {
            transform: translateX(-50%);
         }
         &.clue__clue--bottom {
            @media (min-width: 769px) {
               transform: translate(-50%, -100%) !important;
            }
         }
      }
      & span {
         font-weight: 700;
      }
      &Icon {
         margin-left: 5px;
         font-size: 14px;
         line-height: 17px;
         vertical-align: bottom;
         color: var(--red-color);
         pointer-events: none;
      }
   }
   &__raitingBonus {
      display: flex;
      flex-direction: column;
      gap: 12px;
      @media (max-width: 769px) {
         margin-bottom: 8px;
      }
   }
   &__price {
      font-weight: 700;
      font-size: 16px;
      line-height: 1.2;
   }
   &__counter {
      display: flex;
      flex-direction: column;
      gap: 6px;
   }
   &__counterWrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
   }
   &__priceInf {
      font-weight: 400;
      font-size: 12px;
      line-height: 1.2;
      color: var(--gray-color);
   }
   &__btn {
      &--hidden {
         display: none;
      }
   }
   &__btn2 {
      &Icon {
         font-size: 20px;
         vertical-align: middle;
      }
      &--hidden {
         display: none;
      }
   }
   &__wrapCatCod {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
   }
   &__categ,
   &__cod {
      font-size: 12px;
      line-height: 1.3;
      color: var(--gray-color);
   }
}
.catalogCollectionBanner {
   padding: 17px 0;
   background: #ffffff;
   box-shadow: 0px 0px 20px rgba(151, 165, 173, 0.75);
   z-index: 2000;
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   transition: all 0.4s;
   @media (max-width: 769px) {
      padding: 12px 0;
   }
   &--hidden {
      transform: translateY(-100%);
   }
   &__wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      @media (max-width: 769px) {
         gap: 6px;
         flex-direction: column;
         align-items: flex-start;
      }
   }
   &__right {
      display: flex;
      gap: 20px;
      align-items: center;
      @media (max-width: 769px) {
         gap: 12px;
         flex-direction: column;
         align-items: flex-start;
         width: 100%;
      }
   }
   &__price {
      font-weight: 400;
      font-size: 21px;
      line-height: 1.2;
      @media (max-width: 769px) {
         font-size: 16px;
      }
      & span {
         font-weight: 700;
      }
   }
   &__left {
      display: flex;
      flex-direction: column;
      gap: 10px;
   }
   &__text {
      font-weight: 700;
      font-size: 14px;
      line-height: 1.3;
      @media (max-width: 769px) {
         font-size: 12px;
         font-weight: 400;
      }
   }
   &__linksWrap {
      display: flex;
      gap: 20px;
      @media (max-width: 769px) {
         display: none;
      }
   }
   &__link {
      font-size: 13px;
      line-height: 1.2;
      color: var(--blue-color);
      transition: all 0.4s;
      &:hover {
         color: var(--blue-color-hover-link);
      }
   }
   &__btn {
      @media (max-width: 769px) {
         width: 100%;
         padding: 18.5px;
      }
   }
}
