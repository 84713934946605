.graph-modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.7);
    cursor: pointer;
    overflow-y: auto;
    overflow-x: hidden;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity var(--transition-time), visibility var(--transition-time);
    -o-transition: opacity var(--transition-time), visibility var(--transition-time);
    transition: opacity var(--transition-time), visibility var(--transition-time);
    background-color: transparent;
    opacity: 1;
    transition: 0.5s;
    background-color: transparent;
    @media (max-width: 769px) {
        overflow: hidden;
    }

    &:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        height: 100%;
    }

    &.is-open {
        opacity: 1;
        visibility: visible;
        -webkit-transition: opacity var(--transition-time), visibility var(--transition-time);
        -o-transition: opacity var(--transition-time), visibility var(--transition-time);
        transition: opacity var(--transition-time), visibility var(--transition-time);
        background-color: rgba(0, 0, 0, 0.4);
        transition: 0.4s;
    }

    &__header {
        display: block;
        @media (max-width: 769px) {
            display: flex;
            align-items: center;
            position: relative;
            min-height: 60px;
            padding: 12px;
            border-bottom: 1px solid #e5e5e5;
            & .graph-modal__close {
                color: var(--dark-color);
                right: 22px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}

.graph-modal__container {
    position: relative;
    width: 1000px;
    margin: 50px auto;
    vertical-align: middle;
    background-color: #fff;
    border-radius: 9px;
    padding: 20px;
    cursor: default;
    text-align: left;
    display: none;
    opacity: 0;
    transition: 0.4s !important;

    &.graph-modal-open {
        display: inline-block;
        opacity: 0;

        &[data-graph-target="mainMenu"] {
            padding-right: 0;

            & .mainMenuModal__content {
                padding-right: 20px;
            }
        }
    }

    &.time {
        filter: brightness(0.6);
    }

    &.graph-modal-open-ok {
        opacity: 1;
    }

    &.close {
        display: inline-block;
    }

    &.lr {
        display: flex;
        flex-direction: column;
        transform: translateX(-100%) !important;
        transition: transform 0.5s;
        opacity: 1 !important;

        &.graph-modal-open {
            transform: translateX(0) !important;
        }

        & .mainMenuModal__content {
            overflow: auto;
        }
    }
}

.graph-modal__close {
    position: absolute;
    cursor: pointer;
    right: 19px;
    top: 19px;
    width: initial;
    height: initial;
    background: none;
    color: var(--gray-color);
    transition: all 0.4s;
    font-size: 24px;

    &:hover {
        color: var(--gray-color-hover-link);
    }
}

.modalLeft {
    width: calc(100% - 20px);
    position: absolute;
    left: 0;
    bottom: 70px;
    height: calc(100% - 70px);
    margin: 0;
    overflow: auto;
}

.modalCity {
    width: calc(100% - 40px);
    max-width: 980px;
    padding: 40px 50px;
    @media (max-width: 769px) {
        width: 100%;
        border-radius: 0;
        flex-direction: column;
        margin: 0 !important;
        max-height: calc(100% - 70px);
        position: absolute;
        bottom: 70px;
        left: 0;
        padding: 0;
        opacity: 1 !important;
        transition: transform 0.4s !important;
        transform: translateX(-100%);
        &.graph-modal-open {
            display: flex;

            &-ok {
                transform: none;
            }
        }
    }

    &__title {
        margin: 0 0 25px;
        font-weight: 700;
        font-size: 32px;
        line-height: 1.25;
        color: var(--dark-color);
        @media (max-width: 1025px) {
            font-size: 24px;
            margin-bottom: 20px;
        }
        @media (max-width: 769px) {
            font-size: 16px;
            text-align: center;
            margin-bottom: 0;
            width: 100%;
        }
    }

    &__content {
        column-count: 4;
        column-fill: balance;
        column-gap: 60px;
        max-width: 830px;
        @media (max-width: 1025px) {
            column-count: 3;
        }
        @media (max-width: 601px) {
            column-count: 2;
        }
        @media (max-width: 401px) {
            column-count: 1;
        }
    }

    &__listWrap {
        page-break-inside: avoid;
        break-inside: avoid;
        display: flex;
        gap: 20px;
        margin: 0 0 30px;

        &:last-of-type {
            margin-bottom: 0;
        }

        & > span {
            font-weight: 700;
            font-size: 16px;
            line-height: 1.4;
            color: var(--red-color);
        }
    }

    &__list {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    &__link {
        font-weight: 400;
        font-size: 16px;
        line-height: 1.4;
        color: var(--gray-color);
        transition: all 0.5s;

        &:hover,
        &--active {
            color: var(--dark-color);
        }

        @media (max-width: 769px) {
            color: var(--dark-color);
            &--active {
                color: var(--red-color);
            }
        }
    }

    &__search {
        display: block;
        width: 100%;
        margin-bottom: 25px;
        max-width: 420px;
        @media (max-width: 769px) {
            margin-bottom: 30px;
        }
    }

    &__searchInp {
        display: block;
        width: 100%;
        border: 1px solid var(--dark-gray-color3-hover-btn);
        border-radius: 5px;
        background-color: transparent;
        padding: 11px 40px 11px 11px;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.2;
        color: var(--dark-color);
        outline: none;
        background: url("../img/svg/search.svg") no-repeat center right 8px / 24px;

        &::placeholder {
            color: var(--gray-color);
        }

        @media (max-width: 769px) {
            font-size: 16px;
            padding: 15.5px 55px 15.5px 20px;
            background-position-x: right 20px;
        }
    }

    &__contentWrap {
        @media (max-width: 769px) {
            padding: 18px 12px;
            overflow: auto;
        }
    }

    &__header {
    }
}

.modalSearch {
    display: none;
    opacity: 1;
    background-color: transparent;
    overflow: hidden;
    @media (max-width: 769px) {
        width: 100%;
        border-radius: 0;
        flex-direction: column;
        margin: 0 !important;
        max-height: calc(100% - 70px);
        position: absolute;
        top: 0;
        left: 0;
        padding: 0;
        opacity: 1 !important;
        transition: transform 0.4s, filter 0.4s !important;
        transform: translateY(-100%);
        &.graph-modal-open {
            display: flex;

            &-ok {
                transform: none;
            }
        }
    }

    &__header {
        background-color: var(--light-color);
        height: 80px;
        min-height: 80px;
    }

    &__contentWrap {
        opacity: 0;
        pointer-events: none;
        transition: height 0.4s, opacity 0.4s 0.4s, padding-top 0.4s, padding-bottom 0.4s;
        background-color: var(--light-color);
        height: 0;
        overflow: auto;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 12px;
        padding-right: 12px;

        &--active {
            padding: 24px 12px;
            transition: opacity 0.4s;
            opacity: 1;
            height: calc(100vh - 150px);
            pointer-events: all;
        }
    }
}

.modalMain {
    display: none;
    @media (max-width: 769px) {
        width: calc(100% - 40px);
        border-radius: 0;
        flex-direction: column;
        margin: 0 !important;
        max-height: calc(100% - 70px);
        position: absolute;
        bottom: 70px;
        left: 0;
        padding: 0;
        opacity: 1 !important;
        transition: transform 0.4s, filter 0.4s !important;
        transform: translateX(-100%);
        &.graph-modal-open {
            display: flex;

            &-ok {
                transform: none;
            }
        }
    }

    &__contentWrap {
        @media (max-width: 769px) {
            overflow: auto;
        }
    }

    &__city {
        display: flex;
        gap: 8px;
        align-items: center;
        font-size: 14px;
        line-height: 1;
        color: var(--dark-color);
    }

    &__iconCity {
        font-size: 24px;
        color: var(--red-color);
    }

    &__btnCategories {
        display: flex;
        flex-direction: column;
        margin-bottom: 3px;

        &--bg {
            background-color: var(--dark-gray-color3);
        }
    }

    &__btnCategor {
        padding: 15px 20px;
        display: flex;
        align-items: center;
        gap: 15px;
        width: 100%;
        color: var(--dark-color);
    }

    &__btnIcon {
        font-size: 28px;
        color: var(--red-color);

        &--arrow {
            color: var(--dark-color);
            font-size: 24px;
            margin-left: auto;
        }

        &--white {
            color: var(--light-color);
            font-size: 24px;
        }
    }

    &__telsBlock {
        padding: 20px;
        background-color: var(--dark-gray-color3);
        margin-bottom: 3px;
    }

    &__tel {
        display: flex;
        gap: 15px;
        align-items: center;
        color: var(--dark-color);
        font-size: 20px;
        line-height: 1.15;
        width: fit-content;
        font-weight: 700;
        margin-bottom: 20px;
    }

    &__socWrap {
        display: flex;
        gap: 20px 40px;
        flex-wrap: wrap;
        padding: 20px;
        background-color: var(--dark-gray-color3);
    }

    &__soc {
        font-size: 48px;

        & span {
            pointer-events: none;
        }
    }
    &__menu {
        &Wrap {
            padding: 15px 20px;
            width: 100%;
        }
        &Title {
            font-size: 18px;
            color: var(--dark-color);
            line-height: 1.15;
            font-weight: 700;
            margin-bottom: 20px;
        }
        &List {
            display:flex;
            flex-wrap: wrap;
            gap: 20px 20px;
            align-items: flex-start;
            &Item {
                display: block;
                width: calc(50% - 20px/2);
                &Img {
                    display: block;
                    width: 60px;
                    height: 60px;
                    border-radius: 10px;
                    object-fit: cover;
                    overflow: hidden;
                    margin: 0 auto 5px auto;
                }
                &Text{
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 1.2;
                    color: var(--dark-color);
                    text-align: center;
                }
            }
        }
    }
}

.modalCategor {
    display: none;
    @media (max-width: 769px) {
        width: calc(100% - 40px);
        border-radius: 0;
        flex-direction: column;
        margin: 0 !important;
        height: calc(100% - 70px);
        position: absolute;
        bottom: 70px;
        left: 0;
        padding: 0;
        opacity: 1 !important;
        transition: transform 0.4s, filter 0.4s !important;
        transform: translateX(-100%);
        &.graph-modal-open {
            display: flex;

            &-ok {
                transform: none;
            }
        }
    }

    &__contentWrap {
        @media (max-width: 769px) {
            overflow: auto;
            padding: 24px 10px;
        }
    }

    &__back {
        font-size: 28px;
        color: var(--dark-color);
        display:flex;
        align-items: center;
    }

    &__textBack {
        font-size: 18px;
        line-height: 1.15;
        font-weight: 700;
        margin-left:15px;
    }

    &__categorWrap {
        display:flex;
        flex-wrap: wrap;
        gap: 20px 20px;
        align-items: flex-start;
    }

    &__img {
        display: block;
        width: 60px;
        height: 60px;
        border-radius: 10px;
        object-fit: cover;
        overflow: hidden;
        margin: 0 auto 5px auto;
    }

    &__categor {
        display: block;
        width: calc(50% - 20px/2);
    }

    &__name {
        font-weight: 700;
        font-size: 14px;
        line-height: 1.2;
        color: var(--dark-color);
        text-align: center;
    }
}

.modalAuth {
    width: calc(100% - 40px);
    max-width: 440px;
    padding: 40px 50px;
    @media (max-width: 769px) {
        width: 100%;
        max-width: 100%;
        border-radius: 0;
        flex-direction: column;
        margin: 0 !important;
        max-height: calc(100% - 70px);
        position: absolute;
        bottom: 70px;
        left: 0;
        padding: 0;
        opacity: 1 !important;
        transition: transform 0.4s !important;
        transform: translateY(100%);
        &.graph-modal-open {
            display: flex;

            &-ok {
                transform: none;
            }
        }
    }

    &__header {
        @media (max-width: 769px) {
            border: none;
        }
    }

    &__title {
        margin: 0 0 30px;
        font-weight: 700;
        font-size: 24px;
        line-height: 1.25;
        color: var(--dark-color);
        text-align: center;
        width: 100%;
        @media (max-width: 1025px) {
            font-size: 22px;
            margin-bottom: 20px;
        }
        @media (max-width: 769px) {
            font-size: 20px;
            text-align: left;
            margin-bottom: 0;
        }
    }

    &__form {
        display: flex;
        gap: 20px;
        flex-direction: column;
    }

    &__wrapBtn {
        display: flex;
        gap: 20px;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
    }

    &__btnMM {
        display: block;
        width: fit-content;
        font-size: 13px;
        line-height: 1.3;
        color: var(--gray-color);
        transition: all 0.4s;

        &:hover {
            color: var(--gray-color-hover-link);
        }
    }

    &__contentWrap {
        @media (max-width: 769px) {
            overflow: auto;
            padding: 0 12px 20px;
        }
    }
}

.modalRecovery {
    width: calc(100% - 40px);
    max-width: 440px;
    padding: 40px 50px;
    @media (max-width: 769px) {
        width: 100%;
        max-width: 100%;
        border-radius: 0;
        flex-direction: column;
        margin: 0 !important;
        max-height: calc(100% - 70px);
        position: absolute;
        bottom: 70px;
        left: 0;
        padding: 0;
        opacity: 1 !important;
        transition: transform 0.4s !important;
        transform: translateY(100%);
        &.graph-modal-open {
            display: flex;

            &-ok {
                transform: none;
            }
        }
    }

    &__header {
        @media (max-width: 769px) {
            border: none;
        }
    }

    &__title {
        margin: 0 0 30px;
        font-weight: 700;
        font-size: 24px;
        line-height: 1.25;
        color: var(--dark-color);
        text-align: center;
        width: 100%;
        @media (max-width: 1025px) {
            font-size: 22px;
            margin-bottom: 20px;
        }
        @media (max-width: 769px) {
            font-size: 20px;
            text-align: left;
            margin-bottom: 0;
        }
    }

    &__form {
        display: flex;
        gap: 20px;
        flex-direction: column;
    }

    &__wrapBtn {
        display: flex;
        gap: 20px;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
    }

    &__btnMM {
        display: block;
        width: fit-content;
        font-size: 13px;
        line-height: 1.3;
        color: var(--gray-color);
        transition: all 0.4s;

        &:hover {
            color: var(--gray-color-hover-link);
        }
    }

    &__contentWrap {
        @media (max-width: 769px) {
            overflow: auto;
            padding: 0 12px 20px;
        }
    }
}

.modalAfterAddCart  {
    width: calc(100% - 40px);
    max-width: 500px;
    padding: 40px 50px;
    @media (max-width: 769px) {
        width: 100%;
        max-width: 100%;
        border-radius: 0;
        flex-direction: column;
        margin: 0 !important;
        max-height: calc(100% - 70px);
        position: absolute;
        bottom: 70px;
        left: 0;
        padding: 0;
        opacity: 1 !important;
        transition: transform 0.4s !important;
        transform: translateY(100%);
        &.graph-modal-open {
            display: flex;

            &-ok {
                transform: none;
            }
        }
    }

    &__header {
        @media (max-width: 769px) {
            border: none;
        }
    }

    &__title {
        margin: 0 0 30px;
        font-weight: 700;
        font-size: 24px;
        line-height: 1.25;
        color: var(--dark-color);
        text-align: center;
        width: 100%;
        @media (max-width: 1025px) {
            font-size: 22px;
            margin-bottom: 20px;
        }
        @media (max-width: 769px) {
            font-size: 20px;
            text-align: left;
            margin-bottom: 0;
        }
    }

    &__wrapBtn {
        @media (max-width: 769px) {
            justify-content: center;
        }
        @media (max-width: 450px) {
            display:block;
        }
        .btnStnd{
            padding: 18.5px;
            border: none;
            font-size: 16px;
            text-transform: none;
            @media (max-width: 450px) {
                width: 100%;
                margin:10px 0;
            }
        }
    }

    &__contentWrap {
        @media (max-width: 769px) {
            overflow: auto;
            padding: 0 12px 20px;
        }
    }
}

.regModal {
    width: calc(100% - 40px);
    max-width: 800px;
    padding: 40px 50px;
    @media (max-width: 769px) {
        width: 100%;
        max-width: 100%;
        border-radius: 0;
        flex-direction: column;
        margin: 0 !important;
        max-height: calc(100% - 70px);
        position: absolute;
        bottom: 70px;
        left: 0;
        padding: 0;
        opacity: 1 !important;
        transition: transform 0.4s !important;
        transform: translateY(100%);
        &.graph-modal-open {
            display: flex;

            &-ok {
                transform: none;
            }
        }
    }

    &__header {
        @media (max-width: 769px) {
            border: none;
        }
    }

    &__title {
        margin: 0 0 30px;
        font-weight: 700;
        font-size: 24px;
        line-height: 1.25;
        color: var(--dark-color);
        text-align: left;
        width: 100%;
        @media (max-width: 1025px) {
            font-size: 22px;
            margin-bottom: 20px;
        }
        @media (max-width: 769px) {
            font-size: 20px;
            text-align: left;
            margin-bottom: 0;
        }
    }

    &__form {
        display: flex;
        gap: 20px;
        flex-direction: column;
        @media (max-width: 769px) {
            gap: 12px;
        }
    }

    &__wrapBtn {
        display: flex;
        gap: 20px;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
    }

    &__btnMM {
        display: block;
        width: fit-content;
        font-size: 13px;
        line-height: 1.3;
        color: var(--gray-color);
        transition: all 0.4s;

        &:hover {
            color: var(--gray-color-hover-link);
        }
    }

    &__contentWrap {
        @media (max-width: 769px) {
            overflow: auto;
            padding: 0 12px 20px;
        }
    }

    &__colFrom {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        @media (max-width: 769px) {
            gap: 12px;
        }
    }

    &__colFromItem {
        width: calc(50% - 10px);
        @media (max-width: 769px) {
            width: 100%;
        }
    }

    &__btnWr {
        display: flex;
        flex-direction: column;
    }

    &__chWr {
        display: flex;
        flex-direction: column;
        gap: 10px;
        @media (max-width: 769px) {
            margin-top: 8px;
        }
    }

    &__submWr {
        @media (max-width: 769px) {
            margin: 8px 0;
        }
    }
}

.brendsModal {
    display: none;
    @media (max-width: 769px) {
        width: 100%;
        max-width: 100%;
        border-radius: 0;
        flex-direction: column;
        margin: 0 !important;
        max-height: calc(100% - 70px);
        position: absolute;
        bottom: 70px;
        left: 0;
        padding: 0;
        opacity: 1 !important;
        transition: transform 0.4s, filter 0.4s !important;
        transform: translateY(100%);
        &.graph-modal-open {
            display: flex;

            &-ok {
                transform: none;
            }
        }
    }

    &__header {
    }

    &__title {
        @media (max-width: 769px) {
            font-size: 16px;
            text-align: center;
            margin-bottom: 0;
            width: 100%;
        }
    }

    &__content {
        padding-top: 15px;
    }

    &__bottom {
        padding: 10px 12px;
        border-top: 1px solid #e2e8ec;
    }

    &__contentWrap {
        @media (max-width: 769px) {
            overflow: auto;
            padding: 0 12px 20px;
        }
    }
}

.filtersModal {
    display: none;
    @media (max-width: 769px) {
        width: 100%;
        max-width: 100%;
        border-radius: 0;
        flex-direction: column;
        margin: 0 !important;
        max-height: calc(100%);
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 0;
        opacity: 1 !important;
        transition: transform 0.4s, filter 0.4s !important;
        transform: translateY(100%);
        &.graph-modal-open {
            display: flex;

            &-ok {
                transform: none;
            }
        }
    }

    &__header {
    }

    &__title {
        @media (max-width: 769px) {
            font-size: 16px;
            width: 100%;
            text-align: center;
            margin-bottom: 0;
        }
    }

    &__content {
        padding-top: 15px;
    }

    &__bottom {
        padding: 10px 12px;
        border-top: 1px solid #e2e8ec;
    }

    &__contentWrap {
        @media (max-width: 769px) {
            overflow: auto;
            padding: 0 12px 20px;
        }
    }

    &__contentGap {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    & .catalogPageCatalogTegs {
        align-items: center;

        & > .catalogPageCatalogTegs__btn--reset {
            order: -1;
            border-radius: 50px;
            width: 28px;
            height: 28px;
            padding: 0;
            justify-content: center;
            align-items: center;
            line-height: 1;
            text-align: center;

            & .catalogPageCatalogTegs__textC {
                display: none;
            }

            & .catalogPageCatalogTegs__close {
                margin-left: 0;
                line-height: 1;
            }
        }
    }
}

.buyersModal {
    display: none;
    @media (max-width: 769px) {
        width: 100%;
        max-width: 100%;
        border-radius: 0;
        flex-direction: column;
        margin: 0 !important;
        max-height: calc(100% - 70px);
        position: absolute;
        bottom: 70px;
        left: 0;
        padding: 0;
        opacity: 1 !important;
        transition: transform 0.4s, filter 0.4s !important;
        transform: translateY(100%);
        &.graph-modal-open {
            display: flex;

            &-ok {
                transform: none;
            }
        }
    }

    &__header {
    }

    &__title {
        @media (max-width: 769px) {
            font-size: 16px;
            width: 100%;
            text-align: center;
            margin-bottom: 0;
        }
    }

    &__content {
        padding-top: 15px;
        color: var(--gray-color);
    }

    &__contentWrap {
        @media (max-width: 769px) {
            overflow: auto;
            padding: 0 12px 20px;
        }
    }
}

.wholesaleBuyersModal {
    display: none;
    @media (max-width: 769px) {
        width: 100%;
        max-width: 100%;
        border-radius: 0;
        flex-direction: column;
        margin: 0 !important;
        max-height: calc(100% - 70px);
        position: absolute;
        bottom: 70px;
        left: 0;
        padding: 0;
        opacity: 1 !important;
        transition: transform 0.4s, filter 0.4s !important;
        transform: translateY(100%);
        &.graph-modal-open {
            display: flex;

            &-ok {
                transform: none;
            }
        }
    }

    &__header {
    }

    &__title {
        @media (max-width: 769px) {
            font-size: 16px;
            width: 100%;
            text-align: center;
            margin-bottom: 0;
        }
    }

    &__content {
        padding-top: 15px;
        color: var(--gray-color);
    }

    &__contentWrap {
        @media (max-width: 769px) {
            overflow: auto;
            padding: 0 12px 20px;
        }
    }
}

.addAddressModal {
    width: calc(100% - 40px);
    max-width: 840px;
    padding: 40px 50px;
    @media (max-width: 769px) {
        width: 100%;
        max-width: 100%;
        border-radius: 0;
        flex-direction: column;
        margin: 0 !important;
        max-height: calc(100% - 70px);
        position: absolute;
        bottom: 70px;
        left: 0;
        padding: 0;
        opacity: 1 !important;
        transition: transform 0.4s !important;
        transform: translateY(100%);
        &.graph-modal-open {
            display: flex;

            &-ok {
                transform: none;
            }
        }
    }

    &__header {
    }

    &__title {
        margin: 0 0 30px;
        font-weight: 700;
        font-size: 24px;
        line-height: 1.25;
        color: var(--dark-color);
        text-align: left;
        width: 100%;
        @media (max-width: 1025px) {
            font-size: 20px;
            margin-bottom: 20px;
        }
        @media (max-width: 769px) {
            font-size: 16px;
            margin-bottom: 0;
            text-align: center;
        }
    }

    &__contentWrap {
        @media (max-width: 769px) {
            overflow: auto;
            padding: 12px 12px 20px;
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}

.allReviewsModal {
    display: none;
    @media (max-width: 769px) {
        width: 100%;
        max-width: 100%;
        border-radius: 0;
        flex-direction: column;
        margin: 0 !important;
        height: calc(100% - 70px);
        max-height: calc(100% - 70px);
        position: absolute;
        bottom: 70px;
        left: 0;
        padding: 0;
        opacity: 1 !important;
        transition: transform 0.4s, filter 0.4s !important;
        transform: translateY(100%);
        &.graph-modal-open {
            display: flex;

            &-ok {
                transform: none;
            }
        }
    }

    &__title {
        @media (max-width: 769px) {
            font-size: 16px;
            width: 100%;
            text-align: center;
            margin-bottom: 0;
        }
    }

    &__content {
        padding-top: 15px;
        overflow: auto;
    }

    &__contentWrap {
        @media (max-width: 769px) {
            overflow: auto;
            padding: 15px 12px 20px;
        }
    }
}

.oneClickModal {
    width: calc(100% - 40px);
    max-width: 890px;
    padding: 40px 50px;
    @media (max-width: 769px) {
        width: 100%;
        border-radius: 0;
        flex-direction: column;
        margin: 0 !important;
        max-height: calc(100% - 70px);
        position: absolute;
        bottom: 70px;
        left: 0;
        padding: 0;
        opacity: 1 !important;
        transition: transform 0.4s !important;
        transform: translateY(100%);
        &.graph-modal-open {
            display: flex;

            &-ok {
                transform: none;
            }
        }
    }

    &__title {
        margin: 0 0 30px;
        font-weight: 700;
        font-size: 24px;
        line-height: 1.25;
        color: var(--dark-color);
        @media (max-width: 1025px) {
            font-size: 22x;
            margin-bottom: 20px;
        }
        @media (max-width: 769px) {
            font-size: 20px;
            margin-bottom: 0;
            width: 100%;
        }
    }

    &__contentWrap {
        @media (max-width: 769px) {
            overflow: auto;
            padding: 15px 12px 20px;
        }
    }

    &__wrapLabel {
        display: flex;
        gap: 20px;
        @media (max-width: 769px) {
            gap: 12px;
            flex-direction: column;
        }

        & > * {
            width: calc(100% / 3 - 40px / 3);
            @media (max-width: 769px) {
                width: 100%;
            }
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 40px;
    }

    &__wrapBtn {
        display: flex;
        gap: 36px;
        align-items: center;
        @media (max-width: 769px) {
            flex-direction: column;
            gap: 12px;
        }
    }

    &__submit {
        width: calc(100% / 3 - 40px / 3);
        @media (max-width: 769px) {
            width: 100%;
        }
    }

    &__wrapBtnText {
        font-size: 12px;
        color: #b8c0c5;
        @media (max-width: 769px) {
            text-align: center;
        }
    }

    & .basketProdCard__priceMain {
        font-size: 16px;
    }
}

.quickViewModal {
    width: calc(100% - 40px);
    max-width: 1060px;
    padding: 40px 50px;
    @media (max-width: 1025px) {
        padding: 40px 20px;
    }
    @media (max-width: 769px) {
        display: none !important;
    }

    & .product__topRight {
        width: 420px;
        @media (max-width: 1025px) {
            width: fit-content;
        }
    }

    & .product__gallery--modal {
        position: relative;
        top: 0;
    }
}

.addReviewModal {
    width: calc(100% - 40px);
    max-width: 730px;
    padding: 40px 50px;
    @media (max-width: 769px) {
        width: 100%;
        max-width: 100%;
        border-radius: 0;
        flex-direction: column;
        margin: 0 !important;
        max-height: calc(100% - 70px);
        position: absolute;
        bottom: 70px;
        left: 0;
        padding: 0;
        opacity: 1 !important;
        transition: transform 0.4s, filter 0.4s !important;
        transform: translateY(100%);
        &.graph-modal-open {
            display: flex;

            &-ok {
                transform: none;
            }
        }
    }

    &__formContentSubmModal {
        padding-top: 16px;
        padding-bottom: 16px;
        @media (max-width: 769px) {
            padding-top: 18px;
            padding-bottom: 18px;
        }
    }

    &__title {
        display: none;
        @media (max-width: 769px) {
            display: block;
            text-align: center;
            font-size: 16px;
            width: 100%;
            margin-bottom: 0;
        }
    }

    &__content {
        padding-top: 15px;
        overflow: auto;
    }

    &__contentWrap {
        @media (max-width: 769px) {
            overflow: auto;
            padding: 15px 12px 20px;
        }
    }

    &__title2 {
        font-weight: 700;
        font-size: 24px;
        line-height: 1.25;
        margin: 0;
        @media (max-width: 769px) {
            font-size: 22px;
            margin-bottom: 15px;
        }
    }

    &__subTtitle {
        font-weight: 400;
        font-size: 20px;
        line-height: 1.5;
        margin: 0 0 42px;
        @media (max-width: 769px) {
            font-size: 16px;
            margin-bottom: 20px;
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    &__formWrap {
        display: flex;
        gap: 20px;
        @media (max-width: 769px) {
            flex-direction: column;
            gap: 6px;
        }
    }

    &__formCat {
        width: 130px;
        font-size: 14px;
        line-height: 30px;
        @media (max-width: 769px) {
            width: 100%;
        }
    }

    &__formContent {
        width: calc(100% - 150px);
        @media (max-width: 769px) {
            width: 100%;
        }
    }

    &__formColumns2 {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;

        & > * {
            width: calc(50% - 7.5px);
            @media (max-width: 769px) {
                width: 100%;
            }
        }
    }

    &__formColumnsTextarea {
        display: flex;
        flex-direction: column;
        gap: 17px;
    }

    &__formColumnsTextareaText {
        font-weight: 400;
        font-size: 12px;
        line-height: 1.2;
        color: var(--gray-color);
        margin: 0 0 10px;
    }

    &__formColumnsTextareaT {
        height: 110px;
    }

    &__formContentPhoto {
    }

    &__formContentSubm {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        @media (max-width: 769px) {
            gap: 20px;
        }
    }

    &__formContentSubmText {
        font-size: 10px;
        line-height: 12px;
        color: var(--gray-color);
        text-align: center;
    }

    &__formContentRating {
    }
}

.dopProdModal {
    width: calc(100% - 60px);
    max-width: 880px;
    padding: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    position: fixed;
    margin: 0;
    border-radius: 0;
    left: initial;
    @media (max-width: 769px) {
        width: 100%;
        max-width: 100%;
        height: initial;
        top: initial;
        border-radius: 0;
        flex-direction: column;
        margin: 0 !important;
        max-height: calc(100% - 70px);
        position: absolute;
        bottom: 70px;
        left: 0;
        padding: 0;
        opacity: 1 !important;
        transition: transform 0.4s, filter 0.4s !important;
        transform: translateY(100%);
        &.graph-modal-open {
            display: flex;

            &-ok {
                transform: none;
            }
        }
    }

    &__title {
        display: none;
        @media (max-width: 769px) {
            display: block;
            text-align: center;
            font-size: 16px;
            width: 100%;
            margin-bottom: 0;
        }
    }

    &__content {
        overflow: auto;
    }

    &__btnCloseMob {
        display: none;
        @media (max-width: 769px) {
            display: block;
        }
    }

    &__btnClose {
        display: block;
        position: absolute;
        top: 10px;
        width: fit-content;
        left: 0;
        transform: translateX(-52px);
        border-radius: 50%;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 50%;

        & svg {
            display: block;

            & rect {
                transition: all 0.4s;
            }
        }

        &:hover {
            & rect {
                fill: #e2e8ec;
            }
        }
    }

    &__filters {
        width: 300px;
        padding: 30px 40px;
        overflow: auto;
        height: 100%;
        @media (max-width: 1025px) {
            width: 50%;
        }
        @media (max-width: 769px) {
            display: none;
        }
    }

    &__filtersName {
        font-weight: 700;
        font-size: 20px;
        line-height: 1.5;
        margin: 0 0 30px;
    }

    &__contentWrap {
        height: 100%;
        display: flex;
        @media (max-width: 769px) {
            flex-direction: column;
            gap: 15px;
            padding: 15px 12px;
            overflow: auto;
        }
    }

    &__cardsWrap {
        width: 400px;
        height: 100%;
        overflow: auto;
        @media (max-width: 1025px) {
            width: 50%;
        }
        @media (max-width: 769px) {
            width: 100%;
            height: initial;
            overflow: initial;
        }
    }

    &__card {
        padding: 20px;
        display: flex;
        gap: 30px;
        transition: all 0.4s;

        &:hover {
            & .productCard__btn {
                opacity: 1;
            }

            & .productCard__imgTabs {
                opacity: 1;
            }

            &:hover {
                @media (max-width: 1025px) {
                    background: #f2f5f7;
                }
                @media (max-width: 769px) {
                    background-color: transparent;
                }
            }
        }

        &--active {
            background: #f2f5f7;
            @media (max-width: 1025px) {
                background-color: transparent;
            }
        }
    }

    &__cardImgWrap {
        width: 160px;
    }

    &__cardInfo {
        width: 170px;
    }

    &__cardImg {
        width: 100%;
        aspect-ratio: 1;
        position: relative;
        background-color: var(--light-color);

        & img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
            margin-bottom: 3px;
        }
    }

    &__cardName {
        font-size: 14px;
        line-height: 1.3;
        color: var(--dark-color);
        margin-bottom: 15px;
        display: inline-block;
        transition: all 0.4s;

        &:hover {
            color: var(--red-color);
        }
    }

    &__cardPrice {
        font-weight: 700;
        font-size: 16px;
        line-height: 1.2;
        margin-bottom: 17px;

        & span {
            font-weight: 400;
            font-size: 12px;
            line-height: 1.2;
            text-decoration-line: line-through;
            color: #b8c0c5;
            margin-left: 4px;
        }
    }

    &__cardBtn1 {
        margin-bottom: 6px;
        width: 120px;
    }

    &__cardBtn2 {
        width: 120px;
        @media (max-width: 1025px) {
            display: none;
        }
    }

    &__cardBtn3 {
        width: 120px;
        display: none;
        @media (max-width: 1025px) {
            display: flex;
        }
    }

    &__prod {
        width: 480px;
        background: #f2f5f7;
        padding: 20px;
        overflow: auto;
        height: 100%;
        @media (max-width: 1025px) {
            display: none;
        }

        &:hover {
            & .productCard__btns {
                opacity: 1;
            }

            & .productCard__imgTabs {
                opacity: 1;
            }
        }
    }

    &__prodImgWrap {
        width: 100%;
        margin-bottom: 14px;
    }

    &__prodImg {
        position: relative;
        aspect-ratio: 1;
        width: 100%;
        border-radius: 6px;

        & img {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 6px;
            margin-bottom: 4px;
            object-fit: contain;
        }
    }

    &__prodName {
        font-weight: 700;
        font-size: 20px;
        line-height: 1.5;
        color: var(--dark-color);
        margin: 0 0 20px;
    }

    &__prodWrap {
        background: #ffffff;
        border-radius: 5px;
        padding: 20px;
    }

    &__filtersMob {
        display: none !important;
        @media (max-width: 769px) {
            display: flex !important;
        }
    }
}

.prodPodrModal {
    width: calc(100% - 40px);
    max-width: 1200px;
    padding: 0;
    @media (max-width: 769px) {
        width: 100%;
        max-width: 100%;
        border-radius: 0;
        flex-direction: column;
        margin: 0 !important;
        max-height: calc(100% - 70px);
        position: absolute;
        bottom: 70px;
        left: 0;
        margin: 0;
        padding: 0;
        opacity: 1 !important;
        transition: transform 0.4s, filter 0.4s !important;
        transform: translateY(100%);
        &.graph-modal-open {
            display: flex;

            &-ok {
                transform: none;
            }
        }
    }

    &__header {
        @media (max-width: 769px) {
            background-color: transparent;
            border: none;
            height: 0;
            padding: 0;
            min-height: initial;
        }
    }

    &__content {
        padding-top: 15px;
        overflow: auto;
    }

    & .clue__clue {
        right: -15px;
    }

    &__contentWrap {
        @media (max-width: 1025px) {
            overflow: auto;
            padding: 15px 12px 20px;
        }
    }

    &__prod {
        display: block;
        background-color: transparent;
        padding: 0;
        width: 100%;

        & .dopProdModal__prodWrap {
            padding: 0;
        }

        @media (max-width: 769px) {
            & .productCard__btns {
                right: initial;
                top: initial;
                left: 0;
                bottom: 10px;
            }
        }
    }

    &__btnCloseMob {
        @media (max-width: 769px) {
            top: 25px !important;
            z-index: 2;
        }
    }
}

.comparisonModal {
    display: none;
    @media (max-width: 769px) {
        width: 100%;
        max-width: 100%;
        border-radius: 0;
        flex-direction: column;
        margin: 0 !important;
        max-height: calc(100% - 70px);
        position: absolute;
        bottom: 70px;
        left: 0;
        padding: 0;
        opacity: 1 !important;
        transition: transform 0.4s, filter 0.4s !important;
        transform: translateY(100%);
        &.graph-modal-open {
            display: flex;

            &-ok {
                transform: none;
            }
        }
    }

    &__title {
        @media (max-width: 769px) {
            font-size: 16px;
            width: 100%;
            text-align: center;
            margin-bottom: 0;
        }
    }

    &__content {
        padding-top: 15px;
    }

    &__bottom {
        padding: 10px 12px;
        border-top: 1px solid #e2e8ec;
    }

    &__contentWrap {
        @media (max-width: 769px) {
            overflow: auto;
            padding: 20px 12px;
        }
    }

    &__closeL,
    &__closeR {
        font-size: 28px;
    }

    &__label {
        margin-bottom: 12px;
    }

    & .swiper-button-next-custom {
        @media (max-width: 769px) {
            display: none !important;
        }
    }

    & .swiper-button-prev-custom {
        @media (max-width: 769px) {
            display: none !important;
        }
    }
}

.modalReviers {
    width: calc(100% - 80px);
    max-width: 1180px;
    padding: 0;
    max-height: 580px;
    overflow: initial;
    @media (max-width: 769px) {
        overflow: hidden;
        width: 100%;
        max-width: 100%;
        border-radius: 0;
        flex-direction: column;
        margin: 0 !important;
        max-height: calc(100% - 70px);
        height: calc(100% - 70px);
        position: absolute;
        bottom: 70px;
        left: 0;
        padding: 0;
        opacity: 1 !important;
        transition: transform 0.4s, filter 0.4s !important;
        transform: translateY(100%);
        &.graph-modal-open {
            display: flex;

            &-ok {
                transform: none;
            }
        }
    }

    &__content {
        padding: 20px;
        height: 100%;
        overflow: auto;
        width: 380px;
        max-height: 580px;
        @media (max-width: 769px) {
            height: initial;
            max-height: initial;
            width: 100%;
            overflow: initial;
            & > * {
                margin-top: 0 !important;
            }
        }

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-track {
            background-color: darkgrey;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #d3d3d3;
            border-radius: 10px;
        }
    }

    &__contentWrap {
        display: flex;
        @media (max-width: 769px) {
            flex-direction: column;
            overflow: auto;
        }
    }

    &__sliderWrap {
        width: calc(100% - 380px);
        position: relative;
        @media (max-width: 769px) {
            width: 100%;
        }

        & .swiper-button-next-custom {
            position: absolute;
            top: 50%;
            right: 28px;
            transform: translateY(-50%);
            z-index: 3;
            display: block !important;
            @media (max-width: 769px) {
                right: 15px;
                transform: translateY(-50%) !important;
            }
        }

        & .swiper-button-prev-custom {
            position: absolute;
            top: 50%;
            left: 28px;
            transform: translateY(-50%);
            z-index: 3;
            display: block !important;
            @media (max-width: 769px) {
                left: 15px;
                transform: translateY(-50%) !important;
            }
        }

        & .swiper-button-next-custom.swiper-button-disabled, .swiper-button-prev-custom.swiper-button-disabled {
            display:none !important;
        }
    }

    &__top {
        display: flex;
        gap: 20px;
        align-items: center;
        gap: 20px;
        margin-bottom: 20px;
        @media (max-width: 769px) {
            display: none;
            gap: 10px;
        }

        &--mob {
            display: none;
            position: absolute;
            left: 12px;
            max-width: calc(100% - 140px);
            bottom: 14px;
            width: fit-content;
            z-index: 4;
            background: #ffffff;
            border-radius: 9px;
            padding: 10px;
            margin-bottom: 0;
            @media (max-width: 769px) {
                display: flex;
            }
        }
    }

    &__img {
        display: block;
        width: 50px;
        height: 50px;
        overflow: hidden;
        border-radius: 50%;
        object-fit: cover;
        @media (max-width: 769px) {
            width: 30px;
            height: 30px;
        }
    }

    &__nameWrap {
        width: calc(100% - 70px);
        display: flex;
        flex-direction: column;
        gap: 6px;
        @media (max-width: 769px) {
            width: calc(100% - 40px);
            gap: 1px;
        }
    }

    &__name {
        font-weight: 700;
        font-size: 14px;
        line-height: 1.2;
        color: var(--dark-color);
        @media (max-width: 769px) {
            font-size: 12px;
        }
    }

    &__date {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.2;
        color: var(--gray-color);
        @media (max-width: 769px) {
            font-size: 12px;
        }
    }

    &__cardPhoto {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        overflow: hidden;
        margin-top: 20px;

        & img {
            display: block;
            aspect-ratio: 1/1;
            width: calc(100% / 3 - 10px);
        }
    }

    &__raiting {
        position: absolute;
        z-index: 4;
        top: 20px;
        right: 20px;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 17px;
        padding: 8px 12px;
        @media (max-width: 769px) {
            left: 12px;
            top: 14px;
            right: initial;
        }
    }

    &__like {
        position: absolute;
        bottom: 20px;
        right: 20px;
        z-index: 4;

        & .reviews__cardContentGradeBtn {
            color: #ffffff;
        }
    }

    &__closeR {
        display: none;
        position: absolute;
        top: 16px;
        right: 16px;
        z-index: 4;
        color: var(--light-color);
        @media (max-width: 769px) {
            display: block;
        }

        &--pc {
            display: block;
            right: calc(-30px);
            top: 0;
        }
    }
}

.modalReviersSwiper {
    width: 100%;
    height: 100%;
    max-height: 580px;
    @media (max-width: 769px) {
        max-height: unset;
    }
    &__slide {
        height: auto;
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;

        &::after {
            content: "";
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(31, 45, 53, 0.8);
            backdrop-filter: blur(10px);
        }
    }

    &__img {
        display: flex;
        max-width: 100%;
        max-height: 100%;
        position: relative;
        z-index: 2;
        @media (max-width: 769px) {
            min-height: 300px;
        }
    }
}

.modalOrder {
    width: calc(100% - 40px);
    max-width: 1000px;
    @media (max-width: 769px) {
        width: 100%;
        max-width: 100%;
        border-radius: 0;
        flex-direction: column;
        margin: 0 !important;
        max-height: calc(100% - 70px);
        position: absolute;
        bottom: 70px;
        left: 0;
        padding: 0;
        opacity: 1 !important;
        transition: transform 0.4s !important;
        transform: translateY(100%);
        &.graph-modal-open {
            display: flex;

            &-ok {
                transform: none;
            }
        }
    }

    &__header {
        margin-bottom: 30px;
        @media (max-width: 769px) {
            margin-bottom: 0;
        }
    }

    &__title {
        line-height: 1.25;
        color: var(--dark-color);
        @media (max-width: 1025px) {
            font-size: 22px;
            margin-bottom: 20px;
        }
        @media (max-width: 769px) {
            font-size: 20px;
            text-align: left;
            margin-bottom: 0;
        }
    }

    &__contentWrap {
        @media (max-width: 769px) {
            overflow: auto;
            padding: 0 12px 20px;
        }
    }

    & .productCard__btn {
        opacity: 1;
    }

    &__btn {
        width: fit-content;
        margin-top: 30px;
    }
}
