.reviews {
   margin: 60px 0;
   @media (max-width: 1025px) {
      margin: 50px 0;
   }
   @media (max-width: 769px) {
      margin: 0;
      padding: 20px 0;
      background: #f2f5f7;
   }
   &__title {
      font-weight: 700;
      font-size: 20px;
      line-height: 1.5;
      margin: 0 0 20px;
      & span {
         margin-left: 10px;
         font-weight: 400;
         color: var(--gray-color);
      }
   }
   &__photos {
      margin-bottom: 30px;
      @media (max-width: 769px) {
         margin: 20px 0;
      }
   }
   &__photosText {
      font-size: 14px;
      line-height: 1.2;
      margin: 0 0 15px;
   }
   &__main {
      width: calc(100% - 515px);
      @media (max-width: 1441px) {
         width: calc(100% - 480px);
      }
      @media (max-width: 1025px) {
         width: calc(100% - 360px);
      }
   }
   &__br {
      margin: 18px 0 30px;
      display: block;
      width: 100%;
      height: 1px;
      background: #e2e8ec;
   }
   &__cards {
      display: flex;
      flex-direction: column;
      gap: 30px;
      margin: 30px 0;
   }
   &__pagination {
      margin-top: 30px;
      justify-content: flex-start;
   }
   &__card {
      display: flex;
      gap: 20px;
      /*cursor: pointer;*/
   }
   &__cardLogo {
      display: block;
      width: 50px;
      height: 50px;
      overflow: hidden;
      & img {
         display: block;
         width: 100%;
         height: 100%;
         object-fit: contain;
      }
   }
   &__cardContent {
      display: flex;
      flex-direction: column;
      gap: 15px;
      width: calc(100% - 70px);
   }
   &__cardHead {
      display: flex;
      justify-content: space-between;
      gap: 20px;
   }
   &__cardContentGrade {
      display: flex;
      gap: 10px;
      align-items: center;
   }
   &__cardContentGradeBtn {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.3;
      color: var(--dark-color);
      display: flex;
      align-items: center;
      gap: 8px;
      transition: all 0.4s;
      &--active {
         & .reviews__cardContentGradeBtnIcon {
            color: var(--red-color) !important;
         }
      }
      &:hover {
         & .reviews__cardContentGradeBtnIcon {
            color: var(--dark-gray-color3-hover-btn);
         }
      }
   }
   &__cardContentGradeBtnIcon {
      font-size: 24px;
      color: var(--dark-gray-color3);
      transition: all 0.4s;
   }
   &__cardNameBlock {
      display: flex;
      flex-direction: column;
      gap: 8px;
   }
   &__cardNameWrap {
      display: flex;
      gap: 16px;
   }
   &__cardName {
      font-weight: 700;
      font-size: 14px;
      line-height: 1.2;
      color: var(--dark-color);
      transition: all 0.4s;
      &:hover {
         color: var(--dark-color-hover-link);
      }
   }
   &__cardPosl {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.2;
      color: var(--gray-color);
   }
   &__cardPhoto {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      @media (max-width: 769px) {
         margin: 15px 0;
      }
   }
   &__cardPhotoImg {
      display: block;
      width: 60px;
      height: 60px;
      overflow: hidden;
      object-fit: cover;
      border-radius: 5px;
   }
   &__cardText {
      display: flex;
      flex-direction: column;
      gap: 6px;
      @media (max-width: 769px) {
         margin: 15px 0;
      }
   }
   &__cardTextL {
      font-size: 14px;
      line-height: 1.7;
   }
   &__filter {
      border: 1px solid #e2e8ec;
      border-radius: 9px;
      padding: 20px;
      width: 420px;
      transform: translateY(-50px);
      position: sticky;
      top: 150px;
      @media (max-width: 1025px) {
         width: 320px;
      }
      @media (max-width: 769px) {
         position: relative;
         top: initial;
         width: 100%;
         background: #ffffff;
         padding: 12px;
         transform: none;
      }
      &--modal {
         padding: 0;
         border: none;
      }
   }
   &__wrap {
      display: flex;
      gap: 95px;
      align-items: flex-start;
      @media (max-width: 1441px) {
         gap: 60px;
      }
      @media (max-width: 1025px) {
         gap: 40px;
      }
   }
   &__wrapPc {
      @media (max-width: 769px) {
         display: none;
      }
   }
   &__wrapMob {
      display: none;
      @media (max-width: 769px) {
         display: flex;
         flex-direction: column;
         gap: 15px;
      }
   }
   &__filterTitle {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 1.2;
   }
   &__filterRaiting {
      display: flex;
      align-items: center;
      gap: 15px;
      @media (max-width: 769px) {
         gap: 12px;
      }
   }
   &__filterRaitingText {
      font-weight: 700;
      font-size: 29px;
      line-height: 1;
      @media (max-width: 769px) {
         font-size: 23px;
      }
   }
   &__filterRaitingText1 {
      font-weight: 400;
      font-size: 13px;
      line-height: 1.2;
      color: var(--gray-color);
   }
   &__rv {
      display: block;
   }
   &__filterBr {
      background: #e2e8ec;
      width: 100%;
      height: 1px;
      display: block;
      margin: 20px 0 30px;
      &--adaptive {
         margin: 15px 0;
      }
   }
   &__filterBtn {
      margin-top: 30px;
      @media (max-width: 769px) {
         margin-top: 0;
      }
   }
   &__filterLabelWrap {
      display: flex;
      flex-direction: column;
      gap: 10px;
   }
   &__filterLabelText {
      display: flex;
      gap: 15px;
      align-items: center;
      font-size: 14px;
      line-height: 17px;
   }
   &__cardMob {
      background: #ffffff;
      border-radius: 9px;
      padding: 12px;
      &--modal {
         padding: 15px 0;
         border-bottom: 1px solid #e2e8ec;
      }
   }
   &__cardMobBtn {
      margin-top: 30px;
   }
   &__cardMobHead {
      display: flex;
      gap: 20px;
      align-items: center;
      margin-bottom: 15px;
   }
   &__notWrap {
      display: flex;
      gap: 15px 20px;
      align-items: flex-start;
      justify-content: space-between;
      @media (max-width: 769px) {
         flex-direction: column;
      }
   }
   &__notWrapLeft {
      width: 620px;
      @media (max-width: 769px) {
         width: 100%;
      }
   }
   &__notWrapRight {
      width: 380px;
      @media (max-width: 769px) {
         width: 100%;
      }
   }
   &__notRevText {
      font-size: 14px;
      line-height: 1.2;
      margin-bottom: 10px;
      @media (max-width: 769px) {
         margin-bottom: 6px;
      }
   }
   &__notRev {
      @media (max-width: 769px) {
         background: #ffffff;
         border: 1px solid #e2e8ec;
         border-radius: 9px;
         padding: 12px;
         margin-bottom: 15px;
      }
   }
   &__cardMobWrap {
      margin-top: 20px;
   }
}

.reviewsSlider {
   &__slide {
      width: 100px;
      height: 100px;
      overflow: hidden;
      border-radius: 5px;
      @media (max-width: 1441px) {
         width: 80px;
         height: 80px;
      }
      @media (max-width: 769px) {
         width: 60px;
         height: 60px;
      }
      & img {
         display: block;
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }
   & ~ .swiper-button-prev-custom {
      left: 0;
   }
   & ~ .swiper-button-next-custom {
      right: 0;
   }
   &__slideBtn {
      background: var(--dark-gray-color3);
      font-size: 32px;
      color: var(--gray-color);
      transition: all 0.4s;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
         background-color: var(--dark-gray-color3-hover-btn);
      }
   }
}
