.clue {
   padding: 15px;
   display: flex;
   justify-content: space-between;
   gap: 20px;
   align-items: center;
   background: #f2f5f7;
   border-radius: 9px;
   &__main {
      display: flex;
      gap: 10px;
      align-items: center;
      font-size: 14px;
      line-height: 1;
   }
   &__icon {
      font-size: 24px;
      color: var(--red-color);
   }
   &__info {
      font-size: 20px;
      color: var(--gray-color);
      cursor: pointer;
      position: relative;
      &::after {
         content: "";
         display: block;
         border: 7px solid transparent;
         border-bottom: 10px solid #fff;
         z-index: 30;
         position: absolute;
         bottom: 0;
         left: 50%;
         transform: translate(-50%, 10px);
         pointer-events: none;
         opacity: 0;
         pointer-events: none;
         transition: all 0.4s;
      }
      &--active {
         &::after {
            opacity: 1;
         }
      }
   }
   &__clue {
      position: absolute;
      right: -25px;
      bottom: -8px;
      transform: translateY(100%);
      background: var(--light-color);
      border-radius: 9px;
      box-shadow: 0px 0px 20px rgba(151, 165, 173, 0.75);
      width: 445px;
      max-width: calc(100vw - 36px);
      padding: 40px 50px;
      color: var(--dark-color);
      font-family: var(--font-family, sans-serif);
      opacity: 0;
      pointer-events: none;
      transition: all 0.4s;
      z-index: 20;
      @media (max-width: 769px) {
         padding: 25px 30px;
      }
      &--active {
         opacity: 1;
         pointer-events: all;
      }
      &--min {
         width: 300px;
         padding: 20px 30px;
         @media (max-width: 769px) {
            position: fixed;
            bottom: 50%;
            z-index: 300;
            left: 50%;
            transform: translateX(-50%) translateY(50%);
         }
         & .clue__clueClose {
            right: 10px;
            top: 10px;
         }
      }
      &--bottom {
         @media (min-width: 769px) {
            top: -8px;
            bottom: initial;
            transform: translateY(-100%);
            height: fit-content;
            transition: opacity 0.4s;
         }
      }
   }
   &__link {
      display: block;
      width: fit-content;
      margin-top: 15px;
      color: var(--blue-color);
      transition: all 0.3s;
      &:hover {
         color: var(--dark-color-hover-link);
      }
   }
   &__title {
      font-weight: 700;
      font-size: 24px;
      line-height: 1.25;
      margin-bottom: 30px;
      @media (max-width: 769px) {
         font-size: 18px;
         margin-bottom: 20px;
      }
   }
   &__text {
      font-size: 14px;
      line-height: 1.7;
      @media (max-width: 769px) {
         font-size: 12px;
      }
   }
   &__clueClose {
      position: absolute;
      top: 22px;
      right: 22px;
      font-size: 24px;
      color: var(--gray-color);
      transition: all 0.4s;
      &:hover {
         color: var(--gray-color-hover-link);
      }
   }
}
