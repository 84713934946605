.pagination {
   display: flex;
   gap: 6px;
   width: 100%;
   justify-content: center;
   &__btn {
      display: flex;
      gap: 10px;
      padding: 12px;
      min-width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      font-size: 15px;
      line-height: 1.2;
      color: var(--dark-color);
      border: 1px solid var(--dark-gray-color3-hover-btn);
      cursor: pointer;
      transition: all 0.4s;
      &:hover {
         border-color: var(--gray-color);
      }
      &--active {
         background: var(--red-color);
         border-color: var(--red-color) !important;
         color: var(--light-color);
         cursor: default;
      }
      @media (max-width: 769px) {
         padding: 10px;
         font-size: 13px;
         &:not(.pagination__btn--nothide) {
             display: none;
         }
         &:nth-child(1) {
            display: flex;
         }
          &:nth-child(2) {
              display: flex;
          }
         &.pagination__btn--active {
            display: flex;
         }
          &:nth-last-child(2) {
              display: flex;
          }
         &:nth-last-child(1) {
            display: flex;
         }
      }
   }
   &__icon {
      font-size: 16px;
   }
   &__btnText {
      @media (max-width: 769px) {
         display: none;
      }
   }
}
