.footer {
   padding: 50px 0;
   background: #f2f5f7;
   @media (max-width: 1201px) {
      padding: 40px 0 82px;
   }
   @media (max-width: 769px) {
      padding-top: 30px;
   }
   &__info {
      margin: 50px 0;
      @media (max-width: 1201px) {
         margin: 40px 0;
      }
      @media (max-width: 769px) {
         margin: 30px 0;
      }
   }
}
.footerMenu {
   column-count: 4;
   column-gap: 20px;
   @media (max-width: 769px) {
      column-count: 2;
   }
   @media (max-width: 501px) {
      column-count: initial;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
      gap: 30px;
   }
   &__col {
      page-break-inside: avoid;
      break-inside: avoid;
      margin-bottom: 44px;
      @media (max-width: 1441px) {
         &:last-of-type {
            margin-left: 35px;
         }
      }
      @media (max-width: 1201px) {
         &:last-of-type {
            margin-left: initial;
         }
      }
      @media (max-width: 501px) {
         display: none;
         margin-bottom: 0;
         &--mob {
            display: block;
         }
      }
   }
   &__name {
      margin: 0 0 20px;
      font-size: 14px;
      line-height: 1.1;
      @media (max-width: 769px) {
         margin-bottom: 12px;
      }
      @media (max-width: 501px) {
         text-align: center;
         display: none;
         &--mob {
            display: block;
         }
      }
      &--mb {
         margin-bottom: 28px;
         @media (max-width: 769px) {
            margin-bottom: 12px;
         }
      }
   }
   &__list {
      & li {
         line-height: 1.4;
         &:not(:last-child) {
            margin-bottom: 10px;
         }
         & a {
            color: var(--dark-gray-color);
            transition: all 0.4s;
            &:hover {
               color: var(--dark-gray-color-hover-link);
            }
         }
      }
   }
   &__soc {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      @media (max-width: 501px) {
         gap: 20px 30px;
      }
   }
   &__socLink {
      font-size: 24px;
      transition: all 0.4s;
      color: var(--red-color);
      &.icon-vk-new:hover {
         color: #0077ff;
      }
      &.icon-odnoklassniki:hover {
         color: #ee8208;
      }
      &.icon-twitter:hover {
         color: #1da1f2;
      }
   }
   &__contactsCard {
      display: flex;
      gap: 17px;
      line-height: 1.4;
      &:not(:last-of-type) {
         margin-bottom: 10px;
      }
      @media (max-width: 501px) {
         text-align: center;
      }
   }
   &__contactsIcon {
      font-size: 18px;
      color: var(--red-color);
      @media (max-width: 501px) {
         display: none;
      }
   }
   &__contactsContent {
      width: calc(100% - 35px);
      @media (max-width: 501px) {
         width: 100%;
         font-size: 16px;
      }
   }
   &__telCard {
      display: flex;
      flex-direction: column;
      &:not(:last-of-type) {
         margin-bottom: 10px;
      }
   }
   &__tel {
      color: var(--dark-gray-color);
      transition: all 0.4s;
      &:hover {
         color: var(--dark-gray-color-hover-link);
      }
      @media (max-width: 501px) {
         font-size: 16px;
      }
   }
   &__telDescrription {
      font-size: 10px;
      color: #8c959c;
      @media (max-width: 501px) {
         font-size: 12px;
      }
   }
   &__contactsEmail {
      color: var(--dark-gray-color);
      transition: all 0.4s;
      &:hover {
         color: var(--dark-gray-color-hover-link);
      }
      @media (max-width: 501px) {
         font-size: 16px;
      }
   }
}
.footerInfo {
   border-top: 1px solid #b8c0c5;
   border-bottom: 1px solid #b8c0c5;
   padding: 29px 0;
   display: flex;
   justify-content: space-between;
   gap: 30px 20px;
   flex-wrap: wrap;
   align-items: center;
   @media (max-width: 1025px) {
      flex-direction: column;
   }
   &__banks {
      display: flex;
      gap: 18px 30px;
      flex-wrap: wrap;
      align-items: center;
      @media (max-width: 769px) {
         justify-content: center;
         padding: 0 30px;
      }
   }
   &__bank {
      & svg {
         display: block;
         & * {
            transition: all 0.4s;
         }
         &:not(:hover) {
            & * {
               fill: #b8c0c5;
            }
         }
      }
   }
   &__ratings {
      display: flex;
      gap: 20px 60px;
      flex-wrap: wrap;
      @media (max-width: 769px) {
         flex-direction: column;
         align-items: center;
      }
   }
   &__rating {
      margin-bottom: 14px;
      @media (max-width: 769px) {
         margin-bottom: 16.8px;
      }
   }
   &__yandexMarket {
      display: block;
      width: fit-content;
      height: 20px;
      @media (max-width: 769px) {
         height: initial;
      }
      & img {
         display: block;
         width: 100%;
         height: 100%;
         @media (max-width: 769px) {
            height: 24px;
            width: 130px;
            object-fit: contain;
         }
      }
   }
   &__yandex {
      display: flex;
      flex-direction: column;
      gap: 6px;
      @media (max-width: 769px) {
         gap: 7.6px;
      }
   }
   &__yandexWrap {
      display: flex;
      align-items: center;
      gap: 14px;
   }
   &__yandexDescr {
      color: var(--gray-color2);
      font-size: 10px;
      line-height: 1.4;
      @media (max-width: 769px) {
         font-size: 12px;
         text-align: center;
      }
   }
   &__yandexIcon {
      font-size: 32px;
      color: #fc3f1d;
   }
   &__yandexRating {
      font-size: 18px;
      line-height: 1;
      font-weight: 700;
   }
}
.footerBottom {
   &__text {
      font-size: 12px;
      line-height: 1.4;
      color: var(--gray-color2);
      margin-bottom: 45px;
      @media (max-width: 769px) {
         text-align: center;
         margin-bottom: 30px;
      }
   }
   &__info {
      display: flex;
      align-items: center;
      gap: 20px 40px;
      flex-wrap: wrap;
      justify-content: space-between;
      color: var(--gray-color2);
      @media (max-width: 1025px) {
         flex-direction: column;
         align-items: center;
      }
   }
   &__wrap {
      display: flex;
      align-items: center;
      gap: 20px 105px;
      flex-wrap: wrap;
      @media (max-width: 1201px) {
         gap: 20px 50px;
      }
      @media (max-width: 1025px) {
         flex-direction: column;
         align-items: center;
      }
   }
   &__logo {
      font-size: 95px;
      width: 95px;
      height: 24px;
      position: relative;
      color: var(--gray-color2);
      transition: all 0.4s;
      &::before {
         pointer-events: none;
         display: block;
         transform: translateY(-50%);
         top: 50%;
         position: absolute;
      }
      &:hover {
         color: var(--dark-color);
      }
   }
   &__copyright {
      font-size: 12px;
      line-height: 1.4;
   }
   &__termsOfUse {
      font-size: 12px;
      line-height: 1.4;
      color: var(--gray-color2);
      transition: all 0.4s;
      &:hover {
         color: var(--gray-color-hover-link);
      }
   }
   &__design {
      margin-left: auto;
      display: flex;
      gap: 7px;
      align-items: center;
      font-size: 12px;
      line-height: 1.4;
      color: var(--gray-color2);
      & img {
         display: block;
         width: 110px;
         object-fit: contain;
         margin-bottom: -5px;
      }
   }
}
