.personal {
   &__page {
      padding-bottom: 80px;
      @media (max-width: 769px) {
         padding-bottom: 60px;
      }
   }
   &__wrap {
      display: flex;
      align-items: flex-start;
      gap: 60px;
   }
   &__menu {
      width: 300px;
      min-width: 300px;
      @media (max-width: 1441px) {
         width: 240px;
         min-width: 240px;
      }
      @media (max-width: 769px) {
         display: none;
      }
   }
   &__data {
      max-width: 880px;
      flex: 1 1 auto;
      // width: calc(100% - 360px);
      display: flex;
      gap: 40px 100px;
      align-items: flex-start;
      @media (max-width: 1251px) {
         flex-direction: column-reverse;
         width: calc(100% - 300px);
      }
      @media (max-width: 769px) {
         width: 100%;
         gap: 0;
      }
   }
   &__dataWrap {
      width: calc(100% - 300px);
      display: flex;
      flex-direction: column;
      gap: 40px;
      @media (max-width: 1251px) {
         width: 100%;
      }
   }
   &__avatar {
      &--personal {
         @media (max-width: 769px) {
            margin-bottom: 40px;
         }
      }
      & .input-file-row {
         display: flex;
         justify-content: center;
         align-items: center;
         width: 200px;
         height: 220px;
         border: 1px solid var(--dark-gray-color3-hover-btn);
         border-radius: 9px;
         transition: all 0.4s;
         color: var(--gray-color);
         padding: 20px;
         cursor: pointer !important;
         &:hover {
            border-color: var(--dark-gray-color3);
         }
         @media (max-width: 769px) {
            width: 100%;
            height: 80px;
            border: none;
            padding: 0;
         }
      }
      & .fileLabel__listItem {
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
      &Icon {
         font-size: 120px;
         color: var(--dark-gray-color3-hover-btn);
         @media (max-width: 769px) {
            font-size: 80px;
         }
      }
      &Text {
         font-size: 14px;
         line-height: 1.5;
         color: currentColor;
      }
      & input {
         display: none;
      }
      &Label {
         display: flex;
         flex-direction: column;
         align-items: center;
         gap: 20px;
         cursor: pointer;
         @media (max-width: 769px) {
            flex-direction: row;
            gap: 20px;
         }
      }
      & .fileLabel {
         width: initial;
         padding: initial;
         background: initial;
         border-radius: initial;
         transition: initial;
         cursor: pointer;
         & > span {
            color: var(--gray-color);
         }
      }
   }
   &__dataTitle {
      margin-bottom: 20px;
      display: flex;
   }
   &__dataTitleIcon {
      font-size: 32px;
      color: var(--red-color);
      margin-right: 20px;
   }
   &__dataTitleText {
      font-size: 20px;
      line-height: 1.5;
   }
   &__dataLabel {
      padding: 20px 0;
      border-bottom: 1px solid var(--dark-gray-color3-hover-btn);
      &:first-child {
         padding-top: 0;
      }
      &:last-child {
         padding-bottom: 0;
         border: none;
      }
      &--name {
         @media (max-width: 769px) {
            & input {
               max-width: 50%;
            }
         }
      }
   }
   &__dataListLabel {
      padding-left: 55px;
      @media (max-width: 769px) {
         padding-left: 0;
      }
      &--checkbox {
         display: flex;
         flex-direction: column;
         gap: 10px;
      }
   }
   &__addAddr {
      margin-top: 20px;
   }
   &__mobBtns {
      display: none;
      @media (max-width: 769px) {
         display: flex;
         flex-direction: column;
         gap: 10px;
         width: 100%;
      }
      &--personal {
         @media (max-width: 769px) {
            margin-top: 40px;
         }
      }
   }
   &__history {
      width: calc(100% - 360px);
      display: flex;
      flex-direction: column;
      gap: 30px;
      @media (max-width: 1441px) {
         width: calc(100% - 300px);
      }
      @media (max-width: 769px) {
         width: 100%;
      }
      &Card {
         display: flex;
         flex-direction: column;
         width: 100%;
         cursor: pointer;
      }
      &Main {
         background: #f2f5f7;
         border-radius: 5px 5px 0px 0px;
         padding: 20px 30px;
         display: flex;
         gap: 12px;
         align-items: flex-start;
         justify-content: space-between;
         @media (max-width: 769px) {
            padding: 12px;
            flex-direction: column;
         }
      }
      &Date {
         font-weight: 700;
         font-size: 20px;
         line-height: 1.5;
         color: var(--dark-color);
         transition: all 0.4s;
         @media (max-width: 769px) {
            font-size: 16px;
            line-height: 1.875;
            text-decoration: underline;
         }
      }
      &Price {
         font-weight: 700;
         font-size: 20px;
         line-height: 1.5;
         @media (max-width: 769px) {
            font-size: 16px;
            line-height: 1.875;
         }
      }
      &Number {
         font-size: 14px;
         line-height: 1.5;
         color: #97a5ad;
      }
      &MainLeft {
         display: flex;
         flex-direction: column;
         gap: 5px;
         @media (max-width: 769px) {
            gap: 0;
         }
      }
      &Info {
         border-width: 0px 1px 1px 1px;
         border-style: solid;
         border-color: #e2e8ec;
         border-radius: 0px 0px 5px 5px;
         padding: 20px 30px;
         display: flex;
         justify-content: space-between;
         align-items: center;
         gap: 20px 25px;
         flex-wrap: wrap;
         @media (max-width: 769px) {
            padding: 12px;
            gap: 12px;
         }
      }
      &DeliveryDate {
         display: flex;
         flex-direction: column;
         width: 200px;
         @media (max-width: 769px) {
            width: fit-content;
         }
      }
      &Delivery {
         font-weight: 700;
         font-size: 16px;
         line-height: 1.875;
      }
      &DateDel {
         font-weight: 400;
         font-size: 14px;
         line-height: 1.7;
         color: var(--gray-color);
      }
      &Products {
         display: flex;
         gap: 10px;
         flex-wrap: wrap;
         flex: 1 1 auto;
         justify-content: flex-end;
         @media (max-width: 769px) {
            justify-content: flex-start;
            width: 100%;
         }
      }
      &Product {
         display: block;
         width: 60px;
         height: 60px;
         & img {
            display: block;
            width: 60px;
            height: 60px;
            object-fit: contain;
         }
      }
      &Status {
         font-size: 12px;
         line-height: 1.7;
         padding: 8px 18px;
         border: 2px solid #e2e8ec;
         border-radius: 45px;
         @media (max-width: 769px) {
            margin-left: auto;
         }
         &--canceled {
            color: var(--red-color);
            background-color: #f2f5f7;
            border-color: #f2f5f7;
         }
         &--success {
            background-color: #f2f5f7;
            border-color: #f2f5f7;
         }
      }
   }
   &__backLink {
      display: none;
      margin: 15px 0 12px;
      width: fit-content;
      gap: 6px;
      font-size: 12px;
      line-height: 18px;
      align-items: center;
      color: var(--dark-color);
      @media (max-width: 769px) {
         display: flex;
      }
      &Icon {
         color: var(--red-color);
         font-size: 18px;
      }
   }

   &__bonus {
      width: calc(100% - 360px);
      display: flex;
      flex-direction: column;
      gap: 30px;
      @media (max-width: 1441px) {
         width: calc(100% - 300px);
      }
      @media (max-width: 769px) {
         width: 100%;
         gap: 20px;
      }

      &Card {
         background: #1f2d35;
         border-radius: 7px;
         display: flex;
         gap: 50px;
         align-items: center;
         padding: 30px 60px;
         @media (max-width: 1025px) {
            flex-direction: column;
            gap: 12px;
            align-items: flex-start;
            padding: 25px 40px;
         }
         @media (max-width: 769px) {
            padding: 20px;
         }
      }
      &Icon {
         font-size: 36px;
      }
      &Score {
         display: flex;
         gap: 7px 10px;
         align-items: center;
         font-weight: 700;
         font-size: 32px;
         line-height: 1.2;
         color: var(--light-color);
         flex-wrap: wrap;
         width: 150px;
         @media (max-width: 1025px) {
            width: 100%;
         }
      }
      &Text {
         font-weight: 400;
         font-size: 18px;
         width: 100%;
         @media (max-width: 1025px) {
            width: fit-content;
            margin-left: 13px;
         }
      }
      &Decor {
         display: block;
         width: 1px;
         height: 100%;
         min-height: 100px;
         background: #e2e8ec;
         @media (max-width: 1025px) {
            display: none;
         }
      }
      &InfoBlock {
         width: calc(100% - 250px);
         max-width: 420px;
         display: flex;
         flex-direction: column;
         gap: 12px;
         @media (max-width: 1025px) {
            width: 100%;
            max-width: 100%;
         }
      }
      &InfoText {
         font-size: 14px;
         line-height: 1.2;
         color: var(--dark-gray-color3);
         margin: 0;
         @media (max-width: 769px) {
            font-size: 12px;
            color: #97a5ad;
         }
      }
      &InfoLink {
         font-size: 14px;
         line-height: 1.2;
         color: var(--red-color);
         transition: all 0.4s;
         @media (max-width: 769px) {
            font-size: 12px;
         }
         &:hover {
            color: var(--light-color);
         }
      }

      &Item {
         display: flex;
         justify-content: space-between;
         gap: 20px;
         align-items: center;
         padding: 20px 0;
         border-bottom: 1px solid #e2e8ec;
         @media (max-width: 1025px) {
            gap: 12px;
            flex-wrap: wrap;
            padding: 12px 0;
         }
         &:first-of-type {
            padding-top: 0;
         }
      }
      &ItemBonus {
         display: flex;
         width: 100px;
         gap: 12px;
         align-items: center;
         font-size: 16px;
         line-height: 1.2;
         justify-content: flex-end;
         @media (max-width: 1025px) {
            order: -2;
            width: fit-content;
            font-weight: 700;
            gap: 10px;
         }
         &--delivery {
            color: #b8c0c5;
         }
         &--fire {
            color: var(--red-color);
         }
         &Icon {
            font-size: 24px;
            color: var(--red-color);
         }
      }
      &ItemCol {
         display: flex;
         flex-direction: column;
         gap: 4px;
         &1 {
            width: 280px;
            flex: 1 1 auto;
            @media (max-width: 1025px) {
               width: calc(50% - 6px);
               flex: 0 0 initial;
            }
         }
         &2 {
            width: 180px;
            @media (max-width: 1025px) {
               width: calc(50% - 6px);
            }
         }
         &3 {
            width: 180px;
            @media (max-width: 1025px) {
               order: -1;
               width: fit-content;
               flex-direction: row;
               margin-right: auto;
               & .personal__bonusItemText2 {
                  font-size: 12px;
                  line-height: 1.5;
                  color: var(--gray-color);
               }
            }
         }
      }
      &ItemNumber {
         font-size: 14px;
         line-height: 1.5;
         color: var(--dark-color);
         transition: all 0.4s;
         display: block;
         width: fit-content;
         &:hover {
            color: var(--dark-color-hover-link);
         }
      }
      &ItemText {
         font-size: 12px;
         line-height: 1.5;
         color: var(--gray-color);
      }
      &ItemText2 {
         font-size: 14px;
         line-height: 1.5;
         color: var(--dark-color);
      }
   }

   &__listMenu {
      display: flex;
      flex-direction: column;
      gap: 6px;
      &Link {
         background: var(--dark-gray-color3);
         border-radius: 9px;
         display: flex;
         gap: 20px;
         align-items: center;
         padding: 20px;
         color: var(--dark-color);
         transition: all 0.4s;
         font-size: 14px;
         line-height: 1.7;
         &:hover {
            background: var(--dark-gray-color3-hover-btn);
         }
         &Icon {
            font-size: 24px;
            color: var(--red-color);
         }
      }
   }
   &__titleMenuPage {
      margin: 40px 0 20px;
      font-weight: 700;
      font-size: 20px;
      line-height: 1.2;
      color: var(--dark-color);
   }
}
