.header {
   position: sticky;
   top: 0;
   z-index: 1000;
   background: #ffffff;
   &--scroll {
      box-shadow: 0px 0px 20px rgba(151, 165, 173, 0.75);
      transition: all 0.4s;
   }
}
.headerTop {
   padding: 10px 0;
   min-height: 40px;
   margin-bottom: 10px;
   background: #f2f5f7;
   display: flex;
   align-items: center;
   @media (max-width: 1201px) {
      padding: 7px 0;
      min-height: 34px;
      margin-bottom: 5px;
   }
   @media (max-width: 769px) {
      display: none;
   }
   & .container {
      width: 100%;
   }
   &--scroll {
      display: none;
   }
   &__wrap {
      display: flex;
      gap: 20px;
      align-items: center;
      justify-content: space-between;
   }
   &__addr {
      display: flex;
      gap: 10px;
      align-items: center;
      @media (max-width: 1201px) {
         gap: 8px;
      }
   }
   &__addrSep {
      font-size: 13px;
      line-height: 1.15;
      color: rgba(0, 0, 0, 0.4);
      @media (max-width: 1201px) {
         font-size: 12px;
      }
   }
   &__addrC {
      font-size: 12px;
      line-height: 1.4;
      color: #677178;
      @media (max-width: 1201px) {
         font-size: 11px;
      }
   }
   &__addrBtn {
      font-size: 12px;
      line-height: 1.15;
      color: #677178;
      transition: all 0.4s;
      @media (max-width: 1201px) {
         font-size: 11px;
      }
      &:hover {
         color: var(--dark-color);
      }
   }
   &__addrIcon {
      font-size: 16px;
      color: var(--dark-color);
      vertical-align: text-bottom;
      margin-right: 6px;
      display: inline-block;
      @media (max-width: 1201px) {
         font-size: 14px;
         margin-right: 5px;
      }
   }
   &__links {
      display: flex;
      gap: 10px;
      align-items: center;
      @media (max-width: 1201px) {
         gap: 8px;
      }
   }
   &__link {
      font-weight: 400;
      font-size: 13px;
      line-height: 1.15;
      color: #677178;
      transition: all 0.4s;
      @media (max-width: 1201px) {
         font-size: 12px;
      }
      &[href^="tel:"] {
         font-weight: 700;
         color: var(--dark-color);
         &:hover {
            color: #677178;
         }
      }
      &:hover {
         color: var(--dark-color);
      }
   }
}
.headerMiddle {
   padding: 10px 0;
   transition: none;
   @media (max-width: 1201px) {
      padding: 7px 0;
   }
   @media (max-width: 769px) {
      display: none;
   }
   &--scroll {
      transition: all 0.4s;
   }
   &__wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
   }
   &__logo {
      font-size: 111px;
      width: 111px;
      height: 28px;
      position: relative;
      color: var(--dark-color);
      transition: all 0.4s;
      margin-right: 10px;
      @media (max-width: 1201px) {
         font-size: 95px;
         width: 95px;
         height: 24px;
         margin-right: 0;
      }
      &::before {
         pointer-events: none;
         display: block;
         transform: translateY(-50%);
         top: 50%;
         position: absolute;
      }
   }
   &__iconBtnCatalog {
      font-size: 24px;
      @media (max-width: 1201px) {
         font-size: 20px;
      }
   }
   &__search {
      flex: 1 1 auto;
      display: block;
      margin-right: 15px;
      position: relative;
      @media (max-width: 1201px) {
         margin-right: 0;
      }
   }
   &__searchLabel {
      display: block;
      width: 100%;
      position: relative;
      z-index: 10;
      &--mob {
         width: calc(100% - 48px);
      }
   }
   &__searchInput {
      display: block;
      width: 100%;
      padding: 17px 55px 18px 20px;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.2;
      color: #2b2b2b;
      background: #ffffff;
      border: 1px solid #e2e8ec;
      border-radius: 9px;
      outline: none;
      @media (max-width: 1201px) {
         font-size: 14px;
         padding: 13px 50px 13px 15px;
      }
      &::placeholder {
         color: #b8c0c5;
         font-weight: inherit;
         font-size: inherit;
      }
   }
   &__searchBtn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 5px;
      width: 44px;
      height: 44px;
      background: #ed0000;
      border-radius: 7px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      color: var(--light-color);
      transition: all 0.4s;
      @media (max-width: 1201px) {
         width: 36px;
         height: 36px;
         font-size: 20px;
      }
      @media (max-width: 769px) {
         width: 44px;
         height: 44px;
         font-size: 24px;
      }
      &:hover {
         background: #c70000;
      }
   }
   &__icons {
      display: flex;
      gap: 30px;
      @media (max-width: 1201px) {
         gap: 20px;
      }
   }
   &__searchBg {
      position: fixed;
      z-index: 5;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
      opacity: 0;
      pointer-events: none;
      transition: all 0.4s;
      &--active {
         opacity: 1;
         pointer-events: all;
      }
   }
}
.itemBtn {
   display: flex;
   flex-direction: column;
   gap: 4px;
   align-items: center;
   color: var(--gray-color);
   font-size: 12px;
   line-height: 1.15;
   text-align: center;
   position: relative;
   outline: none;
   &--active {
      color: var(--dark-color);
   }
   @media (max-width: 1201px) {
      font-size: 11px;
      gap: 2px;
   }
   @media (max-width: 769px) {
      font-size: 9px;
   }
   &__account {
      &:hover {
         &::after {
            content: "";
            display: block;
            width: 100%;
            height: 20px;
            z-index: 2;
            position: absolute;
            bottom: 0;
            transform: translateY(100%);
            left: 0;
         }
         & .itemBtn__profileMenu {
            opacity: 1;
            pointer-events: all;
         }
      }
   }
   &__profileMenu {
      z-index: 10;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 240px;
      filter: drop-shadow(0px 0px 20px rgba(151, 165, 173, 0.75));
      display: flex;
      flex-direction: column;
      background: #ffffff;
      transform: translateY(calc(100% + 10px));
      border: 1px solid #e2e8ec;
      border-radius: 5px 5px 0px 0px;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.4s;
      @media (max-width: 1201px) {
         width: 220px;
      }
      @media (max-width: 1025px) {
         width: 160px;
      }
      & a {
         text-align: left;
         display: block;
         width: 100%;
         padding: 16px 20px;
         font-weight: 700;
         font-size: 14px;
         color: #2b2b2b;
         transition: all 0.4s;
         @media (max-width: 1201px) {
            font-size: 13px;
            padding: 13px 17px;
         }
         @media (max-width: 1025px) {
            font-size: 12px;
            padding: 11px 15px;
         }
         &:hover {
            background: #f2f5f7;
         }
         &:not(:last-child) {
            border-bottom: 1px solid #e2e8ec;
         }
      }
   }
   &__name {
      transition: all 0.4s;
      &--bold {
         font-weight: 700;
      }
   }
   &:hover {
      color: var(--dark-color);
      & .itemBtn__name {
         color: var(--red-color);
      }
   }
   &__icon {
      font-size: 32px;
      position: relative;
      transition: all 0.4s;
      @media (max-width: 1201px) {
         font-size: 28px;
      }
      &--numb {
         color: var(--dark-color);
         & ~ .itemBtn__name {
            color: var(--dark-color);
         }
         &::after {
            content: attr(data-numb);
            font-family: var(--font-family);
            top: 0;
            right: 0;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            background: var(--red-color);
            border: 2px solid var(--light-color);
            border-radius: 20px;
            padding: 1px 5px;
            font-weight: 700;
            font-size: 10px;
            line-height: 1.4;
            color: var(--light-color);
            transform: translateX(33%);
            transition: all 0.4s;
         }

          &[data-numb=""]::after, &[data-numb="0"]::after {
              display:none;
          }
      }
   }
   &__basketHover {
      opacity: 0;
      pointer-events: none;
      transition: all 0.4s;
      position: absolute;
      z-index: 5;
      bottom: 0;
      right: 0;
      transform: translateY(calc(100% + 12px));
   }
   &__basket {
      position: relative;
      &:hover {
         &::after {
            content: "";
            display: block;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 15px;
            transform: translateY(100%);
            position: absolute;
         }
         & .itemBtn__basketHover {
            opacity: 1;
            pointer-events: all;
         }
      }
   }
}
.headerBottom {
   position: relative;
   z-index: 3;
   background-color: var(--light-color);
   padding: 0 0 10px;
   @media (max-width: 1201px) {
      padding: 0 0 7px;
   }
   @media (max-width: 769px) {
      display: none;
   }
   &--scroll {
      display: none;
   }
   &__wrap {
      display: flex;
      gap: 2px 40px;
      flex-wrap: wrap;
      @media (max-width: 1201px) {
         gap: 2px 20px;
      }
      @media (max-width: 1025px) {
         gap: 2px 15px;
      }
   }
   &__link {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      line-height: 1;
      color: var(--dark-color);
      padding: 8px 0;
      transition: all 0.4s;
      @media (max-width: 1201px) {
         gap: 6px;
         font-size: 13px;
         padding: 5px 0;
      }
      @media (max-width: 1025px) {
         gap: 5px;
         font-size: 12px;
         padding: 4px 0;
      }
      &:hover {
         color: var(--dark-color-hover-link);
      }
   }
   &__linkIcon {
      font-size: 24px;
      color: var(--red-color);
      @media (max-width: 1201px) {
         font-size: 20px;
      }
      @media (max-width: 1025px) {
         font-size: 18px;
      }
   }
}
.headerMob {
   display: none;
   height: 60px;
   padding: 10px 0;
   background: #ffffff;
   align-items: center;
   transition: all 0.4s;
   border-bottom: 1px solid #e5e5e5;
   @media (max-width: 769px) {
      display: flex;
   }
   & .container {
      width: 100%;
   }
   &__wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
   }
   &__burger,
   &__search {
      font-size: 28px;
      color: var(--red-color);
   }
   &__logo {
      font-size: 87px;
      width: 87px;
      height: 22px;
      position: relative;
      color: var(--dark-color);
      transition: all 0.4s;
      &::before {
         pointer-events: none;
         display: block;
         transform: translateY(-50%);
         top: 50%;
         position: absolute;
      }
   }
}
.menuMob {
   display: none;
   position: fixed;
   align-items: center;
   height: 70px;
   bottom: 0;
   left: 0;
   width: 100%;
   background-color: #fff;
   padding: 14px 0;
   z-index: 1010;
   border: 1px solid #e5e5e5;
   @media (max-width: 769px) {
      display: flex;
   }
   & .container {
      width: 100%;
   }
   &__wrap {
      padding: 0 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
   }
   &__item {
      font-size: 9px;
      width: 50px;
      gap: 3px;
   }
}
.searchResult {
   max-height: calc(100vh - 150px);
   overflow: auto;
   position: absolute;
   bottom: 0;
   left: 0;
   width: 100%;
   min-width: 450px;
   background: #ffffff;
   border-radius: 15px;
   z-index: 10;
   padding: 20px;
   transform: translateY(calc(100% + 10px));
   opacity: 0;
   pointer-events: none;
   transition: opacity 0.4s;
   &::-webkit-scrollbar {
      width: 6px;
   }
   &::-webkit-scrollbar-track {
      background-color: darkgrey;
   }
   &::-webkit-scrollbar-thumb {
      background-color: #d3d3d3;
      border-radius: 10px;
   }
   &--active {
      opacity: 1;
      pointer-events: all;
   }
   &--mob {
      position: relative;
      left: initial;
      bottom: initial;
      left: initial;
      opacity: 1;
      transform: none;
      padding: 0;
      border-radius: 0;
      width: 100%;
      max-height: initial;
      min-width: initial;
      pointer-events: all;
   }
   &__listLinks {
      display: flex;
      flex-direction: column;
      gap: 17px;
      margin-bottom: 20px;
      @media (max-width: 1025px) {
         gap: 10px;
         margin-bottom: 15px;
         @media (max-width: 769px) {
            margin-bottom: 20px;
            gap: 17px;
         }
      }
      & a {
         font-weight: 400;
         font-size: 16px;
         line-height: 1.2;
         color: var(--dark-color);
         transition: all 0.4s;
         @media (max-width: 1025px) {
            font-size: 14px;
         }
         @media (max-width: 769px) {
            font-size: 16px;
         }
         &:hover {
            color: var(--dark-color-hover-link);
         }
      }
   }
   &__more {
      @media (max-width: 769px) {
         margin-top: 5px;
      }
   }
   &__card {
   }
}
.searchResultCard {
   padding: 4px 0;
   border-top: 1px solid #e2e8ec;
   display: flex;
   align-items: center;
   justify-content: space-between;
   gap: 20px;
   &__img {
      display: block;
      width: 60px;
      height: 60px;
      object-fit: cover;
   }
   &__info {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      gap: 6px;
      padding: 3px 0;
   }
   &__name {
      font-size: 14px;
      line-height: 1.15;
      color: var(--dark-color);
      transition: all 0.4s;
      &:hover {
         color: var(--dark-color-hover-link);
      }
   }
   &__descr {
      font-weight: 700;
      font-size: 12px;
      line-height: 1.2;
      color: var(--gray-color);
      display: flex;
      gap: 8px;
      align-items: center;
      &--delivery {
         color: #a7352e;
      }
      &--in {
         color: #37a72e;
      }
   }
   &__icon {
      font-size: 16px;
   }
   &__priceWrap {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: flex-end;
      text-align: right;
   }
   &__price {
      font-weight: 700;
      font-size: 14px;
      line-height: 1.2;
      text-align: right;
      color: var(--dark-color);
      white-space: nowrap;
   }
   &__saleWrap {
      display: flex;
      gap: 7px;
      align-items: center;
   }
   &__priceOld {
      font-size: 12px;
      line-height: 1.2;
      text-decoration: line-through;
      color: var(--gray-color);
      white-space: nowrap;
   }
   &__sale {
      font-size: 10px;
      white-space: nowrap;
      line-height: 1.2;
      color: var(--light-color);
      padding: 3px 4px 3px 6px;
      background: var(--red-color);
      border-radius: 10px 0px 0px 10px;
   }
}
.searchMob {
   padding: 16px 55px 16px 20px;
   font-size: 16px;
}
.headerKatalog {
   position: absolute;
   border: 1px solid var(--dark-gray-color3);
   z-index: 2;
   width: 100%;
   bottom: 0;
   transform: translateY(100%);
   background: var(--dark-gray-color3);
   filter: drop-shadow(0px 20px 20px rgba(151, 165, 173, 0.35));
   height: calc(100vh - 200px);
   max-height: 750px;
   overflow: hidden;
   transition: opacity 0.4s;
   opacity: 0;
   pointer-events: none;
   @media (max-width: 769px) {
      display: none;
   }
   &--active {
      opacity: 1;
      pointer-events: all;
   }
   & .container {
      height: 100%;
   }
   &--scroll {
      height: calc(100vh - 100px);
   }
   &__wrap {
      display: flex;
      height: 100%;
   }
   /*&__menu {
      width: 290px;
      padding: 30px 0 30px 30px;
      position: relative;
      z-index: 2;
      height: fit-content;
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 100%;
      &::-webkit-scrollbar {
         width: 6px;
      }
      &::-webkit-scrollbar-track {
         background-color: darkgrey;
      }
      &::-webkit-scrollbar-thumb {
         background-color: #d3d3d3;
         border-radius: 10px;
      }
      @media (max-width: 1025px) {
         padding: 20px 0;
         width: 220px;
      }
      // &::after {
      //    content: "";
      //    display: block;
      //    position: absolute;
      //    top: 0;
      //    left: 100%;
      //    height: 1000px;
      //    background-color: var(--light-color);
      //    width: 100000%;
      // }
   }
   &__menuList {
      list-style: 0;
      margin: 0;
      padding: 0;
      width: 100%;
      list-style: none;
      height: fit-content;
   }
   &__icon {
      color: var(--red-color);
      font-size: 28px;
      @media (max-width: 1025px) {
         font-size: 24px;
      }
   }
   &__menuItem {
      font-size: 14px;
      line-height: 1.2;
      color: var(--dark-color);
      display: flex;
      gap: 11px;
      align-items: center;
      padding: 15px;
      transition: all 0.4s;
      border-right: 2px solid transparent;
      @media (max-width: 1025px) {
         font-size: 12px;
         gap: 10px;
      }
      &--active {
         background: #ffffff;
         border-color: var(--red-color);
      }
   }

   &__contentBlock {
      display: flex;
      gap: 40px 20px;
      flex-wrap: wrap;
      width: 100%;
      @media (max-width: 1441px) {
         max-width: 700px;
      }
      @media (max-width: 1025px) {
         gap: 30px 20px;
      }
   }
   &__contentCard {
      width: calc(25% - 15px);
      @media (max-width: 1441px) {
         width: calc(100% / 3 - 40px / 3);
      }
      @media (max-width: 1025px) {
         width: calc(50% - 10px);
      }
   }

   &__contentCardList {
      padding: 0;
      margin: 0;
      list-style: none;
      line-height: 1;
   }
   &__contentCardLink {
      transition: all 0.4s;
      font-size: 14px;
      line-height: 1.6;
      color: var(--dark-color);
      &:hover {
         color: var(--dark-color-hover-link);
      }
   }*/
    &__contentWrap {
        position: relative;
        z-index: 2;
        width: 100%;
        height: 100%;
        @media (max-width: 1025px) {
            width: 100%;
        }
    }
    &__contentBack {
        display: none;
        position: absolute;
        left: 40px;
        top: 30px;
        @media (max-width: 1025px) {
            left: 30px;
        }
    }
    &__back {
        color:#677178;
        &:hover {
            color: var(--dark-color);
        }
    }
    &__content {
        height: 100%;
        padding: 30px 40px;
        overflow: auto;
        display: none;
        @media (max-width: 1025px) {
            padding: 20px 30px;
        }
        &--pad{
            padding-top: 60px;
        }
        &--active {
            display: block;
        }
        &::-webkit-scrollbar {
            width: 6px;
        }
        &::-webkit-scrollbar-track {
            background-color: darkgrey;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #d3d3d3;
            border-radius: 10px;
        }
    }
    &__contentName {
        font-weight: 700;
        font-size: 24px;
        line-height: 1.25;
        color: var(--dark-color);
        margin-bottom: 28px;
        @media (max-width: 1025px) {
            font-size: 22px;
            margin-bottom: 22px;
        }
    }
    &__contenClose {
        font-size: 24px;
        position: absolute;
        top: 23px;
        right: 0;
        color: var(--gray-color);
        transition: all 0.4s;
        &:hover {
            color: var(--gray-color-hover-link);
        }
    }
    &__contentNameCat {
        font-weight: 700;
        font-size: 14px;
        line-height: 1.2;
        color: var(--dark-color);
        margin-bottom: 10px;
        width: fit-content;
        position: relative;
        transition: all 0.4s;
        &:hover {
            color: var(--dark-color-hover-link);
        }
        &::after {
            content: attr(data-text);
            font-weight: 400;
            font-size: 12px;
            line-height: 1.2;
            color: var(--gray-color);
            // position: absolute;
            // right: 0;
            // top: 0;
            // transform: translateX(100%);
            padding-left: 2px;
            padding-top: 5px;
            white-space: nowrap;
            display: block;
        }
    }
    &__contentCardItem {
        width: calc(100% - 130px);
        display: flex;
        flex-direction: column;
        gap: 12px;
        @media (max-width: 769px) {
            gap: 22px;
            width: calc(100% - 90px);
        }
        justify-content: center;
    }
}
.basketHover {
   background: #ffffff;
   box-shadow: 0px 0px 20px rgba(151, 165, 173, 0.75);
   border-radius: 9px;
   padding: 20px;
   width: 540px;
   cursor: default;
   &__card {
      display: flex;
      gap: 20px;
      align-items: center;
      justify-content: space-between;
      &--template {
          display:none;
      }
      &:not(:last-of-type) {
         margin-bottom: 20px;
      }
   }
   &__imgWrap {
      display: block;
      width: 60px;
      & img {
         display: block;
         width: 60px;
         height: 60px;
         object-fit: cover;
      }
   }
   &__remove {
      color: var(--gray-color);
      font-size: 16px;
      transition: all 0.4s;
      &:hover {
         color: var(--gray-color-hover-link);
      }
   }
   &__info {
      display: flex;
      flex-direction: column;
      gap: 6px;
      align-items: flex-start;
      flex: 1 1 auto;
   }
   &__infoAttr {
      font-size: 12px;
      line-height: 1.4;
      color: var(--gray-color);
   }
   &__infoName {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.15;
      color: var(--dark-color);
      transition: all 0.4s;
      text-align: left;
      &:hover {
         color: var(--dark-color-hover-link);
      }
   }
   &__infoDescr {
      font-size: 12px;
      line-height: 1.4;
      color: var(--gray-color);
   }
   &__wrap {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      align-items: center;
   }
   &__basketLink {
   }
   &__total {
      font-weight: 700;
      font-size: 24px;
      line-height: 1.2;
      color: var(--dark-color);
   }
   &__cardsWrap {
      max-height: 450px;
      overflow: auto;
      padding-bottom: 20px;
      &::-webkit-scrollbar {
         width: 6px;
      }
      &::-webkit-scrollbar-track {
         background-color: darkgrey;
      }
      &::-webkit-scrollbar-thumb {
         background-color: #d3d3d3;
         border-radius: 10px;
      }
   }
}
.headerBannerText {
   min-height: 60px;
   padding: 10px 0;
   font-size: 16px;
   line-height: 1.15;
   color: #ffffff;
   display: flex;
   align-items: center;
   @media (max-width: 1025px) {
      font-size: 14px;
      min-height: 55px;
   }
   @media (max-width: 769px) {
      font-size: 12px;
      min-height: 50px;
   }
   &--hidden {
      display: none;
   }
   & .container {
      width: 100%;
   }
   &__wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 19px;
      text-align: center;
      @media (max-width: 769px) {
         justify-content: flex-start;
         text-align: left;
      }
      & p {
         margin: 0;
         text-align: center;
         @media (max-width: 769px) {
            text-align: left;
         }
      }
   }
   &__close {
      display: none;
      font-size: 28px;
      opacity: 0.5;
      color: #ffffff;
      @media (max-width: 769px) {
         display: block;
      }
   }
   &__pc {
      @media (max-width: 769px) {
         display: none;
      }
   }
   &__mob {
      display: none;
      @media (max-width: 769px) {
         display: inline-block;
      }
   }
}
.headerBannerDecor {
   background: #31383c;
   height: 60px;
   overflow: hidden;
   display: flex;
   align-items: center;
   @media (max-width: 769px) {
      height: 50px;
   }
   & .container {
      width: 100%;
   }
   &--hidden {
      display: none;
   }
   &__wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 30px;
      @media (max-width: 1025px) {
         gap: 15px;
      }
      @media (max-width: 769px) {
         display: none;
      }
      &--mob {
         display: none;
         @media (max-width: 769px) {
            display: flex;
            gap: 19px;
         }
      }
   }
   &__sale {
      display: flex;
      gap: 0;
      align-items: center;
   }
   &__sale1 {
      font-weight: 800;
      font-size: 23.5px;
      line-height: 1.2;
      color: #ffffff;
      padding: 14.7px;
      background: #ff0000;
      border-radius: 13px;
      transform: rotate(-5.52deg);
      font-family: "Montserrat", sans-serif;
      @media (max-width: 1025px) {
         font-size: 20px;
         padding: 14.7px 12px;
      }
      @media (max-width: 769px) {
         font-size: 17.5px;
         padding: 11px;
         transform: rotate(-11deg);
      }
   }
   &__sale2 {
      font-weight: 800;
      font-size: 14.5px;
      line-height: 1.15;
      color: var(--light-color);
      padding: 10px;
      background: #1a1a1a;
      border-radius: 8px;
      transform: rotate(-5.52deg) translateX(-7px);
      font-family: "Montserrat", sans-serif;
      @media (max-width: 1025px) {
         font-size: 12px;
         padding: 8px;
      }
      @media (max-width: 769px) {
         font-size: 10.5px;
         padding: 6.5px;
         transform: rotate(-11deg) translateX(-2px) translateY(-7px);
      }
   }
   &__bigText {
      font-family: "Montserrat", sans-serif;
      font-weight: 800;
      font-size: 18.5px;
      line-height: 1.25;
      color: var(--light-color);
      @media (max-width: 1025px) {
         font-size: 14px;
      }
      @media (max-width: 769px) {
         font-size: 9.3px;
      }
   }
   &__promo {
      padding: 10px 24px;
      font-family: "Montserrat", sans-serif;
      font-weight: 800;
      font-size: 18.5px;
      line-height: 1.25;
      color: var(--light-color);
      border: 1px dashed #818181;
      border-radius: 10px;
      @media (max-width: 1025px) {
         font-size: 16px;
         padding: 8px 15px;
      }
   }
   &__text {
      margin-right: auto;
      max-width: 205px;
      font-family: "Montserrat", sans-serif;
      font-size: 6.75px;
      line-height: 1.2;
      color: var(--light-color);
      opacity: 0.3;
   }
   &__closePc {
      font-size: 14px;
      padding: 5px;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 50px;
      color: rgba(255, 255, 255, 0.5);
      transition: all 0.4s;
      &:hover {
         color: var(--light-color);
      }
   }
   &__wrapMob {
      display: flex;
      gap: 5px;
      align-items: center;
   }
}
