.comparison {
   margin-bottom: 60px;
   @media (max-width: 1441px) {
      margin-bottom: 50px;
   }
   @media (max-width: 769px) {
      margin-bottom: 40px;
   }
   &__filters {
      margin-bottom: 24px;
      @media (max-width: 769px) {
         display: none !important;
      }
   }
   &__sort {
      margin-bottom: 20px;
      width: 100%;
      max-width: 700px;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      flex-wrap: wrap;
      display: flex;
      @media (max-width: 769px) {
         display: none;
      }
   }
   &__sortReset {
      display: flex;
      gap: 10px;
      font-weight: 400;
      font-size: 13px;
      line-height: 24px;
      color: var(--gray-color);
      transition: all 0.4s;
      &:hover {
         color: var(--gray-color-hover-link);
      }
   }
   &__sortResetIcon {
      font-size: 24px;
      line-height: 1;
      color: currentColor;
      transition: all 0.4s;
   }
   &__sliderWrap {
      @media (max-width: 769px) {
         display: none;
      }
      & .swiper-button-next-custom {
         right: 0 !important;
         @media (max-width: 1501px) {
            transform: translateY(-50%);
         }
         @media (max-width: 769px) {
            display: none !important;
         }
      }
      & .swiper-button-prev-custom {
         left: 0 !important;
         @media (max-width: 1501px) {
            transform: translateY(-50%);
         }
         @media (max-width: 769px) {
            display: none !important;
         }
      }
   }
   &__mobBlock {
      display: none;
      @media (max-width: 769px) {
         display: block;
      }
   }
   &__mobBlockBtn {
      width: 100%;
      margin-top: 15px;
      padding: 16.5px;
      font-size: 16px;
      display: none;
      &--active {
         display: flex;
      }
   }
   &__mobBlockBtn2 {
      width: 100%;
      margin-top: 15px;
      padding: 16.5px;
      font-size: 16px;
      display: none;
      &--active {
         display: flex;
      }
   }
   &__sliderM {
      margin: 40px 0;
   }
   &__sliderMBtn {
      gap: 10px;
      width: fit-content;
      @media (max-width: 769px) {
         width: 100%;
      }
   }
   &__sliderSticky {
      position: fixed;
      top: 0;
      left: 0;
      background-color: white;
      width: 100%;
      z-index: 1001;
      display: none;
      & .sliderComparison__slideLine {
         border: none !important;
      }
      &--active {
         border-top: 1px solid #e2e8ec;
         display: block;
         box-shadow: 0px 10px 10px rgba(151, 165, 173, 0.5);
      }
      @media (max-width: 769px) {
         & .sliderComparison__slideImg {
            width: 60px;
            height: 60px;
         }
      }
   }
   & .sliderNavWrap {
      & .sliderComparison__slideLine--sticky {
         border-top: none;
         padding-top: 0;
      }
   }
}

.comparisonFilters {
   display: flex;
   gap: 10px;
   flex-wrap: wrap;
   &__item {
      display: flex;
      gap: 8px;
      align-items: center;
      padding: 8px 10px 8px 14px;
      border: 1px solid var(--dark-gray-color3);
      transition: all 0.4s;
      border-radius: 5px;
      transition: all 0.4s;
      color: var(--dark-color);
      &:hover {
         border-color: var(--dark-gray-color3-hover-btn);
      }
      &--active {
         background: #1f2d35;
         color: var(--light-color);
         border-color: #1f2d35;
         &:hover {
            border-color: #1f2d35;
         }
      }
   }
   &__text {
      font-size: 14px;
      line-height: 1.25;
      color: currentColor;
      & span {
         font-size: 14px;
         line-height: 1.25;
         color: var(--gray-color);
         margin-left: 6px;
      }
   }
   &__close {
      font-size: 14px;
      transition: all 0.4s;
      color: var(--gray-color);
      &:hover {
         color: var(--gray-color-hover-link);
      }
   }
}

.comparisonMobBtns {
   display: flex;
   flex-direction: column;
   &--edit {
      & .comparisonMobBtns__btnRemove {
         display: block;
      }
      & .comparisonMobBtns__btnChevrone {
         display: none;
      }
      & .comparisonMobBtns__remove {
         display: block;
      }
   }
   &__btn {
      border: 1px solid #e2e8ec;
      text-align: left;
      padding: 20px;
      display: flex;
      gap: 20px;
      align-items: center;
      width: 100%;
      position: relative;
      &:not(:last-child) {
         border-bottom: none;
      }
      &:first-child {
         border-radius: 5px 5px 0px 0px;
      }
      &:last-child {
         border-radius: 0px 0px 5px 5px;
      }
   }
   &__btnText {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.7;
      color: var(--dark-color);
      & span {
         font-weight: 400;
         font-size: 14px;
         line-height: 1.7;
         color: var(--gray-color);
         margin-left: 10px;
      }
   }
   &__btnChevrone {
      margin-left: auto;
      font-size: 24px;
   }
   &__btnRemove {
      font-size: 24px;
      display: none;
   }
   &__remove {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
   }
}

.sliderComparison {
   &__remove {
      position: absolute;
      top: 0;
      right: 40px;
      font-size: 16px;
      transition: all 0.3s;
      color: var(--dark-color);
      &:hover {
         color: var(--dark-color-hover-link);
      }
      &--min {
         top: 10px;
      }
   }
   &--line1 {
      & .sliderComparison__slideLine {
         @media (max-width: 769px) {
            border: none;
            padding: 6px 20px 6px 0;
         }
      }
   }
   &__slide {
      width: 200px;
      @media (max-width: 769px) {
         width: 180px;
      }
      &:not(:first-child) {
         & .sliderComparison__slideLine--textTop::before {
            color: transparent;
         }
      }
   }
   &__slideLine {
      padding: 15px 40px 15px 0;
      border-bottom: 1px solid #e2e8ec;
      @media (max-width: 769px) {
         padding-right: 20px;
      }
      &:first-child {
         border-top: 1px solid #e2e8ec;
      }
      &--noTop {
         padding-top: 0;
         &:first-child {
            border-top: none;
         }
      }
      &--textTop {
         &::before {
            content: attr(data-text);
            font-weight: 400;
            font-size: 14px;
            line-height: 1.2;
            color: var(--gray-color);
            margin-bottom: 9px;
            display: inline-block;
            height: 17px;
         }
      }
      &--sticky {
         padding-top: 10px;
         padding-bottom: 14px;
      }
   }
   &__slideImg {
      display: block;
      width: 100%;
      aspect-ratio: 1/1;
      margin-bottom: 20px;
      @media (max-width: 769px) {
         max-width: 130px;
      }
      &--min {
         max-width: 130px;
      }
      &--min2 {
         max-width: 60px;
         margin-bottom: 5px;
      }
      & img {
         display: block;
         width: 100%;
         height: 100%;
         object-fit: contain;
      }
   }
   &__slideName {
      transition: all 0.4s;
      font-weight: 400;
      font-size: 12px;
      line-height: 1.3;
      display: inline-block;
      color: var(--dark-color);
      &:hover {
         color: var(--dark-color-hover-link);
      }
   }
   &__slideRaiting {
      font-size: 14px;
      line-height: 1.2;
      color: var(--gray-color);
   }
   &__slideLinePrice {
      font-weight: 700;
      font-size: 16px;
      line-height: 1.2;
      & span {
         margin-left: 12px;
         font-weight: 400;
         font-size: 12px;
         line-height: 1.2;
         text-decoration-line: line-through;
         color: var(--gray-color);
         vertical-align: bottom;
      }
   }
   &__slideLineText {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.2;
      color: var(--dark-color);
   }
}
