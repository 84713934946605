.faq {
   display: flex;
   flex-direction: column;
   &__card {
   }
   &__icon {
      font-size: 24px;
      transition: all 0.4s;
      transform: scaleY(-1);
   }
   &__btn {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      align-items: center;
      border-top: 1px solid var(--dark-gray-color3-hover-btn);
      padding: 20px 0;
      width: 100%;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.85;
      color: var(--dark-color);
      text-align: left;
      @media (max-width: 1025px) {
         align-items: flex-start;
         line-height: 1.4;
      }
      @media (max-width: 769px) {
         width: calc(100% + var(--container-offset) * 2);
         padding-left: var(--container-offset);
         padding-right: var(--container-offset);
         margin-left: calc(-1 * var(--container-offset));
         margin-right: calc(-1 * var(--container-offset));
      }
      &--active {
         & .faq__icon {
            transform: none;
            @media (max-width: 769px) {
               color: var(--red-color);
            }
         }
      }
   }
   &__content {
      display: none;
      margin-bottom: 20px;
      font-weight: 400;
      color: #97a5ad;
      &--active {
         display: block;
      }
      @media (max-width: 769px) {
         width: calc(100% + var(--container-offset) * 2);
         padding-left: var(--container-offset);
         padding-right: var(--container-offset);
         margin-left: calc(-1 * var(--container-offset));
         margin-right: calc(-1 * var(--container-offset));
      }
   }
}
