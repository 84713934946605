.collections {
   &__section {
      margin: 50px 0;
      @media (max-width: 1025px) {
         margin: 45px 0;
      }
      @media (max-width: 769px) {
         margin: 40px 0;
      }
   }
   &__wrapVert {
      display: flex;
      flex-direction: column;
      gap: 50px;
      @media (max-width: 1441px) {
         gap: 40px;
      }
      @media (max-width: 1025px) {
         gap: 30px;
      }
      @media (max-width: 769px) {
         gap: 20px;
      }
   }
   &__title {
      font-weight: 700;
      font-size: 24px;
      line-height: 1.25;
      margin: 0 0 30px;
      color: var(--dark-color);
      @media (max-width: 1441px) {
         margin-bottom: 20px;
      }
      @media (max-width: 1025px) {
         font-size: 22px;
      }
      @media (max-width: 769px) {
         font-size: 20px;
      }
   }
   &__wrap {
      display: flex;
      flex-wrap: wrap;
      gap: 30px 20px;
      @media (max-width: 769px) {
         gap: 20px;
      }
   }
   &__card {
      display: block;
      width: calc(100% / 3 - 40px / 3);
      @media (max-width: 769px) {
         width: calc(50% - 10px);
      }
      @media (max-width: 601px) {
         width: 100%;
      }
      & .collections__cardImg {
         display: block;
         width: 100%;
         border-radius: 5px;
         aspect-ratio: 2/1;
         margin-bottom: 20px;
         object-fit: cover;
         @media (max-width: 1025px) {
            margin-bottom: 15px;
         }
         @media (max-width: 769px) {
            margin-bottom: 12px;
         }
      }
      &--middle {
         width: calc(25% - 15px);
         @media (max-width: 769px) {
            width: calc(100% / 3 - 40px / 3);
         }
         @media (max-width: 601px) {
            width: calc(50% - 10px);
         }
         & .collections__cardImg {
            aspect-ratio: 34/23;
            @media (max-width: 601px) {
               aspect-ratio: 1/1;
            }
         }
      }
      &--big {
         width: calc(25% - 15px);
         @media (max-width: 769px) {
            width: calc(100% / 3 - 40px / 3);
         }
         @media (max-width: 601px) {
            width: calc(50% - 10px);
         }
         & .collections__cardImg {
            aspect-ratio: 1/1;
         }
      }
   }
   &__name {
      font-weight: 400;
      font-size: 18px;
      line-height: 1.3;
      color: var(--dark-color);
      margin: 0;
      transition: all 0.4s;
      &:hover {
         color: var(--dark-color-hover-link);
      }
      @media (max-width: 1441px) {
         font-size: 16px;
      }
      @media (max-width: 769px) {
         font-size: 14px;
      }
   }
}
