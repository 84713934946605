.product {
    margin-bottom: 90px;
    @media (max-width: 1441px) {
        margin-bottom: 55px;
    }
    @media (max-width: 769px) {
        margin-bottom: 50px;
    }

    &--m0 {
        margin: 0;
    }

    &__wrap {
        display: flex;
        gap: 60px;
        justify-content: space-between;
        @media (max-width: 1025px) {
            gap: 30px;
        }
        @media (max-width: 769px) {
            flex-direction: column;
            gap: 10px;
        }

        &--modal {
            gap: 40px;
        }
    }

    &__infoTitleModal {
        font-weight: 700;
        font-size: 24px;
        line-height: 1.25;
        margin: 0 0 10px;
    }

    &__gallery {
        width: calc(100% - 520px);
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        height: fit-content;
        position: sticky;
        top: 110px;
        @media (max-width: 1025px) {
            width: calc(100% - 350px);
        }
        @media (max-width: 769px) {
            width: 100%;
            position: relative;
            top: initial;
        }

        &--tile {
            width: calc(100% - 480px);
            @media (max-width: 1025px) {
                width: calc(100% - 350px);
            }
            @media (max-width: 769px) {
                width: 100%;
            }
        }

        &--modal {
            flex-direction: column-reverse;
            width: 500px;

            & .product__galleryVert {
                flex-direction: row;
                width: 100%;
            }

            & .product__galleryImgMin {
                width: auto;
                height: auto;
                flex: 1 1 100%;
                aspect-ratio: 1;
                padding: 5px;
            }

            & .product__galleryImgMinMore {
                flex: 1 1 100%;
            }

            & .product__galleryMain {
                width: 100%;
            }
        }
    }

    &__info {
        width: 420px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        @media (max-width: 1025px) {
            width: 320px;
        }
        @media (max-width: 769px) {
            width: 100%;
        }

        &--noGap {
            display: block;
            gap: 0;
            @media (max-width: 769px) {
                & .product__btnsWrap {
                    margin: 0;
                }
            }
        }

        &--modal {
            @media (max-width: 1025px) {
                width: 420px;
            }
        }
    }

    &__galleryMain {
        position: relative;
        width: calc(100% - 120px);

        & .productCard__btns {
            display: none;
            @media (max-width: 769px) {
                display: flex;
            }
        }

        @media (max-width: 1441px) {
            width: calc(100% - 100px);
        }
        @media (max-width: 1025px) {
            width: calc(100% - 80px);
        }
        @media (max-width: 769px) {
            width: 100%;
        }

        &--full {
            width: 100%;
        }

        & .productCard__btns {
            right: 10px;
            top: 10px;
        }
    }

    &__galleryTop {
        overflow: hidden;
        position: relative;
    }

    &__galleryImg {
        display: block;
        width: 100%;
        aspect-ratio: 1/1;
        border-radius: 9px;
        object-fit: cover;
        @media (max-width: 769px) {
            margin-bottom: 8px;
        }

        &--tile {
            aspect-ratio: initial;
            margin-bottom: 0;
        }
    }

    &__galleryVertWrap {
        overflow: hidden;
        position: relative;
        @media (max-width: 769px) {
            display: none;
        }
    }

    &__galleryVert {
        width: 100px;
        display: flex;
        flex-direction: column;
        @media (max-width: 1441px) {
            width: 80px;
        }
        @media (max-width: 1025px) {
            width: 60px;
        }
    }

    &__galleryImgMin {
        display: block;
        width: 100%;
        aspect-ratio: 1/1;
        border: 1px solid var(--dark-gray-color3-hover-btn);
        border-radius: 5px;
        transition: all 0.4s;
        padding: 8.75px;
        height: auto; /*added ipv*/
        box-sizing: border-box; /*added ipv*/
        margin-bottom: 10px;
        @media (max-width: 1025px) {
            padding: 5.5px;
        }
        &:last-child {
            margin-bottom: 0;
        }
        &--active {
            padding: 7.75px;
            border: 2px solid var(--red-color);
            @media (max-width: 1025px) {
                padding: 4.5px;
            }
        }

        & img {
            display: block;
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }

    &__galleryImgMinMore {
        font-weight: 400;
        font-size: 13px;
        line-height: 1.2;
        color: var(--blue-color);
        transition: all 0.4s;

        &:hover {
            color: var(--blue-color-hover-link);
        }
    }

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        flex-wrap: wrap;
        margin-bottom: 16px;
        @media (max-width: 769px) {
            display: none;
        }
    }

    &__topLeft {
        display: flex;
        gap: 12px 20px;
        align-items: center;
        flex-wrap: wrap;
    }

    &__topRight {
        display: flex;
        gap: 20px;
        align-items: center;
    }

    &__topBtn {
        display: flex;
        gap: 10px;
        align-items: center;
        color: var(--blue-color);
        transition: all 0.4s;

        &:hover {
            color: #1f2d35;

            & .product__topIcon {
                color: var(--red-color);
            }
        }

        &--active {
            color: var(--red-color);

            & .product__topTextSt {
                display: none;
            }

            & .product__topTextActive {
                display: inline-block;
            }

            & .product__topIcon {
                & span {
                    opacity: 1;
                }
            }
        }
    }

    &__topIcon {
        transition: all 0.4s;
        position: relative;

        & span {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
        }
    }

    &__topTextActive {
        display: none;
    }

    &__topIcon {
        font-size: 24px;
    }

    &__cod {
        background: var(--dark-gray-color3);
        border-radius: 5px;
        font-size: 14px;
        line-height: 1.2;
        padding: 6.5px 10px;
    }

    &__codCopy {
        font-size: 16px;
        color: var(--gray-color);
        transition: all 0.4s;
        display: inline-block;
        margin-left: 10px;
        vertical-align: text-top;

        &:hover {
            color: var(--gray-color-hover-link);
        }
    }

    &__anchor {
        font-size: 13px;
        line-height: 1.2;
        color: var(--blue-color);
        transition: all 0.4s;

        &:hover {
            color: var(--blue-color-hover-link);
        }
    }

    &__galleryMainTegs {
        left: 15px;
        top: 15px;
    }

    &__btnsWrap {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 20px;
        border: 1px solid #e2e8ec;
        border-radius: 9px;
        @media (max-width: 769px) {
            padding: 0;
            border: none;
            margin: -15px 0;
        }

        &--noBorder {
            padding: 0;
            border-radius: 0;
            border: none;
        }
    }

    &__btn {
        padding: 16.5px;
        width: 100%;
        font-size: 16px;
        line-height: 1.15;
    }

    &__btnLink {
        width: 100%;
        color: var(--dark-color);
        padding: 16.5px;
        font-size: 16px;
        line-height: 1.15;
        background: var(--dark-gray-color3);

        &:hover {
            background: var(--dark-gray-color3-hover-btn);
        }
    }

    &__infoBlock {
        padding: 0 20px;
        @media (max-width: 1025px) {
            padding: 0;
        }

        &--m30 {
            margin: 30px 0;
        }

        &--mb {
            margin-bottom: 20px;
            @media (max-width: 769px) {
                margin: 20px 0 0;
            }
        }
    }

    &__infoBlockWrap {
        display: flex;
        gap: 20px;
        align-items: flex-end;
        @media (max-width: 769px) {
            gap: 0;
        }

        &:not(:last-child) {
            margin-bottom: 6px;
        }
    }

    &__infoBlockLeft {
        width: calc(50% - 10px);
        font-size: 14px;
        line-height: 1.2;
        display: flex;
        color: var(--gray-color);
        align-items: flex-end;
        @media (max-width: 769px) {
            width: 50%;
        }

        & span {
            flex: 1 1 auto;
            display: inline-block;
            border-bottom: 1px dashed #e2e8ec;
            margin-bottom: 3px;
        }
    }

    &__infoBlockRight {
        width: calc(50% - 10px);
        font-size: 14px;
        line-height: 1.2;
        @media (max-width: 769px) {
            width: 50%;
        }

        & a {
            transition: all 0.4s;
            color: var(--blue-color);
            text-decoration: underline;

            &:hover {
                color: var(--blue-color-hover-link);
            }
        }
    }

    &__infoBlockTitle {
        font-weight: 700;
        font-size: 14px;
        line-height: 0.9;
        margin-bottom: 15px;
    }

    &__mobBlock {
        display: none;
        @media (max-width: 769px) {
            display: block;
            &--mb {
                margin-bottom: 20px;
            }
        }
    }

    &__mobBlockTitle {
        font-weight: 700;
        font-size: 22px;
        line-height: 1.2;
        margin: 23px 0 12px;
    }

    &__mobBlockWrap {
        display: flex;
        gap: 12px 20px;
        flex-wrap: wrap;
    }

    &__btnsPriceBlock {
        display: flex;
        gap: 20px;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;
    }

    &__btnsSale {
        padding: 7px 4px;
        background: var(--red-color);
        border-radius: 4px;
        font-weight: 700;
        font-size: 14px;
        line-height: 1.35;
        color: var(--light-color);
    }

    &__btnsPrice {
        font-weight: 700;
        font-size: 24px;
        line-height: 1.2;
        color: var(--dark-color);

        & span {
            font-weight: 400;
            font-size: 16px;
            line-height: 1.2;
            text-decoration-line: line-through;
            color: var(--gray-color);
            margin-left: 10px;
        }
    }

    &__btnsClue {
        margin-bottom: 5px;
        @media (max-width: 769px) {
            margin-top: 0;
            order: initial !important;
        }
    }

    &__btnsStatus {
        margin-bottom: 5px;
    }

    &__btnsInf {
        display: flex;
        flex-direction: column;
        gap: 6px;
        margin-bottom: 20px;
    }

    &__btnsInfStr {
        font-size: 14px;
        line-height: 1.2;
    }

    &__btnsCounter {
        align-items: center;
        margin-bottom: 10px;
    }

    &__btnsCounterWrap {
        width: 100%;

        & .quantity {
            width: 100%;
        }
    }

    &__btnsPrice22 {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        gap: 20px;
        align-items: center;
    }

    &__btnsPrice1 {
        font-weight: 700;
        font-size: 16px;
        line-height: 1.5;
    }

    &__btnsPrice2 {
        font-weight: 700;
        font-size: 22px;
        line-height: 1.3;
        text-align: right;
    }

    &__clueMt {
        margin-top: -20px;
        order: initial !important;
        @media (max-width: 769px) {
            margin-top: 0;
        }
    }

    &__clueMt10 {
        margin: 10px 0;
        padding-right: 20px;
        padding-left: 20px;
    }

    &__diller {
        border: 1px solid #e2e8ec;
        border-radius: 9px;
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        overflow: hidden;

        &--m30 {
            margin: 30px 0;
        }
    }

    &__dillerImg {
        display: block;
        max-width: 140px;
        max-height: 70px;
        width: auto;
    }

    &__dillerLeft {
        font-weight: 700;
        font-size: 14px;
        line-height: 0.9;
        display: flex;
        gap: 14px;
        align-items: center;
    }

    &__dillerLeftIcon {
        font-size: 30px;
        color: var(--red-color);
    }

    &__blockWrap {
        display: flex;
        gap: 12px 20px;
        flex-wrap: wrap;

        & > * {
            width: calc(50% - 10px);
        }
    }

    &__btnsWarning {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 15px;
        background: #f2f5f7;
        border-radius: 9px;
        margin-bottom: 20px;

        &--mb10 {
            margin-bottom: 10px;
        }
    }

    &__btnsWarningIcon {
        font-size: 24px;
        color: var(--red-color);
    }
    &__btnsSuccessIcon {
        font-size: 24px;
        color: var(--green-color);
    }

    &__btnsWarningText {
        font-size: 12px;
        line-height: 1.3;
    }

    &__topTop {
        font-size: 13px;
        line-height: 1.2;
        color: #3caa3c;
        width: 100%;
        @media (max-width: 769px) {
            margin-top: 12px;
        }
    }

    &__topTopIcon {
        font-size: 16px;
        margin-right: 6px;
        vertical-align: middle;
    }

    &__btnsComplectWrap {
        background: #f2f5f7;
        border-radius: 9px;
        padding: 20px;
        margin-bottom: 5px;
        display: none;

        &--active {
            display: block;
        }
    }

    &__btnsComplectWrapTitle {
        font-weight: 700;
        font-size: 14px;
        line-height: 1.2;
        margin: 0 0 20px;
    }

    &__btnsComplectWrapFlex {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    &__btnsComplectCard {
        display: flex;
        gap: 20px;
        align-items: center;
    }

    &__btnsComplectImg {
        display: block;
        width: 60px;
        height: 60px;

        & img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__btnsComplectName {
        font-size: 14px;
        line-height: 1.3;
        color: var(--dark-color);
        transition: all 0.4s;
        width: calc(100% - 80px);

        &:hover {
            color: var(--dark-color-hover-link);
        }
    }

    &__btnsInstall {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #f2f5f7;
        border-radius: 9px;
        padding: 15px;
        margin-bottom: 5px;
    }

    &__btnsInstallLeft {
        display: flex;
        gap: 20px;
        align-items: center;
    }

    &__btnsInstallIcon {
        font-size: 32px;
        color: var(--red-color) !important;
    }

    &__btnsInstallTextWrap {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    &__btnsInstallPrice {
        font-weight: 700;
        font-size: 16px;
        line-height: 0.9;
    }

    &__btnsInstallText {
        font-size: 12px;
        line-height: 0.9;
    }

    &__btnsInstallRight {
        display: flex;
        gap: 10px;
        align-items: center;
    }

    &__btnsInstallTextGr {
        font-size: 12px;
        line-height: 1.2;
        color: var(--gray-color);
        @media (max-width: 1025px) {
            display: none;
        }
    }

    &__infoBlockWrapSize {
        display: flex;
        gap: 5px;
        flex-wrap: wrap;
    }

    &__infoBlockBtnSize {
        width: calc(25% - 15px / 4);
        padding: 10px 10px;
        border: 2px solid #e2e8ec;
        border-radius: 3px;
        transition: all 0.4s;
        color: var(--dark-color);
        @media (max-width: 769px) {
            width: calc(100% / 3 - 10px / 3);
        }

        &:hover {
            border-color: var(--dark-gray-color3);
        }

        &:disabled {
            pointer-events: none;
            border-color: #f2f5f7;
            color: #e2e8ec;
        }

        &--width {
            width: fit-content;
            padding-left: 17px;
            padding-right: 17px;
        }

        &--active {
            border-color: var(--red-color) !important;
        }
    }

    &__credit {
        border: 1px solid #e2e8ec;
        border-radius: 9px;
        padding: 15px 20px 30px;
    }

    &__creditTitleWrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        margin-bottom: 15px;
    }

    &__creditTitle {
        font-weight: 700;
        font-size: 14px;
        line-height: 0.9;
    }

    &__creditPeriod {
        display: flex;
        gap: 2px;
        background: #f2f5f7;
        border-radius: 5px;
        padding: 2px;
        margin-bottom: 17px;
    }

    &__creditPeriodBtn {
        background: transparent;
        border-radius: 3px;
        font-weight: 400;
        font-size: 12px;
        line-height: 0.9;
        flex: 1 1 auto;
        padding: 7.5px;
        transition: all 0.4s;

        &:hover {
            background-color: var(--dark-gray-color3-hover-btn);
        }

        &--active {
            background: #ffffff !important;
        }
    }

    &__creditTotalBtn {
        font-weight: 400;
        font-size: 10px;
        line-height: 1.2;
        padding: 10px 17px;
    }

    &__creditTotalWrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        margin-bottom: 17px;
    }

    &__creditTotal {
        font-weight: 700;
        font-size: 16px;
        line-height: 0.9;
    }

    &__creditPayWrap {
        display: flex;
        gap: 3px;
    }

    &__creditPay {
        flex: 1 1 auto;
    }

    &__creditPayDecor {
        display: block;
        width: 100%;
        height: 3px;
        background: var(--red-color);
        border-radius: 5px;
        margin-bottom: 10px;
    }

    &__creditPayDate {
        font-size: 12px;
        line-height: 0.9;
        margin-bottom: 7px;
    }

    &__creditPayPrice {
        font-weight: 700;
        font-size: 14px;
        line-height: 0.9;
    }

    &__btnsComplectSel {
        margin-bottom: 5px;
    }

    &__btnsComplectSelWrap {
        display: flex;
        gap: 5px;
        flex-wrap: wrap;
    }

    &__btnsComplectSelTitle {
        margin-bottom: 15px;
        font-weight: 700;
        font-size: 14px;
        line-height: 0.9;
    }

    &__btnsComplectSelBtn {
        padding: 14px 21px;
        font-weight: 400;
        font-size: 14px;
        line-height: 0.9;
        border: 2px solid #e2e8ec;
        border-radius: 3px;
        transition: all 0.4s;

        &:hover {
            border-color: var(--dark-gray-color3);
        }

        &--active {
            border-color: var(--red-color) !important;
        }
    }

    &__galleryReviews {
        background: #f2f5f7;
        border-radius: 9px;
        padding: 10px;
        width: 100%;
        display: flex;
        gap: 10px;
        @media (max-width: 769px) {
            padding: 0;
            background-color: transparent;
            border-radius: 0;
            gap: 6px;
        }
    }

    &__galleryReviewsImgWrap {
        width: calc(100% / 9 - 80px / 9);
        border-radius: 5px;
        overflow: hidden;
        @media (min-width: 1442px) {
            &:nth-child(n + 10) {
                display: none;
            }
        }
        @media (max-width: 1441px) {
            width: calc(100% / 7 - 60px / 7);
            &:nth-child(n + 8) {
                display: none;
            }
        }
        @media (max-width: 769px) {
            width: calc(100% / 7 - 40px / 7);
        }
        @media (max-width: 501px) {
            width: calc(100% / 4 - 18px / 4);
            &:nth-child(n + 5) {
                display: none;
            }
        }

        &[data-col] {
            /*display: block !important;*/
            position: relative;

            &::after {
                content: attr(data-col);
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
                font-weight: 700;
                font-size: 22px;
                line-height: 1.2;
                color: #f2f5f7;
            }
        }

        & img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.productDop {
    margin-bottom: 60px;
    @media (max-width: 1025px) {
        margin-bottom: 50px;
    }
    @media (max-width: 769px) {
        margin-bottom: 40px;
    }

    &__bigCard {
        width: calc(100% / 3);
        @media (max-width: 1025px) {
            width: 50%;
        }
        @media (max-width: 551px) {
            width: calc(100% + 24px);
            margin-left: -12px;
            margin-right: -12px;
        }
    }

    &__mb {
        margin-bottom: 50px;
    }

    &__mbMin {
        margin-bottom: 30px;
    }

    &__card {
        width: 25%;
        @media (max-width: 1025px) {
            width: calc(100% / 3);
        }
        @media (max-width: 769px) {
            width: 50%;
        }
        @media (max-width: 551px) {
            width: calc(100% + 24px);
            margin-left: -12px;
            margin-right: -12px;
        }
    }

    &__description {
        margin: 60px 0;
        @media (max-width: 769px) {
            margin: 50px 0;
        }
    }
}

.productHead {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1200;
    background: #ffffff;
    transition: all 0.4s;
    transform: translateY(-100%);
    padding: 10px 0;
    @media (max-width: 769px) {
        display: none;
    }

    &--active {
        transform: none;
        box-shadow: 0px 0px 20px rgba(151, 165, 173, 0.75);
    }

    &--hidden {
        transform: translateY(-100%);
    }

    &__wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
    }

    &__wrapLeft {
        display: flex;
        gap: 20px;
        align-items: center;
    }

    &__wrapImg {
        display: block;
        width: 60px;
        aspect-ratio: 1/1;

        & img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__wrapContent {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    &__wrapName {
        font-weight: 700;
        font-size: 14px;
        line-height: 1.3;
        color: var(--dark-color);
        transition: all 0.4s;

        &:hover {
            color: var(--dark-color-hover-link);
        }
    }

    &__wrapRight {
        display: flex;
        gap: 5px 20px;
        align-items: center;
        @media (max-width: 1025px) {
            flex-direction: column;
            align-items: flex-end;
        }
    }

    &__wrapRightPrice {
        font-weight: 700;
        font-size: 21px;
        line-height: 1.2;
        white-space: nowrap;

        & span {
            font-weight: 400;
            font-size: 13px;
            line-height: 1.2;
            text-decoration-line: line-through;
            color: var(--gray-color);
            margin-left: 10px;
            white-space: nowrap;
        }
    }
}

.productBottom {
    border-top: 1px solid #e2e8ec;
    position: fixed;
    z-index: 1080;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(151, 165, 173, 0.75);
    padding: 15px 0;
    display: none;
    transition: all 0.4s;

    &--hidden {
        transform: translateY(100%);
    }

    @media (max-width: 769px) {
        display: block;
    }

    &__btn {
        justify-content: space-between;

        & span {
            font-weight: 400;
        }
    }

    &__lineWrap {
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin-bottom: 15px;
    }

    &__line {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
    }

    &__lineLeft {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.2;

        & a {
            color: var(--blue-color);
            text-decoration: underline;
        }

        &--red {
            color: var(--red-color);
        }
    }

    &__lineRight {
        font-weight: 700;
        font-size: 14px;
        line-height: 1.2;

        &--red {
            color: var(--red-color);
        }
    }
}

.productDopDescription {
    &__wrap {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
    }

    &__col {
        width: calc(50% - 10px);
        @media (max-width: 769px) {
            width: 100%;
        }
    }
}

.productInform {
    margin: 60px 0;
    @media (max-width: 1025px) {
        margin: 50px 0;
    }
    @media (max-width: 769px) {
        margin: 40px 0;
    }

    &__wrap {
        display: flex;
        gap: 60px;
        justify-content: space-between;
        @media (max-width: 1025px) {
            gap: 50px;
            flex-wrap: wrap;
        }
        @media (max-width: 769px) {
            gap: 40px;
        }
    }

    &__left {
        width: 680px;
        @media (max-width: 1025px) {
            width: 100%;
        }
    }

    &__right {
        width: 460px;
        @media (max-width: 1441px) {
            width: 380px;
        }
        @media (max-width: 1025px) {
            width: 100%;
        }
    }

    &__sertificates {
        display: flex;
        flex-direction: column;
        gap: 13px;
    }

    &__wrapCharacteristics {
        display: flex;
        gap: 60px;
        justify-content: space-between;
        @media (max-width: 1025px) {
            gap: 50px;
            flex-wrap: wrap;
        }
        @media (max-width: 769px) {
            gap: 40px;
        }
    }

    &__videoWrap {
        width: 340px;
        @media (max-width: 1441px) {
            width: 280px;
        }
    }

    &__characteristics {
        width: 810px;
        @media (max-width: 1025px) {
            width: 100%;
        }
    }
}

.sertificate {
    padding: 15px 20px;
    background: #f2f5f7;
    border-radius: 9px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.4s;

    &:hover {
        background: #e2e8ec;
    }

    &__icon {
        font-size: 20px;
        color: #b8c0c5;
    }

    &__left {
        display: flex;
        gap: 20px;
        align-items: center;
    }

    &__leftIcon {
        font-size: 32px;
        color: var(--red-color);
    }

    &__leftWrap {
        display: flex;
        flex-direction: column;
        gap: 9px;
    }

    &__leftName {
        font-size: 14px;
        line-height: 0.9;
        color: var(--dark-color);
    }

    &__leftSize {
        font-weight: 700;
        font-size: 10px;
        line-height: 0.9;
        color: #97a5ad;
    }
}

.productInformVideo {
    &__img {
        position: relative;
        aspect-ratio: 34/19.1;
        overflow: hidden;
        border-radius: 9px;
        margin-bottom: 20px;

        & iframe {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__btn {
        font-size: 94px;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        transform: translate(-50%, -50%);
        transition: all 0.4s;

        &:hover {
            transform: translate(-50%, -50%) scale(1.025);
        }

        & img {
            display: block;
            width: 94px;
            @media (max-width: 1441px) {
                width: 78px;
            }
        }
    }

    &__name {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.4;
    }
}

.characteristics {
    &__wrap {
        display: flex;
        gap: 30px 20px;
        flex-wrap: wrap;
    }

    &__infoBlock {
        width: calc(50% - 10px);
        @media (max-width: 769px) {
            width: 100%;
        }
    }

    &__title {
        font-weight: 700;
        font-size: 14px;
        line-height: 0.9;
        margin-bottom: 15px;
    }

    &__infoBlockWrap {
        display: flex;
        gap: 20px;
        align-items: flex-end;
        @media (max-width: 769px) {
            gap: 0;
        }

        &:not(:last-child) {
            margin-bottom: 6px;
        }
    }

    &__infoBlockLeft {
        width: calc(50% - 10px);
        font-size: 14px;
        display: flex;
        color: var(--gray-color);
        align-items: flex-end;
        // border-bottom: 1px dashed #e2e8ec;
        overflow: hidden;
        @media (max-width: 769px) {
            width: 50%;
        }

        & span {
            line-height: 1.2;
            // border-bottom: 1px dashed #ffffff;
            // margin-bottom: -1px;
            position: relative;

            &:after {
                content: "";
                display: inline-block;
                white-space: pre;
                position: absolute;
                border-bottom: 1px dashed #e2e8ec;
                width: 1000px;
                height: 100%;
                bottom: 0;
            }
        }
    }

    &__infoBlockRight {
        width: calc(50% - 10px);
        font-size: 14px;
        line-height: 1.2;
        @media (max-width: 769px) {
            width: 50%;
        }

        & a {
            transition: all 0.4s;
            color: var(--blue-color);
            text-decoration: underline;

            &:hover {
                color: var(--blue-color-hover-link);
            }
        }
    }

    &__dop {
        margin-top: 60px;
        @media (max-width: 1025px) {
            margin-top: 30px;
        }
    }
}

.productNecessarily {
    margin: 40px 0 60px;

    &__card {
        border: 4px solid #e2e8ec;
        border-radius: 9px;
        padding: 25px;
        @media (max-width: 769px) {
            border: none;
            padding: 0;
        }
    }

    &__btnsWrap {
        margin-bottom: 25px;
        display: flex;
        gap: 6px;
        flex-wrap: wrap;
        @media (max-width: 769px) {
            display: none;
        }
    }

    &__btn {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.35;
        text-align: center;
        color: var(--dark-color);
        background: var(--dark-gray-color3);
        border-radius: 5px;
        transition: all 0.4s;
        padding: 8px 14px;

        &:hover {
            background: var(--dark-gray-color3-hover-btn);
        }
    }

    &__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 12px 20px;
        margin-top: 25px;
        flex-wrap: wrap;
        @media (max-width: 769px) {
            display: none;
        }
    }

    &__btnMore {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 14px;
        line-height: 1.7;
        color: var(--blue-color);
        transition: all 0.4s;

        &--active {
            display: none;
        }

        &--sv {
            display: none;

            &--active {
                display: flex;
            }
        }

        &:hover {
            color: var(--blue-color-hover-link);
        }
    }

    &__btnMoreICon {
        font-size: 24px;
    }

    &__bottomLeftBtn {
        padding: 16.5px 30px;
        font-size: 16px;
        line-height: 1.15;
    }

    &__bottomLeft {
        display: flex;
        gap: 40px;
        align-items: center;
    }

    &__priceWrap {
        display: flex;
        gap: 20px;
        align-items: flex-end;
    }

    &__priceWrapLeft {
    }

    &__priceText {
        font-size: 14px;
        line-height: 1.2;
        margin-bottom: 3px;
    }

    &__price {
        font-weight: 700;
        font-size: 24px;
        line-height: 1.2;

        & span {
            font-size: 16px;
            line-height: 1.2;
            text-decoration-line: line-through;
            color: #b8c0c5;
            margin-left: 10px;
            font-weight: 400;
        }
    }

    &__benefit {
        background: #f2f5f7;
        border-radius: 22px;
        padding: 3px 14px 3px 4px;
        display: flex;
        align-items: center;
        gap: 10px;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
    }

    &__benefitIcon {
        font-size: 24px;
        color: var(--red-color);
    }

    &__itemWrap {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        @media (max-width: 769px) {
            flex-wrap: nowrap;
            width: fit-content;
            gap: 12px;
        }

        & > *:nth-child(n + 7) {
            display: none;
            @media (max-width: 769px) {
                display: flex;
            }
        }

        &--active {
            & > *:nth-child(n + 7) {
                display: flex;
            }
        }
    }

    &__item {
        width: calc(100% / 6 - 80px / 6);

        &:hover {
            box-shadow: 0px 0px 10px rgba(151, 165, 173, 0.5);
            @media (max-width: 769px) {
                box-shadow: none;
            }
        }

        @media (max-width: 1025px) {
            width: calc(100% / 3 - 32px / 3);
        }
        @media (max-width: 769px) {
            width: 140px;
        }
    }
}

.productNecessarilyCard {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    transition: all 0.4s;
    border-radius: 9px;
    padding: 15px;
    @media (max-width: 769px) {
        padding: 0;
        border-radius: 0;
    }

    &--compl {
        @media (max-width: 769px) {
            display: none;
        }
    }

    &__imgWrap {
        position: relative;

        & img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__warning {
        margin: 14px 0 6px;
        font-weight: 400;
        font-size: 10px;
        line-height: 1.3;
        display: flex;
        align-items: center;
        color: var(--red-color);
    }

    &__warningIcon {
        font-size: 16px;
        margin-right: 7px;
    }

    &__name {
        font-size: 12px;
        line-height: 1.3;
        display: inline-block;
        color: var(--dark-color);
        transition: all 0.4s;
        margin: 6px 0;

        &:hover {
            color: var(--red-color);
        }
    }

    &__price {
        font-weight: 700;
        font-size: 14px;
        line-height: 1.3;
        margin-bottom: auto;

        & span {
            font-size: 12px;
            line-height: 1.2;
            text-decoration-line: line-through;
            font-weight: 400;
            color: var(--gray-color);
            margin-left: 10px;
        }
    }

    &__label {
        top: 0;
        left: 0;
        position: absolute;
    }

    &__labComp {
        position: absolute;
        top: 0;
        left: 0;
        background: #f2f5f7;
        border-radius: 3px;
        overflow: hidden;
        display: flex;
        gap: 8px;
        padding-right: 8px;
        align-items: center;
    }

    &__labCompCh {
        background: var(--red-color);
        border-radius: 3px;
        color: var(--light-color);
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
    }

    &__labCompText {
        font-size: 12px;
        line-height: 1.2;
        color: var(--dark-color);
    }

    &__btn {
        margin-top: 14px;
    }

    &__category {
        font-size: 12px;
        line-height: 1.3;
        margin: 14px 0 6px;
    }
}
