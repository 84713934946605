.vacancies {
   &__page {
      padding-bottom: 60px;
   }
   &__filters {
      margin-bottom: 30px;
   }
   &__wrap {
      border-bottom: 1px solid var(--dark-gray-color3-hover-btn);
   }
   &__faqBtn {
      padding-top: 30px;
      padding-bottom: 30px;
      @media (max-width: 1025px) {
         padding-top: 25px;
         padding-bottom: 25px;
      }
      @media (max-width: 769px) {
         flex-wrap: wrap;
         padding-top: 20px;
         padding-bottom: 20px;
         gap: 7px 20px;
         align-items: flex-start;
      }
   }
   &__faqName {
      width: 820px;
      font-size: 20px;
      @media (max-width: 1025px) {
         font-size: 18px;
         width: 600px;
         line-height: 1.4;
      }
      @media (max-width: 769px) {
         font-size: 16px;
         order: -2;
         width: calc(100% - 45px);
      }
   }
   &__faqCity {
      width: 180px;
      text-align: right;
      font-size: 14px;
      line-height: 2.1;
      color: var(--gray-color);
      @media (max-width: 1025px) {
      }
      @media (max-width: 769px) {
         order: 2;
         width: calc(40% - 10px);
      }
   }
   &__faqPrice {
      width: 340px;
      font-size: 20px;
      line-height: 1.5;
      text-align: right;
      color: var(--dark-color);
      @media (max-width: 1025px) {
         font-size: 17px;
      }
      @media (max-width: 769px) {
         font-size: 14px;
         order: 1;
         width: calc(60% - 10px);
         font-weight: 700;
         text-align: left;
      }
   }
   & .faq__icon {
      @media (max-width: 769px) {
         order: -1;
      }
   }
   &__faqContentWrap {
      display: flex;
      gap: 20px 90px;
      align-items: flex-start;
      @media (max-width: 769px) {
         flex-direction: column;
      }
   }
   &__faqContent {
      color: var(--dark-color);
      width: calc(100% - 360px);
      @media (max-width: 769px) {
         width: 100%;
      }
   }
   &__faqRespond {
      width: 270px;
   }
}
