.basket {
   &__page {
      padding-bottom: 80px;
      @media (max-width: 769px) {
         padding-bottom: 60px;
      }
      &--bg {
         background-color: #f2f5f7;
      }
   }
   &__thanksWrap {
      display: flex;
      gap: 30px 20px;
      align-items: flex-start;
      @media (max-width: 769px) {
         flex-direction: column;
      }
   }
   &__thanksTitle {
      font-weight: 700;
      font-size: 18px;
      line-height: 2.2;
      margin: 0 0 20px;
      @media (max-width: 1025px) {
         line-height: 1.3;
      }
   }
   &__thanksBack {
      margin-top: 32px;
      width: 100%;
      max-width: 340px;
      padding: 20.5px;
      font-size: 16px;
      &--mobHidden {
         @media (max-width: 769px) {
            display: none;
         }
      }
      &--mobOnly {
         display: none;
         @media (max-width: 769px) {
            display: flex;
            margin-top: 0;
         }
      }
   }
   &__thanksContent {
      width: calc(100% - 480px);
      @media (max-width: 1441px) {
         width: calc(100% - 400px);
      }
      @media (max-width: 1025px) {
         width: calc(100% - 370px);
      }
      @media (max-width: 769px) {
         width: 100%;
      }
   }
   &__thanksDop {
      background: #f2f5f7;
      border-radius: 9px;
      padding: 20px;
      width: 460px;
      display: flex;
      flex-direction: column;
      gap: 25px;
      @media (max-width: 1441px) {
         width: 380px;
      }
      @media (max-width: 1025px) {
         width: 350px;
      }
      @media (max-width: 769px) {
         width: 100%;
      }
   }
   &__thanksDopNumber {
      font-weight: 700;
      font-size: 20px;
      line-height: 1.5;
   }
   &__thanksDopTotal {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      align-items: flex-end;
   }
   &__thanksDopTotalText {
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
   }
   &__thanksDopTotalSum {
      font-weight: 700;
      font-size: 22px;
      line-height: 1.3;
      text-align: right;
   }
   &__thanksDopBr {
      display: block;
      width: 100%;
      height: 1px;
      background: #e2e8ec;
   }
   &__thanksDopProd {
      display: flex;
      flex-direction: column;
      gap: 6px;
   }
   &__thanksDopName {
      transition: all 0.4s;
      font-size: 14px;
      line-height: 1.15;
      color: var(--dark-color);
      &:hover {
         color: var(--dark-color-hover-link);
      }
   }
   &__thanksDopDescr {
      font-size: 14px;
      line-height: 1.4;
      color: #97a5ad;
   }
   &__slider {
      background: #ffffff;
      border-radius: 9px;
      padding: 20px;
      @media (max-width: 769px) {
         display: none;
      }
   }
   &__empty {
       margin-bottom: 25px;
       line-height:1.5;
       font-size:14px;
       > a {
            margin-top: 15px;
       }
   }
   &__prodDop {
      display: flex;
      gap: 10px 20px;
      align-items: flex-start;
      margin-bottom: 25px;
      justify-content: space-between;
      @media (max-width: 769px) {
         flex-direction: column;
         & .basket__promo--monOnlyWrap {
            margin-bottom: 25px;
         }
         & .basketForm__paymentBtn {
            margin-top: 25px;
         }
         & .clue {
            margin-top: 10px;
            margin-bottom: 0;
         }
         & .basketForm__prom {
            margin-top: 10px;
         }
      }
      & .productCard__btn {
         opacity: 1;
      }
   }
   &__prodDopPaymentInfo {
      position: sticky;
      top: 110px;
      width: 380px;
      @media (max-width: 1441px) {
         top: 100px;
      }
      @media (max-width: 1201px) {
         top: 80px;
      }
      @media (max-width: 1025px) {
         width: 330px;
      }
      @media (max-width: 769px) {
         width: 100%;
         gap: 0 !important;
         position: relative;
         top: initial;
      }
   }
   &__promo {
       &Wrap {
           margin-bottom: 10px;
           &--monHidden {
               @media (max-width: 769px) {
                   display: none;
               }
           }
       }
      display: block;
      width: 100%;
      display: flex;
      gap: 20px;
      justify-content: space-between;
      &--monOnlyWrap {
         display: none;
         @media (max-width: 769px) {
            display: block;
         }
      }
      & input {
         border: 1px solid #e2e8ec;
         border-radius: 5px;
         font-weight: 400;
         font-size: 14px;
         line-height: 150%;
         color: var(--dark-color);
         padding: 10px 18px;
         outline: none;
         width: calc(100% - 114px);
         &::placeholder {
            color: #b8c0c5;
         }
      }
      &--alert{
        margin-top:10px;
      }
   }
   &__promoBtn {
   }
   &__prodCardList {
      width: calc(100% - 400px);
      display: flex;
      flex-direction: column;
      gap: 10px;
      @media (max-width: 1025px) {
         width: calc(100% - 350px);
      }
      @media (max-width: 769px) {
         width: 100%;
      }
      &--full {
         width: 100%;
      }
   }
}

.basketForm {
   display: flex;
   gap: 20px;
   @media (max-width: 769px) {
      flex-direction: column;
   }
   &__main {
      width: calc(100% - 400px);
      display: flex;
      flex-direction: column;
      gap: 20px;
      @media (max-width: 1025px) {
         width: calc(100% - 350px);
      }
      @media (max-width: 769px) {
         width: 100%;
      }
   }
   &__dop {
      display: flex;
      width: 380px;
      flex-direction: column;
      gap: 20px;
      height: fit-content;
      position: sticky;
      top: 110px;
      @media (max-width: 1441px) {
         top: 100px;
      }
      @media (max-width: 1201px) {
         top: 80px;
      }
      @media (max-width: 1025px) {
         width: 330px;
      }
      @media (max-width: 769px) {
         width: 100%;
         position: relative;
         top: initial;
         flex-direction: column-reverse;
      }
   }
   &__payment {
      background: #ffffff;
      border-radius: 9px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 25px;
      @media (max-width: 769px) {
         flex-direction: column-reverse;
         gap: 0;
      }
   }
   &__paymentBtns {
      display: flex;
      flex-direction: column;
      gap: 10px;
      @media (max-width: 769px) {
         margin-top: 25px;
         &--personal {
            margin-top: 0;
         }
      }
   }
   &__paymentInfo {
      display: flex;
      flex-direction: column;
      gap: 20px;
   }
   &__paymentPrice {
      display: flex;
      gap: 20px;
      align-items: flex-end;
      justify-content: space-between;
   }
   &__paymentPriceText {
      font-weight: 700;
      font-size: 16px;
      line-height: 1.5;
      color: var(--dark-color);
   }
   &__paymentPriceTotal {
      font-weight: 700;
      font-size: 22px;
      line-height: 1.3;
      text-align: right;
      color: var(--dark-color);
   }
   &__paymentBr {
      width: 100%;
      display: block;
      height: 1px;
      background: #e2e8ec;
   }
   &__paymentDescr {
      display: flex;
      flex-direction: column;
      gap: 10px;
   }
   &__paymentDescrCard {
      display: flex;
      justify-content: space-between;
      align-items: center;
   }
   &__paymentDescrText1 {
      font-size: 14px;
      line-height: 1.5;
   }
   &__paymentDescrText2 {
      font-size: 14px;
      line-height: 1.3;
      display: flex;
      align-items: center;
      gap: 5px;
      &--clue {
         position: relative;
         padding: 0;
         cursor: pointer;
         background-color: transparent;
      }
   }
   &__dopServ {
      background: #ffffff;
      border-radius: 9px;
      padding: 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
   }
   &__dopServTitle {
      margin: 0;
      font-weight: 700;
      font-size: 20px;
      line-height: 1.5;
      @media (max-width: 769px) {
         font-size: 18px;
      }
   }
   &__textInfo {
      color: var(--gray-color);
      font-size: 13px;
      line-height: 1.2;
      &--mobHidden {
         @media (max-width: 769px) {
            display: none;
         }
      }
      &--mobOnly {
         display: none;
         @media (max-width: 769px) {
            display: block;
         }
      }
      & a {
         color: #97a5ad;
         transition: all 0.4s;
         &:hover {
            color: var(--red-color);
         }
      }
   }
   &__payMeth {
      background: #ffffff;
      border-radius: 9px;
      padding: 20px;
       &--error {
           position: relative;
           top:-20px;
           font-weight: 400;
           font-size: 11px;
           line-height: 1.3;
           color: var(--red-color);
       }
   }
   &__titleH3 {
      margin: 0 0 20px;
      font-weight: 700;
      font-size: 20px;
      line-height: 1.5;
      @media (max-width: 769px) {
         font-size: 18px;
      }
   }
   &__payMethWrap {
      display: flex;
      gap: 20px;
      flex-direction: column;
   }
   &__payMethWrap2 {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      & > * {
         width: calc(50% - 10px);
         @media (max-width: 1025px) {
            width: 100%;
         }
      }
   }
   &__data {
      padding: 20px;
      background: #ffffff;
      border-radius: 9px;
   }
   &__dataRadio {
      display: flex;
      gap: 12px 20px;
      flex-wrap: wrap;
      margin-bottom: 20px;
   }
   &__dataBlok {
      display: none;
      &--active {
         display: block;
      }
   }
   &__dataContentCommon{
       display: flex;
       flex-direction: column;
       gap: 10px;
       margin-top:10px;
   }
   &__dataWrap {
      display: flex;
      flex-direction: column;
      gap: 10px;
      &2 {
         display: flex;
         flex-wrap: wrap;
         gap: 10px 20px;
         & > * {
            width: calc(50% - 10px);
            @media (max-width: 1025px) {
               width: 100%;
            }
         }
      }
      &3 {
         display: flex;
         flex-wrap: wrap;
         gap: 10px 20px;
         & > * {
            width: calc(100% / 3 - 40px / 3);
            @media (max-width: 1025px) {
               &:first-child {
                  width: 100%;
               }
               width: calc(50% - 10px);
            }
         }
      }
   }
   &__receiving {
      padding: 20px;
      background: #ffffff;
      border-radius: 9px;
   }
   &__dataWrapTextarea {
      height: 95px;
   }
   &__dataWarning {
      display: flex;
      gap: 10px;
      align-items: center;
      background: #f2f5f7;
      border-radius: 9px;
      padding: 15px;
      font-size: 12px;
      line-height: 1.3;
      &Icon {
         font-size: 24px;
         color: var(--red-color);
      }
   }

   &__dataBlokMb {
      margin-bottom: 20px;
   }
   &__dataEditAddr {
      position: absolute;
      top: 50%;
      right: 20px;
      font-size: 24px;
      color: var(--dark-gray-color3-hover-btn);
      transform: translateY(-50%);
      transition: all 0.4s;
      border: none;
      background-color: transparent;
      margin: 0;
      padding: 0;
      cursor: pointer;
      &:hover {
         color: var(--red-color);
      }
   }
   & .buyers__optType {
      @media (max-width: 769px) {
         width: 60px;
         min-width: 60px;
      }
   }
   &__clue {
      @media (max-width: 769px) {
         order: -2;
         margin: -15px 0;
      }
   }
   &__prom {
      @media (max-width: 769px) {
         order: -3;
      }
   }
}

.basketProdCard {
   background: #ffffff;
   border-radius: 9px;
   padding: 20px;
   width: 100%;
   display: flex;
   gap: 20px;
   align-items: flex-start;
   position: relative;
   @media (max-width: 1025px) {
      padding: 12px;
   }
   &--modal {
      padding: 0;
      justify-content: space-between;
   }
   &__img {
      display: block;
      width: 100px;
      height: 100px;
      @media (max-width: 1025px) {
         width: 80px;
      }
      & img {
         display: block;
         width: 100%;
         height: 100%;
         object-fit: contain;
      }
      &--min {
         width: 80px;
         height: 80px;
      }
   }
   &__wrap {
      display: flex;
      justify-content: space-between;
      width: calc(100% - 120px);
      gap: 20px;
      @media (max-width: 1025px) {
         width: calc(100% - 100px);
         flex-direction: column;
         gap: 12px;
      }
      &--modal {
         width: calc(100% - 100px);
         @media (max-width: 1025px) {
            gap: 20px;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            & .basketProdCard__price {
               width: fit-content;
               order: 1;
               justify-content: flex-end;
            }
            & .basketProdCard__priceBonus {
               justify-content: flex-end;
            }
            & .basketProdCard__quantity {
               width: fit-content;
               margin-left: -100px;
            }
         }
      }
   }
   &__price {
      width: 120px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 7px;
      @media (max-width: 1025px) {
         width: 100%;
         flex-wrap: wrap;
         flex-direction: row;
         gap: 4px 7px;
         margin-bottom: 8px;
      }
      &Main {
         font-weight: 700;
         font-size: 20px;
         line-height: 130%;
      }
      &Sale {
         font-size: 15px;
         line-height: 1.2;
         text-align: right;
         text-decoration-line: line-through;
         color: var(--gray-color);
      }
      &Bonus {
         display: flex;
         gap: 5px;
         align-items: center;
         font-size: 15px;
         line-height: 1.2;
         position: relative;
         justify-content: flex-start;
         cursor: pointer;
         padding: 0;
         background-color: transparent;
         &Icon {
            font-size: 14px;
            color: var(--red-color);
         }
         @media (max-width: 1025px) {
            width: 100%;
         }
      }
   }
   &__mainInfo {
      width: calc(100% - 300px);
      @media (max-width: 1025px) {
         width: 100%;
      }
   }
   &__outOfStock {
       min-height: 54px;
       display: flex;
       align-items: center;
       margin-left: auto;
       font-size: 16px;
       font-weight: 700;
       color: var(--red-color);
   }
   &__cod {
      font-size: 12px;
      line-height: 1.4;
      color: var(--gray-color);
      margin-bottom: 8px;
   }
   &__name {
      font-size: 14px;
      line-height: 1.3;
      color: var(--dark-color);
      display: inline-block;
      margin-bottom: 8px;
      transition: all 0.4s;
      &:hover {
         color: var(--dark-color-hover-link);
      }
      @media (max-width: 1025px) {
         font-size: 13px;
      }
   }
   &__descr {
      font-size: 12px;
      line-height: 1.4;
      color: var(--gray-color);
   }
   &__mainInfoBtns {
      display: flex;
      gap: 20px;
      align-items: center;
      margin-top: 20px;
      @media (max-width: 1025px) {
         margin: 0;
         position: absolute;
         left: 20px;
         bottom: 22px;
      }
   }
   &__delete {
      display: flex;
      gap: 5px;
      align-items: center;
      font-size: 13px;
      line-height: 1.2;
      color: var(--gray-color);
      &Icon {
         font-size: 24px;
         transition: all 0.4s;
         @media (max-width: 1025px) {
            font-size: 32px;
         }
      }
      &:hover {
         & .basketProdCard__deleteIcon {
            color: #97a5ad;
         }
      }
      @media (max-width: 1025px) {
         & span {
            display: none;
         }
      }
   }
   &__quantity {
      @media (max-width: 1025px) {
         width: 100%;
         display: flex;
         justify-content: flex-end;
         order: 1;
      }
   }
}
#cart_page_wrap{
    [data-step] {
        display:none;
    }
    [data-step='1'] {
        display:block;
    }
}
