.stock {
   &__page {
      padding-bottom: 60px;
      @media (max-width: 769px) {
         padding-bottom: 40px;
      }
   }
   &__wrap {
      display: flex;
      gap: 30px;
   }
   &__left {
      width: 160px;
      @media (max-width: 1025px) {
         width: 130px;
      }
      @media (max-width: 769px) {
         display: none;
      }
   }
   &__content {
      max-width: 940px;
      width: calc(100% - 190px);
      @media (max-width: 1441px) {
         max-width: 780px;
      }
      @media (max-width: 1025px) {
         width: calc(100% - 160px);
      }
      @media (max-width: 769px) {
         width: 100%;
      }
   }
   &__back {
      margin-left: 15px;
      @media (max-width: 1201px) {
         margin-left: 0;
      }
      @media (max-width: 1025px) {
         width: 100%;
      }
      &--mob {
         display: none;
         @media (max-width: 769px) {
            display: flex;
         }
      }
   }
   &__backIcon {
      font-size: 24px;
      @media (max-width: 769px) {
         font-size: 20px;
      }
   }
   &__imgBanner {
      display: block;
      width: 100%;
      border-radius: 5px;
      margin: 43px 0;
      @media (max-width: 1441px) {
         margin: 38px 0;
      }
      @media (max-width: 769px) {
         margin: 20px 0;
      }
   }
   &__dopInfo {
      margin: 43px 0;
      @media (max-width: 1441px) {
         margin: 38px 0;
      }
      @media (max-width: 769px) {
         margin: 20px 0;
      }
   }
   &__titleH2 {
      font-weight: 700;
      font-size: 24px;
      line-height: 1.25;
      color: var(--dark-color);
      margin-bottom: 43px;
      @media (max-width: 1441px) {
         margin-bottom: 38px;
      }
      @media (max-width: 769px) {
         margin-bottom: 20px;
         font-size: 20px;
      }
   }
   &__dopInfoWrap {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      align-items: flex-start;
      @media (max-width: 1025px) {
         align-items: initial;
      }
   }
   &__dopInfoCard {
      background: #f2f5f7;
      border-radius: 9px;
      padding: 15px;
      width: calc(20% - 80px / 5);
      display: flex;
      flex-direction: column;
      gap: 20px;
      @media (max-width: 1025px) {
         width: initial;
         flex: 1 1 calc(100% / 3 - 40px / 3);
      }
      @media (max-width: 769px) {
         flex: 1 1 calc(50% - 10px);
      }
   }
   &__dopInfoName {
      font-weight: 700;
      font-size: 14px;
      line-height: 1.3;
      color: var(--dark-color);
      margin: 0;
   }
   &__dopInfoText {
      margin: 0;
      font-weight: 400;
      font-size: 12px;
      line-height: 1.3;
      color: var(--dark-color);
   }
}
