.quantity {
   border-radius: 5px;
   overflow: hidden;
   border: 1px solid var(--dark-gray-color3);
   display: flex;
   width: fit-content;
   &--nb {
      border: none;
   }
   &__btn {
      font-size: 16px;
      padding: 12px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.4s;
      background: var(--dark-gray-color3);
      color: var(--dark-color);
      &:hover {
         background: var(--dark-gray-color3-hover-btn);
      }
      @media (max-width: 1025px) {
         font-size: 20px;
         padding: 16px;
      }
      &:disabled {
         background: var(--dark-gray-color3);
         cursor: default;
      }
   }
   &__text {
      width: 60px;
      display: flex;
      gap: 5px;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      line-height: 1.2;
      text-align: center;
      @media (max-width: 1025px) {
         font-size: 20px;
         & div {
            display: none;
         }
      }
      &--min {
         font-size: 13px;
         width: 80px;
         @media (max-width: 1025px) {
            font-size: 13px;
            width: 50px;
            & div {
               display: block;
            }
         }
      }
      &--min2 {
         width: 45px;
      }
      &--width {
         flex: 1 1 auto;
      }
   }
}
