.catalogList {
   margin-bottom: 90px;
   @media (max-width: 1441px) {
      margin-bottom: 55px;
   }
   @media (max-width: 769px) {
      margin-bottom: 50px;
   }
   &__wrap {
      display: flex;
      flex-wrap: wrap;
      gap: 70px 40px;
      @media (max-width: 769px) {
         gap: 50px 30px;
      }
      &--pc {
         @media (max-width: 651px) {
            display: none;
         }
      }
      &--mob {
         display: none;
         @media (max-width: 651px) {
            display: flex;
            gap: 0;
         }
      }
   }
   &__category {
      width: calc(100% / 4 - 120px / 4);
      display: flex;
      gap: 30px;
       @media (max-width: 1441px) {
           width: calc(100% / 3 - 80px / 3);
       }
      @media (max-width: 1025px) {
         width: calc(50% - 20px);
      }
      @media (max-width: 651px) {
         width: 100%;
      }
   }
   &__img {
      display: block;
      width: 100px;
      height: 100px;
      border-radius: 7.35px;
      overflow: hidden;
      @media (max-width: 769px) {
         width: 60px;
         min-width: 60px;
         height: 60px;
      }
      & img {
         display: block;
      }
   }
   &__content {
      width: calc(100% - 130px);
      display: flex;
      flex-direction: column;
      gap: 12px;
      @media (max-width: 769px) {
         gap: 22px;
         width: calc(100% - 90px);
      }
   }
   &__name {
      font-weight: 700;
      font-size: 18px;
      line-height: 1.15;
      color: var(--dark-color);
      transition: all 0.4s;
      &:hover:not(&--no_hover) {
         color: var(--dark-color-hover-link);
      }
      &--min {
         font-weight: 400;
         font-size: 16px;
      }
   }
   &__list {
      display: flex;
      flex-direction: column;
      gap: 12px;
      & a {
         font-weight: 400;
         font-size: 14px;
         display: inline-block;
         width: fit-content;
         line-height: 1.4;
         color: var(--dark-color);
         transition: all 0.4s;
         &:hover {
            color: var(--dark-color-hover-link);
         }
      }
      & > *:nth-child(n + 8) {
         display: none;
      }
      &--active {
         & > *:nth-child(n + 8) {
            display: inline;
         }
      }
   }
   &__more {
      display: block;
      width: fit-content;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.8;
      color: var(--blue-color);
      transition: all 0.4s;
      &:hover {
         color: var(--blue-color-hover-link);
      }
   }
   &__categoryMob {
      border-bottom: 1px solid var(--gray-color);
      width: 100%;
   }
   &__categoryWrap {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
      padding: 5px 0;
      &--active {
         & .catalogList__arrow {
            transform: rotate(180deg);
         }
      }
   }
   &__arrow {
      font-size: 24px;
      color: var(--gray-color);
      margin-left: auto;
      transition: all 0.4s;
   }
   &__categorContent {
      display: none;
      padding: 0 0 20px 80px;
      &--active {
         display: block;
      }
      & .catalogList__more {
         margin-top: 10px;
      }
   }
}
