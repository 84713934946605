.brend {
   &__page {
      margin-bottom: 100px;
      @media (max-width: 769px) {
         margin-bottom: 75px;
      }
   }
   &__sliderMain {
      margin-bottom: 50px;
      @media (max-width: 769px) {
         margin-bottom: 40px;
      }
   }
   &__infoBlock {
      display: flex;
      gap: 40px;
      align-items: flex-start;
      margin-bottom: 40px;
      @media (max-width: 769px) {
         flex-direction: column;
         align-items: center;
         margin-bottom: 10px;
      }
   }
   &__infoImg {
      display: block;
      width: 220px;
      height: auto;
   }
   &__infoBlockText {
      width: calc(100% - 260px);
      @media (max-width: 769px) {
         width: 100%;
      }
   }
   &__tabs {
      display: flex;
      gap: 30px;
      width: 100%;
      border-bottom: 2px solid #f2f5f7;
      margin-bottom: 40px;
      @media (max-width: 769px) {
         border-width: 1px;
         gap: 0;
         margin-bottom: 25px;
      }
   }
   &__tab {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 24px;
      line-height: 1;
      border-bottom: 2px solid transparent;
      transition: all 0.4s;
      padding: 8px 0 18px;
      margin-bottom: -2px;
      color: var(--dark-color);
      @media (max-width: 769px) {
         font-size: 20px;
         padding: 8px 0;
         margin-bottom: -1px;
         width: 50%;
      }
      &:hover {
         border-bottom: 2px solid #ed0000;
      }
      &--active {
         border-bottom: 2px solid #ed0000;
         font-weight: 700;
      }
   }
   &__block {
      margin-bottom: 60px;
      @media (max-width: 769px) {
         margin-bottom: 50px;
      }
   }
}

.sliderBrend {
   overflow: hidden;
   &__section {
      margin-bottom: 30px;
      @media (max-width: 769px) {
         margin-bottom: 20px;
      }
   }
   &__slide {
      display: block;
      width: 980px;
      border-radius: 10px;
      overflow: hidden;
      @media (max-width: 1201px) {
         width: 880px;
      }
      @media (max-width: 1025px) {
         width: 100%;
         border-radius: 0;
      }
      & img {
         display: block;
         object-fit: cover;
         width: 100%;
      }
   }
}

.sliderProdMin {
   &__slide {
      width: 160px;
      padding: 0;
      border: none;
       height:auto;
      @media (max-width: 769px) {
         width: 120px;
         & .productCard__item4 {
            display: none;
         }
         & .productCard__item3 {
            margin-bottom: 0;
         }
      }
      &::after {
         content: none;
      }
   }
   & ~ .swiper-button-prev-custom {
      left: 0;
   }
   & ~ .swiper-button-next-custom {
      right: 0;
   }
}
