.seoText {
   &__section {
      margin: 80px 0;
      @media (max-width: 1441px) {
         margin: 60px 0;
      }
      @media (max-width: 1201px) {
         margin: 45px 0;
      }
      @media (max-width: 769px) {
         margin: 30px 0;
      }
   }
   &__text {
      max-width: 1180px;
   }
}
