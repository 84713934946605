.title {
   margin: 0;
   color: var(--dark-color);
   display: flex;
   align-items: center;
   justify-content: space-between;
   &--mobHid {
      @media (max-width: 769px) {
         display: none;
      }
   }
   &--hid {
      display: none;
      @media (max-width: 769px) {
         display: flex;
      }
   }
   &--mb {
      margin-bottom: 45px;
      @media (max-width: 1201px) {
         margin-bottom: 40px;
      }
      @media (max-width: 769px) {
         margin-bottom: 30px;
      }
   }
   &--mbMin {
      margin-bottom: 30px;
   }
   &--mbMin2 {
      margin-bottom: 25px;
      @media (max-width: 769px) {
         margin-bottom: 20px;
      }
   }
   &--mbMin3 {
      margin-bottom: 25px;
      @media (max-width: 769px) {
         margin-bottom: 8px;
      }
   }
   &--mbMin4 {
      margin-bottom: 18px;
      @media (max-width: 769px) {
         margin-bottom: 12px;
      }
   }
   &--mbMin5 {
      margin-bottom: 40px;
      @media (max-width: 1025px) {
         margin-bottom: 25px;
      }
      @media (max-width: 769px) {
         margin-bottom: 15px;
      }
   }
   &--mbMin6 {
      margin-bottom: 28px;
      @media (max-width: 769px) {
         margin-bottom: 20px;
      }
   }
   &--mbMin7 {
      margin-bottom: 20px;
      @media (max-width: 769px) {
         margin-bottom: 15px;
      }
   }
   &--mbMin8 {
      margin-bottom: 35px;
      @media (max-width: 769px) {
         margin-bottom: 20px;
      }
   }
   &--mbMax {
      margin-bottom: 50px;
      @media (max-width: 1201px) {
         margin-bottom: 40px;
      }
      @media (max-width: 769px) {
         margin-bottom: 30px;
      }
   }
   &--mbMax2 {
      margin-bottom: 59px;
      @media (max-width: 769px) {
         margin-bottom: 30px;
      }
   }
   &--mbMax3 {
      margin-bottom: 54px;
      @media (max-width: 769px) {
         margin-bottom: 19px;
      }
   }
   &--maAdaptiveCentered {
      @media (max-width: 769px) {
         text-align: center;
         justify-content: center;
      }
   }
   &__name {
      font-size: 32px;
      margin: 0;
      line-height: 1.25;
      @media (max-width: 1201px) {
         font-size: 26px;
      }
      @media (max-width: 769px) {
         font-size: 20px;
      }
      &--h1 {
         @media (max-width: 1201px) {
            font-size: 28px;
         }
         @media (max-width: 769px) {
            font-size: 24px;
         }
      }
      &--min {
         font-size: 24px;
      }
      &--minAdaptive {
         font-size: 24px;
         @media (max-width: 769px) {
            font-size: 20px;
         }
      }
   }
   &__link {
      padding: 15px 40px;
      font-size: 14px;
      line-height: 1.14;
      @media (max-width: 1201px) {
         font-size: 13px;
         padding: 13px 25px;
      }
      @media (max-width: 769px) {
         font-size: 12px;
         padding: 10px;
      }
   }
   &__linkBack {
      display: flex;
      gap: 10px;
      align-items: center;
      font-size: 13px;
      line-height: 1.2;
      color: var(--gray-color);
      transition: all 0.4s;
      @media (max-width: 769px) {
         display: none;
      }
      &:hover {
         color: var(--gray-color-hover-link);
      }
      &Icon {
         font-size: 24px;
      }
   }
}
