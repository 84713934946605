.productCards {
   display: flex;
   flex-wrap: wrap;
   z-index: 1;
   &__card {
      width: 25%;
      @media (max-width: 1441px) {
         width: calc(100% / 3);
      }
      @media (max-width: 1025px) {
         width: 50%;
      }
      @media (max-width: 769px) {
         width: calc(100% / 3);
      }
      @media (max-width: 601px) {
         width: 50%;
      }
   }
   &--line {
      & .productCards__card {
         width: 100%;
      }
      & .productCard {
         display: grid;
         gap: 0 30px;
         padding-left: 0;
         padding-right: 0;
         grid-template-columns: 210px auto 230px;
         grid-template-rows: auto 1fr;
         grid-template-areas:
            "a b c"
            "a b d";
         @media (max-width: 1025px) {
            gap: 0 20px;
            grid-template-columns: 170px auto 160px;
         }
         @media (max-width: 769px) {
            padding-left: 12px;
            padding-right: 12px;
         }
         @media (max-width: 601px) {
            grid-template-columns: 120px auto;
            grid-template-rows: auto auto auto;
            grid-template-areas:
               "a b"
               "a c"
               "a d";
         }
         &::after {
            border-left: none;
            border-right: none;
         }
         &__item1 {
            grid-area: a;
            margin-bottom: 0;
         }
         &__item2 {
            grid-area: c;
         }
         &__item3 {
            grid-area: b;
            margin-bottom: 0;
            @media (max-width: 1025px) {
               gap: 6px;
            }
         }
         &__item4 {
            grid-area: d;
            gap: 20px;
            margin-top: 0;
            @media (max-width: 1025px) {
               gap: 8px;
            }
            @media (max-width: 601px) {
               align-items: flex-start;
            }
         }
         &__basketInfo {
            flex-direction: column;
            gap: 10px;
         }
         &__info {
            flex-direction: row;
            gap: 2px;
         }
         &__characteristics {
            display: flex;
            flex-direction: column;
            gap: 6px;
         }
         &__characteristic {
            font-size: 14px;
            line-height: 1.2;
            color: var(--gray-color);
            & span {
               color: var(--dark-color);
            }
         }
         &__basket {
            width: 100%;
         }
         &__promo {
            width: 100%;
         }
         &__imgWrap {
            @media (max-width: 1025px) {
               margin-bottom: 10px;
            }
         }
         &__tegs {
            @media (max-width: 1025px) {
               top: 0;
               left: 0;
            }
            @media (max-width: 601px) {
               display: none;
            }
         }
         &__characteristics {
            @media (max-width: 601px) {
               display: none;
            }
         }
         &__tegs {
            @media (max-width: 1025px) {
               font-size: 10px;
            }
         }
         &__videoCountry {
            @media (max-width: 1025px) {
               bottom: 0;
               left: 0;
               flex-direction: row-reverse;
               justify-content: space-between;
               width: 100%;
            }
         }
         &__country {
            @media (max-width: 1025px) {
               font-size: 10px;
            }
         }
         &__name {
            @media (max-width: 1025px) {
               font-size: 13px;
            }
         }
         & .rating__star {
            @media (max-width: 1025px) {
               font-size: 12.6px;
            }
         }
         &__rating {
            @media (max-width: 1025px) {
               gap: 6px;
            }
            @media (max-width: 601px) {
               margin-bottom: 6px;
            }
         }
         &__reviews {
            @media (max-width: 1025px) {
               font-size: 11px;
            }
         }
         &__characteristic {
            @media (max-width: 1025px) {
               font-size: 11px;
            }
         }
         &__price {
            @media (max-width: 1025px) {
               font-size: 16px;
               width: 100%;
            }
         }
         &__bonus {
            @media (max-width: 1025px) {
               font-size: 11px;
               padding: 0;
               border-radius: 0;
               background-color: transparent;
               order: 1;
               width: fit-content;
            }
            @media (max-width: 601px) {
               width: fit-content;
            }
            &Icon {
               @media (max-width: 1025px) {
                  font-size: 14px;
               }
            }
         }
         &__priceBlock {
            @media (max-width: 1025px) {
               gap: 4px;
               align-items: center;
            }
         }
         &__saleText {
            @media (max-width: 1025px) {
               display: none;
            }
         }
         &__sale {
            @media (max-width: 1025px) {
               &Sum {
                  font-size: 11px;
               }
               &Percent {
                  font-size: 11px;
               }
            }
         }
         &__promo {
            @media (max-width: 1025px) {
               font-size: 11px;
               width: fit-content;
               &MobHidden {
                  display: none;
               }
            }
         }
         &__status {
            @media (max-width: 1025px) {
               font-size: 11px;
               font-weight: 400;
            }
         }
         &__basket {
            @media (max-width: 1025px) {
               padding: 8.5px;
            }
            @media (max-width: 601px) {
               width: fit-content;
               padding-left: 24px;
               padding-right: 24px;
               order: 1;
            }
         }
         &__info {
            @media (max-width: 1025px) {
               font-size: 11px;
               color: var(--dark-color);
            }
         }
         &__imgTabs {
            @media (max-width: 601px) {
               height: initial;
            }
         }
         &__imgTab {
            @media (max-width: 601px) {
               display: block;
               width: 8px;
               height: 8px;
               flex-basis: initial;
               flex-shrink: 0;
               flex-grow: 0;
               border-radius: 50%;
            }
         }
         &__btns {
            @media (max-width: 601px) {
               left: calc(100vw - 24px);
               right: initial;
               transform: translateX(-100%);
               top: initial;
               bottom: 0;
               flex-direction: row;
            }
         }
         &__info {
            @media (max-width: 601px) {
               margin-bottom: 8px;
            }
         }
         &__basketInfo {
            @media (max-width: 601px) {
               align-items: flex-start;
            }
         }
      }
   }
}

.productCard {
   padding: 20px;
   position: relative;
   display: flex;
   flex-direction: column;
   @media (max-width: 769px) {
      padding: 12px;
   }
   &:hover {
      & .productCard__imgTabs {
         opacity: 1;
      }
      & .productCard__btn {
         opacity: 1;
      }
      & .productCard__btns--fav {
         & > * {
            opacity: 1 !important;
         }
      }
   }
   &::after {
      content: "";
      display: block;
      position: absolute;
      top: -0.5px;
      left: -0.5px;
      width: calc(100% + 1px);
      height: calc(100% + 1px);
      border: 1px solid var(--dark-gray-color3-hover-btn);
      pointer-events: none;
   }
   &__item1 {
      position: relative;
      margin-bottom: 15px;
      @media (max-width: 769px) {
         margin-bottom: 10px;
      }
   }
   &__item2 {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-bottom: 12px;
      @media (max-width: 769px) {
         gap: 6px;
      }
   }
   &__item3 {
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      @media (max-width: 769px) {
         margin-bottom: 12.5px;
         gap: 8.5px;
      }
      &--mbMin {
         margin-bottom: 12px;
      }
   }
   &__item4 {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: auto;
      @media (max-width: 769px) {
         gap: 6px;
      }
   }
   &__imgLink {
      display: block;
      width: 100%;
      overflow: hidden;
      border-radius: 5px;
      & img {
         display: block;
         aspect-ratio: 1;
         margin: 0 auto;
      }
   }
   &__imgTabs {
      display: flex;
      gap: 4px;
      height: 2px;
      opacity: 0;
      transition: all 0.4s;
      @media (max-width: 769px) {
         opacity: 1;
      }
   }
   &__imgTab {
      flex: 1 1 auto;
      height: 2px;
      background-color: var(--dark-gray-color3-hover-btn);
      transition: all 0.4s;
      &--active {
         background-color: var(--red-color);
      }
   }
   &__imgWrap {
      position: relative;
      margin-bottom: 5px;
   }
   &__tegs {
      display: flex;
      flex-direction: column;
      gap: 3px;
      position: absolute;
      top: 4px;
      left: 4px;
       z-index: 1;
      @media (max-width: 769px) {
         top: 0;
         left: 0;
         gap: 2px;
      }
   }
   &__teg {
      font-size: 12px;
      line-height: 1.2;
      color: #ffffff;
      background: var(--red-color-hover-link);
      border-radius: 3px;
      padding: 3px 8px;
      width: fit-content;
      @media (max-width: 769px) {
         font-size: 10px;
      }
   }
   &__videoCountry {
      position: absolute;
      bottom: 2px;
      left: 2px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;
      @media (max-width: 769px) {
         width: 100%;
         justify-content: space-between;
         flex-direction: row-reverse;
         bottom: 0;
         left: 0;
         align-items: center;
      }
   }
   &__video {
      font-size: 24px;
      color: var(--gray-color);
      transition: all 0.4s;
      &:hover {
         color: var(--gray-color-hover-link);
      }
   }
   &__country {
      background: var(--dark-gray-color3);
      border-radius: 3px;
      padding: 3px 8px;
      display: flex;
      align-items: center;
      gap: 7px;
      font-size: 12px;
      line-height: 1.2;
      color: var(--dark-color);
      @media (max-width: 769px) {
         padding: 3px 6px;
         font-size: 10px;
      }
      & img {
         display: block;
         width: auto;
         height: 12px;
      }
   }
   &__btns {
      display: flex;
      flex-direction: column;
      gap: 2px;
      position: absolute;
      z-index: 5;
      top: 4px;
      right: 4px;
      transition: all 0.4s;
      @media (max-width: 769px) {
         top: 0;
         right: 0;
      }
      &--fav {
         opacity: 1;
         & > *:not(:first-child) {
            opacity: 0;
            transition: opacity 0.4s;
         }
      }
   }
   &__btn {
      background: #ffffff;
      border-radius: 5px;
      padding: 3px;
      display: block;
      position: relative;
      opacity: 0;
      transition: all 0.4s 0s;
      @media (max-width: 769px) {
         opacity: 1;
      }
      &Vis {
         @media (max-width: 769px) {
            display: none;
         }
      }
      & > * {
         // transition: all 0.4s;
         display: block;
         font-size: 24px;
      }
      & .iconActive {
         opacity: 0;
         position: absolute;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
         // transition: all 0.4s 0s;
      }
      color: var(--gray-color);
      &:hover {
         color: #97a5ad;
      }
      &--active {
         color: var(--red-color) !important;
         opacity: 10;
         & .iconActive {
            opacity: 1;
         }
      }
      &Like--textLike {
         margin-right: 85px;
         @media (max-width: 1025px) {
            margin-right: 0;
            & > * {
               font-size: 32px;
            }
         }
         &:after {
            content: "В избранное";
            position: absolute;
            top: 50%;
            width: 80px;
            right: 0;
            font-size: 13px;
            line-height: 1;
            color: var(--gray-color);
            transform: translateX(calc(100% + 5px)) translateY(-50%);
            @media (max-width: 1025px) {
               content: none;
            }
         }
      }
   }
   &__priceBlock {
      display: flex;
      gap: 12px;
      flex-wrap: wrap;
      @media (max-width: 769px) {
         gap: 4px 6px;
      }
   }
   &__price {
      font-weight: 700;
      font-size: 18px;
      line-height: 1.2;
      color: var(--dark-color);
      @media (max-width: 769px) {
         font-size: 16px;
      }
   }
   &__bonus {
      display: flex;
      position: relative;
      cursor: pointer;
      gap: 5px;
      align-items: center;
      background: #f2f5f7;
      border-radius: 10px 10px 0px 10px;
      padding: 4px 5px 4px 10px;
      font-size: 14px;
      line-height: 1;
      color: var(--dark-color);
      @media (max-width: 769px) {
         order: 1;
         width: 100%;
         padding: 0;
         border-radius: 0;
         font-size: 11px;
         background-color: transparent;
         line-height: 14px;
         justify-content: flex-start;
      }
      &Icon {
         color: var(--red-color);
         display: block;
         @media (max-width: 769px) {
            font-size: 14px;
            display: block;
         }
      }
   }
   &__sale {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 4px 8px;
      @media (max-width: 769px) {
         gap: 4px 6px;
      }
      &Sum {
         font-size: 14px;
         line-height: 1.2;
         text-decoration: line-through;
         color: var(--gray-color);
         margin-right: 4px;
         @media (max-width: 769px) {
            margin-right: 0;
            font-size: 11px;
         }
      }
      &Percent {
         font-weight: 700;
         font-size: 13px;
         line-height: 1.2;
         color: #ffffff;
         padding: 3px 4px 3px 6px;
         background: var(--red-color);
         border-radius: 10px 0px 0px 10px;
         @media (max-width: 769px) {
            font-size: 11px;
         }
      }
      &Text {
         font-size: 14px;
         line-height: 1.2;
         color: var(--gray-color);
         @media (max-width: 769px) {
            display: none;
         }
      }
   }
   &__promo {
      padding: 6px 10px;
      background: var(--dark-gray-color3);
      border: 1px dashed var(--gray-color);
      border-radius: 5px;
      width: fit-content;
      text-align: center;
      font-size: 14px;
      line-height: 1.2;
      @media (max-width: 769px) {
         font-size: 11px;
      }
      & span {
         font-weight: 700;
      }
   }
   &__promoMobHidden {
      @media (max-width: 769px) {
         display: none;
      }
   }
   &__status {
      display: flex;
      gap: 8px;
      align-items: center;
      font-weight: 700;
      font-size: 14px;
      line-height: 1.2;
      color: #37a72e;
      @media (max-width: 769px) {
         gap: 4px;
         font-size: 11px;
         font-weight: 400;
      }
      &Icon {
         font-size: 13px;
         display: block;
         @media (max-width: 769px) {
            font-size: 11px;
         }
      }
      &--zakaz {
         color: #a7352e;
         & .productCard__statusIcon {
            font-size: 16px;
            @media (max-width: 769px) {
               font-size: 13px;
            }
         }
      }
   }
   &__basketInfo {
      display: flex;
      gap: 12px 20px;
      align-items: center;
      flex-wrap: wrap;
      @media (max-width: 769px) {
         gap: 6px;
      }
   }
   &__info {
      font-size: 12px;
      line-height: 1.2;
      display: flex;
      align-items: center;
      color: var(--gray-color);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 2px;
      @media (max-width: 769px) {
         gap: 2px;
         flex-direction: row;
         font-size: 11px;
      }
      & span {
         font-weight: 700;
      }
   }
   &__name {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.3;
      color: var(--dark-color);
      transition: all 0.4s;
      width: fit-content;
      @media (max-width: 769px) {
         font-size: 13px;
      }
      &:hover {
         color: var(--dark-color-hover-link);
      }
   }
   &__rating {
      display: flex;
      align-items: center;
      gap: 12px;
   }
   &__reviews {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.2;
      color: var(--gray-color);
      display: block;
      width: fit-content;
      transition: all 0.4s;
      @media (max-width: 769px) {
         font-size: 11px;
      }
      &:hover {
         color: var(--gray-color-hover-link);
      }
   }
   &__characteristics {
      display: none;
   }
   &__btnInfo {
      padding: 10.5px 15px;
      font-weight: 700;
      font-size: 13px;
      line-height: 1.15;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      color: var(--dark-color);
      background: var(--dark-gray-color3);
      border-radius: 9px;
      transition: all 0.4s;
      width: fit-content;
      &:hover {
         background-color: var(--dark-gray-color3-hover-btn);
      }
   }
   &__btnFav {
      position: relative;
      // &::after {
      //    content: "";
      //    display: block;
      //    border: 7px solid transparent;
      //    border-bottom: 10px solid #fff;
      //    z-index: 30;
      //    position: absolute;
      //    bottom: 0;
      //    left: 50%;
      //    transform: translate(-50%, 10px);
      //    pointer-events: none;
      //    opacity: 0;
      //    transition: all 0.4s;
      // }
      // &.productCard__btnFavRect {
      //    &::after {
      //       @media (max-width: 769px) {
      //          opacity: 1;
      //       }
      //    }
      // }
   }
   &__addBasket {
      display: none;
      background: #fff;
      box-shadow: 0px 0px 20px rgba(151, 165, 173, 0.75);
      border-radius: 9px;
      padding: 30px 20px 17px;
      position: fixed;
      z-index: 1250;
      bottom: 80px;
      left: 50%;
      transform: translateX(-50%);
      width: 300px;
      max-width: 300px;
      opacity: 0;
      pointer-events: none;
      transition: all 0.3s;
      &--active {
         opacity: 1;
         pointer-events: all;
      }
      @media (max-width: 769px) {
         display: block;
      }
      &Title {
         font-weight: 400;
         font-size: 16px;
         line-height: 1.3;
         color: var(--dark-color);
         margin: 0 0 5px;
         text-align: left;
      }
      &Text {
         font-size: 14px;
         line-height: 1.3;
         color: var(--gray-color);
         margin: 0 0 20px;
         text-align: left;
      }
      &Close {
         position: absolute;
         top: 8px;
         right: 10px;
         font-size: 24px;
         transition: all 0.4s;
         color: var(--gray-color);
         &:hover {
            color: var(--red-color-hover-link);
         }
      }
   }
    &__CountryImg {
        width: auto;
        height:12px;
    }
}
