.brends {
   &__page {
      padding-bottom: 80px;
      @media (max-width: 769px) {
         padding-bottom: 60px;
      }
   }
   &__wrapFilterContent {
      display: flex;
      gap: 80px;
      justify-content: space-between;
      @media (max-width: 1201px) {
         gap: 30px;
      }
   }
   &__filters {
      max-width: 280px;
      width: 100%;
      @media (max-width: 1441px) {
         max-width: 220px;
      }
      @media (max-width: 769px) {
         display: none;
      }
   }
   &__content {
      width: calc(100% - 360px);
      @media (max-width: 1441px) {
         width: calc(100% - 300px);
      }
      @media (max-width: 1201px) {
         width: calc(100% - 250px);
      }
      @media (max-width: 769px) {
         width: 100%;
      }
   }
   &__inpLabel {
      margin-bottom: 25px;
      @media (max-width: 769px) {
         margin-bottom: 10px;
      }
   }
   &__letters {
      display: flex;
      flex-direction: column;
      gap: 15px;
      padding: 15px 20px;
      background: var(--dark-gray-color3);
      border-radius: 9px;
      margin-bottom: 25px;
      @media (max-width: 769px) {
         display: none;
      }
      &List {
         display: flex;
         gap: 10px 21px;
         flex-wrap: wrap;
      }
      &Link {
         font-size: 14px;
         line-height: 1.2;
         color: var(--dark-color);
         transition: all 0.4s;
         &:hover,
         &--active {
            color: var(--dark-color-hover-link);
         }
      }
   }
   &__listItem {
      display: flex;
      gap: 20px;
      padding: 20px 0;
      border-bottom: 1px solid #e2e8ec;
      @media (max-width: 769px) {
         flex-direction: column;
         &:last-child {
            border-bottom: none;
            padding-bottom: 0;
         }
      }
      &:first-child {
         padding-top: 0;
      }
   }
   &__listLetter {
      width: 80px;
      font-weight: 700;
      font-size: 16px;
      line-height: 1.4;
      color: var(--red-color);
      @media (max-width: 769px) {
         width: 100%;
      }
   }
   &__list {
      width: calc(100% - 100px);
      margin: 0;
      padding: 0;
      list-style: none;
      column-count: 4;
      column-gap: 20px;
      @media (max-width: 1025px) {
         column-count: 3;
      }
      @media (max-width: 769px) {
         width: 100%;
      }
      @media (max-width: 501px) {
         column-count: 2;
      }
      & a {
         transition: all 0.4s;
         font-weight: 400;
         font-size: 14px;
         line-height: 1.4;
         color: var(--dark-color);
         &:hover {
            color: var(--dark-color-hover-link);
         }
      }
      &Wrap{
          .error {
              line-height: 40px;
              margin: 30px 0;
              display: flex;
              flex-direction: column;
              align-items: center;
          }
      }
   }
   &__filterBob {
      display: none;
      margin: 8px 0;
      @media (max-width: 769px) {
         display: flex;
      }
   }
   &__tabs {
      margin-bottom: 8px;
      display: none;
      @media (max-width: 769px) {
         display: flex;
      }
   }
   &__tab {
      flex-basis: calc(25% - 3px);
   }
}
