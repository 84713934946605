.popular {
   &__section {
      margin: 80px 0;
      @media (max-width: 1441px) {
         margin: 60px 0;
      }
      @media (max-width: 1201px) {
         margin: 45px 0;
      }
      @media (max-width: 769px) {
         margin: 30px 0;
      }
   }
   &__tabsWrap {
      @media (max-width: 1201px) {
         overflow: auto;
         width: calc(100% + var(--container-offset) * 2);
         margin-left: calc(-1 * var(--container-offset));
         margin-right: calc(-1 * var(--container-offset));
         padding-bottom: 10px;
      }
   }
   &__tabs {
      display: flex;
      gap: 20px;
      background: #f2f5f7;
      border-radius: 9px;
      justify-content: center;
      padding: 0 12px;
      @media (max-width: 1201px) {
         border-radius: 0;
         width: fit-content;
         min-width: 100%;
      }
   }
   &__tab {
      transition: all 0.4s;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background-color: transparent;
      border-bottom: 2px solid transparent;
      min-height: 54px;
      cursor: pointer;
      font-size: 16px;
      line-height: 1.2;
      white-space: nowrap;
      color: var(--dark-color);
      &--active {
         cursor: default;
      }
      &:hover {
         border-color: var(--gray-color);
      }
      &--active {
         border-color: var(--red-color) !important;
      }
      @media (max-width: 1201px) {
         font-size: 14px;
         min-height: 50px;
      }
      @media (max-width: 769px) {
         font-size: 13px;
         min-height: 46px;
      }
   }
   &__brands {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      display: none;
      &--active {
         display: flex;
      }
      @media (max-width: 769px) {
         gap: 9px;
      }
   }
   &__brand {
      width: calc(100% / 6 - 100px / 6);
      padding: 30px 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 1441px) {
         padding: 20px 30px;
      }
      @media (max-width: 1025px) {
         padding: 15px 25px;
      }
      @media (max-width: 769px) {
         padding: 10px 20px;
         width: calc(25% - 27px / 4);
      }
      @media (max-width: 501px) {
         padding: 7px 15px;
         width: calc(100% / 3 - 6px);
      }
      & img {
         width: 100%;
         display: block;
         filter: grayscale(1);
         transition: all 0.4s;
      }
      &:hover {
         & img {
            filter: none;
         }
      }
   }
}
